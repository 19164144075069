import React, { useState } from 'react'
import { Avatar, Grid, IconButton, Popper, Fade, Paper, ClickAwayListener, Box, Backdrop } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Logo from '../../assets/Logo 32px.svg'
import { ReactComponent as ThemeModeIcon } from '../../assets/icons/Theme Mode.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg'
import { customToastSystem } from '../../common/customToastify'

import * as actions from '../../redux/actions'
import { useStyles } from './headerStyles'
import { useStyles as layoutStyles } from '../../static/layoutStyles'
import { useHoverActiveStyles } from '../../static/hoverActiveStyles'
import { CustomAppBar, CustomSearchTextField, CustomSvgIcon, CustomSvgIconLabelMedium, CustomSwitchLabelMedium, CustomToolbar, LabelMedium, Paragraph2 } from '../../customMui/customMuiStyles'
import { capitalizeFirstLetter, getfromLocalStorage } from '../../common/functions'
import useSidepanelConfig from '../Sidepanel/config/sidepanelConfig'

const Header = () => {
    const classes = useStyles()
    const classesLayout = layoutStyles()
    const classesHoverActive = useHoverActiveStyles()
    const location = useLocation()
    const { logout } = useAuth0()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const stateLayout = useSelector(state => state.layoutReducer)

    const { borderColor } = useSidepanelConfig()

    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)

    const handleClickOpen = (event) => {
        setAnchorEl(prev => prev ? null : event.currentTarget)
        setOpen(prev => !prev)
    }
    const handleCLickClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }
    const toggleLeftSidepanelMobile = () => dispatch(actions.handleLeftSidepanelMobileToggle())

    const handleLogout = () => {
        handleCLickClose()
        setTimeout(() => {
            logout({ returnTo: window.location.origin })
            dispatch(actions.handleLogout())
        }, 5000)
        customToastSystem('Logout Successful', 'success')
    }

    const handleChangeThemeMode = () => {
        // handleCLickClose()
        dispatch(actions.handleChangeTheme())
    }

    return (
        <CustomAppBar position="fixed" color="default" className={`${classesLayout.appBar} ${classes.headerBorder} ${classesHoverActive[borderColor[location.pathname.match(/^\/[^\/]+/)[0]]]}`}>
            <Backdrop open={open} className={`${classes.backdrop}`} />
            <CustomToolbar>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            className={classesLayout.menuButton}
                            onClick={() => toggleLeftSidepanelMobile()}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={Logo} alt="Sherlock.ai" onClick={() => navigate('/home')} />
                    </div>
                    <ClickAwayListener onClickAway={handleCLickClose}>
                        <Paragraph2 variant='body1'>
                            <IconButton onClick={handleClickOpen}>
                                {/* <Avatar alt="User" src={''} className={!Boolean(anchorEl) ? classes.profileIcon : classes.profileIconActive} /> */}
                                <Avatar alt="User" src={''} className={classesLayout.profileIcon} />
                            </IconButton>
                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                placement="bottom"
                                className={`${classes.popper}`}
                                disablePortal={true}
                                modifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        boundariesElement: 'scrollParent',
                                    },
                                    arrow: {
                                        enabled: true,
                                    },
                                }}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper variant={'elevation'} classes={{ root: classes.profileMenu }}>
                                            <Box className={`${classes.popperArrow}`} />
                                            {
                                                getfromLocalStorage('group').toLowerCase() === 'admin' &&
                                                <Grid container direction="row" justifyContent='space-between' alignItems='center' className={`${classes.profileMenuItem}`}>
                                                    <LabelMedium variant="body1"><CustomSvgIconLabelMedium component={ThemeModeIcon} className={`${classes.svgMarginRight}`} />Theme: {capitalizeFirstLetter(stateLayout.theme)}</LabelMedium>
                                                    <CustomSwitchLabelMedium disableRipple checked={stateLayout.theme !== 'light'} onChange={handleChangeThemeMode} name="theme" />
                                                </Grid>
                                            }
                                            <div className={`${classes.profileMenuItem} ${classesHoverActive.errorButton}`} onClick={handleLogout}>
                                                <LabelMedium variant="body1"><CustomSvgIconLabelMedium component={LogoutIcon} className={`${classes.svgMarginRight}`} />Logout</LabelMedium>
                                            </div>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </Paragraph2>
                    </ClickAwayListener>
                </Grid>
            </CustomToolbar>
        </CustomAppBar>
    );
}

export default Header