import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  TypographySemiBold,
  CustomBody1SemiBoldButton,
  CustomHeading3ButtonSecondary,
  CustomDividerColor,
} from "../../../../customMui/customMuiStyles";
import { styled } from "@material-ui/core";
import { useStyles as bentoStyles } from "./bentoStyles";
import { ReactComponent as Logo } from "../../../../assets/Logo 64px.svg";
import { useStyles as contentStyles } from "../../styles";
import { SingleSelectFilled } from "../../../../customMui/customMuiComponents";
import {
  reportListReverse,
  ratingMarks,
  visitDurationMarks,
  visitTimeMarks,
} from "../../Explore/Config/exploreConfig";
import { districtCodeToDistrictDropdowns } from "../../../../common/functions";
import { useHoverActiveStyles } from "../../../../static/hoverActiveStyles";
import { useStyles } from "../../../../static/layoutStyles";
import { Add, Close } from "@material-ui/icons";
import { operators } from "../Config/chartConfig";
import { customToastSystem } from "../../../../common/customToastify";

function AddFilter(props) {
  const classes = useStyles();
  const bentoClasses = bentoStyles();
  const classesContent = contentStyles();
  const classesHoverActive = useHoverActiveStyles();
  const [complexFilterAnchorEl, setComplexFilterAnchorEl] = useState(null);
  const [anchorCoordinates, setAnchorCoordinates] = useState({});
  const [menuWindow, setMenuWindow] = useState()
  const [expression, setExpression] = useState({})

  const handleClose = () => {
    setComplexFilterAnchorEl(null);
    setAnchorCoordinates({ x: 0, y: 0 });
    setExpression({})
  };

  const handleClick = (event, menuOption) => {
    setMenuWindow(menuOption);
    setComplexFilterAnchorEl(event.currentTarget);
    setAnchorCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleSubmit = () => {
    if (props.metrics.length > 0) {
      props.handleCreateModule()
    } else {
      customToastSystem("Please create or add a saved metrics!", 'error')
    }
  }

  const handleAddBentoExpressions = (e, type) => {
    e.preventDefault()
    console.log(expression, props.columns, props.metrics, props.filters);
    switch (type) {
      case "columns":
        if (!props.columns.some(item => item.column === expression.column)) {
          props.setColumns([...props.columns, expression]);
        } else {
          customToastSystem('Dimension already present!', 'error')
        }
        handleClose()
        break
      case "metrics":
        if (!props.metrics.some(item => item.column === expression.column && item.aggregate === expression.aggregate)) {
          props.setMetrics([...props.metrics, expression])
        } else {
          customToastSystem('Metric already present!', 'error')
        }
        handleClose()
        break
      case "filter":
        if (!props.filters.some(item => item.column === expression.column && item.operator === expression.operator && item.filter_value === expression.filter_value)) {
          props.setFilters([...props.filters, expression])
        } else {
          customToastSystem('Filter already present!', 'error')
        }
        handleClose()
        break
    }
  }

  const handleDeleteBentoExpression = (indexOfExpression, type) => {
    switch (type) {
      case "columns":
        props.columns.splice(indexOfExpression, 1)
        props.setColumns(props.columns)
        setExpression({})
        break
      case "metrics":
        props.metrics.splice(indexOfExpression, 1)
        props.setMetrics(props.metrics)
        setExpression({})
        break
      case "filter":
        props.filters.splice(indexOfExpression, 1)
        props.setFilters(props.filters)
        setExpression({})
        break
    }
  }

  const handleAddExpressionParamter = (name) => (e) => setExpression({ ...expression, [name]: e.target.value })

  const checkDisabled = (type) => {
    switch (type) {
      case 'columns':
        return !(expression.column)
      case 'metrics':
        return !(expression.column && expression.aggregate)
      case 'filters':
        return !(expression.column && expression.operator && expression.filter_value)
    }
  }

  const menus = {
    columns: (<div>

      <form onSubmit={(e) => handleAddBentoExpressions(e, 'columns')}>

        <label for="column">
          Columns
        </label>
        <select
          id="column"
          name="column"
          value={expression['column'] ?? "Select the column(s) that you would like to use"}
          onChange={handleAddExpressionParamter('column')}
          style={{ marginTop: "0px" }}
          className={`${bentoClasses.selectInputStyle}`}
          placeholder="Select the column(s) that you would like to use"
          required
        >
          <option
            className={`${bentoClasses.selectOption}`}
            value="Select the column(s) that you would like to use"
            disabled
            selected
          >
            Select the column(s) that you would like to use
          </option>
          {props.availableColumns.map((ele, index) => (
            <option
              key={index}
              className={`${bentoClasses.selectOption}`}
              value={ele.column_name}
            >
              {ele.column_name}
            </option>
          ))}
        </select>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button type="submit" className={`${bentoClasses.primaryButton} ${bentoClasses.smallButton}`} disabled={checkDisabled('columns')}>
            Save
          </button>
        </div>

      </form>

    </div>),

    metrics: (<div>

      <form onSubmit={(e) => handleAddBentoExpressions(e, 'metrics')}>

        <label for="column">
          Columns
        </label>
        <select
          id="column"
          name="column"
          value={expression['column'] ?? "Select the column(s) that you would like to use"}
          onChange={handleAddExpressionParamter('column')}
          style={{ marginTop: "0px" }}
          className={`${bentoClasses.selectInputStyle}`}
          placeholder="Select the column(s) that you would like to use"
          required
        >
          <option
            className={`${bentoClasses.selectOption}`}
            value="Select the column(s) that you would like to use"
            disabled
            selected
          >
            Select the column(s) that you would like to use
          </option>
          {props.availableColumns.map((ele, index) => (
            <option
              key={index}
              className={`${bentoClasses.selectOption}`}
              value={ele.column_name}
            >
              {ele.column_name}
            </option>
          ))}
        </select>

        <label for="aggregate">
          Aggregate
        </label>
        <select
          id="aggregate"
          name="aggregate"
          value={expression['aggregate'] ?? "Select the aggregate that you would like to use"}
          onChange={handleAddExpressionParamter('aggregate')}
          style={{ marginTop: "0px" }}
          className={`${bentoClasses.selectInputStyle}`}
          placeholder="Select the aggregate that you would like to use"
          required
        >
          <option
            className={`${bentoClasses.selectOption}`}
            value="Select the aggregate that you would like to use"
            disabled
            selected
          >
            Select the aggregate that you would like to use
          </option>
          {["AVG", "COUNT", "COUNT_DISTINCT", "MAX", "MIN", "SUM"].map(
            (ele, index) => (
              <option
                key={index}
                className={`${bentoClasses.chartOption}`}
                value={ele}
              >
                {ele}
              </option>
            )
          )}
        </select>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button type="submit" className={`${bentoClasses.primaryButton} ${bentoClasses.smallButton}`} disabled={checkDisabled('metrics')}>
            Save
          </button>
        </div>

      </form>

    </div>),

    filters: (<div>

      <form onSubmit={(e) => handleAddBentoExpressions(e, 'filter')}>

        <label for="column">
          Columns
        </label>
        <select
          id="column"
          value={expression['column'] ?? "Select the column(s) that you would like to use"}
          onChange={handleAddExpressionParamter('column')}
          style={{ marginTop: "0px" }}
          className={`${bentoClasses.selectInputStyle}`}
          placeholder="Select the column(s) that you would like to use"
          required
        >
          <option
            className={`${bentoClasses.selectOption}`}
            value="Select the column(s) that you would like to use"
            disabled
            selected
          >
            Select the column(s) that you would like to use
          </option>
          {props.availableColumns.map((ele, index) => (
            <option
              key={index}
              className={`${bentoClasses.selectOption}`}
              value={ele.column_name}
            >
              {ele.column_name}
            </option>
          ))}
        </select>

        <label for="operator">
          Operator
        </label>
        <select
          id="operator"
          value={expression['operator'] ?? "Select the operator that you would like to use"}
          onChange={handleAddExpressionParamter('operator')}
          style={{ marginTop: "0px" }}
          className={`${bentoClasses.selectInputStyle}`}
          placeholder="Select the operator that you would like to use"
          required
        >
          <option
            className={`${bentoClasses.selectOption}`}
            value="Select the operator that you would like to use"
            disabled
            selected
          >
            Select the operator that you would like to use
          </option>
          {Object.keys(operators).map(
            (ele, index) => (
              <option
                key={index}
                className={`${bentoClasses.chartOption}`}
                value={ele}
              >
                {ele}
              </option>
            )
          )}
        </select>

        <label for='filter_value'>Filter Value</label>
        <input
          style={{ marginTop: "0px", padding: '0px 12px' }}
          id="filter_value"
          name="filter_value"
          value={expression['filter_value'] ?? ""}
          onChange={handleAddExpressionParamter('filter_value')}
          placeholder="Filter value (case sensitive)"
          className={`${bentoClasses.selectInputStyle}`}
          required
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button type="submit" className={`${bentoClasses.primaryButton} ${bentoClasses.smallButton}`} disabled={checkDisabled('filters')}>
            Save
          </button>
        </div>

      </form>

    </div>),
  }

  return (
    <div
    //style={{ height: "100%" }}
    >
      <p className={`${bentoClasses.texth1} ${bentoClasses.textColorPrimary}`}>
        New Bento Module
      </p>
      <div style={{ marginTop: "8px" }}>
        <TypographySemiBold variant="body2">
          Configure the category and metric variables that will be used towards
          the new Bar Bento Module.
        </TypographySemiBold>
      </div>

      <Menu
        id="complex-filters-context-menu"
        anchorEl={complexFilterAnchorEl}
        open={Boolean(complexFilterAnchorEl)}
        onClose={handleClose}
        classes={{
          paper: bentoClasses.menuDropdownBackground,
        }}
        keepMounted
        anchorReference="anchorPosition"
        anchorPosition={
          anchorCoordinates.y !== 0 && anchorCoordinates.x !== 0
            ? { top: anchorCoordinates.y, left: anchorCoordinates.x }
            : undefined
        }
      >
        {menus[menuWindow]}
      </Menu>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div style={{ marginTop: "16px" }}>
            <TypographySemiBold variant="body2">
              Dimension
            </TypographySemiBold>

            {props.columns.map((ele, index) => {
              return <div
                className={`${bentoClasses.textExpressionInput}`}
              >
                <Close onClick={() => handleDeleteBentoExpression(index, 'columns')} style={{ marginRight: '1em' }} />  {ele.column}
              </div>
            })}

            <div
              onClick={(e) => handleClick(e, 'columns')}
              className={`${bentoClasses.textExpressionInput}`}
            >
              <Add style={{ marginRight: '1em' }} /> Add Columns
            </div>
          </div>

          <div style={{ marginTop: "16px" }}>
            <TypographySemiBold variant="body2">
              Metrics Expression
            </TypographySemiBold>

            {/* <input
              type="text"
              placeholder="Craft complex filters"
              className={`${bentoClasses.textExpressionInput}`}
            /> */}
            {props.metrics.map((ele, index) => {
              return <div
                className={`${bentoClasses.textExpressionInput}`}
              >
                <Close onClick={() => handleDeleteBentoExpression(index, 'metrics')} style={{ marginRight: '1em' }} />  {ele.aggregate} ( {ele.column} )
              </div>
            })}

            <div
              onClick={(e) => handleClick(e, 'metrics')}
              className={`${bentoClasses.textExpressionInput}`}
            >
              <Add style={{ marginRight: '1em' }} /> Craft complex metrics
            </div>
          </div>

          {/*
          <div
            style={{ display: "flex", marginTop: "19px", alignItems: "center" }}
          >
            <div className={`${bentoClasses.borderBeam}`}></div>
            <p style={{ marginLeft: "8px", marginRight: "8px" }}>or</p>
            <div className={`${bentoClasses.borderBeam}`}></div>
          </div>
          */}

          <div style={{ marginTop: "16px" }}>
            <TypographySemiBold variant="body2">
              Available Metrics
            </TypographySemiBold>

            <select
              onChange={() => {
                props.setMetrics([...props.metrics, { column: '*', aggregate: 'COUNT' }])
              }}
              className={`${bentoClasses.selectInputStyle}`}
              placeholder="Select the filter(s) that you would like to use"
              value={props.metrics.map(ele => ele.column).includes('*') ? "COUNT (*)" : "1 saved metric(s)"}
            >
              <option
                className={`${bentoClasses.chartOption}`}
                value="1 saved metric(s)"
                disabled
                selected
              >
                1 saved metric(s)
              </option>
              {["COUNT (*)"].map((ele, index) => (
                <option
                  key={index}
                  className={`${bentoClasses.chartOption}`}
                  value={ele}
                >
                  {ele}
                </option>
              ))}
            </select>
          </div>

          <div style={{ marginTop: "16px" }}>
            <TypographySemiBold variant="body2">
              Filters Expression
            </TypographySemiBold>

            {props.filters.map((ele, index) => {
              return <div
                className={`${bentoClasses.textExpressionInput}`}
              >
                <Close onClick={() => handleDeleteBentoExpression(index, 'filter')} style={{ marginRight: '1em' }} />  {ele.column} {ele.operator}  {ele.filter_value}
              </div>
            })}

            <div
              onClick={(e) => handleClick(e, 'filters')}
              className={`${bentoClasses.textExpressionInput}`}
            >
              <Add style={{ marginRight: '1em' }} /> Craft complex filter
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={`${bentoClasses.configLeftContainer}`}>
            <div className={`${bentoClasses.bentoIconBackgroud}`}>
              <Logo />
            </div>

            <div style={{ marginTop: "24px" }}>
              <p className={`${bentoClasses.texth1} ${bentoClasses.textColorPrimary}`}>Add Filters</p>

              <p className={`${bentoClasses.textb1}`}>
                Hover any of the layers shown on the left to see its metadata
                here, or click the layer item to edit its properties and/or
                settings.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', marginTop: '5vh' }}
      >
        {/* <CustomHeading3ButtonSecondary style={{padding:"14px 41px",borderRadius:"8px"}} variant="outlined" background="rgba(0, 0, 0, 0)" >Cancel</CustomHeading3ButtonSecondary> */}
        <button
          onClick={(e) => {
            props.handleBack(e);
          }}
          className={`${bentoClasses.blackButton} ${bentoClasses.bigButton}`}
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={props.buttonLoading}
          className={`${bentoClasses.primaryButton} ${bentoClasses.bigButton}`}
        >
          {props.buttonLoading ? <CircularProgress className={`${classesHoverActive.whiteColor}`} size={18} /> : "Create Module"}
        </button>
        {/* <CustomBody1SemiBoldButton 
              style={{borderRadius:"8px"}} variant='outlined'
               type="submit"
               >Next Step</CustomBody1SemiBoldButton> */}
      </Grid>
    </div >
  );
}

export default AddFilter;
