import { makeStyles } from "@material-ui/core"
import { getLightDarkGrayscaleColor } from "../../../../common/functions"
import { after } from "lodash"

export const useStyles = makeStyles((theme) => ({
    chartOption: {
        display: 'flex',
        flexDirection: 'column',
        height: '160px',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: '20px',
        backgroundColor: 'rgba(51, 51, 51, 0.48)',
        color: 'white',
        cursor: 'pointer'
    },
    chartTypeRadioBtn: {
        position: 'absolute',
        opacity: "0",
        '&:checked + div': {
            border: '3px solid rgba(6, 169, 239, 1)'
        }
    },
    textExpressionInput: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '60'),
        //"rgba(34, 34, 34, 0.48)",
        color: theme.palette.text.primary,
        height: "20px",
        border: "1px dashed grey",
        outline: "none",
        marginTop: "8px",
        padding: "12px",
        borderRadius: "4px",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    textInputStyle: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '60'),
        //"rgba(34, 34, 34, 0.48)",
        color: theme.palette.text.primary,
        height: "20px",
        width: "100%",
        border: "none",
        outline: "none",
        marginTop: "8px",
        padding: "12px",
        borderRadius: "4px"
    },
    selectInputStyle: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '60'),
        color: theme.palette.text.primary,
        height: "44px",
        width: "100%",
        border: "none",
        outline: "none",
        marginTop: "8px",
        padding: "12px",
        borderRadius: "4px"
    },
    selectOption: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '60'),
        height: "44px",
        width: "100%",
        border: "none",
        outline: "none",
        padding: "12px",
        borderRadius: "4px",
    },
    bentoIconBackgroud: {
        backgroundColor: "rgba(34, 34, 34, 0.48)",
        width: "100%",
        height: "260px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px"
    },
    configLeftContainer: {
        height: '100%',//65vh
        //width: "96%",
        borderLeft: "4px solid rgba(34, 34, 34, 0.86)",
        borderRadius: "2px",
        paddingLeft: "16px",
        marginLeft: "16px",
    },
    texth1: {
        fontFamily: '"Inter",sans-serif',
        fontSize: "18px",
        fontWeight: "640",
        color: "rgba(255, 255, 255, 1)"
    },
    textb1: {
        fontFamily: '"Inter",sans-serif',
        fontSize: "14px",
        fontWeight: "520",
        color: "rgba(153, 153, 153, 1)"
    },
    blackButton: {
        color: "white",
        backgroundColor: getLightDarkGrayscaleColor(theme, '10', '60'),
        borderRadius: "8px"
    },
    primaryButton: {
        color: "white",
        backgroundColor: "rgba(6, 169, 239, 1)",
        borderRadius: "8px",
        "&:disabled":{
            backgroundColor: getLightDarkGrayscaleColor(theme, '10', '60')
        }
    },
    smallButton: {
        padding: "8px 20px"
    },
    bigButton: {
        width: '12rem',
        padding: "15px 35px"
    },
    borderBeam: {
        flexGrow: "1",
        borderRadius: "2px",
        // BackgroundColor:"rgba(34, 34, 34, 0.86)",
        backgroundColor: "rgba(34, 34, 34, 0.48)",
        // backgroundColor:"red",
        height: "4px"
    },
    bentoMainContainer: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        //"rgba(0, 0, 0, 0.84)",
        borderRadius: "16px",
        margin: "16px",
        marginTop: "20px",
        padding: "0px 24px",
        // height:"calc(100vh 76px)",
        //  width:"calc(100vw - 32px)"
        height: "95%",
        width: "95%",
        backdropFilter: "blur(10px)",
        overflow: "hidden",
        opacity: "0.8"
    },
    textColorPrimary: {
        color: theme.palette.text.primary
    },
    stepperLabelText: {
        fontSize: "14px",
        fontWeight: "600",
    },
    activeStepperLabelText: {
        fontSize: "14px",
        fontWeight: "600",
        color: "#06A9EF"
    },
    menuDropdownBackground: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '60'),
        width: "300px",
        padding: "10px"
    },
    treeSelectStyle: {
        width: "300px",
        "& .ant-select-selector": {
            border: "none"
        }
    },
    bentoMapBackground:{
        backgroundColor:"#000000D6",
        borderRadius:"16px",
        padding:"24px",
        height:"80%"
    }
}))