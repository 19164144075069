import axios from 'axios'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { countries, states, districts } from '../config/combinedCountryStateDistrict'
const crypto = require('crypto-js')

export const getRid = () => Math.random().toString(36).substring(2,7)
  
export const get_roles = ()=>{
    let role = getfromLocalStorage("group")
    return JSON.parse(role)
}
export const encryptData = (data)=> crypto.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString()
export const decryptData = (data)=> {
    if(data !== ""){
        return JSON.parse(crypto.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY).toString(crypto.enc.Utf8))
    }else{
        return ""
    }
}
export const generateUuid = () => 'xxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
})
export const getfromLocalStorage = (field)=>{
    let value = localStorage.getItem(field)
    
    if(value === undefined || value === null){
        return ""
    }
    
    return decryptData(value)
    // return value
}
export const puttoLocalStorage = (field, value)=>{
    
    if(value === undefined || value === null){
        return ""
    }
    localStorage.setItem(field, encryptData(value))
    // localStorage.setItem(field, value)
    return true
}
export const putToSessionStorage = (field, value) => {
    if(value === undefined || value === null){
        return ""
    }
    sessionStorage.setItem(field, encryptData(value))
    return true
}
export const getFromSessionStorage = (field) => {
    let value = sessionStorage.getItem(field)
    if(value === undefined || value === null){
        value = getRid()
        putToSessionStorage('rid', value)
        return value
    }
    return decryptData(value)
}
export const getAcessLevel = () => {
    let temp = getfromLocalStorage("group").toLowerCase()
    switch (temp) {
        case "admin":
            return 0
        case "manager":
            return 1
        case "baseuser":
        default:
            return 2
    }
}
export const formatDate = (value) => value && format(value, 'LLL dd, yyyy')
export const formatDateShort = (value, diff=0) => {
    let temp = value
    if (!value) {
        return
    }
    // if (diff !== 0) {
    //     temp.setDate(temp.getDate() + diff)
    // }
    return format(new Date(temp), 'yyyy-MM-dd')
}
export const getNthDate = (val) => new Date(new Date().getTime() - (val * 24 * 60 * 60 * 1000))
export const capitalizeFirstLetter = (str) => str ? str.split(' ').map(ele => ele.charAt(0).toUpperCase() + ele.slice(1).toLowerCase()).join(' ') : str
export const sortObjects = (key, altKey, array) => array.sort((a, b) => {
    let nameA = a[key] ? a[key].toUpperCase() : a[altKey].toUpperCase()
    let nameB = b[key] ? b[key].toUpperCase() : b[altKey].toUpperCase()
    
    if (nameA < nameB) {
        return -1
    }
    
    if (nameA > nameB) {
        return 1
    }
    return 0
})

export const findValueInObject = (object, key) => {
    let value = ''
    Object.keys(object).some(function(k) {
        if (k === key) {
            value = object[k]
            return true
        }
        if (object[k] && typeof object[k] === 'object') {
            value = findValueInObject(object[k], key)
            return value !== undefined
        }
    })
    return value
}

export const getKeyFromValue = (object, value) => Object.keys(object).find(key => object[key] === value)

export const getErrorMessageFromResponse = (err, message) => {
    if (err.response ? err.response.data.detail : false) {
        return err.response.data.detail
    }
    return message
}

export const districtCodeToDistrict = (temp) => {
    let tempCheck = countries.filter(ele => temp === ele.value)
    if (tempCheck.length > 0) {
        return tempCheck[0].name
    }
    tempCheck = states.filter(ele => temp === ele.value).map(ele => ele.name.split(', ')[0])
    if (tempCheck.length > 0) {
        return tempCheck[0]
    }
    return districts.filter(ele => temp.split(',').includes(ele.value)).map(ele => capitalizeFirstLetter(ele.name.split(', ')[0])).sort().join(', ')
}

export const districtCodeToDistrictDropdowns = (temp) => {
    let tempCheck = countries.filter(ele => temp === ele.value)
    if (tempCheck.length > 0) {
        return tempCheck
    }
    tempCheck = states.filter(ele => temp === ele.value)
    if (tempCheck.length > 0) {
        return tempCheck
    }
    return districts.filter(ele => temp.split(',').includes(ele.value))
}

export const getDistricts = (data, country) => {
    let tempDataSplit = []
    if (typeof data === 'string') {
        tempDataSplit = data?.split(',') ?? []
    } else {
        tempDataSplit = data ?? []
    }
    let result = []
    for (let i of tempDataSplit) {
        if (country === 'US') {
            result.push(...districts.filter(ele => ele.value === i))
        } else {
            let t = i.toString().split('.')
            if (t.length === 1) {
                result.push(...countries.filter(ele => ele.sname === i))
            } else if (t.length === 2) {
                result.push(...states.filter(ele => ele.sname === t[1]))
            } else {
                result.push(...districts.filter(ele => ele.value === i))
            }
        }
    }
    return result
}

export const numFormatter = (num, toFixed=1) => {
    let tempNum = Number(num.toString().split('.')[0].replace(/\D/g,''))
    if (tempNum >= 1000000000) {
        return (tempNum / 1000000000).toFixed(toFixed).replace(/\.0$/, '') + 'B'
    } else if (tempNum >= 1000000) {
        return (tempNum / 1000000).toFixed(toFixed).replace(/\.0$/, '') + 'M'
    } else if (tempNum >= 1000) {
        return (tempNum / 1000).toFixed(toFixed).replace(/\.0$/, '') + 'K'
    }
    return num.toFixed(toFixed)
}

export const checkScrollVisible = (id) => {
    const element = document.getElementById(id)
    return element?.scrollHeight > element?.clientHeight
}

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null
}

const componentToHex = (c) => {
    var hex = c.toString(16)
    return hex.length == 1 ? "0" + hex : hex
}
  
export const rgbToHex = (color) => {
    return "#" + componentToHex(color[0] ?? 0) + componentToHex(color[1] ?? 0) + componentToHex(color[2] ?? 0)
}

export const checkMuiLightDark = (theme, a, b) => theme.palette.type === 'light' ? a : b

export const getLightDarkGrayscaleColor = (theme, a, b, reverse=false) => {
    if (reverse) {
        if (theme.palette.type === 'light') {
            return theme.palette.grayscaleDark?.[b] ?? theme.palette.primary.main
        } else {
            return theme.palette.grayscaleLight?.[a] ?? theme.palette.primary.main
        }
    } else {
        if (theme.palette.type === 'light') {
            return theme.palette.grayscaleLight?.[a] ?? theme.palette.primary.main
        } else {
            return theme.palette.grayscaleDark?.[b] ?? theme.palette.primary.main
        }
    }
}

export const CheckPartner = () => {
    const stateLoginRegister = useSelector(state => state.loginRegisterReducer)
    return stateLoginRegister.partner && getfromLocalStorage('email') ? true : false
}

export const getNumberLocaleString = (num, type="en-US") => Number(num).toLocaleString(type)
// export const getNumberLocaleString = (num, type="en-IN") => Number(num).toLocaleString(type)

export const getClientLocationData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    return res.data
}