import { makeStyles } from "@material-ui/core"
import { isMobileOnly } from "react-device-detect"
import { getLightDarkGrayscaleColor } from "../common/functions"

const drawerWidth = 'min(350px, calc(200vw/12))'

export const useStyles = makeStyles((theme) => ({
    '@global': {
        color: theme.palette.text.primary,
        '*::-webkit-scrollbar': {
            marginLeft: theme.spacing(0.5),
            width: theme.spacing(1),
            height: theme.spacing(1),
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text.disabled,
            borderRadius: theme.shape.borderRadius,
        }
    },
    'body': {
        color: theme.palette.text.primary
    },
    websiteRoot: {
        backgroundColor: theme.palette.foreground,
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    root: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '90'),
        display: 'flex',
        width: '100%',
        height: '100%',
        // minWidth: '900px',
        // minHeight: '700px',
        overflow: 'auto',
        // [theme.breakpoints.down('md')]: {
        //     width: '1280px',
        //     // height: '620px',
        // },
        // ['@media (max-height: 620px)']: {
        //     height: '620px',
        // }
    },
    margin: {
        margin: `${theme.spacing(1)}px 0`,
    },
    appBar: {
        boxShadow: `${theme.shadows[1]} !important`,
    },
    gradientIV: {
        background: theme.palette.gradient4,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    inverseMenuButton: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    toolbar: theme.mixins.toolbar,
    websiteToolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(6)
        }
    },
    profileIcon: {
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
        padding: `0 ${theme.spacing(1.5)} 0 0`
    },
    profileIconActive: {
        transition: 'all 0.3s ease-in-out',
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: theme.palette.primary.contrastText,
        border: `3px solid transparent`,
        background: theme.palette.gradient4,
    },
    search: {
        width: '50vw',
        [theme.breakpoints.down('md')]: {
            width: '30vw',
        },
    },
    drawerPaper: {
        backgroundColor: theme.palette.foreground,
        width: drawerWidth,
        borderRight: 'none',
    },
    menuPaper: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
    },
    drawerStyle: {
        paddingLeft: theme.spacing(4.5),
        paddingRight: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(4.5)}px - ${theme.spacing(3)}px)`,
    },
    omniGradientSvg: {
        fill: `url(#omniGradient) ${theme.palette.text.primary}`
    },
    accountDivider: {
        background: theme.palette.primaryGradient,
        height: '3px',
    },
    secondaryAccountDivider: {
        background: theme.palette.secondaryGradient,
        height: '3px',
    },
    tertiaryAccountDivider: {
        background: theme.palette.tertiaryGradient,
        height: '3px',
    },
    quarternaryAccountDivider: {
        background: theme.palette.quarternaryGradient,
        height: '3px',
    },
    dashboardDivider: {
        background: theme.divider,
        height: '3px',
    },
    activeDashboard: {
        cursor: 'pointer',
        opacity: 0.5,
        display: 'inline-block',
        verticalAlign: 'top',
        position: 'relative',
        textAlign: 'center',
        lineHeight: '24px',
        '&:hover': {
            opacity: 1,
        },
        '&::before': {
            transform: 'translateX(-50%)',
            borderRadius: '100%',
            position: 'absolute',
            bottom: theme.spacing(-0.5),
            height: `calc(${theme.spacing(1)}px - 4px)`,
            width: `calc(${theme.spacing(1)}px - 4px)`,
            content: '""',
            left: '50%',
        },
    },
    activeDashboardHome: {
        opacity: 1,
        '&::before': {
            background: theme.palette.primaryGradient
        },
        '& > svg > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.primary.main,
            }
        }
    },
    activeDashboardExplore: {
        opacity: 1,
        '&::before': {
            background: theme.palette.secondaryGradient
        },
        '& > svg > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.secondary.main,
            }
        }
    },
    activeDashboardMonitor: {
        opacity: 1,
        '&::before': {
            background: theme.palette.tertiaryGradient
        },
        '& > svg > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit',
            }
        }
    },
    activeDashboardOptimize: {
        opacity: 1,
        '&::before': {
            background: theme.palette.quarternaryGradient
        },
        '& > svg > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit',
            }
        }
    },
    drawerPadding: {
        padding: `${theme.spacing(3.5)}px 0`,
        height: `calc(100% - 4 * ${theme.spacing(3.75)}px)`,
    },
    drawerFilterPadding: {
        padding: `${theme.spacing(3.5)}px ${theme.spacing(3)}px`,
        height: `calc(100% - 4 * ${theme.spacing(3.75)}px)`,
    },
    drawerItems: {
        overflowY: 'auto',
        // alignItems: 'flex-start',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
    },
    drawerIconHoverWhiteActive: {
        fill: theme.palette.text.primary,
    },
    iconHover: {
        '&:hover': {
            fill: theme.palette.secondary.contrastText,
        }
    },
    drawerIconPrimaryHover: {
        fill: theme.palette.text.primary,
        '&:hover': {
            fill: theme.palette.primary.main,
        },
        '&:active': {
            fill: theme.palette.primary.dark,
        },
    },
    drawerIconHoverPrimaryActive: {
        fill: theme.palette.foreground,
    },
    drawerIconSecondaryHover: {
        fill: theme.palette.text.primary,
        '&:hover': {
            fill: theme.palette.secondary.main,
        },
        '&:active': {
            fill: theme.palette.secondary.dark,
        },
    },
    drawerIconHoverSecondaryActive: {
        fill: theme.palette.foreground,
    },
    drawerIconTertiaryHover: {
        fill: theme.palette.text.primary,
        '&:hover': {
            fill: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
        },
        '&:active': {
            fill: theme.palette.tertiary ? theme.palette.tertiary.dark : theme.palette.primary.main,
        },
    },
    drawerIconHoverTertiaryActive: {
        fill: theme.palette.foreground,
    },
    drawerIconQuarternaryHover: {
        fill: theme.palette.text.primary,
        '&:hover': {
            fill: theme.palette.quarternary ? theme.palette.quarternary.main : theme.palette.secondary.main,
        },
        '&:active': {
            fill: theme.palette.quarternary ? theme.palette.quarternary.dark : theme.palette.secondary.main,
        },
    },
    drawerIconHoverQuarternaryActive: {
        fill: theme.palette.foreground,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        maxWidth: '100%',
        padding: `${theme.spacing(3.5)}px ${theme.spacing(4.5)}px ${theme.spacing(3.5)}px ${theme.spacing(3)}px`,
        height: `calc(100% - ${2 * theme.spacing(3.5)}px)`,
        overflowY: 'auto',
    },
    contentPadding: {
        padding: `${theme.spacing(3.5)}px ${theme.spacing(3)}px`,
    },
    contentHeight: {
        height: `calc(100% - ${4 * theme.spacing(3.75)}px)`,
    },
    contentFilter: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        padding: `${theme.spacing(2.25)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        maxWidth: '100%',
        background:"white",
    },
    websiteContentFilter: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        padding: `0 ${theme.spacing(7.5)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `0 ${theme.spacing(1.5)}px`,
        },
        maxWidth: '100%',
        overflowY: 'auto',
    },
    loginRegisterContent: {
        background: '#121212',
    },
    main: {
        // backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        flex: '1 1 0',
        // height: `max(100%, calc(700px - ${theme.spacing(3.75)}px - ${theme.mixins.toolbar.minHeight}px))`,
        height: `100%`,
    },
    websiteMain: {
        flex: '1 1 0',
        height: `100%`,
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    closeMenuButtonLanding: {
        marginLeft: 'auto',
        marginRight: 0,
    },
}))