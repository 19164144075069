import { makeStyles } from "@material-ui/core"
import { DrawerSize } from "../../common/config"

export const useStyles = makeStyles((theme) => ({
    fullheight: {
        height: '100%',
    },
    halfheight: {
        height: '50%',
    },
    fullHeightFlex: {
        flex: 1,
        height: '100%'
    },
    halfHeightFlex: {
        flex: 1,
        minHeight: 'calc(50% + 24px)'
    },
    halfHeightFlexWithoutCalc: {
        flex: 1,
        height: '50%'
    },
    FullHeightFlexWithoutCalc: {
        flex: 1,
        height: '100%'
    },
    flexHeader: {
        flex: '0 1 0'
    },
    flexHero: {
        flex: '0.40 1 0'
    },
    flexContent: {
        flex: '1 1 0'
    },
    headerPadding: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
    },
    contentPadding: {
        padding: `${theme.spacing(1.5)}px`
    },
    flexNoWrap: {
        flexWrap: 'nowrap'
    },
    defaultBackground: {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.background,
    },
    gradient1: {
        background: theme.palette.gradient1
    },
    gradient2: {
        background: theme.palette.gradient2
    },
    gradient3: {
        background: theme.palette.gradient3
    },
    gradient4: {
        background: theme.palette.gradient4
    },
    primaryGradient: {
        background: theme.palette.primaryGradient
    },
    secondaryGradient: {
        background: theme.palette.secondaryGradient
    },
    tertiaryGradient: {
        background: theme.palette.tertiaryGradient
    },
    quarternaryGradient: {
        background: theme.palette.quarternaryGradient
    },
    verticalMedium: {
        boxShadow: theme.shadows[2],
    },
    borderRadius: {
        borderRadius: theme.shape.borderRadius,
    },
    rightSidePanelIcons: {
        marginRight: theme.spacing(2.5),
    },
    rightSidePanelContent: {
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        alignItems: 'flex-start',
        width: '100%',
        margin: `${theme.spacing(1)}px 0`,
        flexDirection: 'column',
    },
    sidepanelWideContentArea: {
        width: "max(700px, 60vw)",
        transition: 'all 0.5s ease-in-out',
        [theme.breakpoints.up('md')]: {
            width: `min(70vw, 1000px)`,
            maxWidth: `min(70vw, 1000px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    sidepanelContentArea: {
        width: "30vw",
        transition: 'all 0.5s ease-in-out',
        [theme.breakpoints.up('md')]: {
            width: `calc(1.5 * ${DrawerSize})`,
            maxWidth: `calc(1.5 * ${DrawerSize})`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
        },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%'
        // },
    },
    sidepanelPadding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    },
    blog: {
        border: '4px solid transparent',
        height: theme.spacing(40),
        cursor: 'pointer',
        backgroundColor: theme.palette.text.disabled,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // padding: theme.spacing(2),
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
    },
    spaceEvenlyVertically: {
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start'
    },
    centerVertically: {
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    flexEndVertically: {
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },
}))