// ############### Color Pallette ###############
// export const Primary = false ? '#0070B9' : '#B500B9'
// export const PrimaryHSL = '204, 100%, 50%'
// export const Secondary = false ? '#B500B9' : '#0070B9'
// ############### Color Pallette ###############

var Primary, PrimaryTint1, PrimaryTint2, PrimaryShade, Secondary, SecondaryTint1, SecondaryTint2, SecondaryShade, Tertiary, TertiaryTint1, TertiaryTint2, TertiaryShade, DisabledText, Background, Foreground, Text

Text = '#121212'
Background = '#F4F4F4'
Foreground = '#FAFAFA'

if (true) {
    // Primary Palette
    Primary = '#0070B9'
    PrimaryTint1 = '#008EEB'
    PrimaryTint2 = '#1FA6FF'
    PrimaryShade = '#005085'
    Secondary = '#B500B9'
    SecondaryTint1 = '#E600EB'
    SecondaryTint2 = '#FA1FFF'
    SecondaryShade = '#820085'
    Tertiary = '#EB8D00'
    TertiaryTint1 = '#FFA51F'
    TertiaryTint2 = '#FFBA52'
    TertiaryShade = '#B86E00'
    DisabledText = '#969290'
} else {
    // Secondary Palette
    Primary = '#9B287B'
    PrimaryTint1 = '#C3329A'
    PrimaryTint2 = '#D355B0'
    PrimaryShade = '#721D5A'
    Secondary = '#D0395B'
    SecondaryTint1 = '#DA627D'
    SecondaryTint2 = '#E48B9F'
    SecondaryShade = '#AD2947'
    Tertiary = '#3F826D'
    TertiaryTint1 = '50A58A'
    TertiaryTint2 = '6FB9A2'
    TertiaryShade = '#2F6051'
    DisabledText = '#969290'
}

if (false) {
    // Dark Mode
    Text = '#FAFAFA'
    Background = '#121212'
    Foreground = '#323232'
}

export {Primary, PrimaryTint1, PrimaryTint2, PrimaryShade, Secondary, SecondaryTint1, SecondaryTint2, SecondaryShade, Tertiary, TertiaryTint1, TertiaryTint2, TertiaryShade, DisabledText, Background, Foreground, Text}
export const Success = '#12CC15'
export const Failure = '#CC1512'
// export const Gradient_one = "linear-gradient(303deg, #0070B9, #B500B9)"
export const Gradient_one = `linear-gradient(-2deg, ${Secondary}, ${Primary})`
// export const Gradient_two = "linear-gradient(303deg, #B500B9, #EB8D00)"
export const Gradient_two = `linear-gradient(-2deg, ${Tertiary}, ${Secondary})`
export const verticalHigh = '0 4px 8px rgb(0 0 0 / 0.16)'
export const verticalMedium = '0 3px 6px rgb(0 0 0 / 0.12)'
export const verticalLow = '0 2px 4px rgb(0 0 0 / 0.08)'
export const horizontalHigh = '4px 0 8px rgb(0 0 0 / 0.16)'
export const horizontalMedium = '3px 0 6px rgb(0 0 0 / 0.12)'
export const horizontalLow = '2px 0 4px rgb(0 0 0 / 0.08)'

// export const LargestFont = '2.369rem'
// export const SemiLargestFont = '1.777rem'
// export const MediumFont = '1.333rem'
// export const NormalFont = '1rem'
// export const SmallestFont = '0.75rem'
export const LargestFont = '2.369em'
export const SemiLargestFont = '1.777em'
export const MediumFont = '1.333em'
export const NormalFont = '1em'
export const SmallestFont = '0.75em'
export const BoldText = '700'
export const SemiBoldText = '600'
export const MediumText = '500'

export const Heading0 = {
    fontSize: LargestFont,
    fontWeight: BoldText,
    //lineHeight: '0.039vh',
    letterSpacing: '1%',
}
export const Heading1 = {
    fontSize: SemiLargestFont,
    fontWeight: BoldText,
    //lineHeight: '0.031vh',
    letterSpacing: '1%',
}
export const Heading2 = {
    fontSize: MediumFont,
    fontWeight: SemiBoldText,
    //lineHeight: '0.023vh',
    letterSpacing: '1%',
}
export const Heading3 = {
    fontSize: SemiLargestFont,
    fontWeight: SemiBoldText,
    //lineHeight: '0.031vh',
    letterSpacing: '1%',
}
export const Heading4 = {
    fontSize: MediumFont,
    fontWeight: BoldText,
    //lineHeight: '0.023vh',
    letterSpacing: '1%',
}
export const Paragraph1 = {
    fontSize: MediumFont,
    fontWeight: MediumText,
    //lineHeight: '0.023vh',
    letterSpacing: '1%',
}
export const Paragraph2 = {
    fontSize: NormalFont,
    fontWeight: MediumText,
    //lineHeight: '0.016vh',
    letterSpacing: '1%',
}
export const Paragraph3 = {
    fontSize: SmallestFont,
    fontWeight: MediumText,
    //lineHeight: '0.016vh',
    letterSpacing: '1%',
}
export const Paragraph4 = {
    fontSize: NormalFont,
    fontWeight: SemiBoldText,
    //lineHeight: '0.016vh',
    letterSpacing: '1%',
}
export const Paragraph5 = {
    fontSize: SmallestFont,
    fontWeight: BoldText,
    //lineHeight: '0.016vh',
    letterSpacing: '1%',
}