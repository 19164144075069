import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    sidepanelDivider: {
        height: theme.spacing(0.5)
    },
    flexHeader: {
        flex: '0 1 0'
    },
    flexContent: {
        flex: '1 1 0'
    },
}))