import * as actions from "../actions/actionTypes"

const initialState = {
    query: "",
    data: {},
    database: 'Search',
    loading: false,
    error: false,
    chat: []
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CHANGE_SEARCH_QUERY:
            return {
                ...state,
                query: action.payload
            }
        case actions.CHANGE_SEARCH_DATABASE:
            return {
                ...state,
                database: action.payload
            }
        case actions.UPDATE_SEARCH_DATA:
            return {
                ...state,
                error: false,
                data: action.payload
            }
        case actions.TOGGLE_SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                data: {}
            }
        case actions.TOGGLE_SEARCH_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: false,
            }
        case actions.UPDATE_CHAT:
            return {
                ...state,
                chat: [...state.chat, action.payload],
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default searchReducer