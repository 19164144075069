export const planList = ["Trial", "Enterprise", "Starter", "Pro", "Essentials", "Basic", "Premium"]
export const plansMap = {
    "Trial": 0,
    "Enterprise": 1,
    "Starter": 2,
    "Pro": 3,
    "Essentials": 4,
    "Basic": 5,
    "Premium": 6,
}
export const DrawerSize = "300px"
export const MiniDrawerSize = "64px"