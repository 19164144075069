import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    radioRoot: {
        transition: `all 0.5s ease-in-out`,
        '&:hover': {
            '& > span > span': {
                borderColor: theme.palette.primary.light,
            },
        },
        '&:active': {
            '& > span > span': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    defaultIcon: {
        borderRadius: theme.spacing(0.5),
        border: `2px solid ${theme.palette.text.primary}`,
    },
    checkedIconOuter: {
        borderRadius: theme.spacing(0.5),
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkedIconInner: {
        borderRadius: theme.spacing(0.25),
        backgroundColor: theme.palette.primary.main,
        width: '75%',
        height: '75%',
    },
    body2: {
        height: theme.typography.body2.fontSize,
        width: theme.typography.body2.fontSize,
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    }
}))