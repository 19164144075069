import { Fragment, useMemo, useState } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import * as actions from '../../../../redux/actions'
import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import { ReactComponent as AddIcon } from '../../../../assets/icons/Add.svg'
import { ReactComponent as DragIcon } from '../../../../assets/icons/Drag.svg'
import { CustomGrid } from '../../../../customMui/customMuiStyles'

import CreateReport from './createReport'
import LayerCards from './layerCards'

const useExploreRightSidepanelConfig = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const currentLocation = location.pathname.match(/^\/[^\/]+/)[0]
    const activeDashboard = useParams().activeDashboard
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const stateExplore = useSelector(state => state.exploreReducer)
    const stateMonitor = useSelector(state => state.monitorReducer)
    const stateOptimize = useSelector(state => state.optimizeReducer)
    const rightSidepanelState = useSelector(state => state.rightSidepanelReducer)

    return useMemo(() => ({
        sidepanelContent: () => {
            switch(rightSidepanelState.activeContent) {
                case 'Layers':
                    return ({
                        'header': {
                            'justifyContent': 'space-between',
                            'header': 'Layer Cards',
                            'icon': <AddIcon />
                        },
                        'content': <LayerCards />
                    })
                case 'Setup Campaign':
                    return ({
                        'header': {
                            'justifyContent': 'space-between',
                            'header': 'Campaign Setup',
                        },
                        'content': <div>Default</div>
                    })
                default:
                    return ({
                        'header': {
                            'justifyContent': 'space-between',
                            'header': 'Create New Report',
                        },
                        'content': <CreateReport />
                    })
            }
        },
    }), [rightSidepanelState.activeContent])
}

export default useExploreRightSidepanelConfig