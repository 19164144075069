import * as actions from "../actions/actionTypes"

const initialState = {
    sidepanel: [],
    loading: false,
    reportLoading: false,
    reportError: false,
    selected: 'none',
    selectedConfig: {},
    mergedLayers: {},
    mapStyle: 'light',
    datasets: [],
    filters: [],
    layers: [],
    tooltips: {},
    reportData: {},
    aggregatedVisitationData: [],
    lastFolderIndex: 0,
    rootFolderId: null
}

const exploreReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_AGGREGATED_VISITATION_COUNT: {
            return {
                ...state,
                aggregatedVisitationData: action.payload
            }
        }
        case actions.ADD_EXPLORE_MERGED_REPORT_DATA: {
            let temp = { ...state.reportData }
            temp[`${action.payload.id}-merged`] = temp[action.payload.id]
            temp[action.payload.id] = action.payload.data
            return {
                ...state,
                reportData: temp
            }
        }
        case actions.ADD_EXPLORE_REPORT_DATA: {
            let temp = { ...state.reportData }
            temp[action.payload.id] = action.payload.data
            return {
                ...state,
                reportData: temp
            }
        }
        case actions.ADD_ROOT_FOLDER_ID: {
            return {
                ...state,
                rootFolderId: action.payload
            }
        }
        case actions.UPDATE_EXPLORE_SAVE_CONFIG: {
            let temp = { ...state.reportData }
            let tempSelectReport = temp[action.payload.id]
            if (tempSelectReport) {
                tempSelectReport["config"]["config"] = action.payload.config
                temp[action.payload.id] = tempSelectReport
            }
            return {
                ...state,
                reportData: temp
            }
        }
        case actions.SELECT_EXPLORE_SIDEPANEL: {
            return {
                ...state,
                selected: action.payload?.path,
                selectedConfig: action.payload?.config
            }
        }
        case actions.UPDATE_MAP_STYLE:
            return {
                ...state,
                mapStyle: action.payload
            }
        case actions.UPDATE_EXPLORE_SIDEPANEL:
            return {
                ...state,
                sidepanel: [...action.payload ?? [], ...state.sidepanel]
            }
        case actions.RENAME_REPORT:
            var sidepanelArr = state.sidepanel
            var selectedConfig = state.selectedConfig;
            var updatedObject = sidepanelArr[action.payload.stateExploreContextMenu.index]

            if (updatedObject.type == "folder") {
                let children = updatedObject.children.map(report => {
                    if (report.id == action.payload.stateExploreContextMenu.report.id) {
                        return { ...report, name: action.payload.newName }
                    } else {
                        return report
                    }
                })
                updatedObject['children'] = children
                sidepanelArr.splice(action.payload.stateExploreContextMenu.index, 1, updatedObject)
            } else {
                sidepanelArr[action.payload.stateExploreContextMenu.index]['name'] = action.payload.newName
            }

            if (Object.keys(state.selectedConfig).length != 0){
                selectedConfig['name'] = action.payload.newName
            }

            return {
                ...state,
                sidepanel: [...sidepanelArr],
                selectedConfig: {...selectedConfig}
            }
        case actions.RENAME_FOLDER:
            var sidepanelArr = state.sidepanel
            var updatedObject = sidepanelArr[action.payload.stateExploreContextMenu.index]
            sidepanelArr[action.payload.stateExploreContextMenu.index]['name'] = action.payload.newName
            return {
                ...state,
                sidepanel: [...sidepanelArr]
            }
        case actions.DELETE_REPORT:
            const sidepanelDelArr = state.sidepanel
            const updateObject = sidepanelDelArr[action.payload.index]

            if (updateObject.type == "folder") {
                let children = updateObject.children.filter(report => report.id !== action.payload.report.id)
                updateObject['children'] = children
                sidepanelDelArr.splice(action.payload.index, 1, updateObject)
            } else {
                sidepanelDelArr.splice(action.payload.index, 1)
            }

            return {
                ...state,
                sidepanel: [...sidepanelDelArr]
            }
        case actions.CREATE_FOLDER:
            var sidepanel = state.sidepanel
            sidepanel.splice(0, 0, action.payload)
            return {
                ...state,
                sidepanel: [...sidepanel]
            }
        case actions.DELETE_FOLDER:
            var sidepanel = state.sidepanel
            sidepanel.splice(action.payload.index, 1)

            return {
                ...state,
                sidepanel: [...sidepanel]
            }
        case actions.ADD_REPORT_TO_FOLDER:
            var sidepanel = state.sidepanel
            var reportToPushInFolder = sidepanel[action.payload.source.index]
            var folderToBePushedIn = sidepanel[action.payload.destination.index]
            var selectedConfig = state.selectedConfig

            selectedConfig['folder_id'] = folderToBePushedIn.id
            reportToPushInFolder['folder_id'] = folderToBePushedIn.id
            folderToBePushedIn.children.unshift(reportToPushInFolder)
            sidepanel.splice(action.payload.destination.index, 1, folderToBePushedIn)
            sidepanel.splice(action.payload.source.index, 1)
            return {
                ...state,
                sidepanel: [...sidepanel],
                selectedConfig: selectedConfig
            }
        case actions.REMOVE_REPORT_FROM_FOLDER:
            var sidepanel = state.sidepanel
            var reportData = action.payload.draggableId.split('_')
            var reportId = reportData[1]
            var folderId = reportData[2]

            var sourceFolder = sidepanel.find(ele => ele.folder_id == folderId)
            var sourceFolderIndex = sidepanel.findIndex(ele => ele.folder_id == folderId)

            var report = sourceFolder.children.find(ele => ele.id == reportId)
            report.folder_id = state.rootFolderId

            sourceFolder['children'] = sourceFolder.children.filter(report => report.id != reportId)

            sidepanel.splice(action.payload.destination.index, 0, report)
            sidepanel.splice(sourceFolderIndex, 1, sourceFolder)

            return {
                ...state,
                sidepanel: [...sidepanel]
            }
        case actions.MIGRATE_REPORT_TO_ANOTHER_FOLDER:
            var sidepanel = state.sidepanel
            var reportData = action.payload.draggableId.split('_')
            var reportId = reportData[1]
            var folderId = reportData[2]

            var sourceFolder = sidepanel.find(ele => ele.folder_id == folderId)
            var destinationFolder = sidepanel[action.payload.destination?.index]

            var reportToBeMoved = sourceFolder.children.find(report => report.id == reportId)

            let children = sourceFolder.children.filter(report => report.id != reportId)
            destinationFolder.children.unshift(reportToBeMoved)
            sourceFolder['children'] = children

            return {
                ...state,
                sidepanel: [...sidepanel]
            }
        case actions.INCREMENT_LAST_FOLDER_INDEX:
            return {
                ...state,
                lastFolderIndex: action.payload
            }
        case actions.UPDATE_EXPLORE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actions.UPDATE_EXPLORE_REPORT_LOADING:
            return {
                ...state,
                reportLoading: action.payload,
                reportError: false
            }
        case actions.UPDATE_EXPLORE_REPORT_ERROR:
            return {
                ...state,
                reportLoading: false,
                reportError: action.payload,
                selected: 'none',
                selectedConfig: {}
            }
        case actions.MERGE_LAYER: {
            let temp = { ...state.mergedLayers }
            const source = action.payload.source
            const destination = action.payload.destination
            if (Object.keys(temp).includes(source)) {
                temp[source].push(destination)
            } else {
                temp[source] = [destination]
            }
            return {
                ...state,
                mergedLayers: temp
            }
        }
        case actions.UNMERGE_LAYER: {
            let temp = { ...state.mergedLayers }
            delete temp[action.payload]

            return {
                ...state,
                mergedLayers: temp
            }
        }
        case actions.REORDER_EXPLORE_LAYERS: {
            let reportData = { ...(state.reportData[action.payload.id] ?? {}) }
            const items = [...reportData?.config?.config?.visState?.layers ?? []]
            const [reorderedItem] = items.splice(action.payload.source, 1)
            items.splice(action.payload.destination, 0, reorderedItem)

            reportData['config']['config']['visState']['layers'] = items

            return {
                ...state,
                reportData: {
                    ...state,
                    [action.payload.id]: reportData
                }
            }
        }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default exploreReducer