import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import useMonitorService from '../Service/useMonitorService'

import { checkScrollVisible, getErrorMessageFromResponse } from '../../../../common/functions'
import { breakdowns } from '../Config/monitorConfig'
import { CustomBody1BoldButton, CustomDividerColor, TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { CustomDateRange, CustomTextFieldBody1, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import { customToastSystem } from '../../../../common/customToastify'
import { DateRangePicker } from 'rsuite'
import { useSelector } from 'react-redux'

const UploadData = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const stateSettingsData = useSelector(state => state.settingsDataReducer)

    const { uploadData } = useMonitorService()

    const defaultCampaignData = {
        account: stateSettingsData.social?.account_name ?? 'none',
        breakdown: 'none',
        dateRange: [],
        csv: {}
    }

    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [campaignData, setCampaignData] = useState(defaultCampaignData)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        await uploadData(campaignData)
            .then(res => {
                customToastSystem('Uploaded Data Successfully', 'success')
            })
            .catch(err => customToastSystem(getErrorMessageFromResponse(err, 'Failed to Upload Data'), 'error'))
        setCampaignData(prev => ({...prev, "csv": {}}))
        setLoading(false)
    }

    const handleChangeCampaignData = (e) => setCampaignData(prev => ({...prev, [e.target.name]: e.target.value}))
    const handleChangeDateRange = (val) => setCampaignData(prev => ({...prev, "dateRange": val ? val : []}))
    const handleChangeCsv = (e) => e.target.value !== '' && setCampaignData(prev => ({...prev, 'csv': {file: e.target.files[0], path: e.target.value}}))

    const checkButtonDisabled = () => {
        const selectCheck = [campaignData['account'], campaignData['breakdown']].some(ele => ele === 'none')
        const dateCheck = campaignData['dateRange'].length === 0
        const csvCheck = Object.keys(campaignData['csv']).length !== 2

        return selectCheck || dateCheck || csvCheck
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <TypographySemiBold variant="body1">Upload Data</TypographySemiBold>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="exploreSidepanelContentScrollArea">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='account'
                            color="primary"
                            title="Account"
                            options={stateSettingsData.accounts.map(ele => ele.account_name)}
                            value={campaignData['account']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='breakdown'
                            color="primary"
                            title="Breakdown"
                            options={Object.keys(breakdowns)}
                            value={campaignData['breakdown']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDateRange
                            color="primary"
                            name='dateRange'
                            character=' to '
                            title="Date Range"
                            onChange={handleChangeDateRange}
                            value={campaignData['dateRange']}
                            disabledDate={DateRangePicker.afterToday()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextFieldBody1
                            name='csv'
                            color="primary"
                            title="Data File (csv)"
                            placeholder="Upload CSV"
                            type="file"
                            accept=".csv"
                            onChange={handleChangeCsv}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomBody1BoldButton onClick={handleSubmit} variant='outlined' disabled={checkButtonDisabled() || loading} fullWidth>
                    {loading || 'Upload Data'}
                    {loading && <CircularProgress className={`${classesHoverActive.whiteColor}`} size={20} />}
                </CustomBody1BoldButton>
            </Grid>
        </Grid>
    )
}

export default UploadData