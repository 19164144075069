import window from "global/window"
import {createStore, applyMiddleware, compose} from "redux"
import {taskMiddleware} from "react-palm/tasks"
import logger from 'redux-logger'

import allReducers from "./reducers"

let enhancers = [applyMiddleware(taskMiddleware)]
if (process.env.NODE_ENV === 'development') {
    enhancers = [applyMiddleware(taskMiddleware), applyMiddleware(logger)]
}

// This adds redux devtools to help in debugging
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(allReducers, {}, composeEnhancers(...enhancers))

export default store