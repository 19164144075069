export const headersData = [
    {
        label: "Product",
        href: "/product",
    },
    // {
    //     label: "Solutions",
    //     href: "/solutions",
    // },
    {
        label: "Plans",
        href: "/plans",
    },
    // {
    //     label: "Resources",
    //     href: "/resources",
    // },
    {
        label: "Insights",
        href: "/insights",
    },
    // {
    //     label: "Blogs",
    //     href: "/blogs"
    // },
    {
        label: "About Us",
        href: "/about-us",
    },
    {
        label: "Contact",
        href: "/contact-us",
    },
]