import axios from 'axios'
import { getfromLocalStorage } from '../common/functions'

let host = process.env.REACT_APP_TEST_URL
let token = process.env.REACT_APP_SHERLOCK_BACKEND_TEST_TOKEN
const sherlockTestAxios = axios.create({baseURL:host})

// Add a request interceptor
sherlockTestAxios.interceptors.request.use(
    async config => {

        // UPDATE YOUR TOKEN HERE FROM https://sherlockbackendtest.infiniteanalytics.com/docs

        config.headers['Authorization'] = `Bearer ${token}`
        
        if(config.headers['Content-Type'] !== 'multipart/form-data') 
            config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    })

export default sherlockTestAxios