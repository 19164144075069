import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import sherlockAxios from "../../../../axiosSetup/sherlockaxios.service"
import mobilityAxiosReport, { mobilityAxiosReportV2 } from "../../../../axiosSetup/mobilityaxiosReport.service"

import * as actions from '../../../../redux/actions'
import { formatDateShort, generateUuid, getFromSessionStorage, sortObjects } from "../../../../common/functions"
import { ReactGaError, ReactGaSuccess } from "../../../../common/reactGA"
import { breakdowns, campaign_bidStrategy, campaign_objective, campaign_specialAdCategory_api, campaign_status, currencyDataKeys, fetchMonitorData, GroupTypes_dict, group_dict, Metrics_dict, monitorDataKeys, platform_dict, percentDataKeys } from "../Config/monitorConfig"
import { customToastSystem } from "../../../../common/customToastify"
import sherlockTestAxios from "../../../../axiosSetup/sherlocktest.service"

const useMonitorService = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const stateMonitor = useSelector(state => state.monitorReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)
    const stateRightSidepanel = useSelector(state => state.rightSidepanelReducer)

    const generateTreeStructure = (obj) => {
        const result = []

        for (let property in obj) {
            let tempResult = {
                id: generateUuid(),
                value: "",
                name: property,
                display: property,
                title: property,
                isLeaf: false,
                children: [],
                type: 'platform',
                platform: property
            }

            for (let i of obj[property]) {
                let campaign = {
                    id: generateUuid(),
                    value: i.value ? i.value : "No Name",
                    name: i.title ? i.title : "No Name",
                    display: i.title,
                    objective: i.objective ? i.objective : 'campaign',
                    title: i.title ? i.title : "No Name",
                    isLeaf: false,
                    children: [],
                    type: 'campaign',
                    platform: property
                }
                if (i.children) {
                    for (let j of i.children) {
                        const adResult = []
                        if (j.children) {
                            for (let k of j.children) {
                                adResult.push({
                                    ...k,
                                    id: generateUuid(),
                                    name: k.title ? k.title : "No Name",
                                    type: 'ad',
                                    campaign: i.value ? i.value : "No Name",
                                    adset: j.value ? j.value : "No Name",
                                    platform: property,
                                })
                            }
                        }
                        campaign['children'].push({
                            ...j,
                            id: generateUuid(),
                            name: j.title ? j.title : "No Name",
                            children: adResult,
                            type: 'adset',
                            campaign: i.value ? i.value : "No Name",
                            platform: property
                        })
                    }
                }
                tempResult['children'].push(campaign)
            }
            result.push(tempResult)
        }
        return result
    }

    const fetchSummarizedData = async (x, val, date, selectedAccounts) => {
        return new Promise(async (resolve, reject) => {
            let account = selectedAccounts.map(ele => ele.account_name)
            let platform = selectedAccounts.map(ele => ele.platform)
            let temp = {
                "fromDate": formatDateShort(date[0]),
                "toDate": formatDateShort(date[1]),
                "account_names": account,
                "platform": platform ?? 0,
                'selectedCampaign': typeof val.value == typeof account ? "" : val.value,
                "groupType": GroupTypes_dict["campaign"],
                "xmetrics": Metrics_dict[x],
                // "ymetrics": Metrics_dict[""],
                // "group": []
            }

            if (val.type === 'adset') {
                temp['adsetName'] = val.value
                temp['selectedCampaign'] = val.campaign
            } else if (val.type === 'ad') {
                temp['adsName'] = val.value
                temp['adsetName'] = val.adset
                temp['selectedCampaign'] = val.campaign
            }

            await mobilityAxiosReportV2.post(`/dashboardreports/summarized/${getFromSessionStorage('rid')}`, temp, { 'Content-Type': 'application/json' })
            await mobilityAxiosReportV2.post(`/dashboardreports/summarized/${getFromSessionStorage('rid')}`, temp, { 'Content-Type': 'application/json' })
                .then(res => {
                    ReactGaSuccess('Fetch Summarized Data', 'Fetched Summarized Data Successfully')
                    resolve(res.data.data)
                })
                .catch(error => {
                    ReactGaError(`Failed to fetch summarized data: ${error}`)
                    reject(error)
                })
        })
    }

    const fetchChartData = async (data, val, date, selectedAccounts) => {
        return new Promise(async (resolve, reject) => {
            let account = selectedAccounts.map(ele => ele.account_name)
            let platform = selectedAccounts.map(ele => ele.platform)
            let temp = {
                // "fromDate": "2022-08-01",
                // "toDate": "2022-08-30",
                "fromDate": formatDateShort(date[0]),
                "toDate": formatDateShort(date[1]),
                "account_names": account,
                "platform": platform ?? 0,
                'selectedCampaign': typeof val.value == typeof account ? "" : val.value,
                "groupType": GroupTypes_dict[data.groupType],
                "xmetrics": Metrics_dict[data.x],
                "ymetrics": group_dict[data.y],
                "group": data.group ? data.group.map(ele => GroupTypes_dict[ele]) : []
            }

            if (val.type === 'adset') {
                temp['adsetName'] = val.value
                temp['selectedCampaign'] = val.campaign
            } else if (val.type === 'ad') {
                temp['adsName'] = val.value
                temp['adsetName'] = val.adset
                temp['selectedCampaign'] = val.campaign
            }

            await mobilityAxiosReportV2.post(`/dashboardreports/get_multi_summarized/${getFromSessionStorage('rid')}`, temp, { 'Content-Type': 'application/json' })
            await mobilityAxiosReportV2.post(`/dashboardreports/get_multi_summarized/${getFromSessionStorage('rid')}`, temp, { 'Content-Type': 'application/json' })
                .then(res => {
                    ReactGaSuccess('Fetch Account/Camapign Data', 'Fetched Account/Camapign Data Successfully')
                    resolve(res.data.data)
                })
                .catch(error => {
                    ReactGaError(`Failed to Account/Camapign chart data: ${error}`)
                    reject(error)
                })
        })
    }

    return useMemo(() => ({
        fetchCampaignsList: (name, platforms) => {
            return new Promise((resolve, reject) => {
                let params = { account_names: name, platforms }

                mobilityAxiosReportV2.post(`/dashboardreports/get_adset_ads/${getFromSessionStorage('rid')}`, params)
                    .then(resp => {
                        ReactGaSuccess('Fetch All Campaigns', 'Fetched All Campaigns Successfully')
                        let temp = []
                        let res = resp.data
                        if (name === 'none') {
                            // temp = [{ "id": "account", 'display': 'Client A - All Facebook Campaigns', "name": val, "objective": "campaign" }].concat(sortObjects("name", "", res.filter(ele => ['IA_Eng_Narrow', 'IA_Math'].includes(ele.title)).map(ele => ({ id: generateUuid(), name: ele.title ? ele.title : "No Name", display: ele.title==='IA_Eng_Narrow' ? 'Client A - Product-1 Facebook Campaign' : 'Client A - Product-2 Facebook Campaign', objective: ele.objective ? ele.objective : 'campaign' }))))
                            // temp = [{"id": generateUuid(), "value": name, 'name': 'Client A - All Facebook Campaigns', "objective": "campaign" }].concat(sortObjects("name", "", generateTreeStructure(res.filter(ele => ['IA_Eng_Narrow', 'IA_Math'].includes(ele.title)))))
                            resolve(temp)
                        } else {
                            // temp = [{ "id": "account", 'display': 'All Campaigns', "name": val, "objective": "campaign" }].concat(sortObjects("name", "", res.campaignName.map(ele => ({ id: generateUuid(), name: ele.campaign_name ? ele.campaign_name : "No Name", display: ele.campaign_name, objective: ele.objective ? ele.objective : 'campaign'}))))
                            temp = [{ "id": generateUuid(), "value": name, 'name': 'All Campaigns', "objective": "campaign", "platform": "" }].concat(sortObjects("name", "", generateTreeStructure(res)))
                        }
                        resolve(temp)
                    })
                    .catch(error => {
                        ReactGaError(`Failed to fetch all Campaigns: ${error}`)
                        // if (error.response.status === 403) {
                        //     localStorage.removeItem('userJwtReport')
                        //     window.location.reload(false)
                        // }
                        reject(error)
                    })
            })
        },
        getMonitorData: async () => {
            if (stateMonitor.selected !== "none" && stateSettingsData.social.map(ele => ele?.account_name) && stateMonitor.sidepanel.length > 1 && stateMonitor.dateRange.length === 2) {
                dispatch(actions.handleUpdateMonitorDataLoadingCompleted(false))
                let tempReport = stateMonitor.selectedValue
                if (Object.keys(tempReport).length > 0) {
                    const dateRange = stateMonitor.dateRange
                    console.log(tempReport?.platform, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social);
                    let temp = monitorDataKeys.map(ele => fetchSummarizedData(ele, tempReport, dateRange, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social))

                    await Promise.allSettled(temp)
                        .then((val) => {
                            const tmp = val.map(elem => {
                                const ele = elem.value
                                if (ele) {
                                    let key = Object.keys(ele)[0]
                                    if (Object.values(ele[key] ?? {}).length > 0) {
                                        let value = 0
                                        if (Object.values(ele[key])[0]) {
                                            if (percentDataKeys.includes(key)) {
                                                value = Number((Object.values(ele[key])[0]).toFixed(2))
                                            } else {
                                                value = Number(Object.values(ele[key])[0].toFixed(currencyDataKeys.includes(key) ? 2 : 0))
                                            }
                                        }
                                        return ({ [key]: value })
                                    }

                                    return ({ [key]: 0 })
                                }
                            })
                            dispatch(actions.handleUpdateMonitorSummarizedData(Object.assign({ cost_per_app_installs: Number((isNaN(tmp[0]?.spend / tmp[10]?.app_installs) || !isFinite(tmp[0]?.spend / tmp[10]?.app_installs) ? 0 : tmp[0]?.spend / tmp[10]?.app_installs).toFixed(2)) }, ...tmp.filter(ele => ele))))
                            dispatch(actions.handleUpdateComparisionSummarized({ [tempReport.id]: Object.assign({ cost_per_app_installs: Number((isNaN(tmp[0]?.spend / tmp[10]?.app_installs) || !isFinite(tmp[0]?.spend / tmp[10]?.app_installs) ? 0 : tmp[0]?.spend / tmp[10]?.app_installs).toFixed(2)) }, ...tmp.filter(ele => ele)) }))
                        })
                        .catch(err => console.log(err))

                    temp = {}

                    for (const dataKey of Object.keys(fetchMonitorData).slice(0, 2)) {
                        let tmp = await fetchMonitorData[dataKey].map(elem => fetchChartData(elem, tempReport, dateRange, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social))
                        await Promise.allSettled(tmp)
                            .then(res => {
                                const dt = res.map(ele => {
                                    let tempor = ele.value
                                    if (Object.keys(tempor).length > 0) {
                                        Object.keys(tempor).forEach(elem => tempor[elem] = Object.values(tempor[elem]))
                                    }
                                    return tempor
                                })
                                let tempResult = Object.assign({}, ...dt)
                                if (Object.keys(dt[0]).length != 0 || Object.keys(dt[10]).length != 0){
                                    tempResult['cost_per_app_installs'] = dt?.[0]?.spend.map((ele, index) => Number((isNaN(ele / dt?.[10]?.app_installs[index]) || !isFinite(ele / dt?.[10]?.app_installs[index]) ? 0 : ele / dt?.[10]?.app_installs[index]).toFixed(2)))
                                }else{
                                    tempResult['cost_per_app_installs'] = []
                                }
                                // if (dataKey === 'region') {
                                //     tempResult[dataKey] = tempResult[dataKey].map(val => val ?? val)
                                // }
                                // console.log(dataKey, tempResult)
                                temp[dataKey] = tempResult
                            })
                            .catch(err => console.log(err))
                    }

                    dispatch(actions.handleUpdateMonitorData(temp))
                    dispatch(actions.handleUpdateMonitorDataLoading(false))
                    if (stateRightSidepanel.activeContent == 'Filter') {
                        dispatch(actions.handleRightSidepanel(false))
                        dispatch(actions.handleRightSidepanelActiveContent('none'))
                    }

                    temp = {}
                    for (const dataKey of Object.keys(fetchMonitorData).slice(2, fetchMonitorData.length)) {
                        let tmp = await fetchMonitorData[dataKey].map(elem => fetchChartData(elem, tempReport, dateRange, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social))
                        await Promise.allSettled(tmp)
                            .then(res => {
                                const dt = res.map(ele => {
                                    let tempor = ele.value
                                    if (Object.keys(tempor).length > 0) {
                                        Object.keys(tempor).forEach(elem => tempor[elem] = Object.values(tempor[elem]))
                                    }
                                    return tempor
                                })
                                let tempResult = Object.assign({}, ...dt)
                                if (Object.keys(dt[0]).length != 0 || Object.keys(dt[10]).length != 0){
                                    tempResult['cost_per_app_installs'] = dt?.[0]?.spend.map((ele, index) => Number((isNaN(ele / dt?.[10]?.app_installs[index]) || !isFinite(ele / dt?.[10]?.app_installs[index]) ? 0 : ele / dt?.[10]?.app_installs[index]).toFixed(2)))
                                }else{
                                    tempResult['cost_per_app_installs'] = []
                                }
                                // if (dataKey === 'region') {
                                //     tempResult[dataKey] = tempResult[dataKey].map(val => val ?? val)
                                // }
                                // console.log(dataKey, tempResult)
                                temp[dataKey] = tempResult
                            })
                            .catch(err => console.log(err))
                    }
                    dispatch(actions.handleUpdateMonitorData(temp))
                }
                dispatch(actions.handleUpdateMonitorDataLoading(false))
                dispatch(actions.handleUpdateMonitorDataLoadingCompleted(true))
            } else {
                dispatch(actions.handleUpdateMonitorDataLoading(false))
            }
            return true
        },
        getComparsionSummarizedData: async (dateRange) => {
            let tempReport = stateMonitor.selectedValue
            if (Object.keys(tempReport).length > 0) {
                let temp = monitorDataKeys.map(ele => fetchSummarizedData(ele, tempReport, dateRange, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social))

                await Promise.allSettled(temp)
                    .then((val) => {
                        const tmp = val.map(elem => {
                            const ele = elem.value
                            if (ele) {
                                let key = Object.keys(ele)[0]
                                if (Object.values(ele[key] ?? {}).length > 0) {
                                    let value = 0
                                    if (Object.values(ele[key])[0]) {
                                        if (percentDataKeys.includes(key)) {
                                            value = Number((Object.values(ele[key])[0]).toFixed(2))
                                        } else {
                                            value = Number(Object.values(ele[key])[0].toFixed(currencyDataKeys.includes(key) ? 2 : 0))
                                        }
                                    }
                                    return ({ [key]: value })
                                }

                                return ({ [key]: 0 })
                            }
                        })

                        dispatch(actions.handleUpdateMonitorComparisonSummarizedData(Object.assign({ cost_per_app_installs: Number((isNaN(tmp[0]?.spend / tmp[10]?.app_installs) || !isFinite(tmp[0]?.spend / tmp[10]?.app_installs) ? 0 : tmp[0]?.spend / tmp[10]?.app_installs).toFixed(2)) }, ...tmp.filter(ele => ele))))
                    })
                    .catch(err => console.log(err))
            }
        },
        getAdsetSummarizedData: (tempReport) => {
            return new Promise(async (resolve, reject) => {
                if (Object.keys(tempReport).length > 0) {
                    let temp = monitorDataKeys.map(ele => fetchSummarizedData(ele, tempReport, stateMonitor.dateRange, tempReport?.platform ? stateSettingsData.social.filter(ele => ele?.platform == platform_dict[tempReport?.platform]) : stateSettingsData.social))

                    await Promise.allSettled(temp)
                        .then((val) => {
                            const tmp = val.map(elem => {
                                const ele = elem.value
                                if (ele) {
                                    let key = Object.keys(ele)[0]
                                    if (Object.values(ele[key] ?? {}).length > 0) {
                                        let value = 0
                                        if (Object.values(ele[key])[0]) {
                                            if (percentDataKeys.includes(key)) {
                                                value = Number((Object.values(ele[key])[0]).toFixed(2))
                                            } else {
                                                value = Number(Object.values(ele[key])[0].toFixed(currencyDataKeys.includes(key) ? 2 : 0))
                                            }
                                        }
                                        return ({ [key]: value })
                                    }

                                    return ({ [key]: 0 })
                                }
                            })
                            resolve(Object.assign({ cost_per_app_installs: Number((isNaN(tmp[0]?.spend / tmp[10]?.app_installs) || !isFinite(tmp[0]?.spend / tmp[10]?.app_installs) ? 0 : tmp[0]?.spend / tmp[10]?.app_installs).toFixed(2)) }, ...tmp.filter(ele => ele)))
                        })
                        .catch(err => {
                            reject(err)
                        })
                }
            })
        },
        createCampaign: async (data) => {
            return new Promise(async (resolve, reject) => {
                let tempData = {
                    "biddingStrategy": campaign_bidStrategy[data.bidStratergy].toUpperCase(),
                    "campaignName": data.name,
                    "lifetimeBugdet": `${data.lifetimeBudget}00`,
                    "specialAdCategory": campaign_specialAdCategory_api[data.specialAdCategory].toUpperCase(),
                    "spendCap": "1000",
                    "status": campaign_status[data.status].toUpperCase(),
                    "accountId": stateSettingsData.social?.account_id ?? 'none',
                    "objective": campaign_objective[data.objective].toUpperCase()
                }
                let tempId = stateSettingsData.social?.account_id
                if (tempId) {
                    await sherlockAxios.post(`/campaignSetup/${tempId}/${getFromSessionStorage('rid')}`, tempData, { 'Content-Type': 'application/json' })
                        .then(res => {
                            ReactGaSuccess('Create Campaign', 'Campaign created Successfully')
                            resolve(res.data)
                        })
                        .catch(error => {
                            ReactGaError(`Failed to Create Campaign: ${error}`)
                            reject(error)
                        })
                } else {
                    reject({})
                }
            })
        },
        uploadData: async (data) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let params = {
                        "account_name": data.account ?? 'none',
                        "group_type": breakdowns[data.breakdown],
                        "from_date": formatDateShort(data.dateRange[0]),
                        "to_date": formatDateShort(data.dateRange[1])
                    }
                    let file = data.csv

                    // resolve({params, tempFile})
                    let formdata = new FormData();
                    formdata.append("file", file.file, file.path)

                    await mobilityAxiosReport.post(`/extract/fb_file_ingestion`, formdata, { "headers": { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }, params })
                        .then(res => {
                            ReactGaSuccess('Add Custom Marketing Data', 'Custom Marketing Data added Successfully')
                            resolve(res.data)
                        })
                        .catch(err => {
                            ReactGaError(`Failed to Create Campaign: ${err}`)
                            reject(err)
                        })
                } catch (err) {
                    ReactGaError(`Failed to Add Custom Marketing Data: ${err}`)
                    reject('Something went wrong')
                }
            })
        },
        fetchSocialAccounts: async () => {
            return new Promise((resolve, reject) => {
                mobilityAxiosReport.get(`/getaccounts/${getFromSessionStorage('rid')}`)
                    .then(response => {
                        ReactGaSuccess('Get Social Accounts', 'Social Accounts Fetched')
                        resolve(response?.data?.accountName ?? [])
                    })
                    .catch(error => {
                        ReactGaError(`Failed to Get Social Accounts: ${error}`)
                        reject(error)
                    })
            })
        }
    }), [stateMonitor, stateSettingsData])
}

export default useMonitorService