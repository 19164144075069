import authAxios from '../../../axiosSetup/authaxios.service'
import authpwlessaxios from '../../../axiosSetup/authpwlessaxios.service'
import { getfromLocalStorage, getFromSessionStorage, getRid, putToSessionStorage } from '../../../common/functions'
import { ReactGaError, ReactGaSuccess } from '../../../common/reactGA'
import { plansList } from '../../Content/Plans/planConfig'

export const loginService = async(data) => {
    return new Promise(async (resolve, reject) => {
        const temp_token = getfromLocalStorage("userJwt")
        if (temp_token)
            resolve(temp_token)
        else {
            let bodyFormData = new FormData()
            bodyFormData.append("username", data.username)
            bodyFormData.append("password", data.password)
            await authAxios.post('/login/access-token/' + getFromSessionStorage('rid'), bodyFormData)
                .then(response => {
                    ReactGaSuccess('Fetch Access Token', 'Access Token Fetched Sucessfully')
                    resolve(response.data.access_token)
                })
                .catch(error => {
                    ReactGaError(`Failed to fetch access token: ${error}`)
                    reject(error)
                })
        }
    })
};

export const getAccessToken = async(data) => {
    return new Promise(async (resolve, reject) => {
        const temp_token = getfromLocalStorage("userJwt")
        if (temp_token)
            resolve(temp_token)
        else {
            let bodyFormData = new FormData()
            bodyFormData.append("username", data.username)
            bodyFormData.append("password", data.password)
            await authpwlessaxios.post('/login/access-token/' + getFromSessionStorage('rid'), bodyFormData)
                .then(response => {
                    ReactGaSuccess('Fetch Access Token', 'Access Token Fetched Sucessfully')
                    resolve(response.data.access_token)
                })
                .catch(error => {
                    ReactGaError(`Failed to fetch access token: ${error}`)
                    reject(error)
                })
        }
    })
};

export const checkFirstLoginConfirmed = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.get('/users/firstLoginconfirmed/' + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Check First Login Confirmed', 'First Login Confirmed')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to check first login confirmed: ${error}`)
                reject(error)
            })
    })
}

export const getUserDetails = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.get('/users/me/' + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Fetch User Details', 'User Details Fetched Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to fetch user details: ${error}`)
                reject(error)
            })
    })
}

export const updateUserDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await authAxios.put(`/users/me/` + getFromSessionStorage('rid'), data)
            .then(response => {
                ReactGaSuccess('Update User Details', 'User Details Updated Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to update user details: ${error}`)
                reject(error)
            })
    })
}

export const editUserDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        await authAxios.put(`/users/${data.id}/${getFromSessionStorage('rid')}`, data)
            .then(response => {
                ReactGaSuccess('Update User Details', 'User Details Updated Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to update user details: ${error}`)
                reject(error)
            })
    })
}

export const verifyOtp = async (totp) => {
    return new Promise(async (resolve, reject) => {
        await authAxios.get(`/secrets/verify/${totp.replace(/ /g,'')}/` + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Verify OTP', 'OTP Verified Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to verify OTP: ${error}`)
                reject(error)
            })
    })
}

export const getSecretCodeQr = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.get('/secrets/me/' + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Fetch Secret Code QR', 'Secret Code QR Fetched Sucessfully')
                resolve(response.data.msg)
            })
            .catch(error => {
                ReactGaError(`Failed to fetch secret code QR: ${error}`)
                reject(error)
            })
    })
}

export const addCompany = async(data) =>{
    return new Promise(async (resolve, reject)=>{
        let bodydata = {
            "name": "",
            "companyId": "",
            "address1": "",
            "address2": "",
            "country": "",
            "state": "",
            "city": "",
            "zipcode": "",
            "companysize": 0,
            "empname": "",
            "empemail": "",
            "empphone": "",
            "empdesignation": "",
            "pan": "",
            "gstin": "",
            "accountno": "",
            "annualrev": 0,
            "nicCode": "",
            "industry": "",
            "website": "",
            "socialfb": "",
            "socialtw": ""
          }

        bodydata.name = data.companyName
        bodydata.companyId = data.companyId
        bodydata.address1 =  data.companyAddress1
        bodydata.address2 =  data.companyAddress2
        bodydata.country = data.companyCountry
        bodydata.state = data.companyState
        bodydata.city = data.companyCity
        bodydata.zipcode = data.companyPinCode
        bodydata.companysize = data.companySize
        bodydata.empname = data.employeeName
        bodydata.empemail = data.employeeEmail
        bodydata.empphone = data.employeePhone
        bodydata.empdesignation = data.employeeDesignation
        bodydata.pan = data.employeePan
        bodydata.gstin = data.companyGst
        bodydata.accountno = data.companyAccountNumber
        bodydata.annualrev = data.companyRevenue
        bodydata.nicCode = data.companyRegistrationCode
        bodydata.industry = data.companyIndustry
        bodydata.website = data.companyWebsiteLink
        bodydata.socialfb = data.companyFacebookPage
        bodydata.socialtw = data.companyTwitter
        
        
        await authpwlessaxios.post('/company/' + getFromSessionStorage('rid'), bodydata)
            .then(response => {
                ReactGaSuccess('Add Company', 'Company Added Sucessfully')
                resolve(response)
            })
            .catch(error => {
                ReactGaError(`Failed to add company: ${error}`)
                reject(error)
            })  
    })
};

export const getCompanyDetails = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.post('company/getCompanyByUser/'+ getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Fetch Company Details', 'Company Details Fetched Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to fetch company details: ${error}`)
                reject(error)
            })
    })
}

export const createUser = async (data)=>{

    return new Promise(async (resolve, reject)=>{
        await authpwlessaxios.post("/users/" + getFromSessionStorage('rid'), data)
            .then(response =>{
                ReactGaSuccess('Create User', 'User Created Sucessfully')
                resolve(response)
            })
            .catch(error=>{
                ReactGaError(`Failed to create user: ${error}`)
                reject(error)
            })
    })
}; 


export const getTotpUrl = async ()=>{

    return new Promise(async (resolve, reject)=>{
        await authAxios.get("/secrets/me/" + getFromSessionStorage('rid')).then(response=>{
            ReactGaSuccess('Fetch Totp Url', 'Totp Url Fetched Sucessfully')
            resolve(response)
        })
        .catch((error)=>{
            ReactGaError(`Failed to fetch totp url: ${error}`)
            reject(error)
        })
    })

}

export const partnerLogin = async (data)=>{

    return new Promise(async (resolve, reject)=>{

        const encrypted_obj = data
        let rid = getFromSessionStorage('rid')
        const body = {"obj": encrypted_obj}

        if(rid === ""){
            rid = getRid()
            putToSessionStorage('rid',rid)   
        }
        
        await authpwlessaxios.post("/partners/checkuser/" + rid, body).then((res)=>{
            ReactGaSuccess('Check Partner User', 'Partner User Present')
            resolve(res.data)
        })
        .catch((error)=>{
            ReactGaError(`Failed to check partner user: ${error}`)
            reject(error)
        })
    })

}

export const update_partner_user = async (data)=>{

    return new Promise(async (resolve, reject)=>{

        const encrypted_obj = data
        let rid = getFromSessionStorage('rid')
        const body = {"obj": encrypted_obj}

        if(rid === ""){
            rid = getRid()
            putToSessionStorage('rid',rid)   
        }
        
        await authpwlessaxios.put("/partners/checkuser/" + rid, body).then((res)=>{
            ReactGaSuccess('Update Partner User', 'Partner User Updated')
            resolve(res.data)
        })
        .catch((error)=>{
            ReactGaError(`Failed to update partner user: ${error}`)
            reject(error)
        })
    })

}


export const sendVerificatioMail = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.post('/email-confirmation/' + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Send Verification Mail', 'Verification Mail Sent Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to send verification mail: ${error}`)
                reject(error)
            })
    })
}

export const verifyMailOtp = async (data) => {
    return new Promise(async (resolve, reject) => {
        await authAxios.post(`/verify-email-confirmation/${data}/${getFromSessionStorage('rid')}`)
            .then(response => {
                ReactGaSuccess('Verify Mail OTP', 'Verification Mail OTP Verified Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to verify mail otp: ${error}`)
                reject(error)
            })
    })
}

export const switchDevice = async () => {
    return new Promise(async (resolve, reject) => {
        await authAxios.post('/partners/switchdevices/' + getFromSessionStorage('rid'))
            .then(response => {
                ReactGaSuccess('Switch Device', 'Device Switched Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to switch device: ${error}`)
                reject(error)
            })
    })
}

export const createAccountPlan = async (data) => {
    return new Promise(async (resolve, reject) => {
        const tempPlans = plansList.map(ele => ele.title)
        const tempData = {
            ...data,
            "planType": tempPlans.indexOf(data.planType),
            "startDate": new Date().toISOString().slice(0, -1)
        }

        await authAxios.post(`/accountPlans/${getFromSessionStorage('rid')}`, tempData)
            .then(response => {
                ReactGaSuccess('Create Account Plan', 'Account Plan Created Sucessfully')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to create account plan: ${error}`)
                reject(error)
            })
    })
}

export const getAccountPlan = async () => {
    return new Promise((resolve, reject) => {
        authAxios.get(`/accountPlans/me/${getFromSessionStorage('rid')}`)
            .then(response => {
                ReactGaSuccess('Get Account Plan', 'Account Plan Fetched')
                resolve(response.data)
            })
            .catch(error => {
                ReactGaError(`Failed to Get Account Plan: ${error}`)
                reject(error)
            })
    })
}

export const getAllSocialAccount = () => {
    return new Promise((resolve, reject) => {
        authAxios.get(`/socialAccounts/${getFromSessionStorage('rid')}`)
            .then(res => {
                ReactGaSuccess('Get All Social Account', 'All Social Account Fetched')
                resolve(res.data)
            })
            .catch(error => {
                ReactGaError(`Failed to Get All Social Account: ${error}`)
                reject(error)
            })
    })
}

export const getUserSocialAccount = () => {
    return new Promise((resolve, reject) => {
        authAxios.get(`/socialAccounts/getSocialAccount/me/${getFromSessionStorage('rid')}`)
            .then(res => {
                ReactGaSuccess('Get User Social Account', 'User Social Account Fetched')
                resolve(res.data)
            })
            .catch(error => {
                ReactGaError(`Failed to Get User Social Account: ${error}`)
                reject(error)
            })
    })
}

export const addUserSocialAccount = (data) => {
    return new Promise((resolve, reject) => {
        authAxios.post(`/socialAccounts/${getFromSessionStorage('rid')}`, data)
            .then(res => {
                ReactGaSuccess('Create User Social Account', 'User Social Account created')
                resolve(res.data)
            })
            .catch(error => {
                ReactGaError(`Failed to Create User Social Account: ${error}`)
                reject(error)
            })
    })
}

export const deleteUserSocialAccount = (social_account_id) => {
    return new Promise((resolve, reject) => {
        authAxios.delete(`/socialAccounts/deleteSocialAccount/${social_account_id}/${getFromSessionStorage('rid')}`)
            .then(res => {
                ReactGaSuccess('Delete User Social Account', 'User Social Account deleted')
                resolve(res.data)
            })
            .catch(error => {
                ReactGaError(`Failed to Delete User Social Account: ${error}`)
                reject(error)
            })
    })
}