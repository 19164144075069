import axios from 'axios'
import { getfromLocalStorage } from '../common/functions'

let host =  process.env.REACT_APP_API_URL
let host2 = process.env.REACT_APP_API_URL_V2
const sherlockAxios = axios.create({ baseURL: host, timeout: 600000 })
export const sherlockAxiosV2 = axios.create({ baseURL: host2, timeout: 600000 })
const listOfRequests = [sherlockAxios, sherlockAxiosV2]

const getToken = () => {
    return new Promise(async (resolve, reject) => {

        const temp_token = getfromLocalStorage("userJwt")
        if (temp_token)
            resolve(temp_token)
        else {
            const email = getfromLocalStorage("email")
            if (email === "") {
                reject("No email found")
            }

            var bodyFormData = new FormData()
            bodyFormData.append("username", email)
            bodyFormData.append("password", "VerySecret123!")
            axios.post(host + "/login/access-token", bodyFormData)
                .then(res => {
                    //localStorage.setItem("userJwt", res.data.access_token)
                    resolve(res.data.access_token)
                })
                .catch(error => {
                    reject(error)
                })
        }
    })
}

// Add a request interceptor
listOfRequests.forEach(request => {
    request.interceptors.request.use(
        async config => {
            let token = ""
            await getToken()
                .then(res => {
                    console.log(`getToken called ${res}`)
                    token = res
                })
                .catch(err => {
                    console.log("access err",err)
                    console.log(err)
                })

            // console.log(token)
            if (token) {
                config.headers['Authorization'] = `Bearer ${token.replace(/^"(.+(?="$))"$/, '$1')}`
            }
            if (config.headers['Content-Type'] !== 'multipart/form-data')
                config.headers['Content-Type'] = 'application/json'
            console.log("working here request access first")

            return config
        },
        error => {
            console.log("working error ",error)
            Promise.reject(error)
        })
})

console.log("working here request access")
export default sherlockAxios