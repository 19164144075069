import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import useMonitorService from '../Service/useMonitorService'

import { checkScrollVisible, getErrorMessageFromResponse } from '../../../../common/functions'
import { campaign_bidStrategy, campaign_objective, campaign_specialAdCategory, campaign_status } from '../Config/monitorConfig'
import { CustomBody1BoldButton, CustomDividerColor, TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { CustomTextFieldBody1, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import { customToastSystem } from '../../../../common/customToastify'

const CreateCampaign = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()

    const { createCampaign } = useMonitorService()

    const defaultCampaignData = {
        platform: 'facebook',
        name: '',
        status: 'Paused',
        objective: 'none',
        bidStratergy: 'none',
        specialAdCategory: 'none',
        lifetimeBudget: '100',
    }

    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [campaignData, setCampaignData] = useState(defaultCampaignData)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        await createCampaign(campaignData)
            .then(res => {
                setCampaignData(defaultCampaignData)
                customToastSystem('Campaign Created Successfully', 'success')
            })
            .catch(err => customToastSystem(getErrorMessageFromResponse(err, 'Failed to Create Campaign'), 'error'))
        setLoading(false)
    }

    const handleChangeCampaignData = (e) => setCampaignData(prev => ({...prev, [e.target.name]: e.target.value}))

    const checkButtonDisabled = () => {
        const selectCheck = [campaignData['platform'], campaignData['status'], campaignData['objective'], campaignData['bidStratergy']].some(ele => ele === 'none')
        const textfieldCheck = [campaignData['name']].some(ele => ['', '0'].includes(ele))
        const budgetFieldCheck = campaignData['lifetimeBudget'] < 100

        return selectCheck || textfieldCheck || budgetFieldCheck
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <TypographySemiBold variant="body1">Create Campaign</TypographySemiBold>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="exploreSidepanelContentScrollArea">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='platform'
                            color="primary"
                            title="Platform*"
                            options={['facebook', 'google']}
                            value={campaignData['platform']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextFieldBody1
                            name='name'
                            color="primary"
                            title="Campaign Name*"
                            placeholder="Campaign Name"
                            value={campaignData['name']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='status'
                            color="primary"
                            title="Campaign Status*"
                            options={Object.keys(campaign_status)}
                            value={campaignData['status']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='objective'
                            color="primary"
                            title="Campaign Objective*"
                            options={Object.keys(campaign_objective)}
                            value={campaignData['objective']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='bidStratergy'
                            color="primary"
                            title="Bid Stratergy*"
                            options={Object.keys(campaign_bidStrategy)}
                            value={campaignData['bidStratergy']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            name='specialAdCategory'
                            color="primary"
                            title="Special Ad Category"
                            options={Object.keys(campaign_specialAdCategory)}
                            showNone={true}
                            value={campaignData['specialAdCategory']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextFieldBody1
                            name='lifetimeBudget'
                            color="primary"
                            title="Lifetime Budget (min 100) *"
                            placeholder="Minimum 100"
                            type="number"
                            value={campaignData['lifetimeBudget']}
                            onChange={handleChangeCampaignData}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomBody1BoldButton onClick={handleSubmit} variant='outlined' disabled={checkButtonDisabled() || loading} fullWidth>
                    {loading || 'Create Campaign'}
                    {loading && <CircularProgress className={`${classesHoverActive.whiteColor}`} size={20} />}
                </CustomBody1BoldButton>
            </Grid>
        </Grid>
    )
}

export default CreateCampaign