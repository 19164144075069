import keplerGlReducer from "kepler.gl/reducers"

const mapStyles = {
    light: {
        id: 'light',
        label: 'Light',
        url: 'mapbox://styles/mapbox/light-v10',
    },
    satellite: {
        id: 'satellite',
        label: 'Satellite',
        url: 'mapbox://styles/mapbox/satellite-streets-v11',
    },
    street: {
        id: 'street',
        label: 'Street',
        url: 'mapbox://styles/mapbox/streets-v11',
    },
    dark: {
        id: 'dark',
        label: 'Dark',
        url: 'mapbox://styles/mapbox/dark-v10',
        // url: 'mapbox://styles/mapbox/light-v10',
    },
}

const keplerGl = keplerGlReducer
    .initialState({
        mapStyle: {
            mapStyles,
            styleType: 'light',
        },
        uiState: {
            activeSidePanel: null,
            // currentModal: null,
            // readOnly: true,
            centerMap: false,
            mapControls: {
                visibleLayers: {
                    show: false
                },
                mapLegend: {
                    show: true,
                    active: true
                },
                toggle3d: {
                    show: false
                },
                splitMap: {
                    show: false
                }
            }
        },
    })

export default keplerGl