import { lazy, Suspense, useEffect, useState, Fragment } from 'react'
import { createTheme } from '@material-ui/core/styles'
import { CssBaseline, Grid, ThemeProvider } from '@material-ui/core'
import { ErrorBoundary } from 'react-error-boundary'
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import ReactGA from 'react-ga'
import CacheBuster from 'react-cache-buster'
import { LicenseInfo } from '@mui/x-license-pro';

import Loading from './common/loading'

import { headersData } from './Website/Header/headerConfig'
import { CustomToastify } from './common/customToastify'
import { version } from '../package.json'
import { defaultTheme, themeMode, typography, overrides, boxShadows, gradientColors } from './config/styleConfig'
import GuestRoute from './route/guestRoute'
import AuthRoute from './route/authRoute'
import ReportVisitationSummary from './Dashboard/Content/Explore/Dashboard/reportSummary'
import CreateChart from './Dashboard/Content/BentoBox/Dashboard/createChart'
import ScheduleWorkflows from './Dashboard/Content/Repository/Dashboard/scheduleWorkflows'


const LazyLayout = lazy(() => import('./static/layout'))
const LazyWebsiteLayout = lazy(() => import('./static/websiteLayout'))
const LazyLoginConfirmed = lazy(() => import('./Website/LoginRegister/loginConfirmed'))
const LazyLoginRegisterPartner = lazy(() => import('./Website/LoginRegister/loginRegisterPartnerModal'))
const LazyErrorPage = lazy(() => import('./static/errorPage'))
const LazyPageNotFound = lazy(() => import('./static/404'))
const LazyPivotPage = lazy(() => import('./Dashboard/PivotPage/pivotPage'))

const App = () => {
  const [theme, setTheme] = useState({})
  const customPalette = useSelector(state => state.layoutReducer)

  const errorHandler = (error, errorInfo) => {
		console.log('Logging', error, errorInfo)
	}

  const TRACKING_ID = "371242500" //"G-63691QMR8H"; // YOUR_OWN_TRACKING_ID
  // Comment out initializing ReactGA statement while testing
	ReactGA.initialize(TRACKING_ID)

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
  
  useEffect(() => {
    if (Object.keys(customPalette).length > 0) {
      setTheme({overrides: overrides[customPalette.theme], shadows: boxShadows[customPalette.theme], palette: {...defaultTheme[customPalette.palette], ...gradientColors, ...themeMode[customPalette.theme], common: {black: '#121212', white: '#FAFAFA'}}, typography: {...typography, fontColor: themeMode[customPalette.theme].text.primary}})
    }
  }, [customPalette])
  const isProduction = process.env.NODE_ENV === 'production'

  const LoadingLayout = () => (
    <div style={{width: '100vw', height: '100vh'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '100%', width: '100%', padding: '10vw'}}
      >
        <Loading />
      </Grid>
    </div>
  )

  const addQueryParameter = (path, index) => {
    if (['/blogs', '/insights'].includes(path)) {
      return (
        <Fragment key={index}>
          <Route exact path={path} key={index} element={<GuestRoute />}>
            <Route exact path={path} key={index} element={<LazyWebsiteLayout />} />
          </Route>
          <Route exact path={`${path}/:activeDashboard`} key={`param_${index}`} element={<GuestRoute />}>
            <Route exact path={`${path}/:activeDashboard`} key={`param_${index}`} element={<LazyWebsiteLayout />} />
          </Route>
        </Fragment>
      )
    }
    return (
      <Route exact path={path} key={index} element={<GuestRoute />}>
        <Route exact path={path} key={index} element={<LazyWebsiteLayout />} />
      </Route>
    )
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingLayout />} //If not pass, nothing appears at the time of new version check.
    >
      <Suspense fallback={<LoadingLayout />}>
        <ErrorBoundary
          FallbackComponent={LazyErrorPage}
          onError={errorHandler}
        >
          <ThemeProvider theme={createTheme(theme)}>
            <CssBaseline />
            {/* <div className="App" style={{width: 'max(100vw, 900px)', height: 'max(100vh, 700px)'}}> */}
            <div className="App" style={{width: '100vw', height: '100vh'}}>
              <Router>
                <Routes>
                  {/* <Route path='/home'>
                    <Route path=':activeDashboard' element={<Layout />} />
                    <Route path='' element={<Layout />} />
                  </Route> */}
                  {
                    ['/terms-conditions', '/privacy-policy', '/', ...headersData.map(ele => ele.href)].map((path, index) => addQueryParameter(path, index))
                  }
                  <Route exact path="/partner-redirect" element={<GuestRoute />}>
                    <Route exact path="/partner-redirect" element={<LazyLoginRegisterPartner />} />
                  </Route>
                  <Route exact path="/loginconfirmed" element={<GuestRoute />}>
                    <Route path="/loginconfirmed" element={<LazyLoginConfirmed />} />
                  </Route>
                  {
                    // ['/'].map((path, index) => (
                    ['/home/:activeDashboard', '/home', '/explore', '/explore/:activeDashboard', '/monitor', '/monitor/:activeDashboard', '/optimize', '/optimize/:activeDashboard', '/admin', '/settings', '/settings/:activeDashboard', '/studio', '/dashboard', '/dashboard/:activeDashboard','/cdp','/cdp/:activeDashboard'].map((path, index) => (
                      <Route exact path={path} key={index} element={<AuthRoute />}>
                        <Route exact path={path} key={index} element={<LazyLayout />} />
                      </Route>
                    ))
                  }
                  <Route exact path={'/pivot'} element={<AuthRoute />}>
                    <Route exact path={'/pivot'} element={<LazyPivotPage />} />
                  </Route>
                  <Route exact path={'/summary/:activeDashboard'} element={<AuthRoute />}>
                    <Route exact path={'/summary/:activeDashboard'} element={<ReportVisitationSummary />} />
                  </Route>
                  <Route exact path={'/dashboard/create'} element={<AuthRoute />}>
                    <Route exact path={'/dashboard/create'} element={<CreateChart />} />
                  </Route>

                  <Route exact path={'/cdp/create'} element={<AuthRoute />}>
                    <Route exact path={'/cdp/create'} element={<ScheduleWorkflows/>} />
                  </Route>

                  <Route path="*" element={<LazyPageNotFound />} />
                </Routes>
              </Router>
              <CustomToastify />
            </div>
          </ThemeProvider>
        </ErrorBoundary>
      </Suspense>
    </CacheBuster>
  )
}

export default App;