import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components'
import { customToastSystem } from '../../common/customToastify';
import useExploreReportService from '../Content/Explore/Service/fetchReportsService';
import { getFromSessionStorage } from '../../common/functions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions'
import { CustomInput } from '../../static/customMuiElements';
import { CircularProgress } from '@material-ui/core';
import { useHoverActiveStyles } from '../../static/hoverActiveStyles';

function ChatComponent() {
  const [email, setEmail] = useState(window.pendo?.visitorId || '');
  const [inputText, setInputText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const { createExplorationReport } = useExploreReportService()
  const stateSearch = useSelector(state => state.searchReducer)
  const stateSettingsData = useSelector(state => state.settingsDataReducer)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const classesHoverActive = useHoverActiveStyles()

  /*
  var chatbox = document.querySelector("#chatbox");
    var inputform = document.querySelector("#inputform");
    var inputfield = document.querySelector("#inputfield");
    var email = localStorage.getItem("email");

    // Function to display a chat message in the chatbox
    function displayMessage(message, sender) {
      var senderClass = (sender == "user") ? "sent" : "received";
      var messageElement = document.querySelector("<div>", {
        class: "message " + senderClass
      });
      messageElement.text(message);
      chatbox.append(messageElement);
      chatbox.scrollTop(chatbox.prop("scrollHeight"));
    }

    // Function to handle form submission
    inputform.submit(event => {
      event.preventDefault();
      var inputText = inputfield.val();
      displayMessage(inputText, "user");
      inputfield.val("");
      var email = localStorage.getItem('email');

      // Make an AJAX request to the backend API
      var tempUrl = `https://devsearch.infiniteanalytics.com/umm?query=${inputText}&email=${email}`;
      axios.get(tempUrl, {
        "headers": {
          "Authorization": "Basic c2VhcmNodXNlcjpzRldnSUZPb0A3andIVzE0OHBMNG5XJEVlWkhKaGZ6V2hAQ3B2OWxtOTRkMDFWQUJjNzMyTQ=="
        }}).then(response => {
          var answerText = response.Answer;
          //var dataText = response["Answer based on Data"].output
          var answerMessage = answerText //+ "\t" + dataText;
          displayMessage(answerMessage, "bot");
        }).catch(err => console.error(err))
      	
    	
    });
          
    // Function to check email entered
    function checkEmailPresent() {
      var tempEmail = localStorage.getItem("email")
      // return tempEmail ? true : false
      return false
    };
  	
    // Function to store email
    var emailform = document.querySelector("#emailform");
          var emailfield = document.querySelector("#emailfield");
    document.getElementById("emailfield").defaultValue = localStorage.getItem("email")
  	
          emailform.submit(function(event) {
              event.preventDefault();
              var inputText = emailfield.val();
              localStorage.setItem("email", inputText);
          });
  */

  const scrollToTheBottom = () => {
    const chatbox = document.getElementById('chatbox');
    chatbox.scrollTop = chatbox.scrollHeight;
  }

  // Function to display a chat message in the chatbox
  function displayMessage(message, sender) {
    //console.log(message);
    const senderClass = sender === 'user' ? 'sent' : 'received';
    const messageElement = (
      <div className={`message ${senderClass}`}>
        {message.split('\n').map(ele => (
          <>
            <p>
              {ele}
            </p>
          </>
        ))}
      </div>
    );
    setChatMessages(chatMessages => ([...chatMessages, messageElement]));
    scrollToTheBottom()
  }

  // Function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    dispatch(actions.handleUpdateChat({ message: inputText, sender: 'user' }))
    setLoading(true)
    scrollToTheBottom()
    setInputText('');

    // Make an AJAX request to the backend API
    const tempUrl = process.env.REACT_APP_CONVERSATIONAL_CHAT

    /*
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic c2VhcmNodXNlcjpzRldnSUZPb0A3andIVzE0OHBMNG5XJEVlWkhKaGZ6V2hAQ3B2OWxtOTRkMDFWQUJjNzMyTQ==");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    */
    const username = 'chatbot'
    const password = 'Rk794Yz33vXWVN2'

    await fetch(tempUrl, {
      method: 'POST',
      body: JSON.stringify({
        "query": inputText,
        "email": stateSettingsData?.user?.email,
        "rid": getFromSessionStorage('rid')
      }),

      headers: {
        "Content-Type": "application/json",
        //"Authorization": `Basic ${btoa(username + ':' + password)}`,
      },

    })
      .then(response => response.text())
      .then(result => {

        let data = JSON.parse(result)

        dispatch(actions.handleUpdateChat({ message: data?.query_response?.text_output, sender: 'server' }))
        scrollToTheBottom()

        if (Object.keys(data?.query_response?.API_payload).length != 0) {
          let tempData = {
            "type": data?.query_response?.API_payload?.poitype,
            "radius": 12,
            "startDate": data?.query_response?.API_payload?.date_range_start_date ?? undefined,
            "endDate": data?.query_response?.API_payload?.date_range_end_date ?? undefined,
            "country": data?.query_response?.API_payload?.country ?? 'none',
            "visitDuration": data?.query_response?.API_payload?.visitDuration ?? 0,
            "district": data?.query_response?.API_payload?.district_codes,
            "name": data?.query_response?.API_payload?.name,
            "reportType": data?.query_response?.API_payload?.reportName,
            "ratings": data?.query_response?.API_payload?.rating,
            "brands": data?.query_response?.API_payload?.brands,
            "visitTime": [data?.query_response?.API_payload?.visit_time_start_hour, data?.query_response?.API_payload?.visit_time_end_hour],
            "visitDuration": [data?.query_response?.API_payload?.visit_duration_start_duration, data?.query_response?.API_payload?.visit_duration_end_duration],
          }
          createExplorationReport(tempData)
        }

      })
      .catch(error => console.log('error', error));
    setLoading(false)
  };

  // Function to check if an email is present
  function checkEmailPresent() {
    const tempEmail = localStorage.getItem('email');
    return !!tempEmail;
  }

  // Function to store email
  const handleEmailFormSubmit = (event) => {
    event.preventDefault();
    const userEmail = document.getElementById('emailfield').value;
    localStorage.setItem('email', userEmail);
    setEmail(userEmail);
    customToastSystem('Email Added Successfully!', 'success')
  };


  useEffect(() => {

    // document.getElementById('emailfield').defaultValue = email;

    // Add event listeners
    const inputform = document.getElementById('inputform');
    inputform.addEventListener('submit', handleFormSubmit);

    // const emailform = document.getElementById('emailform');
    // emailform.addEventListener('submit', handleEmailFormSubmit);

    // Clean up event listeners on unmount
    return () => {
      inputform.removeEventListener('submit', handleFormSubmit);
      // emailform.removeEventListener('submit', handleEmailFormSubmit);
    };
  }, [email, inputText]);

  useEffect(() => {
    scrollToTheBottom()
  }, [])

  return (
    <ChatDialog>
      {/*
      <div className="inputbox">

        <form id="emailform">
          <input
            type="text"
            placeholder="Your email ID goes here... (This is mandatory)"
            name="emailfield"
            className="inputfield"
            id="emailfield"
          />
          <button type="submit" className="submitbtn">
            Send
          </button>
        </form>

      </div>
      */}

      <div className="chatbox" id="chatbox">
        {stateSearch.chat.length > 0 ? stateSearch.chat.map((item, index) =>
          <div className={`message ${item.sender === 'user' ? 'sent' : 'received'}`}>
            {item.message.split('\n').map(ele => (
              <>
                <p>
                  {ele}
                </p>
              </>
            ))}
          </div>
        )
          :
          <p style={{ color: '#ccc' }}>Start the conversation...</p>
        }
      </div>
      <div className="inputbox">
        <form id="inputform" className="clearfix">
          <CustomInput
            type="text"
            placeholder="Type your queries here..."
            name="inputfield"
            className="inputfield"
            id="inputfield"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            required
          />
          <button type="submit" className="submitbtn" disabled={loading}>
            {loading ? <CircularProgress size={16} className={`${classesHoverActive.whiteColor}`} /> : 'Send'}
          </button>
        </form>
      </div>
    </ChatDialog>
  );
}

const ChatDialog = styled.div`
    width: 325px;
    height: 100%;

    /* Chat messages container */
    .chatbox {
      padding: 10px;
      border: 1px solid #ccc;
      height: 87%;
      overflow-y: auto;
      border-radius: 5px;
    }

    /* Chat messages */
    .message {
      padding: 10px;
      border: 1px solid #ccc;
      word-wrap: break-word;
    }
    .sent{
      border-radius: 10px 10px 2px 10px;
      margin: 10px 0px 10px 20px;
    }
    .received{
      border-radius: 10px 10px 10px 2px;
      margin: 10px 20px 10px 0px;
    }

    /* User input form */
    .inputbox {
        margin: auto;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    /* User input text field */
    .inputfield {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: none;
        font-size: 16px;
        display: inline-block;
        box-sizing: border-box;
        width: 75%;
    }
    
    /* User input submit button */
    .submitbtn {
        text-align: center;
        border-radius: 5px;
        border: none;
        background-color: #4CAF50;
        color: white;
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        box-sizing: border-box;
        width: 20%;
    }
    #emailform, #inputform{
      display: flex;
      justify-content: space-around;
    }
    
    /* Clearfix to fix floated elements */
    .clearfix::after {
        content: "";
        clear: both;
        display: table;
    }
  `

export default ChatComponent;