import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { DateRangePicker } from 'rsuite'

import { useStyles } from './styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'
import { useStyles as layoutStyles } from '../../../../static/layoutStyles'
import { useStyles as contentStyles } from '../../styles'

import * as actions from '../../../../redux/actions'
import { checkScrollVisible, getfromLocalStorage, puttoLocalStorage } from '../../../../common/functions'
import { CustomBody1BoldButton, CustomDividerColor, TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { CustomDateRange, MultipleAutocompleteFilled, MultipleSelectFilled, SingleSelectFilledAccounts } from '../../../../customMui/customMuiComponents'

const FilterSidepanel = () => {
    const classes = useStyles()
    const classesLayout = layoutStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const stateMonitor = useSelector(state => state.monitorReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)
    const commonStyles = `${classesHoverActive.foreground} ${classesHoverActive.bottomMedium} ${classesHoverActive.borderRadius} ${classes.layerCardPadding}`
    const primaryIconClass = `${classesHoverActive.primaryIcon} ${classesHoverActive.cursor}`

    const [contentHasScroll, setContentHasScroll] = useState(false)

    const handleUpdateDateRange = (val) => dispatch(actions.handleUpdateMonitorDateRange(val ? val : []))

    const handleUpdateSocialAccount = (name) => (_, val) => {
        let tempAcc = stateSettingsData.accounts.filter(ele => val.includes(ele.account_name)) ?? []
        dispatch(actions.handleUpdateSettingsSocialData(tempAcc))
        //puttoLocalStorage('social', JSON.stringify(tempAcc))
    }

    const handleSubmit = () => {
        dispatch(actions.handleUpdateMonitorDataLoading(true))
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('monitorSidepanelContentScrollArea')), [dispatch])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                >
                    <TypographySemiBold variant="body1">Filter</TypographySemiBold>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor className={`${classes.sidepanelDivider}`} />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="monitorSidepanelContentScrollArea">
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <CustomDateRange
                            color="primary"
                            name='dateRange'
                            character=' to '
                            title="Date Range"
                            onChange={handleUpdateDateRange}
                            value={stateMonitor['dateRange']}
                            disabledDate={DateRangePicker.afterToday()}
                            disabled={stateMonitor.dataLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultipleAutocompleteFilled
                            color="primary"
                            name='account'
                            title="Account"
                            value={stateSettingsData.social.map(ele => ele.account_name) ?? ['none']}
                            onChange={handleUpdateSocialAccount('account')}
                            options={stateSettingsData.accounts.map(ele => ele.account_name) ?? []}
                            disabled={stateMonitor.dataLoading}
                        />
                    </Grid>
                    {/* {
                        getfromLocalStorage('group').toLowerCase() === 'admin' &&
                        <Grid item xs={12}>
                            <SingleSelectFilled
                                color="primary"
                                name='account'
                                title="Account"
                                value={stateSettingsData.social.account_name ?? 'none'}
                                onChange={handleUpdateSocialAccount}
                                options={stateSettingsData.accounts.map(ele => ele.account_name) ?? []}
                                disabled={stateMonitor.dataLoading}
                            />
                        </Grid>
                    } */}
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomBody1BoldButton onClick={handleSubmit} disabled={stateMonitor.dataLoading} variant='outlined' fullWidth>
                    {stateMonitor.dataLoading ? <CircularProgress className={`${classesHoverActive.whiteColor}`} size={20} /> : 'Submit'}
                </CustomBody1BoldButton>
            </Grid>
        </Grid>
    )
}

export default FilterSidepanel