import * as actions from "../actions/actionTypes"

const initialState = {
    sidepanel: [],
    loading: false,
    dataLoading: false,
    reportError: false,
    selected: 'none',
    bentoData: {},
    bentoBoxActive: {
        open: false
    },
    axis: {
        x: ['total_visits', 'visitation_count'],
        y: ['poiType', 'district']
    }
}

const bentoBoxReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CREATE_BENTO_MODULE_FOR_REPORT: {
            let temp = { ...state.bentoData }
            temp[action.payload.id] = action.payload.data
            return {
                ...state,
                bentoData: temp
            }
        }
        case actions.ADD_CHART_DATA_TO_REPORT: {
            let temp = { ...state.bentoData }
            temp[action.payload.id] = action.payload.data
            return {
                ...state,
                bentoData: temp
            }
        }
        case actions.ADD_AXIS_FOR_CHART: {
            return {
                ...state,
                axis: action.payload
            }
        }
        case actions.UPDATE_BENTOBOX_SAVE_CONFIG: {
            let temp = { ...state.bentoData }
            let tempSelectReport = temp[action.payload.id]
            tempSelectReport.push(action.payload.data)
            return {
                ...state,
                bentoData: temp
            }
        }
        case actions.SELECT_BENTOBOX_SIDEPANEL: {
            return {
                ...state,
                selected: action.payload,
            }
        }
        case actions.UPDATE_MAP_STYLE:
            return {
                ...state,
                mapStyle: action.payload
            }
        case actions.UPDATE_BENTOBOX_SIDEPANEL:
            return {
                ...state,
                sidepanel: [...action.payload ?? [], ...state.sidepanel]
            }
        case actions.UPDATE_BENTOBOX_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actions.UPDATE_BENTOBOX_REPORT_LOADING:
            return {
                ...state,
                dataLoading: action.payload,
                reportError: false
            }
        case actions.UPDATE_BENTOBOX_REPORT_ERROR:
            return {
                ...state,
                dataLoading: false,
                reportError: action.payload,
                selected: 'none',
                selectedConfig: {}
            }
        case actions.BENTOBOX_TOGGLE:
            return {
                ...state,
                bentoBoxActive: action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default bentoBoxReducer