import React, { useMemo } from 'react'

import mobilityAxiosReport from '../../../../axiosSetup/mobilityaxiosReport.service'

import { getFromSessionStorage } from '../../../../common/functions'
import { ReactGaError, ReactGaSuccess } from '../../../../common/reactGA'

const useSearchService = () => {
    return useMemo(() => ({
        getSearchResult: (nlq, account_name, db_id) => {
            return new Promise(async (resolve, reject) => {
                let params = {nlq: nlq, account_name: account_name, db_id}
                
                await mobilityAxiosReport.get(`/searchEngine/${getFromSessionStorage('rid')}`, {params})
                    .then(res => {
                        let temp = {...res.data, type: db_id}
                        if (temp.Output?.length > 0) {
                            let updatedTemp = {
                                ...temp,
                                Output: temp.Output.map((ele ,index) => ({id: index, ...ele}))
                            }
                            ReactGaSuccess('Get Search Result', 'Search Result Found')
                            resolve(updatedTemp)
                        } else {
                            ReactGaSuccess('Empty Search Data', 'Search Result Found')
                            resolve(temp)
                        }
                    })
                    .catch(err => {
                        ReactGaError(`Failed to Get Search Result: ${err}`)
                        reject(err)
                    })
            })
        },
        getSearchResultReport: (nlq, account_name, db_id, countryCode) => {
            return new Promise(async (resolve, reject) => {
                let params = { 
                    nlq: nlq, 
                    account_name: account_name, 
                    //country: countryCode
                }
                
                await mobilityAxiosReport.get(`/searchEngine/search_report_generation/${getFromSessionStorage('rid')}`, {params})
                    .then(res => {
                        let temp = {...res.data, type: res.data?.db_id ?? 'none'}
                        if (temp.Output?.length > 0) {
                            let updatedTemp = {
                                ...temp,
                                Output: temp.Output.map((ele ,index) => ({id: index, ...ele}))
                            }
                            ReactGaSuccess('Get Search Result', 'Search Result Found')
                            resolve(updatedTemp)
                        } else {
                            ReactGaSuccess('Empty Search Data', 'Search Result Found')
                            resolve(temp)
                        }
                    })
                    .catch(err => {
                        ReactGaError(`Failed to Get Search Result: ${err}`)
                        reject(err)
                    })
            })
        },
        getSearchResultImage: (nlq) => {
            return new Promise(async (resolve, reject) => {
                let params = {prompt: nlq}
                let result = {
                    'type': 'Creatives',
                    'Output': []
                }

                let fetchImages = Array.from(Array(6)).map(_ => mobilityAxiosReport.get(`/searchEngine/image-genration/${getFromSessionStorage('rid')}`, {params}))
                await Promise.allSettled(fetchImages)
                    .then(res => {
                        res.forEach(ele => {
                            result['Output'].push(ele?.value?.data ?? '')
                        })
                        ReactGaSuccess('Get Search Result Image', 'Search Result Image Found')
                    })
                    .catch(err => {
                        ReactGaError(`Failed to Get Search Result Image: ${err}`)
                        reject(err)
                    })
                resolve(result)
            })
        }
    }), [])
}

export default useSearchService