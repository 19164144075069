import * as actions from "../actions/actionTypes"

const initialState = {
    connectedDB: null,
    version: 1
}

const jsStoreReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.ADD_CONNECTED_DB:
            return {
                ...state,
                connectedDB: action.payload,
                version: state.version + 1
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default jsStoreReducer