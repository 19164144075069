import React, { useEffect, useState, Fragment, useMemo, useRef } from 'react'
import { Button, ButtonGroup, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Grow, Menu, MenuItem, MenuList, Paper, Popper, RadioGroup } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import KeplerGlSchema from 'kepler.gl/schemas'
import { layerConfigChange } from 'kepler.gl/actions'
import { useLocation, useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DateRangePicker } from 'rsuite'


import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useStyles as layoutStyles } from '../../../../static/layoutStyles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import { ReactComponent as FocusIcon } from '../../../../assets/icons/Focus.svg'
import { ReactComponent as UploadIcon } from '../../../../assets/icons/Upload.svg'
import { ReactComponent as AddIcon } from '../../../../assets/icons/Add Report.svg'
import { ReactComponent as HexagonIcon } from '../../../../assets/icons/Hexagon.svg'
import { ReactComponent as LocationIcon } from '../../../../assets/icons/Location.svg'
import { ReactComponent as BackIcon } from '../../../../assets/icons/Toggle Down Arrow.svg'
import { ReactComponent as MenuVerticalIcon } from '../../../../assets/icons/Menu Vertical.svg'
import { ReactComponent as IncrementDecrementIcon } from '../../../../assets/icons/Increment Decrement.svg'

import { missingFields } from './layerConfig'
import * as actions from '../../../../redux/actions'
import useExploreService from '../Service/useExploreService'
import useExploreReportService from '../Service/fetchReportsService'
import { customToast, customToastSystem } from '../../../../common/customToastify'
import useSidepanelConfig from '../../../Sidepanel/config/sidepanelConfig'
import { getIdValue, multipleDatasetsConfig } from '../Config/keplerLayerConfig'
import { getPoisFromKey, multiLayerMapping, poiData, reportTypes, tempDataset } from '../Config/exploreConfig'
import { capitalizeFirstLetter, checkScrollVisible, districtCodeToDistrict, formatDateShort, getErrorMessageFromResponse } from '../../../../common/functions'
import { CustomDateRange, CustomRadioSquareIcon, CustomTreeSelect, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import { CustomBody1BoldButton, CustomDividerColor, CustomDrawerListContent, CustomFormControlLabelBodySecondary, CustomGrid, CustomHoverTooltip, CustomMenuItemBodyPrimary, CustomMenuItemLabelLarge, CustomSvgIcon, CustomSvgIcon20, CustomSvgIconLabelLarge, CustomTextFieldBody1Medium, Heading2, LabelMedium, TypographySemiBold } from '../../../../customMui/customMuiStyles'

import LayerCardContent from './layerCardContent'
import { index } from 'd3'

const LayerCards = () => {
    const classes = useStyles()
    const classesLayout = layoutStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const activeDashboard = useParams().activeDashboard
    const currentLocation = location.pathname.match(/^\/[^\/]+/)[0]
    const stateKepler = useSelector(state => state.keplerGl)
    const stateExplore = useSelector(state => state.exploreReducer)
    const stateRightSidepanel = useSelector(state => state.rightSidepanelReducer)
    const anchorRef = useRef()
    const { ClassByColor, sidepanelContentConfig, ListElementByColor, reportTooltips } = useSidepanelConfig()
    const [reportName, setNewReportName] = useState()
    const [nameError, setNameError] = useState(true)
    const [renameReportDialog, setRenameReportDialog] = useState(false)

    const { getPoiSname } = useExploreService()
    const { getMultipleData, mergeDataLayers, saveMultilayerReport } = useExploreReportService()

    const options = ['Add New Data', 'Add New Layer', 'Upload Data']
    const commonStyles = `${classesHoverActive.foreground} ${classesHoverActive.bottomMedium} ${classesHoverActive.borderRadius} ${classes.layerCardPadding}`
    const primaryIconClass = `${classesHoverActive.primaryIcon} ${classesHoverActive.cursor}`

    const [combinedLayerIds, setCombinedLayerIds] = useState(Object.keys(stateExplore.mergedLayers))
    const [currentNode, setCurrentNode] = useState(null)
    const [activeMergeIcon, setActiveMergeIcon] = useState(null)
    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedMenu, setSelectedMenu] = useState(null)
    const [layerCards, setLayerCards] = useState([])
    const [reportConfig, setReportConfig] = useState({})
    const [selectedLayer, setSelectedLayer] = useState({})
    const [selectedLayersForMultilayer, setSelectedLayersForMultilayer] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [reportType, setReportType] = useState('none')
    const [loading, setLoading] = useState(false)
    const [currentReportParams, setCurrentReportParams] = useState({})
    const [missingReportFields, setMissingReportFields] = useState([])
    const [activeAddDataStep, setActiveAddDataStep] = useState(0)
    const [reportData, setReportData] = useState({
        pois: [],
        dateRange: [],
        resolution: 'none'
    })
    const [selectedReports, setSelectedReports] = useState(stateExplore?.reportData?.[`${stateExplore.selected}-merged`]?.datasets.map(ele => ele.info.id.split('_')[1]) ?? [])
    const [multiLayerType, setMultilayerType] = useState()

    const handleChangeActiveMergeIcon = (id) => () => id === activeMergeIcon ? setActiveMergeIcon(null) : setActiveMergeIcon(id)
    const handleUnmergeLayer = (id) => () => dispatch(actions.handleUnMergeExploreLayers(id))

    const onDragEndInner = async ({ source, _destination, combine }) => {
        dispatch(actions.handleUpdateExploreReportLoading(true))
        if (combine) {
            setCurrentNode(null)
            const destinationId = getIdValue(combine.draggableId)
            const sourceId = getIdValue(layerCards[source.index].id)
            dispatch(actions.handleMergeExploreLayers({ source: combine.draggableId, destination: layerCards[source.index].id ?? null }))
            let tempReportTypes = [...new Set(layerCards.filter(ele => [destinationId, sourceId].includes(getIdValue(ele.id))).map(ele => getIdValue(ele.id, true)))]
            if (sourceId === destinationId) {
                customToastSystem("Cannot merge same dataset", 'info')
                dispatch(actions.handleUpdateExploreDownloadProgress(null))
                dispatch(actions.handleUpdateExploreReportLoading(false))
                setLoading(false)
                return
            }
            if (!tempReportTypes.includes('poi')) {
                customToastSystem("Cannot merge dataset without POI report", 'info')
                dispatch(actions.handleUpdateExploreDownloadProgress(null))
                dispatch(actions.handleUpdateExploreReportLoading(false))
                setLoading(false)
                return
            }

            // get the poi report id
            let tempPoiId = getIdValue(layerCards.filter(ele => ele?.id.includes('poi') ?? false)?.[0]?.id)
            //get the non poi report id (if their is any, else it return 0)
            let tempOtherReportId = getIdValue(layerCards.filter(ele => !ele?.id.includes('poi') ?? false)?.[0]?.id)

            let tempResultReportId = 'none'
            if (!layerCards[source.index].id.includes('poi')) {
                tempResultReportId = multiLayerMapping[`poi-${getIdValue(layerCards[source.index].id, true)}`] ?? 'none'
            } else {
                tempResultReportId = multiLayerMapping[`poi-${getIdValue(layerCards.filter(ele => ele.id.includes(combine.draggableId))?.[0]?.id, true)}`] ?? 'none'
            }

            setMultilayerType(tempResultReportId)
            setSelectedReports([Number(tempPoiId), Number(tempOtherReportId)])
            // console.log(tempResultReportId, tempPoiId, tempOtherReportId, tempReportTypes, layerCards)

            await getMultipleData(tempResultReportId, [Number(tempPoiId), Number(tempOtherReportId)], stateExplore.selectedConfig ?? {})
                .then(res => {
                    handleShowAddDataLayer('save')
                    customToast('Data Loaded Successfully', 'success')
                })
                .catch(error => {
                    customToastSystem(getErrorMessageFromResponse(error, "Could not fetch multiple data"), 'error')
                })
        } else {
            // setCurrentNode(null)
            // dispatch(actions.handleReorderExploreLayers({id: stateExplore.selected, source: source.index, destination: destination.index}))
        }
        setLoading(false)
        dispatch(actions.handleUpdateExploreDownloadProgress(null))
        dispatch(actions.handleUpdateExploreReportLoading(false))
    }

    const handleMergeLayers = () => {
        setLoading(true)
        const source = {
            index: selectedLayersForMultilayer[selectedLayersForMultilayer.length - 1],
            droppableId: 'droppable'
        }
        const _destination = {}
        const combine = {
            draggableId: stateKepler.exploration.visState?.layers?.[selectedLayersForMultilayer[0]].id,
            droppableId: 'droppable'
        }
        onDragEndInner({ source, _destination, combine })
    }

    const onDragUpdate = ({ combine }) => {
        setCurrentNode(combine?.draggableId ?? null)
    }
    const draggableClasses = (snapshotInner, item) => {
        let tempClass = ''
        if (snapshotInner.isDragging) {
            tempClass += `${classesHoverActive.cursorGrab} ${classes.dragOpacity}`
        } else {
            tempClass += `${classesHoverActive.cursor}`
        }
        if (currentNode === item) {
            tempClass += ` ${classes.dragBorder}`
        }
        return tempClass
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index)
        setOpen(false)
    }

    const handleToggle = () => setOpen((prevOpen) => !prevOpen)

    const handleCloseButton = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleChangeLayer = (item, index) => (_) => {
        if (stateRightSidepanel.button === "select") {
            setSelectedLayersForMultilayer(prev => {
                if (selectedLayersForMultilayer.includes(index)) {
                    return prev.filter(ele => ele != index)
                } else {
                    if (selectedLayersForMultilayer.length >= 2) {
                        customToastSystem("You Can't select more than 2 layers", "info")
                        return [...prev]
                    }
                    return [...prev, index]
                }
            })
        } else {
            setSelectedLayer(item)
        }
    }

    const handleChangeReportType = (e) => setReportType(e.target.value)
    const handleUpdateCurrentReportParams = () => {
        let temp = stateExplore.selectedConfig ?? {}
        if (Object.keys(temp).length > 0) {
            setCurrentReportParams({
                ...temp,
                poitype: [0, 6, 8, 9].includes(temp.reportType) ? temp.poitype : "",
                oldId: temp.id
            })
        }
    }
    const handleChangeReportData = (name) => (e) => setReportData({ ...reportData, [name]: e.target.value })
    const handleChangeTreeSelect = (name) => (val) => setReportData({ ...reportData, [name]: val ? val : [] })

    const getReportConfig = (id) => {
        let tempId = id.split('_')
        tempId = tempId[tempId.length - 1] ?? 'none'
        let tempReport = stateExplore.selectedConfig
        setReportConfig(tempReport ?? 'None')
        return true
    }

    const defaultLayers = () => {
        let tempKepler = stateKepler.exploration
        if (tempKepler) {
            let tempConfig = tempKepler.visState?.layers ?? []
            if (tempConfig[0]) {
                if (Object.keys(selectedLayer).length > 0) {
                    handleChangeLayer(tempConfig[0])
                }
                getReportConfig(tempConfig[0].config?.dataId ?? '')
            }
            return tempConfig
        }
        return []
    }

    const optionsMenuClick = (val) => (e) => {
        setAnchorEl(e.currentTarget)
        setSelectedMenu(val)
        e.stopPropagation()
    }
    const handleClose = (e) => {
        setAnchorEl(null)
        setSelectedMenu(null)
        e.stopPropagation()
    }
    const handleClickMenu = (val, layerId) => (e) => {
        handleClose(e)
        let tempLayer = stateKepler.exploration?.visState.layers.filter(ele => ele.id === layerId)?.[0] ?? {}
        if ('Show/Hide' === val && tempLayer) {
            dispatch(layerConfigChange(tempLayer, { isVisible: !tempLayer.config.isVisible }))
        }
        e.stopPropagation()
    }

    const handleShowAddDataLayer = (val) => {
        setLoading(true)
        if (val) {
            setActiveAddDataStep(0)
            setReportType('none')
        }
        dispatch(actions.handleButtonChange(val))
        setLoading(false)
    }

    const handleSaveMultiLayer = async (event) => {
        event.preventDefault()
        setLoading(true)
        const data = {
            name: reportName,
            reportType: multiLayerType,
            selected_multilayer_reports: selectedReports.join(","),
            district_codes: stateExplore.selectedConfig?.district_codes,
            poitype: stateExplore.selectedConfig?.poitype,
            start_date: stateExplore.selectedConfig?.start_date ?? undefined,
            end_date: stateExplore.selectedConfig?.end_date ?? undefined,
        }
        await saveMultilayerReport(data).then(res => {
            handleShowAddDataLayer(false)
            setRenameReportDialog(false)
            dispatch(actions.handleRightSidepanel(false))
            dispatch(actions.handleRightSidepanelActiveContent('none'))
        })
        setLoading(false)
    }

    const handleCloseReportRenameDialog = () => {
        setNameError(false)
        setRenameReportDialog(false);
        setNewReportName("")
    }
    const handleCreateNew = async () => {
        // let temp = stateExplore.reportData[stateExplore.selected]
        // if (Object.keys(temp).length > 0) {
        //     console.log(multipleDatasetsConfig(temp, tempDataset))
        // }
        if (missingFields[reportType]?.length > 0 && activeAddDataStep !== 1) {
            setActiveAddDataStep(1)
            return
        }
        setLoading(true)
        let newReportConfig = {
            ...currentReportParams,
            name: `${currentReportParams.name} - ${reportType}`,
            reportType: reportType,
            district: currentReportParams.district_codes,
            startDate: formatDateShort(reportData.dateRange[0]),
            endDate: formatDateShort(reportData.dateRange[1]),
        }
        switch (reportType) {
            case 'mobility':
                newReportConfig['type'] = reportData.resolution.includes('Block') ? 'admin.district_block' : 'admin.district_aggregate'
                break
            case 'poi':
            case 'poiInternal':
            case 'poiVisitation':
            case 'visitation':
                newReportConfig['type'] = getPoisFromKey(reportData.pois).join(',')
                break
            case 'wellbeingPrices':
                newReportConfig['type'] = 'WBI_prices'
                break
            case 'wellbeingPlaces':
                newReportConfig['type'] = 'WBI_places'
                break
            case 'wellbeingCensus':
                newReportConfig['type'] = 'WBI_census'
                break
            case 'Congregation':
            case 'CovidInfectionreports':
            case 'CovidVaccinationReports':
            case 'districtMigrationHAs':
                newReportConfig['type'] = 'admin.district_aggregate'
                break
            default:
                break
        }
        // console.log(newReportConfig)
        handleShowAddDataLayer(false)
        await mergeDataLayers(newReportConfig)
        setLoading(false)
    }

    const handleAddExistingData = (item) => async () => {
        setLoading(true)
        await mergeDataLayers({ ...item, oldId: stateExplore.selected }, true)
            .then(res => {
                if (Object.keys(res).length > 0) {
                    handleShowAddDataLayer('select')
                }
            })
        setLoading(false)
    }

    const handleUploadCustomData = (e) => {
        let tempFile = e.target.files[0]
    }

    const HeaderLayout = useMemo(() => {
        if (stateRightSidepanel.button) {
            return (
                <TypographySemiBold variant="body1">Select Data to Add</TypographySemiBold>
            )
        }
        if (Object.keys(selectedLayer).length > 0) {
            return (
                <Fragment>
                    <TypographySemiBold variant="body1" className={`${classesHoverActive.textOverflow}`}><CustomSvgIconLabelLarge component={BackIcon} onClick={handleChangeLayer({})} className={`${classes.backIconRotate} ${classesHoverActive.secondaryIcon} ${classes.layerSummaryIconMarginTop} ${classesHoverActive.cursor}`} /> Layer Cards</TypographySemiBold>
                    {/* <CustomSvgIconLabelLarge component={SaveIcon} className={`${primaryIconClass}`} /> */}
                </Fragment>
            )
        }
        return (
            <Fragment>
                <TypographySemiBold variant="body1">Layer Cards</TypographySemiBold>
                {/* <CustomSvgIconHeading3 component={AddIcon} className={`${primaryIconClass}`} /> */}
            </Fragment>
        )
    }, [selectedLayer, stateRightSidepanel.button])

    const ContentLayout = useMemo(() => {
        if (Object.keys(selectedLayer).length > 0) {
            return (
                <CustomGrid
                    container
                    direction="row"
                    spacing={2}
                >
                    <LayerCardContent layerData={selectedLayer} reportConfig={reportConfig} />
                </CustomGrid>
            )
        }
        return (
            <Fragment>
                <DragDropContext onDragEnd={onDragEndInner} onDragUpdate={onDragUpdate}>
                    <Droppable droppableId="droppable" direction="vertical" isCombineEnabled isReorderEnabled={false}>
                        {(provided, _snapshot) => (
                            <CustomGrid
                                container
                                direction="row"
                                spacing={2}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    layerCards.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(providedInner, snapshotInner) => {
                                                let tempId = item.config.dataId.split('_').at(-1)
                                                let tempConfig = {}
                                                for (let ele of stateExplore.sidepanel) {
                                                    if (ele.type == 'folder') {
                                                        tempConfig = ele?.children.find(element => tempId === element?.id.toString()) ?? {};
                                                        if (Object.keys(tempConfig).length > 0){
                                                            break
                                                        }
                                                    } else if (tempId == ele?.id.toString()) {
                                                        tempConfig = ele
                                                        break
                                                    }
                                                }
                                                
                                                return (
                                                    <Grid item xs={12} className={`${classes.enableOptionsIcon}`} key={index} ref={providedInner.innerRef} {...providedInner.draggableProps} {...providedInner.dragHandleProps}>
                                                        <Grid container direction="column" spacing={1} className={`${classesHoverActive.cursor}`} onClick={handleChangeLayer(item, index)}>
                                                            <Grid item xs={12} style={{ zIndex: 1 }}>
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    spacing={1}
                                                                    style={{ backgroundImage: item?.config?.colorField?.name ? `linear-gradient(135deg, ${item.config.visConfig.colorRange.colors[item.config.visConfig.colorRange.colors.length - 1]} 0%, ${item.config.visConfig.colorRange.colors[0]} 100%)` : `linear-gradient(135deg, rgb(${item.config.color.join(',')}) 0%, rgb(${item.config.color.join(',')}) 100%)`, border: selectedLayersForMultilayer.includes(index) && stateRightSidepanel.button == "select" ? '2px solid rgb(41, 163, 234)' : 'none', opacity: item.config?.isVisible ? 1 : 0.7 }}
                                                                    className={`${commonStyles} ${classesHoverActive.leftBorderGradient2}`}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                                                                            <LabelMedium variant='body1' className={`${classesHoverActive.textOverflow}`}><HexagonIcon className={`${classesHoverActive.secondaryIcon} ${classes.layerSummaryIconMarginRight}`} />{item.config.label}</LabelMedium>
                                                                            <div onClick={optionsMenuClick(item.id)} className={`${classes.optionsMenu}`}><CustomSvgIcon20 component={MenuVerticalIcon} className={`${primaryIconClass}`} /></div>
                                                                            <Menu
                                                                                id={`${item.id}_menu`}
                                                                                anchorEl={anchorEl}
                                                                                open={selectedMenu === item.id && Boolean(anchorEl)}
                                                                                getContentAnchorEl={null}
                                                                                onClose={handleClose}
                                                                                classes={{
                                                                                    paper: classesLayout.menuPaper,
                                                                                }}
                                                                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                                                            >
                                                                                <CustomMenuItemLabelLarge onClick={handleClickMenu('Show/Hide', item.id)}>{item.config.isVisible ? 'Hide' : 'Show'}</CustomMenuItemLabelLarge>
                                                                            </Menu>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <CustomDividerColor />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <LabelMedium variant='body1'>
                                                                            <CustomSvgIconLabelLarge component={LocationIcon} className={`${classes.layerSummaryIconMarginRight}`} />
                                                                            <CustomHoverTooltip title={districtCodeToDistrict(tempConfig?.district_codes ?? '')} placement="bottom" arrow>
                                                                                <div className={`${classesHoverActive.textOverflow} ${classesHoverActive.tooltipOverflow}`}>{districtCodeToDistrict(tempConfig?.district_codes ?? '')}</div>
                                                                            </CustomHoverTooltip>
                                                                        </LabelMedium>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <LabelMedium variant='body1'>
                                                                            <CustomSvgIconLabelLarge component={FocusIcon} className={`${classes.layerSummaryIconMarginRight}`} />
                                                                            <CustomHoverTooltip title={getPoiSname(tempConfig?.poitype ?? '')} placement="bottom" arrow>
                                                                                <div className={`${classesHoverActive.textOverflow}`}>{getPoiSname(tempConfig?.poitype ?? '')}</div>
                                                                            </CustomHoverTooltip>
                                                                        </LabelMedium>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <LabelMedium variant='body1'>
                                                                            <CustomSvgIconLabelLarge component={IncrementDecrementIcon} className={`${classes.layerSummaryIconMarginRight}`} />
                                                                            <CustomHoverTooltip title={capitalizeFirstLetter(item?.config?.colorField?.name ?? 'None')} placement="bottom" arrow>
                                                                                <div className={`${classesHoverActive.textOverflow}`}>{capitalizeFirstLetter(item?.config?.colorField?.name ?? 'None')}</div>
                                                                            </CustomHoverTooltip>
                                                                        </LabelMedium>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                    )}
                            </CustomGrid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Fragment>
        )
    }, [selectedLayer, anchorEl, layerCards, selectedLayersForMultilayer, stateRightSidepanel.button])

    const GetAddDataContent = () => {
        switch (activeAddDataStep) {
            case 1:
                return (
                    <Grid container spacing={1}>
                        {
                            !['poi', 'poiInternal', 'wellbeingPrices', 'wellbeingPlaces', 'wellbeingCensus'].includes(reportType) &&
                            <Grid item xs={12}>
                                <CustomDateRange
                                    color="primary"
                                    name='dateRange'
                                    character=' to '
                                    title="Date Range"
                                    onChange={handleChangeTreeSelect('dateRange')}
                                    value={reportData['dateRange']}
                                    disabledDate={DateRangePicker.afterToday()}
                                />
                            </Grid>
                        }
                        {
                            ['visitation', 'poiVisitation', 'poi', 'poiInternal'].includes(reportType) &&
                            <Grid item xs={12}>
                                <CustomTreeSelect
                                    name='pois'
                                    color="primary"
                                    treeData={poiData}
                                    placeholder='Select POIs'
                                    title="Point of Interest(s)"
                                    value={reportData['pois']}
                                    onChange={handleChangeTreeSelect('pois')}
                                    notFoundContent={<LabelMedium variant="body1">No POIs found</LabelMedium>}
                                />
                            </Grid>
                        }
                        {
                            ['mobility'].includes(reportType) &&
                            <Grid item xs={12}>
                                <SingleSelectFilled
                                    color="primary"
                                    name='resolution'
                                    title="Resolution"
                                    value={reportData['resolution']}
                                    onChange={handleChangeReportData('resolution')}
                                    options={['Block (175m Radius)', 'County/District (Official Boundary Polygon)']}
                                />
                            </Grid>
                        }
                    </Grid>
                )
            default:
                return (
                    <RadioGroup aria-label="report-selection" name="Report Type" value={reportType} onChange={handleChangeReportType}>
                        <CustomGrid container spacing={2}>
                            {
                                reportTypes("multilayer").map((ele, idx) => (
                                    <Grid item xs={12} key={idx}>
                                        <div className={`${classesHoverActive.bottomMedium} ${classes.reportPadding} ${classesHoverActive.foreground}`}>
                                            <CustomFormControlLabelBodySecondary value={ele.value} control={<CustomRadioSquareIcon dimensions="body2" />} label={ele.title} />
                                            <LabelMedium variant="body1" className={`${classes.reportDescription}`}>{ele.description}</LabelMedium>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </CustomGrid>
                    </RadioGroup>
                )
        }
    }

    const checkSubmitDisabled = () => {
        if (loading) {
            return true
        } else if (activeAddDataStep === 0) {
            return reportType === 'none'
        } else if (activeAddDataStep === 1) {
            switch (reportType) {
                case "poi":
                case "poiInternal":
                    return reportData.pois.length === 0
                case "visitation":
                case "poiVisitation":
                    return reportData.pois.length === 0 || reportData.dateRange.length === 0
                case "mobility":
                    return reportData.dateRange.length === 0 || reportData.resolution === 'none'
                case "CovidInfectionreports":
                case "CovidVaccinationReports":
                case "Congregation":
                    return reportData.dateRange.length === 0
                default:
                    return true
            }
        }
        return true
    }

    const checkMultiLayerButtonDisabled = () => {
        let tempDisabled = stateExplore.selected === 'none' || stateExplore.reportLoading || loading
        if (Object.keys(stateKepler?.exploration?.visState?.datasets ?? {}).length === 2 || stateExplore.reportData[`${stateExplore.selected}-merged`]) {
            tempDisabled = true
        }
        return tempDisabled
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [dispatch])

    useEffect(() => {

        const listOfReportTypes = stateExplore?.reportData?.[`${stateExplore.selected}-merged`]?.datasets.map(ele => ele.info.id.split('_')[0]) ?? []

        if (listOfReportTypes.length > 1) {
            const poiIndex = listOfReportTypes.findIndex(ele => ele == 'poi')
            if (poiIndex == 0) {
                setMultilayerType(multiLayerMapping[listOfReportTypes.join('-')])
            }
            else {
                listOfReportTypes.reverse()
                setMultilayerType(multiLayerMapping[listOfReportTypes.join('-')])
            }
        }
        setSelectedLayer({})
    }, [])

    useEffect(() => {
        setLayerCards(defaultLayers())
        handleUpdateCurrentReportParams()
    }, [stateKepler.exploration, stateExplore.selected])

    useEffect(() => {
        if (Object.keys(currentReportParams).length > 0) {
            let tempPois = currentReportParams.poitype ? currentReportParams.poitype?.split(",") : []
            let temp = {
                pois: tempPois,
                resolution: ["admin.district_aggregate", "admin.district_block"].includes(currentReportParams.poitype) ? currentReportParams.poitype : 'none'
            }
            if (currentReportParams.start_date && currentReportParams.end_date) {
                let tempStartDate = currentReportParams.start_date.split("-")
                let tempEndDate = currentReportParams.end_date.split("-")
                temp["dateRange"] = [new Date(tempStartDate[0], tempStartDate[1] - 1, tempStartDate[2]), new Date(tempEndDate[0], tempEndDate[1] - 1, tempEndDate[2])]
            }
            setReportData(prev => ({ ...prev, ...temp }))
        }
    }, [currentReportParams])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                >
                    {HeaderLayout}
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor className={`${classes.sidepanelDivider}`} />
            </Grid>
            {stateRightSidepanel.button === 'select' && <>
                <Heading2>Select two layers to merge together</Heading2>
            </>
            }
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="exploreSidepanelContentScrollArea">
                {
                    ['new', 'list'].includes(stateRightSidepanel.button) ||
                    <CustomGrid
                        container
                        direction="row"
                        spacing={2}
                    >
                        {ContentLayout}
                    </CustomGrid>
                }
                {stateRightSidepanel.button === 'new' && <GetAddDataContent />}
                {stateRightSidepanel.button === 'list' && (
                    <CustomGrid container direction="row" spacing={2}>
                        {
                            sidepanelContentConfig[currentLocation].map((item, idx) => {

                                if (item.type == 'folder') {
                                    return item.children.map((item, index) => {
                                        const CustomIcon = item.icon
                                        const CustomListItemColor = ListElementByColor[item.color]
                                        const tempPath = item?.path.toString() === activeDashboard

                                        return (<CustomHoverTooltip title={reportTooltips(item)} placement="right" arrow interactive>
                                            <CustomListItemColor
                                                selected={tempPath}
                                                onClick={handleAddExistingData(item)}
                                                disabled={stateExplore.reportLoading || item.path.toString() === activeDashboard}
                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                                button
                                            >
                                                <div className={`${classesHoverActive.textOverflow}`} style={{ width: '100%' }}>
                                                    {
                                                        (tempPath && stateExplore.reportLoading) &&
                                                        <CircularProgress size={16} className={`${classesHoverActive.whiteColor} ${classesHoverActive.marginRightOne}`} />
                                                    }
                                                    {
                                                        (tempPath && stateExplore.reportLoading) ||
                                                        <CustomIcon className={`${tempPath ? classesHoverActive.whiteColor : classesHoverActive[ClassByColor[item.color]]} ${classesHoverActive.marginRightOne}`} />
                                                    }
                                                    {item.name}
                                                </div>
                                            </CustomListItemColor>
                                        </CustomHoverTooltip>)
                                    })
                                } else if (!item.selected_multilayer_reports) {
                                    const CustomIcon = item.icon
                                    const CustomListItemColor = ListElementByColor[item.color]
                                    const tempPath = item?.path.toString() === activeDashboard

                                    return (
                                        <CustomHoverTooltip title={reportTooltips(item)} placement="right" arrow interactive>
                                            <CustomListItemColor
                                                key={idx}
                                                selected={tempPath}
                                                onClick={handleAddExistingData(item)}
                                                disabled={stateExplore.reportLoading || item.path.toString() === activeDashboard}
                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                                button
                                            >
                                                <div className={`${classesHoverActive.textOverflow}`} style={{ width: '100%' }}>
                                                    {
                                                        (tempPath && stateExplore.reportLoading) &&
                                                        <CircularProgress size={16} className={`${classesHoverActive.whiteColor} ${classesHoverActive.marginRightOne}`} />
                                                    }
                                                    {
                                                        (tempPath && stateExplore.reportLoading) ||
                                                        <CustomIcon className={`${tempPath ? classesHoverActive.whiteColor : classesHoverActive[ClassByColor[item.color]]} ${classesHoverActive.marginRightOne}`} />
                                                    }
                                                    {item.name}
                                                </div>
                                            </CustomListItemColor>
                                        </CustomHoverTooltip>
                                    )
                                }
                            })
                        }
                    </CustomGrid>
                )}
            </Grid>
            {
                ['new', 'list', 'select', 'save'].includes(stateRightSidepanel.button) ||
                <Grid item xs={12} className={`${classes.flexHeader}`}>
                    <CustomGrid container spacing={1}>
                        {/*
                        <Grid item xs={6}>
                            <CustomBody1BoldButton style={{ height: '10vh' }} onClick={() => handleShowAddDataLayer('select')} variant='outlined' fullWidth>
                                {loading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                                {loading || "Multilayer"}
                            </CustomBody1BoldButton>
                        </Grid>
                        */}
                        <CustomBody1BoldButton style={{ height: '10vh' }} onClick={() => handleShowAddDataLayer('list')} variant='outlined' disabled={checkMultiLayerButtonDisabled()} fullWidth>
                            {loading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                            {loading || "Load data"}
                        </CustomBody1BoldButton>
                        {/* <Grid item xs={2}>
                            <CustomBody1BoldButton variant='outlined' component="label" fullWidth disabled={checkMultiLayerButtonDisabled()}><CustomSvgIcon component={AddIcon} /><input type="file" accept=".csv" onChange={handleUploadCustomData} hidden /></CustomBody1BoldButton>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomBody1BoldButton variant='outlined' component="label" fullWidth disabled={checkMultiLayerButtonDisabled()}><CustomSvgIcon component={UploadIcon} /><input type="file" accept=".csv" onChange={handleUploadCustomData} hidden /></CustomBody1BoldButton>
                        </Grid> */}
                    </CustomGrid>
                </Grid>
            }
            {
                stateRightSidepanel.button == 'save' && <Grid item xs={12} className={`${classes.flexHeader}`}>
                    <CustomGrid container spacing={1}>
                        <CustomBody1BoldButton style={{ height: '10vh' }} onClick={() => setRenameReportDialog(true)} variant='outlined' fullWidth>
                            {loading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                            {loading || "Save"}
                        </CustomBody1BoldButton>
                    </CustomGrid>
                </Grid>
            }
            {
                stateRightSidepanel.button &&
                <Grid item xs={12} className={`${classes.flexHeader}`}>
                    {stateRightSidepanel.button === 'select' && (
                        <CustomGrid container spacing={1}>
                            <Grid item xs={6}>
                                <CustomBody1BoldButton style={{ height: '6vh' }} onClick={() => handleShowAddDataLayer(false)} variant='text' fullWidth>
                                    Cancel
                                </CustomBody1BoldButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomBody1BoldButton style={{ height: '6vh' }} onClick={() => handleMergeLayers()} variant='outlined' fullWidth disabled={selectedLayersForMultilayer.length != 2}>
                                    {loading && <CircularProgress className={`${classesHoverActive.whiteColor}`} size={18} />}
                                    {loading || "Merge Layers"}
                                </CustomBody1BoldButton>
                            </Grid>
                        </CustomGrid>
                    )}
                    <CustomGrid container spacing={1}>
                        {
                            ['new'].includes(stateRightSidepanel.button) &&
                            <Grid item xs={12}>
                                <CustomBody1BoldButton onClick={handleCreateNew} variant='outlined' disabled={checkSubmitDisabled()} fullWidth>
                                    {loading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                                    {loading || (missingFields[reportType]?.length > 0 && activeAddDataStep !== 1 ? 'Next' : 'Create Data Layer')}
                                </CustomBody1BoldButton>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <CustomBody1BoldButton onClick={() => handleShowAddDataLayer(false)} variant="text" disabled={loading} fullWidth>Back to Layers</CustomBody1BoldButton>
                        </Grid>
                    </CustomGrid>
                </Grid>
            }
            <Dialog
                open={renameReportDialog}
                onClose={handleCloseReportRenameDialog}
                classes={{
                    paper: classesLayout.menuPaper,
                }}
            >
                <form onSubmit={handleSaveMultiLayer}>
                    <DialogTitle>Add Report Name</DialogTitle>
                    <DialogContent>
                        <CustomTextFieldBody1Medium
                            autoFocus
                            margin="dense"
                            id="rename-report"
                            label="Report Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ minLength: 1, maxLength: 100 }}
                            value={reportName}
                            onChange={(e) => {
                                setNewReportName(e.target.value)
                                const validPattern = /^[a-zA-Z0-9 :,_-]*$/;
                                const onlySpaces = /^\s*$/

                                if (!validPattern.test(e.target.value) || e.target.value.length == 0 || onlySpaces.test(e.target.value)) {
                                    setNameError(true);
                                } else {
                                    setNameError(false);
                                }
                            }}
                            error={nameError}
                            helperText={
                                nameError ? "Please enter report name (A-Z, a-z, 0-9, _ - , : and spaces only)" : ""
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    return handleSaveMultiLayer(e)
                                }
                            }}
                            required
                            pattern
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button size='medium' onClick={handleCloseReportRenameDialog}>Cancel</Button>
                        <Button type="submit" size='medium' disabled={nameError}>
                            {loading ? <CircularProgress size={16} className={`${classesHoverActive.defaultColor} ${classesHoverActive.marginRightOne}`} /> : <span>Submit</span>}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    )
}

export default LayerCards