import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, RadioGroup } from '@material-ui/core'
import { DateRangePicker } from 'rsuite'
import { useSelector, useDispatch } from 'react-redux'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import { ReactComponent as BackIcon } from '../../../../assets/icons/Toggle Down Arrow.svg'

import * as actions from '../../../../redux/actions'
import { brandsForPOIs, getCountryCode, getPoisFromKey, poiData, ratingMarks, reportListReverse, reportTypes, socialStatus, visitDurationMarks, visitTimeMarks, wbiMarks } from '../Config/exploreConfig'
import { capitalizeFirstLetter, checkScrollVisible, districtCodeToDistrictDropdowns, formatDateShort } from '../../../../common/functions'
import useExploreReportService from '../Service/fetchReportsService'
import { countries, districts, states } from '../../../../config/combinedCountryStateDistrict'
import { CustomDateRange, CustomDoubleSlider, CustomRadioSquareIcon, CustomTextFieldBody1, CustomTreeSelect, MultipleAutocompleteFilled, MultipleAutocompleteFilledWithSelectAll, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import { CustomBody1BoldButton, CustomDividerColor, CustomFormControlLabelBodySecondary, CustomGrid, CustomSvgIconLabelLarge, LabelMedium, TypographySemiBold } from '../../../../customMui/customMuiStyles'

const CreateReport = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const { createExplorationReport } = useExploreReportService()
    const stateRightSidepanel = useSelector(state => state.rightSidepanelReducer)
    const defaultReportData = {
        reportType: 'none',
        pois: [],
        locations: [],
        brands: [],
        dateRange: [],
        name: '',
        resolution: 'none',
        ratings: [ratingMarks[0].value, ratingMarks[ratingMarks.length - 1].value],
        visitDuration: [visitDurationMarks[0].value, visitDurationMarks[visitDurationMarks.length - 1].value],
        visitTime: [visitTimeMarks[0].value, visitTimeMarks[visitTimeMarks.length - 1].value],
        social_status: []
    }

    const [activeStep, setActiveStep] = useState(0)
    const [reportData, setReportData] = useState(defaultReportData)
    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [brands, setBrands] = useState([])

    const handleUpdateActiveStep = (val) => (_) => setActiveStep(val)
    const handleChangeReportData = (name) => (e) => setReportData({ ...reportData, [name]: e.target.value })
    const handleChangeMultipleReportData = (name) => (_, val) => setReportData({ ...reportData, [name]: val ? val : [] })

    const handleChangeTreeSelect = (name) => (val) => {
        setReportData({ ...reportData, [name]: val ? val : [] })

        if (name == "pois") {
            const allTheBrands = new Set();

            for (const poi of val) {
                for (const property in brandsForPOIs) {
                    if (property.startsWith(poi)) {
                        brandsForPOIs[property].forEach(brand => allTheBrands.add(brand))
                    }
                }
            }

            const arrayOfBrandObj = new Array();
            for (const brand of allTheBrands) {
                let obj = {
                    value: brand
                }
                arrayOfBrandObj.push(obj)
            }

            setBrands(arrayOfBrandObj)
        }
    }

    const checkButtonDisabled = () => {
        switch (activeStep) {
            case 1:
                let tempCheck = reportData.name && reportData.locations.length > 0
                switch (reportData.reportType) {
                    case 'poiVisitation':
                    case 'visitation':
                    case 'wbiVisitation':
                        return !(tempCheck && reportData.dateRange.length > 0 && reportData.pois.length > 0)
                    case 'poi':
                    case 'poiInternal':
                        return !(tempCheck && reportData.pois.length > 0)
                    case 'mobility':
                        return !(tempCheck && reportData.resolution !== 'none')
                    case 'Congregation':
                    case 'districtMigrationHAs':
                        return !(tempCheck && reportData.dateRange.length > 0)
                    case 'wellbeingPrices':
                    case 'wellbeingPlaces':
                    case 'wellbeingCensus':
                    case 'wbiVisitation':
                    case 'CovidInfectionreports':
                    case 'CovidVaccinationReports':
                    default:
                        return !tempCheck
                }
            default:
                return reportData['reportType'] === 'none'
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        switch (activeStep) {
            case 1:
                let tempData = {
                    name: reportData.name,
                    reportType: reportData.reportType,
                    district: reportData.locations.map(ele => ele.value),
                    startDate: formatDateShort(reportData.dateRange[0], -1) ?? undefined,
                    endDate: formatDateShort(reportData.dateRange[1], 1) ?? undefined,
                    ratings: reportData.ratings
                }
                let tem = reportData.locations[0]?.name?.includes('United States')
                if (tem) {
                    tempData['country'] = 'US'
                } else {
                    tem = getCountryCode[reportData.locations[0]?.value?.split(',')?.[0]?.split('.')?.[0] ?? 'none'] ?? 'None'
                    tempData['country'] = tem
                }
                // console.log(tempData['district'])
                tempData['district'] = [...new Set(tempData['district'])].join(',')

                switch (reportData.reportType) {
                    case 'mobility':
                        tempData['type'] = reportData.resolution.includes('Block') ? 'admin.district_block' : 'admin.district_aggregate'
                        break
                    case 'poi':
                    case 'poiInternal':
                    case 'poiVisitation':
                    case 'visitation':
                        tempData['type'] = getPoisFromKey(reportData.pois).join(',')
                        tempData['visitDuration'] = reportData['visitDuration']
                        tempData['visitTime'] = reportData['visitTime']
                        tempData['brands'] = reportData['brands'].map(ele => ele.value).join(',')
                        break
                    case 'wellbeingPrices':
                        tempData['type'] = 'WBI_prices'
                        break
                    case 'wellbeingPlaces':
                        tempData['type'] = 'WBI_Places'
                        break
                    case 'wbiVisitation':
                        tempData['type'] = getPoisFromKey(reportData.pois).join(',')
                        tempData['visitDuration'] = reportData['visitDuration']
                        tempData['visitTime'] = reportData['visitTime']
                        tempData['brands'] = reportData['brands'].map(ele => ele.value).join(',')
                        const wbi_score_arr = reportData.social_status.flatMap(ele => ele.value)
                        tempData['wbi_score'] = [Math.min(...wbi_score_arr), Math.max(...wbi_score_arr)]
                        break
                    case 'wellbeingCensus':
                        tempData['type'] = 'WBI_census'
                        break
                    case 'Congregation':
                    case 'CovidInfectionreports':
                    case 'CovidVaccinationReports':
                    case 'districtMigrationHAs':
                        tempData['type'] = 'admin.district_aggregate'
                        break
                    default:
                        break
                }
                //console.log(tempData)
                await createExplorationReport(tempData)
                break
            default:
                handleUpdateActiveStep(activeStep + 1)()
                break
        }
        setLoading(false)
        return true
    }

    const CreateReportLayout = useMemo(() => {
        switch (activeStep) {
            case 1:
                let temp = reportTypes().filter(report => report.value === reportData['reportType'])?.[0]?.title ?? ''

                return ({
                    headerText: <Fragment><CustomSvgIconLabelLarge component={BackIcon} onClick={handleUpdateActiveStep(activeStep - 1)} className={`${classes.backIconRotate} ${classesHoverActive.primaryIcon} ${classes.layerSummaryIconMarginTop} ${classesHoverActive.cursor}`} /><span className={`${classesHoverActive.textOverflow}`}>Setup an {temp} Report</span></Fragment>,
                    content: (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <CustomTextFieldBody1
                                    name='name'
                                    color="primary"
                                    title="Report Name"
                                    placeholder="Report Name"
                                    value={reportData['name']}
                                    onChange={handleChangeReportData('name')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultipleAutocompleteFilled
                                    color="primary"
                                    name='locations'
                                    title="Location(s)"
                                    value={reportData['locations']}
                                    getOptionLabel={option => capitalizeFirstLetter(option.name)}
                                    options={[...countries, ...states, ...districts]}
                                    textFieldProps={{ placeholder: 'Select Location(s)' }}
                                    onChange={handleChangeMultipleReportData('locations')}
                                    limitTags={1}
                                />
                            </Grid>
                            {
                                !['poi', 'poiInternal', 'wellbeingPrices', 'wellbeingPlaces', 'wellbeingCensus'].includes(reportData.reportType) &&
                                <Grid item xs={12}>
                                    <CustomDateRange
                                        color="primary"
                                        name='dateRange'
                                        character=' to '
                                        title="Date Range"
                                        onChange={handleChangeTreeSelect('dateRange')}
                                        value={reportData['dateRange']}
                                        disabledDate={DateRangePicker.afterToday()}
                                    />
                                </Grid>
                            }
                            {
                                ['visitation', 'poiVisitation', 'poi', 'poiInternal', 'wbiVisitation'].includes(reportData.reportType) &&
                                <>
                                    <Grid item xs={12}>
                                        <CustomTreeSelect
                                            name='pois'
                                            color="primary"
                                            treeData={poiData}
                                            placeholder='Select POIs'
                                            title="Point of Interest(s)"
                                            value={reportData['pois']}
                                            onChange={handleChangeTreeSelect('pois')}
                                            notFoundContent={<LabelMedium variant="body1">No POIs found</LabelMedium>}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MultipleAutocompleteFilled
                                            color="primary"
                                            name='brands'
                                            title="Brand(s)"
                                            value={reportData['brands']}
                                            getOptionLabel={option => option.value}
                                            options={brands}
                                            textFieldProps={{ placeholder: 'Select Brand(s)' }}
                                            onChange={handleChangeMultipleReportData('brands')}
                                            limitTags={1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomDoubleSlider
                                            title="Rating"
                                            color="primary"
                                            value={reportData['ratings']}
                                            onChange={handleChangeMultipleReportData('ratings')}
                                            valueLabelDisplay="auto"
                                            marks={ratingMarks}
                                            min={ratingMarks[0].value}
                                            max={ratingMarks[ratingMarks.length - 1].value}
                                            step={0.1}
                                        />
                                    </Grid>
                                </>
                            }
                            {
                                !['poi', 'poiInternal', 'mobility', 'wellbeingPrices', 'wellbeingPlaces', 'wellbeingCensus'].includes(reportData.reportType) &&
                                <>
                                    <Grid item xs={12}>
                                        <CustomDoubleSlider
                                            title="Visit Time"
                                            color="primary"
                                            value={reportData['visitTime']}
                                            onChange={handleChangeMultipleReportData('visitTime')}
                                            valueLabelDisplay="auto"
                                            marks={visitTimeMarks}
                                            min={visitTimeMarks[0].value}
                                            max={visitTimeMarks[visitTimeMarks.length - 1].value}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomDoubleSlider
                                            title="Visit Duration"
                                            color="primary"
                                            value={reportData['visitDuration']}
                                            onChange={handleChangeMultipleReportData('visitDuration')}
                                            valueLabelDisplay="auto"
                                            marks={visitDurationMarks}
                                            min={visitDurationMarks[0].value}
                                            max={visitDurationMarks[visitDurationMarks.length - 1].value}
                                        />
                                    </Grid>
                                </>
                            }
                            {
                                ['wbiVisitation'].includes(reportData.reportType) &&
                                <>
                                    <Grid item xs={12}>
                                        <MultipleAutocompleteFilled
                                            title="Social Status"
                                            color="primary"
                                            options={socialStatus}
                                            getOptionLabel={option => option.name}
                                            value={reportData['social_status']}
                                            onChange={handleChangeMultipleReportData('social_status')}
                                            textFieldProps={{ placeholder: 'Social Status' }}
                                            limitTags={2}
                                        />
                                    </Grid>
                                </>
                            }
                            {
                                ['mobility'].includes(reportData.reportType) &&
                                <Grid item xs={12}>
                                    <SingleSelectFilled
                                        color="primary"
                                        name='resolution'
                                        title="Resolution"
                                        value={reportData['resolution']}
                                        onChange={handleChangeReportData('resolution')}
                                        options={['Block (175m Radius)', 'County/District (Official Boundary Polygon)']}
                                    />
                                </Grid>
                            }
                        </Grid>
                    ),
                    buttonText: ['visitation', 'poiVisitation', 'wbiVisitation'].includes(reportData.reportType) ? 'Create Audience Report' : 'Create Report'
                })
            default: {
                return ({
                    headerText: <Fragment>Select a Report to Create</Fragment>,
                    content: (
                        <Fragment>
                            <RadioGroup aria-label="report-selection" name="Report Type" value={reportData['reportType']} onChange={handleChangeReportData('reportType')}>
                                <CustomGrid container spacing={2}>
                                    {
                                        reportTypes().map((ele, idx) => (
                                            <Grid item xs={12} key={idx}>
                                                <div className={`${classesHoverActive.bottomMedium} ${classes.reportPadding} ${classesHoverActive.foreground}`}>
                                                    <CustomFormControlLabelBodySecondary value={ele.value} control={<CustomRadioSquareIcon dimensions="body2" />} label={ele.title} />
                                                    <LabelMedium variant="body1" className={`${classes.reportDescription}`}>{ele.description}</LabelMedium>
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </CustomGrid>
                            </RadioGroup>
                        </Fragment>
                    ),
                    buttonText: 'Next Step'
                })
            }
        }
    }, [activeStep, reportData])

    useEffect(() => {
        if (Object.keys(stateRightSidepanel.report).length > 0) {
            setReportData(prev => ({
                ...prev,
                reportType: reportListReverse[stateRightSidepanel.report?.reportType] ?? 'none',
                pois: stateRightSidepanel.report?.poitype.split(',') ?? [],
                locations: districtCodeToDistrictDropdowns(stateRightSidepanel.report?.district_codes, stateRightSidepanel.report?.country) ?? [],
                dateRange: [],
                name: `Visitation ${stateRightSidepanel.report?.name}` ?? '',
                resolution: 'none'
            }))
            handleSubmit()
            dispatch(actions.handleCreateVisitReport({}))
        }
    }, [])
    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [activeStep])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <TypographySemiBold variant="body1">{CreateReportLayout.headerText}</TypographySemiBold>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="exploreSidepanelContentScrollArea">
                {CreateReportLayout.content}
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomBody1BoldButton onClick={handleSubmit} variant='outlined' disabled={checkButtonDisabled() || loading} fullWidth>
                    {loading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                    {loading || CreateReportLayout.buttonText}
                </CustomBody1BoldButton>
            </Grid>
        </Grid>
    )
}

export default CreateReport