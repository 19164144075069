import React, { useEffect, useState } from "react";
import "../Common/dataUpload.css";
import { ReactComponent as Logo64 } from "../../../../assets/Logo 64px.svg";
import { ReactComponent as UploadFile } from "../../../../assets/icons/uploadFile.svg";
import { ReactComponent as AwsUploadFile } from "../../../../assets/icons/awsupload.svg";
import { ReactComponent as AzureUploadFile } from "../../../../assets/icons/azureupload.svg";
import { ReactComponent as DatabricksUploadFile } from "../../../../assets/icons/databricksupload.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deletebin.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { Accordion, AccordionTab } from "primereact/accordion";
import BarChart from "../Common/charts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions";
import { useSelector } from "react-redux";
import useExploreService from "../../Explore/Service/useExploreService";
import { districtCodeToDistrict } from "../../../../common/functions";
import { Dropdown } from "primereact/dropdown";
import { reportList, reportListMap } from "../../Explore/Config/exploreConfig";
import useSidepanelConfig from "../../../Sidepanel/config/sidepanelConfig";
import useBentoboxService from "../../BentoBox/Service/fetchBentoboxService";
import sherlockAxios from "../../../../axiosSetup/sherlockaxios.service";
import { getFromSessionStorage } from "../../../../common/functions";
import {Button} from "primereact/button"
import { sherlockCdpAxios } from "../../../../axiosSetup/sherlockcdpaxios.service";
const workflowStepType = {
  EMAILTOIFA : 0,
    IFAVISITATION: 1,
    VISITS : 2,
    POITYPEEMBEDDING : 3,
    POITYPEMODEL : 4,
    POITYPECLUSTER : 5,
    IFABEHAVIOR : 6,
    BEHAVIOREMBEDDING : 7,
    BEHAVIORMODEL : 8,
    BEHAVIORCLUSTER : 9
}
function ScheduleWorkflows() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getPoiSname } = useExploreService();
  const handleUpdateSidepanelActiveContent = (data) =>
    dispatch(actions.handleRightSidepanelActiveContent(data));

  const [loading,setLoading] = useState(false)
  const stateExplore = useSelector((state) => state.exploreReducer);
  const stateCdp = useSelector((state) => state.cdpReducer)
  const {createChart, createBento, getChart} = useBentoboxService()
  const [workflowStep, setWorkflowStep] = useState(0);
  const [openList, setOpenList] = useState([false, false, false, false, false]);
  const [reportsData, setReportsData] = useState([]);
  const [activeReport, setActiveReport] = useState({});
  const [workflowDetails,setWorkflowDetails] =  useState({})
  const [workflowList,setWorkflowList] = useState([
    {
      name: "Enrichment",
      data: [
        {
          name: "Identification for Advertisers (IFAs)",
          queue: false,
          type:[0],
          disabled:true
        },
      ],
    },
    {
      name: "Extraction",
      data: [
        {
          name: "Persona",
          queue: false,
          type:[1,2]
        },
      ],
    },
    {
      name: "Persona",
      data: [
        {
          name: "Behavior",
          queue: false,
          type:[9]
        },
        {
          name: "POI Type",
          queue: false,
          type:[5]
        },
      ],
    },
    // {
    //   name: "Marketing",
    //   data: [
    //     {
    //       name: "Lead Scoring",
    //       queue: false,
    //     },
    //     {
    //       name: "Sales by H9 Location",
    //       queue: false,
    //     },
    //   ],
    // }
    
  ])

  const queueWorkflow = (index,subIndex) =>{
   // console.log("changing index ",index,subIndex)
    let tempWorkflow = [...workflowList]
    tempWorkflow[index].data[subIndex]={
      ...tempWorkflow[index].data[subIndex],
      queue:!tempWorkflow[index].data[subIndex].queue
    }
   // console.log(tempWorkflow[index].data[subIndex])
    setWorkflowList(tempWorkflow)
  }

  useEffect(() =>{
   if(stateCdp?.workflowData?.id){
    let details = {...stateCdp.workflowData}
    setWorkflowDetails(details)
   }
  },[stateCdp])

  useEffect(() => {
    console.log("state explore ", stateExplore);
    let reports = [];
    stateExplore.sidepanel.map((item, idx) => {
      if (item?.type === "folder") {
        item.children.map((currentReport, reportIndex) => {
          reports.push({
            name: currentReport?.name,
            id: currentReport?.id,
            reportType: reportListMap[currentReport?.reportType],
            district_codes: currentReport?.district_codes,
            start_date: currentReport?.start_date,
            end_date: currentReport?.end_date,
            poitype: currentReport?.poitype,
            type:item?.type
          });
        });
      } else {
        reports.push({
          name: item?.name,
          id: item?.id,
          reportType: reportListMap[item?.reportType],
          district_codes: item?.district_codes,
          start_date: item?.start_date,
          end_date: item?.end_date,
          poitype: item?.poitype,
          type:item?.type
        });
      }
    });
    setReportsData(reports);
  }, [stateExplore]);

  useEffect(() => {
    console.log("report data ", reportsData);
  }, [reportsData]);


  const createWorkflowSubmit  = (data) => {
    return new Promise(async (resolve, reject) => {
        await sherlockCdpAxios.post(`/workflow/${getFromSessionStorage('rid')}`,data)
            .then(res => {
             // console.log(" creat workflopw res ",res)
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

let tst ={
  "name":"Vis by POI: Auto mumbaitest create workflows",
  "description":"Vis by POI: Auto mumbai",
  "type":1,
  "status":0,
  "report_id":11958,
  "id":6,
  "user_id":278,
  "company_id":3
}
  const intiateWorkflow = async (data) =>{
    console.log("calling for this data ",data)
    return new Promise(async (resolve, reject) => {
      await sherlockCdpAxios.post(`/workflow_steps/${workflowDetails.id}/${getFromSessionStorage('rid')}`,data)
          .then(res => {
            console.log("single intiate flow ",res)
              resolve(res)
          })
          .catch(err => {
              reject(err)
          })
  })
  }

  const createWorkFlow = async () =>{
    setLoading(true)
     await createWorkflowSubmit({
      "name": activeReport.name + "test create workflows",
      "description": activeReport.name,
      "type": 1,
      "status": 0,
      "report_id": activeReport?.id
    }).then(res =>{
      console.log("create work flow ",res.data)
      setWorkflowDetails(res.data)
      setWorkflowStep(workflowStep+1)
    }).catch(err =>{
      console.log("error : ",err)
    })
    setLoading(false)
      // set worflow id and in redux also

  }
  
  const saveWorkflow = async () =>{
    let addedWorkFlow = []
    workflowList.forEach((currItem) =>{
      currItem.data.forEach((subItem) =>{
     //   console.log("subitem ",subItem.queue)
          if(subItem.queue)
           {

            subItem.type.forEach((currtype) =>{
              console.log("adding types ",currtype)
              addedWorkFlow.push({type:currtype,name:subItem.name})
            })
           }
      })
    })
    console.log("workign calling promise all",addedWorkFlow)
    Promise.all([...addedWorkFlow.map((currItem) => intiateWorkflow({
      "name": currItem.name,
      "description": currItem.name,
      "type": currItem.type,
      "status": 0
    }))]).then(res =>{
      console.log("workflow initiate response ",res)
      handleCreateModule()
    }).catch((err) =>{
        console.log(" error: ",err)
    })
    

   
  } 
  const handleCreateModule = async () => {

    let barchartPayload ={
      "payload": {
        "name": activeReport?.name,
        "type": "big_number",
        "limit": 0,
        "offset": 0,
        "params": {
          "request_id": [
            {
              "operation": "EQ",
              "value": workflowDetails?.id,// "test_cdp_delta_write_2024-08-07_16_42_01"
              "type": "VARCHAR"
            }
          ],
          "visited_poi_text": [
            {
              "operation": "ISNOT",
              "value": "null",
              "type": "VARCHAR"
            }
          ]
        },
        "metrics":[
          {
            aggregator: 'SUM',
            expression: 'auto_car_dealership',
            metric_name: 'sum',
            verbose_name: 'auto_car_dealership'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_maintenance',
            metric_name: 'sum',
            verbose_name: 'auto_car_maintenance'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_parts',
            metric_name: 'sum',
            verbose_name: 'auto_car_parts'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_tire',
            metric_name: 'sum',
            verbose_name: 'auto_car_tire'
          },
          {
            aggregator: 'SUM',
            expression: 'building_office_tax_advisor',
            metric_name: 'sum',
            verbose_name: 'building_office_tax_advisor'
          },
          {
            aggregator: 'SUM',
            expression: 'facility_education_school',
            metric_name: 'sum',
            verbose_name: 'facility_education_school'
          },
          {
            aggregator: 'SUM',
            expression: 'food_nightlife_bar_pub',
            metric_name: 'sum',
            verbose_name: 'food_nightlife_bar_pub'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_asian',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_asian'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_fast_food',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_fast_food'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_pizza_pasta',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_pizza_pasta'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_taqueria',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_taqueria'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_dentist',
            metric_name: 'sum',
            verbose_name: 'health_clinic_dentist'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_physician',
            metric_name: 'sum',
            verbose_name: 'health_clinic_physician'
          },
          {
            aggregator: 'SUM',
            expression: 'leisure_indoor_cinema',
            metric_name: 'sum',
            verbose_name: 'leisure_indoor_cinema'
          },
          {
            aggregator: 'SUM',
            expression: 'service_finance_insurance',
            metric_name: 'sum',
            verbose_name: 'service_finance_insurance'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_beauty',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_beauty'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_hair',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_hair'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_nail',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_nail'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_electronics_mobile',
            metric_name: 'sum',
            verbose_name: 'shop_electronics_mobile'
          },
          {
            aggregator: 'SUM',
            expression: 'transport_fuel_gas',
            metric_name: 'sum',
            verbose_name: 'transport_fuel_gas'
          },
          {
            aggregator: 'SUM',
            expression: 'travel_accommodation_hotel',
            metric_name: 'sum',
            verbose_name: 'travel_accommodation_hotel'
          }
        ],
        "database": {
          "name": "pois",
          "backend": "presto",
          "allows_subquery": true,
          "disable_data_preview": false
        },
        "table_name": 16,
        "schema_name": "pois",
        "spark_job_id": activeReport?.id,
        "datasource_name": 16,
        "cache_timeout": null,
        "time_grain_sqla": null,
        "verbose_map": null,
        "owners": [
        {
            "first_name": "Akshat",
            "id": 254,
            "last_name": "Jain",
            "username": "akshat.jain@infiniteanalytics.com"
        }
    ],
    "template_params": null,

      },
      "chart_type": "bar_chart",
      "chart_metadata": {}
    }

    let bubblechartPayload =  {
      "payload": {
        "name": activeReport?.name,
        "type": "big_number",
        "limit": 0,
        "offset": 0,
        "params": {
          "request_id": [
            {
              "operation": "EQ",
              "value": workflowDetails?.id,// "test_cdp_delta_write_2024-08-07_16_42_01"
              "type": "VARCHAR"
            }
          ],
          "visited_poi_text": [
            {
              "operation": "ISNOT",
              "value": "null",
              "type": "VARCHAR"
            }
          ]
        },
        "metrics":[
          {
            aggregator: 'SUM',
            expression: 'auto_car_dealership',
            metric_name: 'sum',
            verbose_name: 'auto_car_dealership'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_maintenance',
            metric_name: 'sum',
            verbose_name: 'auto_car_maintenance'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_parts',
            metric_name: 'sum',
            verbose_name: 'auto_car_parts'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_tire',
            metric_name: 'sum',
            verbose_name: 'auto_car_tire'
          },
          {
            aggregator: 'SUM',
            expression: 'building_office_tax_advisor',
            metric_name: 'sum',
            verbose_name: 'building_office_tax_advisor'
          },
          {
            aggregator: 'SUM',
            expression: 'facility_education_school',
            metric_name: 'sum',
            verbose_name: 'facility_education_school'
          },
          {
            aggregator: 'SUM',
            expression: 'food_nightlife_bar_pub',
            metric_name: 'sum',
            verbose_name: 'food_nightlife_bar_pub'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_asian',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_asian'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_fast_food',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_fast_food'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_pizza_pasta',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_pizza_pasta'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_taqueria',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_taqueria'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_dentist',
            metric_name: 'sum',
            verbose_name: 'health_clinic_dentist'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_physician',
            metric_name: 'sum',
            verbose_name: 'health_clinic_physician'
          },
          {
            aggregator: 'SUM',
            expression: 'leisure_indoor_cinema',
            metric_name: 'sum',
            verbose_name: 'leisure_indoor_cinema'
          },
          {
            aggregator: 'SUM',
            expression: 'service_finance_insurance',
            metric_name: 'sum',
            verbose_name: 'service_finance_insurance'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_beauty',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_beauty'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_hair',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_hair'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_nail',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_nail'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_electronics_mobile',
            metric_name: 'sum',
            verbose_name: 'shop_electronics_mobile'
          },
          {
            aggregator: 'SUM',
            expression: 'transport_fuel_gas',
            metric_name: 'sum',
            verbose_name: 'transport_fuel_gas'
          },
          {
            aggregator: 'SUM',
            expression: 'travel_accommodation_hotel',
            metric_name: 'sum',
            verbose_name: 'travel_accommodation_hotel'
          }
        ],
        "database": {
          "name": "pois",
          "backend": "presto",
          "allows_subquery": true,
          "disable_data_preview": false
        },
        "table_name": 16,
        "schema_name": "pois",
        "spark_job_id": activeReport?.id,
        "datasource_name": 16,
        "cache_timeout": null,
        "time_grain_sqla": null,
        "verbose_map": null,
        "owners": [
        {
            "first_name": "Akshat",
            "id": 254,
            "last_name": "Jain",
            "username": "akshat.jain@infiniteanalytics.com"
        }
    ],
    "template_params": null,

      },
      "chart_type": "bubble_chart",
      "chart_metadata": {}
    }
    let radarchartPayload =  {
      "payload": {
        "name": activeReport?.name,
        "type": "big_number",
        "limit": 0,
        "offset": 0,
        "params": {
          "request_id": [
            {
              "operation": "EQ",
              "value": workflowDetails?.id,// "test_cdp_delta_write_2024-08-07_16_42_01"
              "type": "VARCHAR"
            }
          ],
          "visited_poi_text": [
            {
              "operation": "ISNOT",
              "value": "null",
              "type": "VARCHAR"
            }
          ]
        },
        "metrics": [
          {
            aggregator: 'SUM',
            expression: 'auto_car_dealership',
            metric_name: 'sum',
            verbose_name: 'auto_car_dealership'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_maintenance',
            metric_name: 'sum',
            verbose_name: 'auto_car_maintenance'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_parts',
            metric_name: 'sum',
            verbose_name: 'auto_car_parts'
          },
          {
            aggregator: 'SUM',
            expression: 'auto_car_tire',
            metric_name: 'sum',
            verbose_name: 'auto_car_tire'
          },
          {
            aggregator: 'SUM',
            expression: 'building_office_tax_advisor',
            metric_name: 'sum',
            verbose_name: 'building_office_tax_advisor'
          },
          {
            aggregator: 'SUM',
            expression: 'facility_education_school',
            metric_name: 'sum',
            verbose_name: 'facility_education_school'
          },
          {
            aggregator: 'SUM',
            expression: 'food_nightlife_bar_pub',
            metric_name: 'sum',
            verbose_name: 'food_nightlife_bar_pub'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_asian',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_asian'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_fast_food',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_fast_food'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_pizza_pasta',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_pizza_pasta'
          },
          {
            aggregator: 'SUM',
            expression: 'food_restaurant_taqueria',
            metric_name: 'sum',
            verbose_name: 'food_restaurant_taqueria'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_dentist',
            metric_name: 'sum',
            verbose_name: 'health_clinic_dentist'
          },
          {
            aggregator: 'SUM',
            expression: 'health_clinic_physician',
            metric_name: 'sum',
            verbose_name: 'health_clinic_physician'
          },
          {
            aggregator: 'SUM',
            expression: 'leisure_indoor_cinema',
            metric_name: 'sum',
            verbose_name: 'leisure_indoor_cinema'
          },
          {
            aggregator: 'SUM',
            expression: 'service_finance_insurance',
            metric_name: 'sum',
            verbose_name: 'service_finance_insurance'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_beauty',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_beauty'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_hair',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_hair'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_cosmetics_nail',
            metric_name: 'sum',
            verbose_name: 'shop_cosmetics_nail'
          },
          {
            aggregator: 'SUM',
            expression: 'shop_electronics_mobile',
            metric_name: 'sum',
            verbose_name: 'shop_electronics_mobile'
          },
          {
            aggregator: 'SUM',
            expression: 'transport_fuel_gas',
            metric_name: 'sum',
            verbose_name: 'transport_fuel_gas'
          },
          {
            aggregator: 'SUM',
            expression: 'travel_accommodation_hotel',
            metric_name: 'sum',
            verbose_name: 'travel_accommodation_hotel'
          }
        ],
        "database": {
          "name": "pois",
          "backend": "presto",
          "allows_subquery": true,
          "disable_data_preview": false
        },
        "table_name": 16,
        "schema_name": "pois",
        "spark_job_id": activeReport?.id,
        "datasource_name": 16,
        "cache_timeout": null,
        "time_grain_sqla": null,
        "verbose_map": null,
        "owners": [
        {
            "first_name": "Akshat",
            "id": 254,
            "last_name": "Jain",
            "username": "akshat.jain@infiniteanalytics.com"
        }
    ],
    "template_params": null,

      },
      "chart_type": "radar_chart",
      "chart_metadata": {}
    }
    let noOfDevicesPayload = {
      
        "payload": {
             "name": "CDP ifa count", 
             "type": "big_number", 
             "limit": 0, 
             "offset": 0,  
             "params": {
              "request_id": [
                {
                  "operation": "EQ",
                  "value": workflowDetails?.id,// "test_cdp_delta_write_2024-08-07_16_42_01"
                  "type": "VARCHAR"
                }
              ]
                 
             }, 
             "metrics":[
              {"expression": "*",
              "metric_name": "count",
              "verbose_name": "Total_count",
              "aggregator": "COUNT"}
              ], 
             "database": {
               "name": "pois", 
               "backend": "presto", 
               "allows_subquery": true, 
               "disable_data_preview": false},  
             "table_name": 16, 
             "schema_name": "pois",  
             "spark_job_id": activeReport?.id, 
             "datasource_name": 16, 
             "order_by_choices": [["Total_count", "asc"]]
            },
        "chart_type": "big_number",
        "chart_metadata": {}
      
    }
    let ifawithbehaviours = {
      "payload": {
           "name": "CDP ifa with behaviour count", 
           "type": "big_number", 
           "limit": 0, 
           "offset": 0,  
           "params": {
            "request_id": [
              {"operation": "EQ", 
              "value": "test_cdp_delta_write_2024-08-07_16_42_01",
              "type":"VARCHAR"}],

            "visited_behavior_text":
              [{"operation": "ISNOT", 
              "value": "null",
              "type":"VARCHAR"}]
           }, 
           "metrics": [
            {"expression": "ifa",
            "metric_name": "count",
            "verbose_name": "Total_count",
            "aggregator": "COUNT"}
            ], 
           "database": {
             "name": "pois", 
             "backend": "presto", 
             "allows_subquery": true, 
             "disable_data_preview": false},  
           "table_name": 13, 
           "schema_name": "pois",  
           "spark_job_id": activeReport?.id, 
           "datasource_name": 13, 
           "order_by_choices": [["Total_count", "asc"]]},
      "chart_type": "big_number",
      "chart_metadata": {}
    }
    

    console.log("bar chart payload ",barchartPayload)
Promise.all([ createChart(barchartPayload),
  createChart(ifawithbehaviours),
  createChart(noOfDevicesPayload),
  createChart(bubblechartPayload),
  createChart(radarchartPayload)
])
.then(async responses => {
  console.log("all charts responses ",responses)
  let allchartids = []
  let chartIds ="";
  responses.forEach((res,resIndex) => {
    allchartids.push(res.id)
    if(resIndex !==0) chartIds +=","
    chartIds += res.id
  
  })
  console.log("string chardid ",chartIds)
  let bentoPayload= {
    "name": activeReport?.name +" CDP-Report",
    "chart_ids": String(chartIds),
    "bento_box_metadata": {},
    "report_id": workflowDetails.id, // worflow_id
    "type": 1
  }
  await createBento(bentoPayload).then(
   async  res =>{
      Promise.all([...allchartids.map(cid => getChart(cid))]).then(async chartResponse =>{
        console.log("all chart data response is ", chartResponse)

        let sortable = [];
        for (var key in chartResponse[0][0]) {
            sortable.push([key, chartResponse[0][0][key]]);
        }
        
        sortable.sort(function(a, b) {
            return   b[1]- a[1];
        });
       
        const finalData = {
            "devices": chartResponse[1][0].Total_count,
            "devices_behaviour": chartResponse[2][0].Total_count,
            "chartData": chartResponse[0][0]
        }

        let xAxis = sortable.map(curr => curr[0])
        let yAxis = sortable.map(curr => curr[1])
        // let xAxis = Object.keys(chartResponse[0][0])
        // let yAxis = Object.values(chartResponse[0][0])

        let cdpData = {
          cdpData:finalData,
          axis:{
            x:xAxis,
            y:yAxis
          }
        }
        console.log("thsi is cdp data ",cdpData)
        dispatch(actions.handleCDPChartData(cdpData))   
       navigate("/cdp") // /cdp/1
      }).catch(err => console.log("get all charts data error ",err))
      console.log("create bento successfull",res)
    }
  )
//  const [response1] = responses;
  // Process the responses
})
.catch(error => {
  console.log("error ",error)
  // Handle any errors
});
    console.log("create charts payload ",barchartPayload);
   // const response = await createChart(barchartPayload)
   // console.log("chart creation ",response)
    //navigate(`/dashboard/${reportData.id}`)
   
    // await createChart({ payload: data, chart_type: data.type, chart_metadata: {} })
    //   .then(async res => {
    //     await getChart(res.id)
    //       .then(async response => {

    //         let responseData = response
    //         if (Object.keys(response[0]).includes('poiType')) {
    //           responseData.forEach(ele => ({
    //             ...ele,
    //             poiType: getPoiSname(ele.poiType)
    //           }))
    //         }
    //         dispatch(actions.handleAddChartData({ id: reportData.id, data: responseData }))
    //         dispatch(actions.handleAddChartAxis({ x: data.metrics.map(ele => ele.verbose_name), y: data.columns.map(ele => ele.column_name) }))

    //         await createBento({
    //           "name": reportData.name + " Bento",
    //           "chart_ids": String(res.id),
    //           "bento_box_metadata": {}
    //         })
    //           .then(res => {
    //             customToastSystem('Chart Created Successfully!', 'success')
    //           })
    //           .catch(err => console.log(err))
    //       })
    //       .catch(err => console.log(err))
    //   })
    //   .catch(err => console.log(err))
  }

  return (
    <div className="uploadDataContainer pl-6 pt-6">
      <div className="flex justify-content-start align-items-center">
        <p
          className={` flex-grow-0 stepperText ${
            workflowStep === 0
              ? "stepperActive"
              : workflowStep > 0
              ? "stepperComplete"
              : "stepperIncomplete"
          }`}
        >
          Details
        </p>

        <div
          className={`stepperDash stepperDashIncomplete
              ${
                workflowStep > 0
                  ? "stepperDashComplete"
                  : "stepperDashIncomplete"
              }`}
        ></div>

        <p
          className={`stepperText ${
            workflowStep === 1
              ? "stepperActive"
              : workflowStep > 1
              ? "stepperComplete"
              : "stepperIncomplete"
          }`}
        >
          Initiate Workflows
        </p>

        <div
          className={`stepperDash stepperDashIncomplete
              ${
                workflowStep > 1
                  ? "stepperDashComplete"
                  : "stepperDashIncomplete"
              }`}
        ></div>

        <p
          className={`stepperText ${
            workflowStep === 2
              ? "stepperActive"
              : workflowStep > 2
              ? "stepperComplete"
              : "stepperIncomplete"
          }`}
        >
          Status
        </p>
      </div>
      <div
        style={{ height: "96%" }}
        className="flex flex-column justify-content-between"
      >
        {workflowStep === 0 ? (
          <div>
            <div>
              <p className="heading1 mt-3">Report Details</p>
              <p className="infoText mt-2">Select Reports for CDP workflow</p>
            </div>

            <div className="grid  uploadDataContent  h-60 mt-3">
              <div className="col p-0 flex justify-centent-between  h-60 ">
                <div className="w-full h-full overflow-y-scroll pr-3">
                  <Dropdown
                    value={activeReport}
                    onChange={(e) => setActiveReport(e.value)}
                    options={reportsData}
                    optionLabel="name"
                    placeholder="Select a Report"
                    className="w-full "
                  />
                  {activeReport?.reportType && (
                    <>
                      <p className="text-sm font-semibold mt-3">Report</p>

                      <div
                        className={`contentSelectItem flex justify-cont
                            ent-between align-items-center w-full 
                            mt-2
                        `}
                      >
                        {activeReport?.reportType}
                      </div>
                    </>
                  )}
                  {activeReport?.district_codes && (
                    <>
                      <p className="text-sm font-semibold mt-3">Location</p>

                      <div
                        className={`contentSelectItemTwo
                          flex justify-content-between 
                          align-items-center w-full mt-2 overflow-y-scroll `}
                      >
                        {districtCodeToDistrict(activeReport?.district_codes)}
                      </div>
                    </>
                  )}

                  {activeReport?.start_date && activeReport?.end_date && (
                    <>
                      <p className="text-sm font-semibold mt-3">Dates</p>

                      <div
                        className={`contentSelectItem flex justify-cont
                            ent-between align-items-center w-full 
                            mt-2
                        `}
                      >
                        {activeReport?.start_date} to {activeReport?.end_date}
                      </div>
                    </>
                  )}

                  {activeReport?.poitype && 

                  <>
                  <p className="text-sm font-semibold mt-3">POI(s)</p>

                  <div
                    className={`contentSelectItemTwo flex justify-cont
                            ent-between align-items-center w-full 
                            mt-2 overflow-y-scroll 
                        `}
                  >
                    <p className="p-0 m-0">{getPoiSname(activeReport?.poitype)}</p>
                  </div>
                  </>
                  }
                </div>

                <div className="contentDivider contentDividerLeft"></div>
              </div>

              <div className="col p-0 flex justify-content-between  h-60">
                <div className="contentDivider contentDividerRight"></div>

                <div className="flex-grow-1 ml-3">
                  <div
                    className={`flex align-items-center justify-content-center contentBannerLogo`}
                  >
                    <Logo64 />
                  </div>

                  <div className="mt-4 ">
                    <p className="text-lg font-[640] text-white">Details</p>

                    <div className="contentDespContainer ">
                      Details of Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : workflowStep === 1 ? (
          <div>
            <div>
              <p className="heading1 mt-3">Schedule Workflows</p>
              <p className="infoText mt-2">
                Schedule a workflow to be completed in the background to either
                enrich your dataset or produce
              </p>
            </div>

            <div className="grid  uploadDataContent  h-60 mt-3">
              <div className="col p-0 flex justify-centent-between  h-60 ">
                <div className="w-full h-full overflow-y-scroll pr-3">
                  {workflowList.map((currItem, index) => (
                    <div className="mt-3" key={`workflow-initiate-${index}`}>
                      <p className="text-sm font-semibold">{currItem.name}</p>
                      {currItem.data.map((subItem, subIndex) => (
                       !subItem.queue ? <div
                          key={`workflow-initiate-${index}-${subIndex}`}
                          className={`contentSelectItem flex justify-content-between align-items-center w-full 
                        ${subIndex === 0 ? "mt-2" : "mt-1"}
                        `}
                        >
                          <p className="text-sm font-medium p-0 m-0">
                            {subItem.name}
                          </p>
                          <p 
                          onClick={() =>{
                            queueWorkflow(index,subIndex)
                          }}
                          className="text-sm font-medium text-cyan-500 cursor-pointer p-0 m-0">
                            Queue
                          </p>
                        </div>:""
                      ))}
                    </div>
                  ))}

                  <div className="mt-4">
                    <p className="text-lg font-semibold">Queued Workflows</p>
                  {workflowList.map((currItem, index) => (
                    <div className="mt-3" key={`workflow-initiate-${index}`}>
                      <p className="text-sm font-semibold">{currItem.name}</p>
                      {currItem.data.map((subItem, subIndex) => (
                       subItem.queue ? <div
                          key={`workflow-initiate-${index}-${subIndex}`}
                          className={`contentSelectItem flex justify-content-between align-items-center w-full 
                        ${subIndex === 0 ? "mt-2" : "mt-1"}
                        `}
                        >
                          <p className="text-sm font-medium p-0 m-0">
                            {subItem.name}
                          </p>
                          <p 
                          onClick={() =>{
                            queueWorkflow(index,subIndex)
                          }}
                          className="text-sm font-medium  cursor-pointer p-0 m-0">
                            Queued
                          </p>
                        </div>:""
                      ))}
                    </div>
                  ))}
                  </div>
                </div>

                <div className="contentDivider contentDividerLeft"></div>
              </div>

              <div className="col p-0 flex justify-content-between  h-60">
                <div className="contentDivider contentDividerRight"></div>

                <div className="flex-grow-1 ml-3">
                  <div
                    className={`flex align-items-center justify-content-center contentBannerLogo`}
                  >
                    <Logo64 />
                  </div>

                  <div className="mt-4 ">
                    <p className="text-lg font-[640] text-white">Behavior</p>

                    <div className="contentDespContainer ">
                      <p>
                        {" "}
                        Craft a Persona report viewable in Explore centered
                        around the behavioral profiles of the IFAs within this
                        dataset.{" "}
                      </p>

                      <p>Prerequisites: </p>
                      <p>IFA (Identifier for Advertisers) Data</p>
                      <p>New Capabilities:</p>
                      <p>Use Doppelgangers to discover lookalike audiences</p>
                      <p>
                        Segment audiences based on a given categorical variable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : workflowStep === 2 ? (
          <div>
            <div>
              <p className="heading1 mt-3">Data Upload</p>
              <p className="infoText mt-2">
                Upload data from your computer or any associated data lake or
                data warehouse associated with your account.
              </p>
            </div>

            <div className="mt-4">
              <Accordion
                className="dataset-structure-accordion"
                activeIndex={0}
              >
                <AccordionTab
                  pt={{
                    header: {
                      className: "dataset-structure-accordion-tab",
                    },
                    // headerClassName:"datasetItem"
                  }}
                  header={
                    <div className="flex align-items-center gap-2 w-full">
                      <span className="font-bold white-space-nowrap">
                        Columns
                      </span>
                      <p className="ml-auto">Count: 8</p>
                    </div>
                  }
                >
                  <div className="px-3">
                    <p className="text-xs font-medium mt-2">
                      This section outlines the columns within this dataset as
                      well as each column’s data type. Integers refer to whole
                      number values, Floats refers to values with a decimal,
                      String refers to values with alphanumerical characters,
                      and Boolean refers to binary values.{" "}
                    </p>

                    <div className="grid mt-2">
                      {new Array(8).fill(0).map((currCol, currColIndex) => (
                        <div
                          key={`col-item-${currColIndex}`}
                          className="flex justify-content-between align-items-center col-3"
                        >
                          <p className="text-xs font-semibold text-black-alpha-90 m-0 p-0">
                            Column Name
                          </p>
                          <p className="text-xs font-semibold text-600 m-0 p-0">
                            Integer
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab
                  className="mt-4"
                  pt={{
                    header: {
                      className: "dataset-structure-accordion-tab",
                    },
                    // headerClassName:"datasetItem"
                  }}
                  header={
                    <span className="flex align-items-center gap-2 w-full">
                      <span className="font-bold white-space-nowrap">Rows</span>
                      <p className="ml-auto">Count: 123,456,789</p>
                    </span>
                  }
                >
                  <div className="px-3">
                    <p className="text-xs font-medium mt-2">
                      This section outlines the number of rows contained within
                      the dataset, minus any column header(s).
                    </p>
                  </div>
                </AccordionTab>
                <AccordionTab
                  className="mt-4"
                  pt={{
                    header: {
                      className: "dataset-structure-accordion-tab",
                    },
                    // headerClassName:"datasetItem"
                  }}
                  header={
                    <p className="flex align-items-center gap-2 w-full">
                      <span className="font-bold white-space-nowrap">
                        Hierarchy Type 1
                      </span>
                      <p className="ml-auto">Column Name: POIType</p>
                    </p>
                  }
                >
                  <div className="px-3">
                    <p className="text-xs font-medium mt-2">
                      This section outlines a hierarchy within the POITypes
                      column, as well as the count for each unique value within
                      the level.
                    </p>

                    <div className="grid mt-2">
                      {new Array(4).fill(0).map((currCol, currColIndex) => (
                        <div
                          key={`col-item-${currColIndex}`}
                          className="flex justify-content-between align-items-center col-3"
                        >
                          <p className="text-xs font-semibold text-black-alpha-90 m-0 p-0">
                            Column Name
                          </p>
                          <p className="text-xs font-semibold text-600 m-0 p-0">
                            L1 | L2
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <p className="heading1 mt-3">Data Upload</p>
              <p className="infoText mt-2">
                Select a schema to upload data based on its associated report or
                layer template. Any data uploaded will only be accessible by
                this account unless shared with others.
              </p>
            </div>

            <div className="mt-4">
              <Accordion
                className="dataset-structure-accordion dataset-scroll-accordion h-60"
                activeIndex={0}
              >
                <AccordionTab
                  pt={{
                    header: {
                      className: "dataset-structure-accordion-tab",
                    },
                    // headerClassName:"datasetItem"
                  }}
                  header={
                    <div className="flex align-items-center gap-2 w-full">
                      <span className="font-bold white-space-nowrap">
                        Columns
                      </span>
                      <p className="ml-auto">
                        Status:{" "}
                        <span className="text-green-500">Completed</span>
                      </p>
                    </div>
                  }
                >
                  <div className="px-3">
                    <p className="text-xs font-medium mt-2">
                      This section outlines the columns within this dataset as
                      well as each column’s data type. Integers refer to whole
                      number values, Floats refers to values with a decimal,
                      String refers to values with alphanumerical characters,
                      and Boolean refers to binary values.{" "}
                    </p>

                    <div className="dataset-shapre-chart">
                      <BarChart />
                    </div>
                  </div>
                </AccordionTab>

                <AccordionTab
                  className="mt-4"
                  pt={{
                    header: {
                      className: "dataset-structure-accordion-tab",
                    },
                    // headerClassName:"datasetItem"
                  }}
                  header={
                    <div className="flex align-items-center gap-2 w-full">
                      <span className="font-bold white-space-nowrap">
                        Columns
                      </span>
                      <p className="ml-auto">
                        Status:{" "}
                        <span className="text-green-500">Completed</span>
                      </p>
                    </div>
                  }
                >
                  <div className="px-3">
                    <p className="text-xs font-medium mt-2">
                      This section outlines the columns within this dataset as
                      well as each column’s data type. Integers refer to whole
                      number values, Floats refers to values with a decimal,
                      String refers to values with alphanumerical characters,
                      and Boolean refers to binary values.{" "}
                    </p>

                    <div className="dataset-shapre-chart">
                      <BarChart />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        )}

        <div className="flex justify-content-end w-full">
          {/* <div>
                  <button className="flex justify-center items-center text-sm font-semibold text-[rgba(255,255,255,1)] bg-[#00000000] rounded-lg h-[48px] w-[130px]">
                    Add New Layer
                  </button>
                </div> */}

          <div className="flex">
            <button
              onClick={() => {
                console.log("naviage to cdp", workflowStep);
                if (workflowStep === 0) {
                  handleUpdateSidepanelActiveContent("none");
                  navigate("/cdp");
                }
                if (workflowStep > 0) setWorkflowStep(workflowStep - 1);
              }}
              className="flex justify-content-center align-items-center  buttonText buttonBlack"
            >
              Cancel
            </button>

            <Button
              onClick={() => {
                if(workflowStep === 0){
                  createWorkFlow()
                }

                if(workflowStep ===1) {
                  console.log('calling save work flow ')
                  saveWorkflow()
                }
                if(workflowStep === 3)
                {
                  handleCreateModule()
                }
               // if (workflowStep < 3) setWorkflowStep(workflowStep + 1);
              }}
              loading={loading}

              className="flex justify-content-center align-items-center buttonBlue buttonText w-10rem ml-1"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleWorkflows;
