import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    sidepanelDivider: {
        height: theme.spacing(0.5),
        backgroundColor: theme.palette.backgroundLevel3 ?? theme.palette.background
    },
    flexHeader: {
        flex: '0 1 0'
    },
    flexContent: {
        flex: '1 1 0'
    },
    transition: {
        transition: 'all 0.5s ease-in-out'
    },
    dragOpacity: {
        opacity: 0.5
    },
    dragBorder: {
        border: `2px solid ${theme.palette.secondary.main}`,
    },
}))