import * as actions from "../actions/actionTypes"

const initialState = {
    sidepanel: [],
    loading: false,
    reportLoading: false,
    reportError: false,
    selected: 'none',
    cdpData: {},
    workflowData:{}, // {id:"",}
    cdpActive: {
        open: false
    },
    axis: {
        x: ['total_visits', 'visitation_count'],
        y: ['poiType', 'district']
    }
}

const cdpReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_DATA_FOR_CDP_CHART: {
            return {
                ...state,
                cdpData: action.payload.cdpData,
                axis:action.payload.axis
            }
        }
        case actions.ADD_WORKFLOW_DATA:{
            return {
                ...state,
                workflowData:action.payload.workflowData
            }
        }
        case actions.CDP_TOGGLE:
            return {
                ...state,
                bentoBoxActive: action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default cdpReducer