const customGetObjects = (target, property, _receiver) => {
    for (let k in target)
        if (typeof k === 'string' && typeof property === 'string') {
            if (new RegExp(k).test(property))
                return target[k]
        }
    return null
}

export const missingFields = new Proxy({
    "poi": ["Point of Interest(s)"],
    "mobility": ["Date Range", "Resolution"],
    "wellbeingPrices|wellbeingPlaces|wellbeingCensus": [],
    "CovidInfectionreports": ["Date Range"],
    "CovidVaccinationReports": ["Date Range"],
    "Congregation": ["Date Range"],
    "visitation": ["Date Range", "Point of Interest(s)"],
    "districtMigrationHAs": ["Date Range"],
    "poiInternal": ["Point of Interest(s)"],
    "poiVisitation": ["Date Range", "Point of Interest(s)"],
}, {get: customGetObjects})