import { makeStyles } from "@material-ui/core"

import HomeHeroImg from '../../../assets/images/Home Hero.png'
import { getLightDarkGrayscaleColor } from "../../../common/functions"

export const useStyles = makeStyles((theme) => ({
    sidepanelDivider: {
        height: theme.spacing(0.5),
        backgroundColor: theme.palette.backgroundLevel3 ?? theme.palette.background
    },
    homeHeroBackground: {
        height: `100%`,
        padding: theme.spacing(5),
        backgroundImage: `linear-gradient(90deg, ${getLightDarkGrayscaleColor(theme, 100, 80)} 0%, ${getLightDarkGrayscaleColor(theme, 100, 80)}80 50.27%, ${getLightDarkGrayscaleColor(theme, 100, 80)}00 76.6%), url('${HomeHeroImg}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `linear-gradient(180deg, ${getLightDarkGrayscaleColor(theme, 100, 80)} 0%, ${getLightDarkGrayscaleColor(theme, 100, 80)}80 49.98%, ${getLightDarkGrayscaleColor(theme, 100, 80)}00 76.15%), url('${HomeHeroImg}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
    },
    heroHeaderMarginBottom: {
        marginBottom: theme.spacing(3)
    },
    dashboardCardTitleMarginBottom: {
        marginBottom: theme.spacing(0.5)
    },
    homeDashboarCards: {
        padding: theme.spacing(3),
        height: '100%'
    },
}))