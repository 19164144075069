import { useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepIcon,
} from "@material-ui/core";
import{ stepperClasses} from "@mui/material/Stepper"
import AuthCode from "react-auth-code-input";
import { DateRangePicker } from "rsuite";
import { TreeSelect } from "antd";
import "../customAntd.css";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useStyles } from "./styles";
import { useHoverActiveStyles } from "../static/hoverActiveStyles";
import { useStyles as layoutStyles } from "../static/layoutStyles";

import { ReactComponent as CalendarIcon } from "../assets/icons/Calendar.svg";

import { capitalizeFirstLetter, formatDate, getLightDarkGrayscaleColor } from "../common/functions";
import { platform_logos } from "../Dashboard/Content/Monitor/Config/monitorConfig";
import {
  CustomAutoCompleteBodyPrimary,
  CustomBodySecondaryAutoComplete,
  CustomFormControlFilled,
  CustomMenuItemBodyPrimary,
  CustomSelectBodyPrimary,
  CustomSwitchLabelMedium,
  CustomTextFieldBody1Medium,
  LabelMedium,
  CustomHoverTooltip,
  CustomSlider,
  CustomSvgIcon,
  CustomFormControlLabelBodySecondary,
} from "./customMuiStyles";
import {
  autocompleteClasses,
} from '@mui/material/Autocomplete';

export const SingleSelectFilled = (props) => {
  const { title, options, color } = props;
  const classes = layoutStyles();
  const theme = useTheme();
  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSelectBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        defaultValue={props?.placeholder ?? "none"}
        fullWidth
        renderValue={(selected) => {
          return selected;
        }}
      >
        <CustomMenuItemBodyPrimary value="none" disabled={!props.showNone}>
          <em>None</em>
        </CustomMenuItemBodyPrimary>
        {options.map((ele, index) => (
          <CustomMenuItemBodyPrimary value={ele} key={index}>
            {ele}
          </CustomMenuItemBodyPrimary>
        ))}
      </CustomSelectBodyPrimary>
    </CustomFormControlFilled>
  );
};

export const SingleSelectFilledWithSelectAll = (props) => {
  const { title, color, textFieldProps, selected, handleToggleSelectAll } =
    props;
  const classes = layoutStyles();
  const theme = useTheme();
  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomAutoCompleteBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur
                pl={1.5}
                py={0.5}
              >
                <CustomFormControlLabelBodySecondary
                  onClick={(e) => {
                    e.preventDefault(); // prevent blur
                    handleToggleSelectAll(title.toLowerCase());
                  }}
                  label="Select all"
                  control={
                    <Checkbox id="select-all-checkbox" checked={selected} />
                  }
                />
              </Box>
              <Divider />
              {children}
            </Paper>
          );
        }}
        renderInput={(params) => (
          <CustomTextFieldBody1Medium {...params} {...textFieldProps} />
        )}
        disableCloseOnSelect
        fullWidth
      />
    </CustomFormControlFilled>
  );
};

export const SingleSelectFilledAccounts = (props) => {
  const { title, options, color } = props;
  const classes = layoutStyles();
  const theme = useTheme();

  const classesHoverActive = useHoverActiveStyles();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSelectBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        defaultValue="none"
        fullWidth
      >
        <CustomMenuItemBodyPrimary value="none" disabled={!props.showNone}>
          <em>None</em>
        </CustomMenuItemBodyPrimary>
        {options.map((ele, index) => (
          <CustomMenuItemBodyPrimary value={ele.account_name} key={index}>
            <span style={{ display: "inline-flex" }}>
              <CustomSvgIcon
                component={platform_logos[ele.marketing_channel]}
                className={`${classesHoverActive.marginRightHalf}`}
              />
              {ele.account_name}
            </span>
          </CustomMenuItemBodyPrimary>
        ))}
      </CustomSelectBodyPrimary>
    </CustomFormControlFilled>
  );
};

export const SingleFillColorSelectFilled = (props) => {
  const { title, color, reverse } = props;
  let options = [
    "#fff7fb,#ece7f2,#d0d1e6,#a6bddb,#74a9cf,#3690c0,#0570b0,#034e7b",
    "#73A7D7,#7E92C4,#887CB0,#93679D,#9E528A,#A93D77,#B32763,#BE1250",
    "#07C8F9,#08B5F6,#09A1F2,#0A8EEF,#0A7BEB,#0B68E8,#0C54E4,#0D41E1",
    "#FCC27E,#F3A977,#EA9071,#E1776A,#D95D64,#D0445D,#C72B57,#BE1250",
    "#FFC500,#F6AC00,#EE9300,#E57A00,#DC6000,#D34700,#CB2E00,#C21500",
    "#F8CDDA,#D9B6C9,#B99FB8,#9A88A7,#7B7097,#5C5986,#3C4275,#1D2B64",
    "#A8C0FF,#99ABF0,#8A95E1,#7B80D2,#6C6BC3,#5D56B4,#4E40A5,#3F2B96",
    "#F4D03F,#D4C949,#B5C253,#95BB5D,#75B567,#55AE71,#36A77B,#16A085",
    "#6C584C,#686655,#65735D,#618166,#5D8E6E,#599C77,#56A97F,#52B788",
    "#00939C,#3EADB3,#7CC7CB,#BAE1E2,#F8C0AA,#E68F71,#D45F39,#C22E00",
    "#ffffcc,#ffeda0,#fed976,#feb24c,#fd8d3c,#fc4e2a,#e31a1c,#b10026",
  ];
  const theme = useTheme();
  const classes = layoutStyles();
  const classesHoverActive = useHoverActiveStyles();

  if (reverse) {
    options = options.map((ele) => ele.split(",").reverse().join(","));
  }

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSelectBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        defaultValue="none"
        fullWidth
      >
        <CustomMenuItemBodyPrimary value="none" disabled={!props.showNone}>
          <em>None</em>
        </CustomMenuItemBodyPrimary>
        {options.map((ele, index) => (
          <CustomMenuItemBodyPrimary value={ele} key={index}>
            <div
              style={{
                backgroundImage: `linear-gradient(to right, ${ele})`,
                width: "100%",
              }}
              className={`${classesHoverActive.borderRadius}`}
            >
              &nbsp;
            </div>
          </CustomMenuItemBodyPrimary>
        ))}
      </CustomSelectBodyPrimary>
    </CustomFormControlFilled>
  );
};

export const MultipleSelectFilled = (props) => {
  const { title, options, color } = props;
  const classes = layoutStyles();
  const theme = useTheme();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSelectBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        defaultValue={[]}
        multiple
        fullWidth
      >
        <CustomMenuItemBodyPrimary value={[]} disabled={!props.showNone}>
          <em>Select {title}(s)</em>
        </CustomMenuItemBodyPrimary>
        {options.map((ele, index) => (
          <CustomMenuItemBodyPrimary value={ele} key={index}>
            {ele}
          </CustomMenuItemBodyPrimary>
        ))}
      </CustomSelectBodyPrimary>
    </CustomFormControlFilled>
  );
};

export const CustomSwitchFilled = (props) => {
  const { title, color } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <CustomFormControlFilled fullWidth>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: `0 ${theme.spacing(1)}px` }}
        className={`${classes.marginBottom}`}
      >
        <LabelMedium
          style={{
            color: theme.palette[color]?.main ?? theme.palette.primary.main,
          }}
        >
          {title}
        </LabelMedium>
        <CustomSwitchLabelMedium {...props} />
      </Grid>
    </CustomFormControlFilled>
  );
};

export const CustomSwitchOptionsFilled = (props) => {
  const { title, color } = props;
  const classes = useStyles();
  const classesHoverActive = useHoverActiveStyles();
  const theme = useTheme();
  const activeClass = classesHoverActive[`${color}Icon`];

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
        className={`${classesHoverActive.marginBottomHalf}`}
      >
        {title}
      </LabelMedium>
      <LabelMedium variant="body1">
        <span
          className={`${
            props.checked ? classesHoverActive.disabledIcon : activeClass
          } ${classesHoverActive.marginRightOne}`}
        >
          {props.options[0] ?? "none"}
        </span>
        <CustomSwitchLabelMedium {...props} />
        <span
          className={`${
            props.checked ? activeClass : classesHoverActive.disabledIcon
          } ${classesHoverActive.marginLeftOne}`}
        >
          {props.options[1] ?? "none"}
        </span>
      </LabelMedium>
    </CustomFormControlFilled>
  );
};

export const MultipleAutocompleteFilled = (props) => {
  const { title, color, textFieldProps } = props;
  const classes = layoutStyles();
  const theme = useTheme();
  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomAutoCompleteBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        renderInput={(params) => (
          <CustomTextFieldBody1Medium {...params} {...textFieldProps} />
        )}
        disableCloseOnSelect
        fullWidth
        multiple
      />
    </CustomFormControlFilled>
  );
};

export const MultipleAutocompleteFilledWithSelectAll = (props) => {
  const { title, color, textFieldProps, selected, handleToggleSelectAll } =
    props;
  const classes = layoutStyles();
  const theme = useTheme();
  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomAutoCompleteBodyPrimary
        {...props}
        MenuProps={{
          classes: {
            paper: classes.menuPaper,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur
                pl={1.5}
                py={0.5}
              >
                <CustomFormControlLabelBodySecondary
                  onClick={(e) => {
                    e.preventDefault(); // prevent blur
                    handleToggleSelectAll(title.toLowerCase());
                  }}
                  label="Select all"
                  control={
                    <Checkbox id="select-all-checkbox" checked={selected} />
                  }
                />
              </Box>
              <Divider />
              {children}
            </Paper>
          );
        }}
        renderInput={(params) => (
          <CustomTextFieldBody1Medium {...params} {...textFieldProps} />
        )}
        disableCloseOnSelect
        fullWidth
        multiple
      />
    </CustomFormControlFilled>
  );
};

export const AutoCompleteSearch = (props) => {
  const { title, options, color, placeholder } = props;
  const classes = layoutStyles();
  const theme = useTheme();

  return (
    <CustomBodySecondaryAutoComplete
      id="search_input"
      options={[]}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          // onChange={e => console.log(e.target.value)}
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
      disableClearable
      fullWidth
      freeSolo
    />
  );
};

export const CustomTextFieldBody1 = (props) => {
  const { title, color } = props;
  const theme = useTheme();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomTextFieldBody1Medium
        {...props}
        id={`${title
          .replaceAll(" ", "-")
          .replaceAll("*", "")
          .toLowerCase()}-textfield`}
        fullWidth
      />
    </CustomFormControlFilled>
  );
};

export const CustomAuthCodeInput = (props) => {
  return <AuthCode allowedCharacters="^[0-9]" {...props} />;
};

export const CustomRadioSquareIcon = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { dimensions } = props;

  return (
    <Radio
      className={`${classes.radioRoot}`}
      icon={
        <span
          className={`${classes.defaultIcon} ${classes[dimensions]}`}
        ></span>
      }
      checkedIcon={
        <span className={`${classes.checkedIconOuter} ${classes[dimensions]}`}>
          <div className={`${classes.checkedIconInner}`}></div>
        </span>
      }
      color="default"
      disableRipple
      {...props}
    />
  );
};

export const CustomTreeSelect = (props) => {
  const theme = useTheme();
  const { title, color } = props;
  const { SHOW_PARENT } = TreeSelect;

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <TreeSelect
        {...props}
        showCheckedStrategy={SHOW_PARENT}
        style={{ width: "100%" }}
        // placement='bottomRight'
        dropdownStyle={{ overflow: "auto", zIndex: 1301 }}
        dropdownMatchSelectWidth={false}
        maxTagCount={2}
        notFoundContent={
          <LabelMedium variant="body1">No POIs found</LabelMedium>
        }
        // disabled multiple select
        treeCheckable={true}
        multiple={true}
        allowClear
        showArrow
        // treeLine
      />
    </CustomFormControlFilled>
  );
};

export const CustomDateRange = (props) => {
  const theme = useTheme();
  const classesHoverActive = useHoverActiveStyles();
  const dateRangeRef = useRef();
  const { title, color } = props;
  const valueFormat =
    props.value.length > 0
      ? `${formatDate(props.value[0])} to ${formatDate(props.value[1])}`
      : ``;

  const handleToggleFocus = () => dateRangeRef.current.open();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <DateRangePicker
        {...props}
        ref={dateRangeRef}
        placeholder="MMM dd, YYYY to MMM dd, YYY"
        renderValue={(value) => (
          <span>
            {value[0] === value[1]
              ? formatDate(value[0])
              : `${formatDate(value[0])} to ${formatDate(value[1])}`}
          </span>
        )}
        preventOverflow
        style={{ visibility: "hidden", position: "absolute" }}
      />
      <CustomTextFieldBody1Medium
        placeholder="MMM dd, YYYY to MMM dd, YYY"
        value={valueFormat}
        onFocus={() => handleToggleFocus()}
        id={props.name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon
                className={`${classesHoverActive.cursor} ${classesHoverActive.lightDarkTextColor}`}
                onClick={() => props.disabled || handleToggleFocus()}
              />
            </InputAdornment>
          ),
        }}
        fullWidth
        disabled={props.disabled}
      />
    </CustomFormControlFilled>
  );
};

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;

  return (
    <CustomHoverTooltip
      open={open}
      title={value}
      enterTouchDelay={0}
      placement="top"
      arrow
    >
      {children}
    </CustomHoverTooltip>
  );
};

export const CustomSingleSlider = (props) => {
  const { title, color } = props;
  const theme = useTheme();
  const classesHoverActive = useHoverActiveStyles();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSlider
        {...props}
        ValueLabelComponent={ValueLabelComponent}
        aria-label="custom thumb label"
      />
    </CustomFormControlFilled>
  );
};

export const CustomDoubleSlider = (props) => {
  const { title, color } = props;
  const theme = useTheme();
  const classesHoverActive = useHoverActiveStyles();

  return (
    <CustomFormControlFilled fullWidth>
      <LabelMedium
        style={{
          paddingLeft: theme.spacing(1),
          color: theme.palette[color]?.main ?? theme.palette.primary.main,
        }}
      >
        {title}
      </LabelMedium>
      <CustomSlider
        {...props}
        ValueLabelComponent={ValueLabelComponent}
        aria-label="custom thumb label"
      />
    </CustomFormControlFilled>
  );
};

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: "4px",
    width:"16px",
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: "2px",
  },
}));

export const StyledAutocompletePopper = styled('div')(({ theme }) => ({

  position:"relative",
  [`& .${autocompleteClasses.listbox}`]: {
    position:"absolute",
    zIndex:"30",
    backgroundColor: "#FAFAFA",
    // theme.palette.mode === 'light' ? 'black' : '#fff' ,
    color:theme.palette.text.primary,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      }
    },
  }
}));