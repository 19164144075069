import * as actions from "../actions/actionTypes"

const initialState = {
    state: 'none',
    userName: '',
    firstLogin: false,
    version: 'default',
    ssoLogin: false,
    modal: false,
    partner: false,
    partnerDetails: ''
}

const loginRegisterReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.TOGGLE_MODAL:
            return {
                ...state,
                modal: action.payload
            }
        case actions.CHANGE_LOGIN_REGISTER_STATE:
            return {
                ...state,
                state: action.payload
            }
        case actions.CHANGE_USERNAME:
            return {
                ...state,
                userName: action.payload
            }
        case actions.CHANGE_FIRST_LOGIN_STATUS:
            return {
                ...state,
                firstLogin: action.payload
            }
        case actions.CHANGE_VERSION:
            return {
                ...state,
                version: action.payload
            }
        case actions.CHANGE_SSOLOGIN:
            return {
                ...state,
                ssoLogin: action.payload
            }
        case actions.CHECK_PARTNER_LOGIN:
            return {
                ...state,
                partner: action.payload
            }
        case actions.ADD_PARTNER_USER_DETAILS:
            return {
                ...state,
                partnerDetails: action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default loginRegisterReducer