export const gradientColors = {
    primaryGradient: `linear-gradient(143.18deg, #4DB2EE 0%, #1488CC 99.92%)`,
    secondaryGradient: `linear-gradient(143.18deg, #8E40C1 0%, #662D8C 99.92%)`,
    tertiaryGradient: `linear-gradient(143.18deg, #EF538B 0%, #D4145A 99.92%)`,
    quarternaryGradient: `linear-gradient(143.18deg, #FCC979 0%, #F49705 99.92%)`,
    omniGradient: `linear-gradient(89.53deg, #1488CC 0.81%, #754D96 33.6%, #D4145A 66.4%, #FBB03B 99.19%)`,
    gradient1: `linear-gradient(135deg, #29A3EA 0%, #662D8C 100%)`,
    gradient2: `linear-gradient(135deg, #EB2D73 0%, #662D8C 100%)`,
    gradient3: `linear-gradient(135deg, #FCC56E 0%, #D4145A 100%)`,
    gradient4: `linear-gradient(143.18deg, #FBB03B 0%, #EB296F 32.27%, #7936A7 69.22%, #24A0E9 99.92%)`,
}

export const defaultTheme = {
    'infinite': {
        action: {
            hover: '#29A3EA',
            active: '#0F699E',
            focus: '#0F699E',
            selected: '#1488CC'
        },
        primary: {
            light: '#73A7D7',
            main: '#1488CC',
            dark: '#127AB6',
            contrastText: '#FAFAFA'
        },
        secondary: {
            light: '#886BA3',
            main: '#754D96',
            dark: '#632C88',
            contrastText: '#FAFAFA'
        },
        tertiary: {
            light: '#DD738C',
            main: '#D4145A',
            dark: '#BE1250',
            contrastText: '#FAFAFA'
        },
        quarternary: {
            light: '#FCC27E',
            main: '#FBB03B',
            dark: '#E19D35',
            contrastText: '#FAFAFA'
        },
        primaryGradient: `linear-gradient(143.18deg, #4DB2EE 0%, #1488CC 99.92%)`,
        secondaryGradient: `linear-gradient(143.18deg, #8E40C1 0%, #662D8C 99.92%)`,
        tertiaryGradient: `linear-gradient(143.18deg, #EF538B 0%, #D4145A 99.92%)`,
        quarternaryGradient: `linear-gradient(143.18deg, #FCC979 0%, #F49705 99.92%)`,
        omniGradient: `linear-gradient(143.18deg, #FBB03B 0%, #EB296F 32.27%, #7936A7 69.22%, #24A0E9 99.92%)`,
        gradient1: `linear-gradient(135deg, #29A3EA 0%, #662D8C 100%)`,
        gradient2: `linear-gradient(135deg, #EB2D73 0%, #662D8C 100%)`,
        gradient3: `linear-gradient(135deg, #FCC56E 0%, #D4145A 100%)`,
        gradient4: `linear-gradient(135deg, #FCC56E 0%, #D4145A 34.9%, #8139B2 68.23%, #1488CC 100%)`,
    },
    'earthly': {
        action: {
            hover: '#D355B0',
            active: '#721D5A',
            focus: '#721D5A',
            selected: '#9B287B'
        },
        primary: {
            light: '#D355B0',
            main: '#9B287B',
            dark: '#721D5A',
            contrastText: '#FAFAFA'
        },
        secondary: {
            light: '#E48B9F',
            main: '#D0395B',
            dark: '#AD2947',
            contrastText: '#FAFAFA'
        },
        tertiary: {
            light: '#6FB9A2',
            main: '#3F826D',
            dark: '#2F6051',
            contrastText: '#FAFAFA'
        },
        quarternary: {
            light: '#FCC56E',
            main: '#EB8D00',
            dark: '#FA9C0A',
            contrastText: '#FAFAFA'
        },
        primaryGradient: `linear-gradient(143.18deg, #4DB2EE 0%, #1488CC 99.92%)`,
        secondaryGradient: `linear-gradient(143.18deg, #8E40C1 0%, #662D8C 99.92%)`,
        tertiaryGradient: `linear-gradient(143.18deg, #EF538B 0%, #D4145A 99.92%)`,
        quarternaryGradient: `linear-gradient(143.18deg, #FCC979 0%, #F49705 99.92%)`,
        omniGradient: `linear-gradient(143.18deg, #FBB03B 0%, #EB296F 32.27%, #7936A7 69.22%, #24A0E9 99.92%)`,
        gradient1: `linear-gradient(135deg, #D355B0 0%, #D0395B 100%)`,
        gradient2: `linear-gradient(135deg, #6FB9A2 0%, #D0395B 100%)`,
        gradient3: `linear-gradient(135deg, #FCC56E 0%, #3F826D 100%)`,
        gradient4: `linear-gradient(135deg, #FCC56E 0%, #3F826D 34.9%, #E48B9F 68.23%, #9B287B 100%)`,
    },
}

export const overrides = {
    'light': {
        MuiCssBaseline: {
            '@global': {
                html: {
                    boxSizing: 'none',
                },
                '*, *::before, *::after': {
                    boxSizing: 'none',
                },
            }
        },
        MuiPickersModal: {
            dialogRoot: {
                backgroundColor: '#FAFAFA'
            },
        },
        MuiPickersBasePicker: {
            container: {
                backgroundColor: '#FAFAFA'
            }
        }
    },
    'dark': {
        MuiCssBaseline: {
            '@global': {
                html: {
                    boxSizing: 'none',
                },
                '*, *::before, *::after': {
                    boxSizing: 'none',
                },
            }
        },
        MuiPickersModal: {
            dialogRoot: {
                backgroundColor: '#323232'
            },
        },
        MuiPickersBasePicker: {
            container: {
                backgroundColor: '#323232'
            }
        }
    },
}

const calculateGrayscale = (color, order="light") => {
    const temp = {}
    let tempNum = 0
    for (let i=1; i < 11; i++) {
        let tempColor = 0
        if (order!=="light") {
            tempColor = (color - tempNum).toString(16)
            tempNum = tempNum + 13
            if ([1, 5, 9].includes(i)) {
                tempNum-=1
            }
        } else {
            tempColor = (color + tempNum).toString(16)
            tempNum = tempNum + 13
            if ((i+1)%4 === 0) {
                tempNum-=1
            }
        }
        if (tempColor.length < 2) {
            tempColor = `0${tempColor}`
        }
        temp[`${i}0`] = `#${tempColor.repeat(3).toUpperCase()}`
    }
    return temp
}

export const themeMode = {
    'light':{
        type: 'light',
        background: '#F0F0F0',
        foreground: '#FAFAFA',
        backgroundLevel3: '#C7C7C7',
        grayscaleLight: calculateGrayscale(135),
        grayscaleDark: calculateGrayscale(122, 'dark'),
        text: {
            primary: '#121212',
            secondary: '#2B2B2B',
            tertiary: '#454545',
        },
        divider: '#ABABAB',
        success: {
            main: '#12C614',
            light: '#82DA83',
            dark: '#0FA811',
        },
        error: {
            main: '#E72421',
            light: '#EE6463',
            dark: '#B91D1B',
        },
    },
    'dark': {
        type: 'dark',
        foreground: '#121212',
        background: '#323232',
        grayscaleLight: calculateGrayscale(135),
        grayscaleDark: calculateGrayscale(122, 'dark'),
        text: {
            primary: '#F4F4F4',
            disabled: '#ABABAB',
        },
        divider: '#ABABAB',
        success: {
            main: '#12C614',
            light: '#82DA83',
            dark: '#0FA811',
        },
        error: {
            main: '#E72421',
            light: '#EE6463',
            dark: '#B91D1B',
        },
    }
}

export const boxShadows = {
    'light': [
        'none',
        '0px 3px 6px rgba(18, 18, 18, 0.16)',
        '0px 2px 4px rgba(18, 18, 18, 0.16)',
        '0px 1px 2px rgba(18, 18, 18, 0.16)',
        '0px -3px 6px rgba(18, 18, 18, 0.16)',
        '0px -2px 4px rgba(18, 18, 18, 0.16)',
        '0px -1px 2px rgba(18, 18, 18, 0.16)',
        '3px 0px 6px rgba(18, 18, 18, 0.16)',
        '2px 0px 4px rgba(18, 18, 18, 0.16)',
        '1px 0px 2px rgba(18, 18, 18, 0.16)',
        '-3px 0px 6px rgba(18, 18, 18, 0.16)',
        '-2px 0px 4px rgba(18, 18, 18, 0.16)',
        '-1px 0px 2px rgba(18, 18, 18, 0.16)',
        "0px 6px 6px -3px rgba(0,0,0,0.12),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.12),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.12),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.12),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.12),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.12),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.12),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.12),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.12),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.12),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.12),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.12),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)"
    ],
    dark: [
        'none',
        '0px 3px 6px rgba(18, 18, 18, 0.16)',
        '0px 2px 4px rgba(18, 18, 18, 0.16)',
        '0px 1px 2px rgba(18, 18, 18, 0.16)',
        '0px -3px 6px rgba(18, 18, 18, 0.16)',
        '0px -2px 4px rgba(18, 18, 18, 0.16)',
        '0px -1px 2px rgba(18, 18, 18, 0.16)',
        '3px 0px 6px rgba(18, 18, 18, 0.16)',
        '2px 0px 4px rgba(18, 18, 18, 0.16)',
        '1px 0px 2px rgba(18, 18, 18, 0.16)',
        '-3px 0px 6px rgba(18, 18, 18, 0.16)',
        '-2px 0px 4px rgba(18, 18, 18, 0.16)',
        '-1px 0px 2px rgba(18, 18, 18, 0.16)',
        "0px 6px 6px -3px rgba(0,0,0,0.12),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.12),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.12),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.12),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.12),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.12),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.12),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.12),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.12),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.12),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.12),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.12),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)"
    ],
}

export const typography = {
    htmlFontSize: 16,
    fontFamily: '"Inter", sans-serif',
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "28.83px",
        lineHeight: '40px',
        letterSpacing: "0.01em",
    },
    h2: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "22.78px",
        lineHeight: '32px',
        letterSpacing: "0.01em",
    },
    h3: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '24px',
        letterSpacing: "0.01em",
    },
    body1: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        letterSpacing: "0.01em",
    },
    body2: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "14.22px",
        lineHeight: '24px',
        letterSpacing: "0.02em",
    },
    labelLarge: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: "14.22px",
        lineHeight: '24px',
        letterSpacing: "0.03em",
    },
    labelMedium: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: "12.64px",
        lineHeight: '16px',
        letterSpacing: "0.03em",
    },
    labelSmall: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: "9.99px",
        lineHeight: '16px',
        letterSpacing: "0.03em",
    },
    displayPrimary: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "41.05px",
        lineHeight: '54px',
        letterSpacing: "0.01em",
    },
    displaySecondary: {
        fontFamily: '"Inter", sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "32.44px",
        lineHeight: '40px',
        letterSpacing: "0.01em",
    },
    paragraph1: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: '28px',
        letterSpacing: "0.01em",
    },
    paragraph2: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 500,
        fontSize: "1.000rem",
        lineHeight: '21px',
        letterSpacing: "0.01em",
    },
    paragraph3: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 500,
        fontSize: "0.889rem",
        lineHeight: '21px',
        letterSpacing: "0.01em",
    },
    paragraph4: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 600,
        fontSize: "1.000rem",
        lineHeight: '21px',
        letterSpacing: "0.01em",
    },
    paragraph5: {
        fontFamily: '"Inter", sans-serif',
        fontWeight: 600,
        fontSize: "0.790rem",
        lineHeight: '21px',
        letterSpacing: "0.01em",
    },
}