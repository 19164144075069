export const exploreLoading = () => {
    const array = [
        "Sherlock AI wades through over 350 million consumers to find your perfect audience",
        "Discovering your audience with Sherlock AI is easier than finding a needle in a haystack",
        "In the blink of an eye, Sherlock AI has just scanned through 80 million places across India",
        "Did you know? Sherlock AI completely understands over 350 million consumers across India, the US, and the Middle East",
        "Sherlock AI's major in university was Doing the Impossible",
        "Which came first: the chicken or the egg? Whatever the answer, Sherlock AI's analytical skills would rank first if it participated",
        "Thank you for your patience while Sherlock AI sorts through petabytes of data each second",
        "Initiating Superlative Number Cruncher Protocol to flex Sherlock AI's data processing powers",
        "Did you know? Sherlock AI processes over 40 datasets to make your job easier",
        "Worried about data ethics? Don't be - Sherlock AI is 100% compliant with GDPR and CCPA standards",
        // "Sherlock AI is wading through 350 million consumers to find you the right fit",
        // "Needle in a haystack? Duh! Sherlock AI has overcome bigger odds",
        // "Beep, bopp; There you go. Sherlock AI has literally went through 80 million places pan-India",
        // "Did you know that Sherlock AI completely understand 350 million consumers across India, US and the Middle East?",
        // "Doing the impossible- This was Sherlock AI's major in university",
        // "What came first the Chicken or the Egg? Well, not sure about that, but can vouch that Sherlock's analytical skills would rank first, if it participated",
        // "Thanks for your patience while Sherlock AI processes millions of gigabits of data per second",
        // "Superlative number cruncher is doing the heavylifting now",
        // "Did you know that Sherlock AI processes 40+ datasets to make your lives easier?",
        // "Oh, and by the way, Sherlock AI is 100% GDPR and CCPA compliant!",
        "Did you know that pet owners in Mumbai travel 20km to Pet supplies?",
        "70% pet parents spend a considerable amount of time towards entertainment and hobbies!",
        "People with Pets take more road trips? Sherlock AI will be back with more intriguing facts",
        "Did you know that people who visit upscale cafes are sneakerheads?",
        "People visiting premium cafes have a tendency to gift more!",
        "Foreign Banks are the darlings of upscale cafe visitors!",
        "Did you know that people who prefer one brand of Reliance also like other brands of Reliance subconsciously?",
        "Phew, after long years, Xiaomi has become a premium brand suggests Sherlock AI",
        "Did you know that Samsung's users are among the highly social consumers?",
        "Talk about Electronics and one of the first brands to come to people's minds is Reliance Digital",
        "Did you know that majority of cellphone buyers go to a bank atleast a week before the purchase?",
        "Did you know that jewelry stores' visitation went up by 125% during the Dhanteras weekend? ",
        "Did you know that there is a typical weekend surge of 16% in Jewelery shops in India?",
        "Digital chatter for Gold shot up by 100% during Dhanteras, whereas Sovereign Gold Bonds' chatter shot up by a whopping 500%!",
        "South India & Gold: A better love story than Twilight",
        "Top 6 states for Gold's digital chatter all come from Southern India!",
        "Did you know that locations with Top 20% Commercial Index are best suited for Cafes?",
        "85% of the Starbucks store's visitation comes from consumers visiting other shopping complexes/outlets rather than from their homes",
        "Sherlock AI also found that people who go to Starbucks, tie in their Starbucks visit to their work",
        "Did you know that more food related outlets in the area, better the cafes perform?",
        "Number of hospitals in the neighbourhood is inversely proportional to food outlets' performance!",
        "Did you know that there was almost a 100% surge in Beach footfall during Ganpati Visarjan in Mumbai? (though it was a weekday)",
        "There is usually a 80% surge in beach footfall during Sundays",
        "Did you know that Bharat Jodo Yatra's maximum digital chatter came from Twitter? (64%)",
        "51% of digital chatter on Bharat Jodo Yatra is multilingual! Wow!",
        "Consumers who prefer EVs are more empathetic and appreciate the finer things in life",
        "People who prefer Japanese automobiles actually tend to be more multicultural!",
        "Did you know that people with premium bikes also go to police station a lot? Say what?",
        "People visiting Harley-Davidson showrooms are frequent international travelers",
        "Did you know that BMX & Premium bicycles tend to act as substitutes to some of the low range e-bikes",
        "KTM & Husqvarna customers have higher lifestyle spends - nightlife, casual dining, cinema halls, Gyms/Health Fitness",
        "Honda, Suzuki, Triumph, Jawa have carved out complete 'niche' consumer segments, suggests Sherlock AI!",
        "Did you know that the top 20% Auto showrooms contribute to ~65% of overall footfalls?",
        "Did you know that Pune has the least concentrated Automobile market (by dealership), whereas Delhi has the most concentrated market?",
        "We see that Bangalore and Pune have the maximum number of 'formal' and 'organized' restaurants!",
        "North and Central India have a love for Asian cuisine (~5%) but not that prevalent in South",
        "Cafes present a bigger mix in South and Central India than North",
        "Fine Dining Restaurants present a bigger mix in South and Central India than North",
        "Between 2017 and 2019, The Indian Premier League (IPL) overtook Premier League in terms of digital popularity",
        "Between 2020-2021, the Indian Premier League overtook the NFL in terms of digital popularity",
        "In India, Bangalore, Pune and Chennai are among the top 3 cities when it comes to fitness!",
        "Bangalore clearly has the maximum sports centers and turfs (which can be rented out by the hour)",
        "Chennai is the Martial arts hero, when it comes to fitness. Yenna Rascala, Mind It!"
    ]
    const random = Math.floor(Math.random() * array.length)

    return array[random]
}

export const exploreDownloading = () => {
    const array = [
    ]
    const random = Math.floor(Math.random() * array.length)

    return array[random]
}