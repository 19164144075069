import { Fragment, useMemo } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import * as actions from '../../../redux/actions'
import { useStyles } from '../sidepanelStyles'
import { useHoverActiveStyles } from '../../../static/hoverActiveStyles'

import { ReactComponent as HomeIcon } from '../../../assets/icons/Home.svg'
import { ReactComponent as ExploreIcon } from '../../../assets/icons/Explore.svg'
import { ReactComponent as MonitorIcon } from '../../../assets/icons/Monitor.svg'
import { ReactComponent as OptimizationIcon } from '../../../assets/icons/Optimization.svg'
import { ReactComponent as BentoBoxColourIcon } from '../../../assets/icons/BentoBoxColour.svg'
import { ReactComponent as DatabaseIcon } from '../../../assets/icons/database.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/icons/Settings.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/Comments.svg'
import { ReactComponent as DashboardIcon } from '../../../assets/icons/New.svg'
import { ReactComponent as WorkspaceIcon } from '../../../assets/icons/Workspace.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/Filter.svg'
import { ReactComponent as AddIcon } from '../../../assets/icons/Add Report.svg'
import { ReactComponent as LayersIcon } from '../../../assets/icons/Layers.svg'
import { ReactComponent as BentoBoxIcon } from '../../../assets/icons/BentoBox.svg'
import { ReactComponent as CampaignIcon } from '../../../assets/icons/Campaign.svg'
import { ReactComponent as UploadIcon } from '../../../assets/icons/Upload.svg'
import { ReactComponent as ContextIcon } from '../../../assets/icons/Context.svg'
import { ReactComponent as TrackChangesIcon } from '../../../assets/icons/Track Changes.svg'
import { ReactComponent as OptimizationModelIcon } from '../../../assets/icons/Optimization Model.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/Toggle Down Arrow.svg'
import { ReactComponent as ParentFolderIcon } from '../../../assets/icons/Parent Folder.svg'
import { ReactComponent as ChildFolderIcon } from '../../../assets/icons/Child Folder.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search.svg'
import { ReactComponent as NotificationsIcon } from '../../../assets/icons/Notifications.svg'
import { ReactComponent as TimerIcon } from '../../../assets/icons/Timer.svg'
import { ReactComponent as CreateWorkflowIcon} from '../../../assets/icons/AddWorkflow.svg'
import { multiLayerMappingReverse, reportListMap } from '../../Content/Explore/Config/exploreConfig'
import useExploreService from '../../Content/Explore/Service/useExploreService'
import { districtCodeToDistrict, getfromLocalStorage } from '../../../common/functions'
import { CustomDefaultDrawerListItem, CustomPrimaryDrawerListItem, CustomQuarternaryDrawerListItem, CustomSecondaryDrawerListItem, CustomTertiaryDrawerListItem, CustomPrimaryTooltip, CustomSecondaryTooltip, CustomTertiaryTooltip, CustomQuarternaryTooltip, CustomDefaultTooltip, CustomPrimaryDrawerListItemContent, CustomSecondaryDrawerListItemContent, CustomTertiaryDrawerListItemContent, CustomQuarternaryDrawerListItemContent, CustomDefaultDrawerListItemContent, LabelMedium, CustomSvgIcon } from '../../../customMui/customMuiStyles'
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import DashboardRightSidepanel from '../../Content/Home/Sidepanel/dashboardRightSidepanel'
import ExploreRightSidepanel from '../../Content/Explore/Sidepanel/exploreRightSidepanel'
import SearchRightSidepanel from '../../Content/Search/searchRightSidepanel'
import NotificationsRightSidepanel from '../../Content/Notifications/notificationsRightSidepanel'
import LayerCards from '../../Content/Explore/Sidepanel/layerCards'
import CreateReport from '../../Content/Explore/Sidepanel/createReport'
import FilterSidepanel from '../../Content/Monitor/Sidepanel/filterSidepanel'
import CreateCampaign from '../../Content/Monitor/Sidepanel/createCampaign'
import UploadData from '../../Content/Monitor/Sidepanel/uploadData'
import ErrorAccount from '../../Content/Monitor/Sidepanel/errorAccount'
import OptimizeFilterSidepanel from '../../Content/Optimize/Sidepanel/optimizeFilterSidepanel'

const useSidepanelConfig = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const currentLocation = location.pathname.match(/^\/[^\/]+/)[0]
    const activeDashboard = useParams().activeDashboard
    const classes = useStyles()
    const classesHoverActive = useHoverActiveStyles()
    const stateLayout = useSelector(state => state.layoutReducer)
    const stateExplore = useSelector(state => state.exploreReducer)
    const stateMonitor = useSelector(state => state.monitorReducer)
    const stateOptimize = useSelector(state => state.optimizeReducer)
    const stateBentobox = useSelector(state => state.bentoBoxReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)
    const { getPoiSname } = useExploreService()
    console.log(activeDashboard);

    const customGetObjects = (target, property, _receiver) => {
        for (let k in target)
            if (typeof k === 'string' && typeof property === 'string') {
                if (new RegExp(k).test(property))
                    return target[k]
            }
        return null
    }

    const handleOnClick = (item) => () => {
        if (!item.hasChildren) {
            switch (currentLocation) {
                case '/explore':
                    dispatch(actions.handleSelectExploreSidepanel(item.index ?? 'none'))
                case '/monitor':
                case '/optimize':
                    return navigate(`${currentLocation}/${item.index ?? ''}`)
                default:
                    return null
            }
        }
        return null
    }

    return useMemo(() => ({
        ElementByColor: {
            'primary': CustomPrimaryDrawerListItem,
            'secondary': CustomSecondaryDrawerListItem,
            'tertiary': CustomTertiaryDrawerListItem,
            'quarternary': CustomQuarternaryDrawerListItem,
            'default': CustomDefaultDrawerListItem
        },
        ListElementByColor: new Proxy({
            'primary|/home|left': CustomPrimaryDrawerListItemContent,
            'secondary|/explore': CustomSecondaryDrawerListItemContent,
            'tertiary|/monitor': CustomTertiaryDrawerListItemContent,
            'quarternary|/optimize': CustomQuarternaryDrawerListItemContent,
            'default|/settings|/admin|/studio': CustomDefaultDrawerListItemContent
        }, { get: customGetObjects }),
        TooltipColor: {
            'primary': CustomPrimaryTooltip,
            'secondary': CustomSecondaryTooltip,
            'tertiary': CustomTertiaryTooltip,
            'quarternary': CustomQuarternaryTooltip,
            'default': CustomDefaultTooltip
        },
        ClassByColor: new Proxy({
            'primary|/home|left': 'primaryIcon',
            'secondary|/explore': 'secondaryIcon',
            'tertiary|/monitor': 'tertiaryIcon',
            'quarternary|/optimize': 'quarternaryIcon',
            'default|/settings|/admin|/studio': 'defaultColor'
        }, { get: customGetObjects }),
        borderColor: {
            '/home': 'primaryActiveBorder',
            '/explore': 'secondaryActiveBorder',
            '/monitor': 'tertiaryActiveBorder',
            '/optimize': 'quarternaryActiveBorder',
            '/settings': 'defaultActiveBorder',
            '/admin': 'defaultActiveBorder',
            '/studio': 'defaultActiveBorder'
        },
        sidepanelContentHeaderConfig: {
            '/home': 'Home',
            '/explore': 'Exploration',
            '/monitor': 'Monitoring',
            '/optimize': 'Optimization',
            '/dashboard': 'Charts',
            '/settings': 'Settings',
            '/admin': 'Admin Console',
            '/studio': 'Studio',
        },
        navigationIcons: {
            top: [
                { name: 'Home', icon: <CustomSvgIcon component={HomeIcon} />, color: 'primary', path: '/home' },
                { name: 'Explore', icon: <CustomSvgIcon component={ExploreIcon} />, color: 'secondary', path: '/explore' },
                { name: 'Monitor', icon: <CustomSvgIcon component={MonitorIcon} />, color: 'tertiary', path: '/monitor' },
                { name: 'Optimize', icon: <CustomSvgIcon component={OptimizationIcon} />, color: 'quarternary', path: '/optimize' },
                { name: 'BentoBox', icon: <CustomSvgIcon component={BentoBoxIcon} />, color: 'primary', path: '/dashboard' },
               // {name: 'CDP', icon : <DatabaseIcon/>, color: 'primary', path: '/cdp'},

            ],
            bottom: [
                // {name: 'Admin Console', icon: <AdminConsoleIcon />, color: 'default', path: '/admin'},
                // {name: 'Studio', icon: <StudioIcon />, color: 'default', path: '/studio'},
                { name: 'Settings', icon: <SettingsIcon />, color: 'default', path: '/settings' },
            ]
        },
        rightNavigationIcons: (location) => {
            const top = () => {
                if (getfromLocalStorage('group').toLowerCase() === 'manager') {
                    switch (location) {
                        case '/explore': return ([
                            { name: 'Create Report', icon: AddIcon, color: 'default' },
                            { name: 'Layers', icon: LayersIcon, color: 'default' },
                            ...(activeDashboard ? [{ name: 'Create Bento', icon: BentoBoxIcon, color: 'default' }] : []),
                           // { name: 'Create CDP Workflow', icon:CreateWorkflowIcon,color:'default'}
                        ])
                        case '/monitor': return ([
                            { name: 'Filter', icon: TimerIcon, color: 'default' },
                            // {name: 'Filter', icon: FilterIcon, color: 'default'},
                            { name: 'Create Campaign', icon: AddIcon, color: 'default' },
                        ])
                        case '/optimize': return ([
                            { name: 'Parameter', icon: ContextIcon, color: 'default' },
                            // {name: 'Context', icon: ContextIcon, color: 'default'},
                            // {name: 'Optimization Model', icon: OptimizationModelIcon, color: 'default'},
                            // {name: 'Workspace', icon: WorkspaceIcon, color: 'default'},
                        ])
                        case '/dashboard': return ([
                            // { name: 'Create Bento', icon: BentoBoxIcon, color: 'default' },
                        ])
                        case "/cdp": return ([
                           // { name:"Create", icon: ControlCameraIcon ,color:"default" }
                        ])
                        case '/settings':
                        case '/admin':
                        case '/studio':
                        case '/home':
                        default: return ([
                            // {name: 'Chat', icon: ChatIcon, color: 'default'},
                        ])
                    }
                } else if (getfromLocalStorage('group').toLowerCase() === 'admin') {
                    switch (location) {
                        case '/explore': return ([
                            { name: 'Create Report', icon: AddIcon, color: 'default' },
                            { name: 'Layers', icon: LayersIcon, color: 'default' },
                            ...(activeDashboard ? [{ name: 'Create Bento', icon: BentoBoxIcon, color: 'default' }] : []),
                           // { name: 'Create CDP Workflow', icon:CreateWorkflowIcon,color:'default'}
                            // {name: 'Create Camapign', icon: CampaignIcon, color: 'default'},
                        ])
                        case '/monitor': return ([
                            { name: 'Filter', icon: TimerIcon, color: 'default' },
                            // {name: 'Filter', icon: FilterIcon, color: 'default'},
                            { name: 'Create Campaign', icon: AddIcon, color: 'default' },
                            { name: 'Upload Data', icon: UploadIcon, color: 'default' },
                            // {name: 'Context', icon: ContextIcon, color: 'default'},
                            // {name: 'Track Changes', icon: TrackChangesIcon, color: 'default'},
                            // {name: 'Workspace', icon: WorkspaceIcon, color: 'default'},
                        ])
                        case '/optimize': return ([
                            { name: 'Parameter', icon: ContextIcon, color: 'default' },
                            // {name: 'Context', icon: ContextIcon, color: 'default'},
                            // {name: 'Optimization Model', icon: OptimizationModelIcon, color: 'default'},
                            // {name: 'Workspace', icon: WorkspaceIcon, color: 'default'},
                        ])
                        case '/dashboard': return ([
                            // { name: 'Create Bento', icon: BentoBoxIcon, color: 'default' },
                        ])
                        case '/settings':
                        case '/admin':
                        case '/studio':
                        case '/home':
                        default: return ([
                            // {name: 'Chat', icon: ChatIcon, color: 'default'},
                        ])
                    }
                } else {
                    switch (location) {
                        case '/explore': return ([
                            { name: 'Layers', icon: LayersIcon, color: 'default' },
                        ])
                        case '/monitor': return ([
                            { name: 'Filter', icon: TimerIcon, color: 'default' },
                            // {name: 'Filter', icon: FilterIcon, color: 'default'},
                            { name: 'Create Campaign', icon: AddIcon, color: 'default' },
                        ])
                        case '/optimize': return ([
                            { name: 'Context', icon: ContextIcon, color: 'default' },
                            // {name: 'Optimization Model', icon: OptimizationModelIcon, color: 'default'},
                            // {name: 'Workspace', icon: WorkspaceIcon, color: 'default'},
                        ])
                        case '/settings':
                        case '/admin':
                        case '/studio':
                        case '/home':
                        default: return ([
                            // {name: 'Chat', icon: ChatIcon, color: 'default'},
                        ])
                    }
                }
            }

            // if (['admin', 'manager'].includes(getfromLocalStorage('group').toLowerCase())) {
            //     return ({
            //         top: top(),
            //         bottom: [
            //             {name: 'Search', icon: SearchIcon, color: 'default', path: '/search'},
            //             // {name: 'Notifications', icon: NotificationsIcon, color: 'default', path: '/notifications'},
            //         ],
            //     })
            // }

            return ({
                top: top(),
                bottom: [
                    { name: 'Search', icon: SearchIcon, color: 'default', path: '/search' },
                    // {name: 'Notifications', icon: NotificationsIcon, color: 'default', path: '/notifications'},
                ],
            })
        },
        sidepanelContentConfig: {
            '/home': [
                // {name: 'Dashboard', icon: DashboardIcon, color: 'primary', parentPath: '/home', path: 'dashboard'},
                { name: 'Welcome', icon: DashboardIcon, color: 'primary', parentPath: '/home', path: 'welcome', id: 'welcome' },
                // {name: 'Audiences', icon: AudienceIcon, color: 'primary', parentPath: '/home', path: 'audiences'},
                // {name: 'Personas', icon: PersonaIcon, color: 'primary', parentPath: '/home', path: 'personas'},
                // {name: 'Blog', icon: BlogIcon, color: 'primary', parentPath: '/home', path: 'blog'},
                // {name: 'Guides', icon: GuidesIcon, color: 'primary', parentPath: '/home', path: 'guides'},
                // {name: 'Promotions', icon: PromotionsIcon, color: 'primary', parentPath: '/home', path: 'promotions'},
            ],
            '/explore': stateExplore.sidepanel,
            '/monitor': stateMonitor.sidepanel,
            '/optimize': stateOptimize.sidepanel,
            '/dashboard': stateBentobox.sidepanel,
            '/settings': [
                { name: 'Basic Details', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'basic-details' },
                { name: 'Subscriptions', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'subscriptions' },
                { name: 'Integrations', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'integrations' },
                { name: 'Uploaded Audience', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'uploaded-audience-logs' },
                ...(getfromLocalStorage('group').toLowerCase() === 'admin' ? [
                    { name: 'Update Social Accounts', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'update-social-accounts' },
                ]
                    :
                    []
                ),
                ...(getfromLocalStorage('group').toLowerCase() === 'admin' || getfromLocalStorage('group').toLowerCase() === 'manager' ? [
                    { name: 'Add User', icon: DashboardIcon, color: 'default', parentPath: '/settings', path: 'add-user' },
                ]
                    :
                    []
                ),
            ],
            '/cdp':[],
            '/admin': [],
            '/studio': [],
        },
        sidepanelStateConfig: {
            'tertiary': stateMonitor,
            'quarternary': stateOptimize,
        },
        sidepanelExpandListState: {
            '/monitor': actions.handleExpandMonitorSidepanel,
            '/optimize': actions.handleExpandOptimizeSidepanel,
        },
        sidepanelStateUpdate: {
            '/explore': actions.handleUpdateExploreSidepanel,
            '/monitor': actions.handleUpdateMonitorSidepanel,
            '/optimize': actions.handleUpdateOptimizeSidepanel,
        },
        sidepanelStateUpdateParentChild: {
            '/explore': actions.handleUpdateParentChildExploreSidepanel,
            '/monitor': actions.handleUpdateParentChildMonitorSidepanel,
            '/optimize': actions.handleUpdateParentChildOptimizeSidepanel,
        },
        updateSidepanelSelectState: {
            'tertiary': actions.handleSelectMonitorSidepanel,
            'quarternary': actions.handleSelectOptimizeSidepanel,
            'primary': actions.handleUpdateSelectedBento
        },
        // rightSidepanelContent: {
        //     '/home': <DashboardRightSidepanel />,
        //     '/explore': <ExploreRightSidepanel />,
        //     '/monitor': <DashboardRightSidepanel />,
        //     '/optimize': <DashboardRightSidepanel />,
        // },
        rightSidepanelContent: (loc, selectedContent) => {
            const tempObj = {
                '/home': <DashboardRightSidepanel />,
                '/explore': <ExploreRightSidepanel />,
                '/monitor': <DashboardRightSidepanel />,
                '/optimize': <DashboardRightSidepanel />,
                'Search': <SearchRightSidepanel />,
                'Notifications': <NotificationsRightSidepanel />,
                'Layers': <LayerCards />,
                'Create Report': <CreateReport />,
                'Filter': <FilterSidepanel />,
                'Create Campaign': <CreateCampaign />,
                'Upload Data': <UploadData />,
                'Parameter': <OptimizeFilterSidepanel />
            }
            if (['Search', 'Notifications', 'Layers', 'Create Report'].includes(selectedContent)) {
                return tempObj[selectedContent]
            } else if (['Filter', 'Create Campaign', 'Upload Data', 'Context', 'Optimization Model', 'Workspace', 'Parameter'].includes(selectedContent)) {
                if (stateSettingsData.social[0]?.account_id) {
                    return tempObj[selectedContent]
                }
                return <ErrorAccount />
            }
            return tempObj[loc]
        },
        treeViewItemArrow: (color) => ({ item, context }) => {
            if (item.hasChildren) {
                return <CustomSvgIcon component={DownArrowIcon} className={`${context.isExpanded ? classes.inverse : undefined} ${classesHoverActive[color]}`} />
            }
            return null
        },
        treeViewItemTitle: (color) => ({ item, title, context }) => {
            const CustomIconStart = () => {
                if (item.hasChildren) {
                    if (context.isExpanded) {
                        return <CustomSvgIcon component={ParentFolderIcon} className={`${classesHoverActive[color]}`} />
                    } else {
                        return <CustomSvgIcon component={ChildFolderIcon} className={`${classesHoverActive[color]}`} />
                    }
                }
                return <CustomSvgIcon component={DashboardIcon} className={`${item.index === activeDashboard ? classesHoverActive.whiteColor : classesHoverActive[color]}`} />
            }
            return <LabelMedium variant="body1"><CustomIconStart />&nbsp;&nbsp;{title}</LabelMedium>
        },
        treeViewItem: (CustomElement) => ({ title, arrow, depth, context, children, item }) => {
            return (
                <div {...context.itemContainerWithChildrenProps}>
                    <CustomElement
                        onClick={handleOnClick(item)}
                        button={!item.hasChildren}
                        style={{ paddingLeft: theme.spacing(3 + depth) }}
                        selected={item.index === activeDashboard}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            {...context.itemContainerWithoutChildrenProps} {...context.interactiveElementProps}
                        >
                            <Fragment>{title}</Fragment>
                            <Fragment>{context.isRenaming || arrow}</Fragment>
                        </Grid>
                    </CustomElement>
                    {children}
                </div>
            )
        },
        reportTooltips: (data) => {
            const ReportTypeLayout = () => {
                switch (data?.reportType) {
                    case 0:
                    case 8:
                        return (
                            <Fragment>
                                <div>POI(s): {getPoiSname(data?.poitype ?? '')}</div>
                            </Fragment>
                        )
                    case 6:
                    case 9:
                    case 12:
                        return (
                            <Fragment>
                                <div>Date Range: {data?.start_date} to {data?.end_date}</div>
                                <div>POI(s): {getPoiSname(data?.poitype ?? '')}</div>
                            </Fragment>
                        )
                    case 1:
                        return (
                            <Fragment>
                                <div>Date Range: {data?.start_date} to {data?.end_date}</div>
                                <div>POI(s): {getPoiSname(data?.poitype ?? '')}</div>
                            </Fragment>
                        )
                    case 5:
                        return (
                            <Fragment>
                                <div>Date Range: {data?.start_date} to {data?.end_date}</div>
                                <div>Type: {getPoiSname(data?.poitype ?? '')}</div>
                            </Fragment>
                        )
                    case 3:
                    case 4:
                    case 7:
                        return (
                            <Fragment>
                                <div>Date Range: {data?.start_date} to {data?.end_date}</div>
                            </Fragment>
                        )
                    case 10:
                        return (
                            <Fragment>
                                <div>Type: {data?.poitype ?? ''}</div>
                            </Fragment>
                        )
                    default:
                        return null
                }
            }

            return (
                <div className={`${classesHoverActive.tooltipOverflow}`} onClick={e => e.preventDefault()}>
                    <div>{data?.name}</div>
                    <div>Report: {reportListMap[data?.reportType]}</div>
                    <div>Location(s): {districtCodeToDistrict(data?.district_codes)}</div>
                    <ReportTypeLayout />
                </div>
            )
        },
        reportTooltipForMultilayer: (data) => {
            const reportType = multiLayerMappingReverse[data?.reportType]
            return (
                <div className={`${classesHoverActive.tooltipOverflow}`} onClick={e => e.preventDefault()}>
                    <div>{data?.name}</div>
                    <div>Report: Multilayer Report</div>
                </div>
            )
        }
    }), [stateExplore.sidepanel, stateMonitor, stateOptimize, currentLocation, activeDashboard, stateLayout])
}

export default useSidepanelConfig