import React from "react"
import { Navigate, Outlet  } from "react-router-dom"
import { getfromLocalStorage } from "../common/functions"

const GuestRoute = () => {
    const registered = getfromLocalStorage('registered') === 'true'
    const isLoggedIn = getfromLocalStorage('userJwt') && registered && getfromLocalStorage('login') ? true : false && getfromLocalStorage('group')
    
	return isLoggedIn ? <Navigate to="/home" /> : <Outlet />
}

export default GuestRoute