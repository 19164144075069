import React, { Fragment, memo, useState, useMemo, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { layerConfigChange, layerTypeChange, layerVisualChannelConfigChange, layerVisConfigChange, interactionConfigChange, layerColorUIChange, setFilter } from 'kepler.gl/actions'
import { DateRangePicker } from 'rsuite'

import { useStyles } from './styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import { CustomGrid } from '../../../../customMui/customMuiStyles'
import useExploreService from '../Service/useExploreService'
import { districtColumns, getCurrentNextDay, hiddenColumns, poiTypeColumns } from '../Config/keplerLayerConfig'
import { getCountryCode, getPoisFromKey, reportListReverse, socialStatus, tempTooltipConfig, visitDurationMarks, visualizationMap } from '../Config/exploreConfig'
import { capitalizeFirstLetter, getDistricts, getKeyFromValue, rgbToHex, hexToRgb, formatDateShort } from '../../../../common/functions'
import { CustomSwitchFilled, SingleSelectFilled, SingleFillColorSelectFilled, CustomSingleSlider, CustomTextFieldBody1, CustomDateRange, MultipleAutocompleteFilledWithSelectAll, CustomDoubleSlider, SingleSelectFilledWithSelectAll } from '../../../../customMui/customMuiComponents'
import useExploreReportService from '../Service/fetchReportsService'

const LayerCardContent = ({ layerData, reportConfig }) => {
    const classes = useStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const stateKepler = useSelector(state => state.keplerGl)
    const stateExplore = useSelector(state => state.exploreReducer)
    let tempId = layerData.config.dataId.split('_')
    tempId = tempId[tempId.length - 1]
    const [reportTempConfig, setReportTempConfig] = useState({})
    const { getPoiSname } = useExploreService()
    const { createExplorationReport, updateReport, reloadExplorationReport } = useExploreReportService()
    const getTooltips = () => stateKepler?.exploration?.visState?.interactionConfig?.tooltip?.config?.fieldsToShow?.[layerData?.config?.dataId ?? 'none'] ?? []
    const [tooltips, setTooltips] = useState(getTooltips())

    // const headers = ['Details', 'Location', 'Style']
    // const headers = ['Location', 'Style', 'Filters']
    const headers = ['Filters', 'Style']

    // const tempLayerDataFields = stateExplore.reportData[stateExplore.selected]?.datasets?.[0]?.data?.fields ?? []
    const tempLayerDataFields = stateExplore.reportData[stateExplore.selected.toString()]?.datasets?.filter(ele => ele?.info?.id.toString().includes(tempId))?.[0]?.data?.fields ?? []
    const [selectedTab, setSelectedTab] = useState('Filters')
    const [data, setData] = useState({
        poitype: [],
        location: [],
        visualization: 'none',
        h3: 'none',
        lat: 'none',
        lng: 'none',
        altitude: 'none',
        destLat: 'none',
        destLng: 'none',
        polygon: 'none',
        brands: [],
        name: [],
        social_status: 'All',
        count: [],
        date: [],
        visitDuration: [visitDurationMarks[0].value, visitDurationMarks[visitDurationMarks.length - 1].value],
    })
    const [options, setOptions] = useState({
        location: [],
        brands: [],
        name: [],
        countRange: [{ value: 0, label: '0' }, { value: 100, label: '100' }],
        date: [],
        social_status: socialStatus.map(item => item?.name)
    })

    const [selectAll, setSelectAll] = useState({
        location: null,
        brands: null,
        name: null,
        social_status: null
    })

    const handleChangeDate = val => {
        let start_date_in_sec = Date.parse(val[0])
        let end_date_in_sec = Date.parse(val[1])
        let report_start_date_in_sec = Date.parse(reportTempConfig.start_date)
        let report_end_date_in_sec = Date.parse(reportTempConfig.end_date)

        // decresing date, because we get a response for selected start_date + 1 to end_date
        //val[0] = new Date(val[0].setDate(val[0].getDate() - 1));

        if ((start_date_in_sec < report_start_date_in_sec) || (end_date_in_sec > report_end_date_in_sec)) {
            setTimeout(() => createNewReport(val), 2000)
        } else {
            updateKepler('date', val)
        }
    }

    const createNewReport = async (dateVal) => {

        let tempData = {
            name: reportTempConfig.name,
            reportType: reportListReverse[reportTempConfig.reportType],
            district: reportTempConfig.district_codes,
            startDate: formatDateShort(dateVal[0], -1) ?? undefined,
            endDate: formatDateShort(dateVal[1], 1) ?? undefined,
            country: reportTempConfig.country,
            type: reportTempConfig.poitype
        }
        await createExplorationReport(tempData)
    }

    const updateKepler = async (name, val) => {
        let oldLayer = stateKepler.exploration?.visState.layers.filter(ele => ele.id === layerData.id)?.[0] ?? {}
        let tempConfigColor, tempChannel, tempConfigSize
        if (!val && !['heightToggle', 'outlineToggle', 'outlineWidth', 'fillColorReverse', 'outlineColorReverse'].includes(name)) {
            return true
        }
        switch (name) {
            case 'visualization':
                return dispatch(layerTypeChange(oldLayer, getKeyFromValue(visualizationMap, val), layerData.id))
            case 'h3':
                return dispatch(layerConfigChange(oldLayer, { 'columns': { 'hex_id': { 'value': val } } }))
            case 'lat':
                return dispatch(layerConfigChange(oldLayer, { 'columns': { 'altitude': { 'value': null, optional: true }, 'lat': { 'value': val }, 'lng': { 'value': null } } }))
            case 'lng':
                return dispatch(layerConfigChange(oldLayer, { 'columns': { 'altitude': { 'value': null, optional: true }, 'lat': { 'value': null }, 'lng': { 'value': val } } }))
            case 'altitude':
                return dispatch(layerConfigChange(oldLayer, { 'columns': { 'altitude': { 'value': val, optional: true }, 'lat': { 'value': null }, 'lng': { 'value': null } } }))
            case 'polygon':
                return dispatch(layerConfigChange(oldLayer, { 'columns': { 'geojson': { 'value': val } } }))
            case 'fillColor':
                return dispatch(layerConfigChange(oldLayer, { 'color': Object.values(hexToRgb(val) ?? {}) ?? [] }))
            case 'fillColorGradient':
                return dispatch(layerVisConfigChange(oldLayer, { 'colorRange': { ...oldLayer.config.visConfig.colorRange ?? {}, 'colors': val.split(',') } }))
            case 'fillColorReverse':
                setData(prev => ({ ...prev, 'fillColorGradient': prev['fillColorGradient'].split(',').reverse().join(',') }))
                return dispatch(layerColorUIChange(oldLayer, "colorRange", { 'colorRangeConfig': { 'reversed': val ? true : false } }))
            case 'colorField':
                tempConfigColor = tempLayerDataFields.filter(ele => ele.name === val)?.[0] ?? null
                tempChannel = ['arc', 'line'].includes(oldLayer.type) ? 'sourceColor' : 'color'
                return dispatch(layerVisualChannelConfigChange(oldLayer, { 'colorField': tempConfigColor }, tempChannel))
            case 'colorScale':
                tempChannel = ['arc', 'line'].includes(oldLayer.type) ? 'sourceColor' : 'color'
                return dispatch(layerVisualChannelConfigChange(oldLayer, { 'colorScale': val }, tempChannel))
            case 'outlineToggle':
                let temp = { 'outline': val }
                if (oldLayer.type === 'geojson') {
                    temp = { 'stroked': val }
                }
                return dispatch(layerVisConfigChange(oldLayer, temp))
            case 'outlineColorField':
                tempConfigSize = tempLayerDataFields.filter(ele => ele.name === val)?.[0] ?? null
                return dispatch(layerVisualChannelConfigChange(oldLayer, { 'strokeColorField': tempConfigSize }, 'strokeColor'))
            case 'outlineColor':
                return dispatch(layerVisConfigChange(oldLayer, { 'strokeColor': Object.values(hexToRgb(val) ?? {}) ?? [] }))
            case 'outlineColorGradient':
                return dispatch(layerVisConfigChange(oldLayer, { 'strokeColorRange': { ...oldLayer.config.visConfig.strokeColorRange ?? {}, 'colors': val.split(',') } }))
            case 'outlineColorReverse':
                return dispatch(layerColorUIChange(oldLayer, "strokeColorRange", { 'colorRangeConfig': { 'reversed': val ? true : false } }))
            case 'outlineWidth':
                return dispatch(layerVisConfigChange(oldLayer, { 'thickness': val }))
            case 'heightToggle':
                return dispatch(layerVisConfigChange(oldLayer, { 'enable3d': val }))
            case 'sizeField':
                tempConfigSize = tempLayerDataFields.filter(ele => ele.name === val)?.[0] ?? null
                tempChannel = ['geojson'].includes(oldLayer.type) ? 'height' : 'size'
                setData(prev => ({ ...prev, [`${tempChannel}Scale`]: 'linear' }))
                return dispatch(layerVisualChannelConfigChange(oldLayer, { [`${tempChannel}Field`]: tempConfigSize }, tempChannel))
            case 'heightScale':
                tempChannel = ['geojson'].includes(oldLayer.type) ? 'height' : 'size'
                return dispatch(layerVisualChannelConfigChange(oldLayer, { [`${tempChannel}Scale`]: val }, tempChannel))
            case 'location':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => districtColumns.includes(ele.name?.[0] ?? 'none') && (ele.dataId?.[0] ?? '0').includes(tempId))
                if (idx !== -1) {
                    return dispatch(setFilter(idx, 'value', val.map(ele => ele.value)))
                }
                return true
            case 'brands':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => ele?.name?.[0] == 'brands' && (ele.dataId?.[0] ?? '0').includes(tempId))
                if (idx !== -1) {
                    return dispatch(setFilter(idx, 'value', val))
                }
                return true
            case 'name':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => ele?.name?.[0] == 'name' && (ele.dataId?.[0] ?? '0').includes(tempId))
                if (idx !== -1) {
                    return dispatch(setFilter(idx, 'value', val))
                }
                return true
            case 'social_status':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => (ele?.name?.[0] == 'wbipricesnormalizedcity' || ele?.name?.[0] == "wellbeingscore"))
                var wbiScoreDomain = filters.filter(ele => (ele?.name?.[0] == 'wbipricesnormalizedcity' || ele?.name?.[0] == "wellbeingscore"))?.[0]?.domain

                if (idx !== -1) {
                    if (typeof val != 'string' && val.length == 0) {
                        var range = []
                    }
                    else if (typeof val != 'string') {
                        var range = [0.1, 10]
                    }
                    else {
                        var wbipricesnormalizedcity = socialStatus.filter(item => item.name == val)
                        var range = wbipricesnormalizedcity[0].value
                    }
                    await updateReport({
                        ...stateExplore.selectedConfig,
                        wbi_score_lower: range[0],
                        wbi_score_upper: range[1],
                    })
                        .then(async res => {
                            if (range[0] < wbiScoreDomain[0] || range[1] > wbiScoreDomain[1]) {
                                await reloadExplorationReport({
                                    ...stateExplore.selectedConfig,
                                    wbi_score_lower: range[0],
                                    wbi_score_upper: range[1],
                                })
                            }else{
                                dispatch(setFilter(idx, 'value', range))
                            }
                        })
                        .catch(err => console.log(err))
                    return true
                }
                return true
            case 'date':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => ele?.name?.[0] == 'date')
                if (idx !== -1) {
                    return dispatch(setFilter(idx, 'value', val))
                }
                return true
            case 'count':
                var filters = (stateKepler?.exploration?.visState?.filters ?? [])
                var idx = filters.findIndex(ele => ele?.name?.[0] == 'count')
                if (idx !== -1) {
                    return dispatch(setFilter(idx, 'value', val))
                }
                return true
            case 'poitype':
                return true
            default:
                return true
        }
    }

    const handleChangeSelectedTab = (val) => (_) => setSelectedTab(val)
    const handleChangeData = (name) => (e) => {
        if (['heightToggle', 'outlineToggle', 'fillColorReverse', 'outlineColorReverse'].includes(name)) {
            updateKepler(name, e.target.checked)
            setData(prev => ({ ...prev, [name]: e.target.checked }))
        } else {
            updateKepler(name, e.target.value)
            setData(prev => ({ ...prev, [name]: e.target.value }))
        }
    }
    const handleChangeDataSlider = (name) => (_, val) => {
        updateKepler(name, val)
        setData(prev => ({ ...prev, [name]: val }))
    }
    const handleChangeMultipleData = (col) => (_, val) => {
        let tempVal = val ? val : []
        // if (col === 'location' && tempVal.length === 0) {
        //     tempVal = getDistricts(reportConfig.district_codes)
        // }

        updateKepler(col, tempVal)
        setData(prev => ({ ...prev, [col]: tempVal }))

        // if all the options are selected from the corresponding options list, it will mark selectAll to true else false
        setSelectAll(prev => ({ ...prev, [col]: tempVal.length == options[col].length && col != 'social_status' }))
    }

    const handleUpdateTooltips = (_, val) => {
        let tempConfig = tempTooltipConfig
        tempConfig.config.fieldsToShow = {
            [layerData.config.dataId]: val ? val : []
        }
        setTooltips(val ? val : [])
        dispatch(interactionConfigChange(tempConfig))
    }

    const handleToggleSelectAll = (col) => {
        switch (col) {
            case 'name':
                var tempVal = selectAll[col] ? [] : options['name']
                break

            case 'brands':
                var tempVal = selectAll[col] ? [] : options['brands']
                break

            case 'location':
                var tempVal = selectAll[col] ? [] : options['location']
                break

            case 'tooltips':
                var tempVal = selectAll[col] ? [] : getTooltipOptions().filter(ele => !tooltips.map(ele => ele.name).includes(ele))
                break
            case 'social_status':
                var tempVal = selectAll[col] ? [] : options['social_status']
                break
            default:
                console.warn('Toggle Name Mismatch!');
        }
        updateKepler(col, tempVal)

        setData(prev => ({ ...prev, [col]: tempVal }))
        setSelectAll(prev => ({ ...prev, [col]: !prev[col] }))
    }

    const extractDataFromLayer = () => {
        let tempVisualization = visualizationMap[layerData.type]
        let tempData = {
            visualization: tempVisualization
        }
        let options = {}

        let filters = stateKepler?.exploration?.visState?.filters ?? []
        let locationFilter = filters.filter(ele => districtColumns.includes(ele.name?.[0] ?? 'none') && ele.dataId?.[0].split('_')?.at(-1) == tempId)
        let poiTypeFilter = filters.filter(ele => poiTypeColumns.includes(ele.name?.[0] ?? 'none') && ele.dataId?.[0].split('_')?.at(-1) == tempId)


        // Layers data
        switch (tempVisualization) {
            case 'Polygon':
                tempData['polygon'] = layerData.config.columns?.geojson?.value ?? 'none'
                tempData['outlineToggle'] = layerData.config.visConfig.stroked ? true : false
                break
            case 'H3':
                tempData['h3'] = layerData.config.columns?.hex_id?.value ?? 'none'
                break
            case 'Point':
                tempData['lat'] = layerData.config.columns?.lat?.value ?? 'none'
                tempData['lng'] = layerData.config.columns?.lng?.value ?? 'none'
                tempData['altitude'] = layerData.config.columns?.altitude?.value ?? 'none'
                tempData['outlineToggle'] = layerData.config.visConfig.outline ? true : false
                break
            case 'Arc':
                tempData['lat'] = layerData.config.columns?.lat0?.value ?? 'none'
                tempData['lng'] = layerData.config.columns?.lng0?.value ?? 'none'
                tempData['destLat'] = layerData.config.columns?.lat1?.value ?? 'none'
                tempData['destLng'] = layerData.config.columns?.lng1?.value ?? 'none'
                break
            default:
                break
        }

        tempData['colorField'] = layerData.config.colorField?.name ?? 'none'
        tempData['fillColor'] = rgbToHex(layerData.config.color) ?? '#1488CC'
        tempData['fillColorGradient'] = (layerData.config.visConfig?.colorRange?.colors ?? []).join(',')
        tempData['fillColorReverse'] = layerData.config.visConfig?.colorRange?.reversed ? true : false
        tempData['colorScale'] = layerData.config.colorScale ?? 'none'

        if (!['H3', 'Arc'].includes(tempVisualization)) {
            tempData['outlineColor'] = rgbToHex(layerData.config.visConfig.strokeColor) ?? '#1488CC'
            tempData['outlineColorGradient'] = (layerData.config.visConfig?.strokeColorRange?.colors ?? []).join(',')
            tempData['outlineColorField'] = layerData.config.strokeColorField?.name ?? 'none'
            tempData['outlineWidth'] = layerData.config.visConfig.thickness ?? 10
            tempData['outlineColorReverse'] = layerData.config.visConfig?.strokeColorRange?.reversed ? true : false

            tempData['radius'] = layerData.config.visConfig.radius ?? 10
            tempData['radiusMeter'] = layerData.config.visConfig.radius ? true : false
        }

        tempData['heightToggle'] = layerData.config.visConfig?.enable3d ? true : false
        tempData['sizeField'] = layerData.config.sizeField?.name ?? 'none'

        tempData['heightScale'] = layerData.config.sizeScale ?? 'linear'

        // Filters data
        tempData['location'] = getDistricts(locationFilter?.[0]?.value ?? [])
        options['location'] = getDistricts(reportTempConfig.district_codes, reportTempConfig.country)
        setSelectAll(prev => ({ ...prev, location: tempData['location'].length == options['location'].length }))


        if ([0, 8, 9].includes(reportTempConfig.reportType)) {
            let nameFilter = filters.filter(ele => ele?.name?.[0] == 'name' && ele.dataId?.[0].split('_')?.at(-1) == tempId)
            let brandFilter = filters.filter(ele => ele?.name?.[0] == 'brands' && ele.dataId?.[0].split('_')?.at(-1) == tempId)

            tempData['name'] = nameFilter?.[0]['value']
            tempData['brands'] = brandFilter?.[0]['value']

            options['name'] = stateKepler?.exploration?.visState?.filters.filter(ele => ele?.name?.[0] == 'name' && ele.dataId?.[0].split('_')?.at(-1) == tempId)?.[0].domain
            options['brands'] = stateKepler?.exploration?.visState?.filters.filter(ele => ele?.name?.[0] == 'brands' && ele.dataId?.[0].split('_')?.at(-1) == tempId)?.[0].domain

            setSelectAll(prev => ({ ...prev, name: tempData['name'].length == options['name'].length }))
            setSelectAll(prev => ({ ...prev, brands: tempData['brands'].length == options['brands'].length }))
        }

        if ([6, 9].includes(reportTempConfig.reportType)) {
            let dateValue = filters.filter(ele => ele?.name?.[0] == 'date' && ele.dataId?.[0].split('_')?.at(-1) == tempId)
            data['date'] = [new Date(dateValue?.[0]?.value?.[0]), new Date(dateValue?.[0]?.value?.[1])]
            options['date'] = [new Date(dateValue?.[0]?.domain?.[0]), new Date(dateValue?.[0]?.domain?.[1])]

            let count = filters.filter(ele => ele?.name?.[0] == 'count' && ele.dataId?.[0].split('_')?.at(-1) == tempId)
            tempData['count'] = count?.[0]?.value
            options['countRange'] = count?.[0]?.domain.map(ele => {
                return {
                    value: ele,
                    label: String(ele)
                }
            })

        }

        if ([10, 12].includes(reportTempConfig.reportType)) {
            let socialFilter = filters.filter(ele => (ele?.name?.[0] == "wbipricesnormalizedcity" || ele?.name?.[0] == "wellbeingscore") && ele.dataId?.[0].split('_')?.at(-1) == tempId)
            let wbiRange = socialStatus.filter(item => item.value == socialFilter?.[0].value)
            data['social_status'] = wbiRange?.[0]?.name ?? 'All'
        }

        setData(prev => ({ ...prev, ...tempData }))
        setOptions(prev => ({ ...prev, ...options }))

        return true
    }

    const getOptions = (filtered = true) => {
        let tempField = []
        let tempResult = tempLayerDataFields?.map(ele => ele.name)
        if (filtered) {
            switch (data.visualization) {
                case 'Polygon':
                    tempField = ['geojson', 'polygon']
                    break
                case 'Point':
                    tempField = ['lat', 'lng', 'lat', 'long', 'latitude', 'longitude', 'alt', 'altitude']
                    break
                case 'H3':
                    tempField = ['h3', 'h3_index', 'h3index', 'poiCode']
                    break
                default:
                    break
            }
            tempResult = tempResult.filter(ele => tempField.includes(ele))
        } else {
            tempField = [...hiddenColumns]
            tempResult = tempResult.filter(ele => !tempField.includes(ele))
        }

        return tempResult
    }

    const getTooltipOptions = () => {
        let tempFields = [...hiddenColumns]
        return tempLayerDataFields?.filter(ele => !tempFields.includes(ele.name))
    }

    const selectedClass = (val) => {
        let temp = `${classesHoverActive.cursor}`
        if (selectedTab === val) {
            temp += ` ${classes.linkUnderline}`
        } else {
            temp += ` ${classes.linkUnderlineHover} ${classesHoverActive.primaryIcon}`
        }
        return temp
    }
    const GetVisualizationFields = useMemo(() => {
        switch (data.visualization) {
            case 'Polygon':
                return (
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            title="Polygon"
                            color="primary"
                            options={getOptions()}
                            value={data['polygon']}
                            onChange={handleChangeData('polygon')}
                        />
                    </Grid>
                )
            case 'H3':
                return (
                    <Grid item xs={12}>
                        <SingleSelectFilled
                            title="H3 Index"
                            color="primary"
                            options={getOptions()}
                            value={data['h3']}
                            onChange={handleChangeData('h3')}
                        />
                    </Grid>
                )
            case 'Point':
                return (
                    <Fragment>
                        <Grid item xs={12}>
                            <SingleSelectFilled
                                title="Latitude"
                                color="primary"
                                options={getOptions()}
                                value={data['lat']}
                                onChange={handleChangeData('lat')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SingleSelectFilled
                                title="Longitude"
                                color="primary"
                                options={getOptions()}
                                value={data['lng']}
                                onChange={handleChangeData('lng')}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <SingleSelectFilled
                                title="Altitude (Optional)"
                                color="primary"
                                options={getOptions()}
                                value={data['altitude']}
                            />
                        </Grid> */}
                    </Fragment>
                )
            default:
                return null
        }
    }, [data.visualization])

    const FieldsLayout = useMemo(() => {
        switch (selectedTab) {
            case 'Filters':
                return (
                    <CustomGrid container spacing={1}>
                        {[0, 8, 9].includes(reportTempConfig.reportType) &&
                            <>
                                <Grid item xs={12}>
                                    <MultipleAutocompleteFilledWithSelectAll
                                        title="Name"
                                        color="primary"
                                        options={options['name']}
                                        value={data['name']}
                                        selected={selectAll['name']}
                                        onChange={handleChangeMultipleData('name')}
                                        handleToggleSelectAll={handleToggleSelectAll}
                                        getOptionLabel={option => capitalizeFirstLetter(option)}
                                        textFieldProps={{ placeholder: 'Name' }}
                                        limitTags={2}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MultipleAutocompleteFilledWithSelectAll
                                        title="Brands"
                                        color="primary"
                                        options={options['brands']}
                                        value={data['brands']}
                                        selected={selectAll['brands']}
                                        onChange={handleChangeMultipleData('brands')}
                                        handleToggleSelectAll={handleToggleSelectAll}
                                        getOptionLabel={option => capitalizeFirstLetter(option)}
                                        textFieldProps={{ placeholder: 'Brands' }}
                                        limitTags={2}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            [6, 9].includes(reportTempConfig.reportType) &&
                            <>
                                <Grid item xs={12}>
                                    <CustomSingleSlider
                                        title="Visitors Count"
                                        color="primary"
                                        value={data['count']}
                                        onChange={handleChangeDataSlider('count')}
                                        valueLabelDisplay="auto"
                                        marks={options['countRange']}
                                        min={options['countRange'][0].value}
                                        max={options['countRange'][1].value}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomDoubleSlider
                                        title="Visit Duration"
                                        color="primary"
                                        value={data['visitDuration']}
                                        onChange={handleChangeDataSlider('visitDuration')}
                                        valueLabelDisplay="auto"
                                        marks={visitDurationMarks}
                                        min={visitDurationMarks[0].value}
                                        max={visitDurationMarks[visitDurationMarks.length - 1].value}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            [6, 9].includes(reportTempConfig.reportType) &&
                            <Grid item xs={12}>
                                <CustomDateRange
                                    color="primary"
                                    name='dateRange'
                                    character=' to '
                                    title="Date Range"
                                    value={data['date']}
                                    onChange={(val) => {
                                        setData(prev => ({ ...prev, date: val }));
                                        handleChangeDate(val);
                                    }}
                                    disabledDate={DateRangePicker.afterToday()}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <MultipleAutocompleteFilledWithSelectAll
                                title="Location"
                                color="primary"
                                options={options['location']}
                                value={data['location']}
                                selected={selectAll['location']}
                                onChange={handleChangeMultipleData('location')}
                                handleToggleSelectAll={handleToggleSelectAll}
                                getOptionLabel={option => capitalizeFirstLetter(option?.name)}
                                textFieldProps={{ placeholder: 'Location' }}
                                limitTags={2}
                            />
                        </Grid>
                        {(['WBI_prices'].includes(reportTempConfig.poitype) || [12].includes(reportTempConfig.reportType)) &&
                            <Grid item xs={12}>
                                <SingleSelectFilledWithSelectAll
                                    title="Social Status"
                                    color="primary"
                                    options={socialStatus.map(item => item?.name)}
                                    value={data['social_status']}
                                    selected={selectAll['social_status']}
                                    handleToggleSelectAll={() => handleToggleSelectAll('social_status')}
                                    onChange={handleChangeMultipleData('social_status')}
                                    textFieldProps={{ placeholder: 'Social Status' }}
                                    limitTags={2}
                                />
                            </Grid>
                        }
                    </ CustomGrid>
                )
            // case 'Filters':
            //     return (
            //         <MultipleAutocompleteFilledWithSelectAll
            //             title="POI(s)"
            //             color="primary"
            //             options={getPoiSname(reportConfig.poitype).split(',').map(ele => ({name: ele}))}
            //             value={data['poitype']}
            //             onChange={handleChangeMultipleData('poitype')}
            //             getOptionLabel={option => capitalizeFirstLetter(option.name)}
            //             textFieldProps={{placeholder: 'POI(s)'}}
            //             limitTags={2}
            //         />
            //     )
            case 'Style':
                return (
                    <Fragment>
                        <CustomGrid container spacing={1}>
                            {/* <Grid item xs={12}>
                                <SingleSelectFilled
                                    title="Visualization Type"
                                    color="primary"
                                    options={Object.values(visualizationMap)}
                                    value={data['visualization']}
                                    onChange={handleChangeData('visualization')}
                                />
                            </Grid>
                            {GetVisualizationFields} */}
                            <Grid item xs={12}>
                                {
                                    data['colorField'] === 'none' &&
                                    <CustomTextFieldBody1
                                        title="Fill Color"
                                        color="primary"
                                        value={data['fillColor']}
                                        type="color"
                                        onChange={handleChangeData('fillColor')}
                                    />
                                }
                                {
                                    data['colorField'] === 'none' ||
                                    <SingleFillColorSelectFilled
                                        title="Fill Color"
                                        color="primary"
                                        reverse={data['fillColorReverse']}
                                        value={data['fillColorGradient']}
                                        onChange={handleChangeData('fillColorGradient')}
                                    />
                                }
                            </Grid>
                            {
                                data['colorField'] === 'none' ||
                                <Grid item xs={12}>
                                    <CustomSwitchFilled
                                        title="Fill Cover Reversed"
                                        color="primary"
                                        name="fillColorReverse"
                                        checked={data['fillColorReverse']}
                                        onChange={handleChangeData('fillColorReverse')}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <SingleSelectFilled
                                    title="Fill Color Based on"
                                    color="primary"
                                    showNone={true}
                                    options={getOptions(false)}
                                    value={data['colorField']}
                                    onChange={handleChangeData('colorField')}
                                />
                            </Grid>
                            {
                                (data['colorField'] !== 'none') &&
                                <Grid item xs={12}>
                                    <SingleSelectFilled
                                        title="Color Scale"
                                        color="primary"
                                        options={['quantize', 'quantile']}
                                        value={data['colorScale']}
                                        onChange={handleChangeData('colorScale')}
                                    />
                                </Grid>
                            }
                            {
                                ['Point', 'Polygon'].includes(data['visualization']) &&
                                <Grid item xs={12}>
                                    <CustomSwitchFilled
                                        title="Outline"
                                        color="primary"
                                        name="outlineToggle"
                                        checked={data['outlineToggle']}
                                        onChange={handleChangeData('outlineToggle')}
                                    />
                                </Grid>
                            }
                            {
                                data['outlineToggle'] &&
                                <Fragment>
                                    {/* <Grid item xs={12}>
                                        <SingleSelectFilled
                                            title="Outline Based on"
                                            color="primary"
                                            showNone={true}
                                            options={getOptions(false)}
                                            value={data['outlineColorField']}
                                            onChange={handleChangeData('outlineColorField')}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <CustomSingleSlider
                                            title="Stroke Width"
                                            color="primary"
                                            value={data['outlineWidth']}
                                            onChange={handleChangeDataSlider('outlineWidth')}
                                            defaultValue={10}
                                            min={0}
                                            max={100}
                                            step={0.1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            data['outlineColorField'] === 'none' &&
                                            <CustomTextFieldBody1
                                                title="Outline Color"
                                                color="primary"
                                                value={data['outlineColor']}
                                                type="color"
                                                onChange={handleChangeData('outlineColor')}
                                            />
                                        }
                                        {
                                            data['outlineColorField'] === 'none' ||
                                            <SingleFillColorSelectFilled
                                                title="Outline Color"
                                                color="primary"
                                                value={data['outlineColorGradient']}
                                                onChange={handleChangeData('outlineColorGradient')}
                                            />
                                        }
                                    </Grid>
                                    {
                                        data['outlineColorField'] === 'none' ||
                                        <Grid item xs={12}>
                                            <CustomSwitchFilled
                                                title="Outline Cover Reversed"
                                                color="primary"
                                                name="outlineColorReverse"
                                                checked={data['outlineColorReverse']}
                                                onChange={handleChangeData('outlineColorReverse')}
                                            />
                                        </Grid>
                                    }
                                </Fragment>
                            }
                            {
                                ['Arc', 'Point'].includes(data['visualization']) ||
                                <Grid item xs={12}>
                                    <CustomSwitchFilled
                                        title="Height"
                                        color="primary"
                                        name="heightToggle"
                                        checked={data['heightToggle']}
                                        onChange={handleChangeData('heightToggle')}
                                    />
                                </Grid>
                            }
                            {
                                data['heightToggle'] &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <SingleSelectFilled
                                            title="Height Based on"
                                            color="primary"
                                            showNone={true}
                                            options={getOptions(false)}
                                            value={data['sizeField']}
                                            onChange={handleChangeData('sizeField')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SingleSelectFilled
                                            title="Height Scale"
                                            color="primary"
                                            options={['linear', 'sqrt', 'log']}
                                            value={data['heightScale']}
                                            onChange={handleChangeData('heightScale')}
                                        />
                                    </Grid>
                                </Fragment>
                            }
                        </CustomGrid>
                    </Fragment>
                )
            case 'Tooltips':
                return (
                    <Fragment>
                        <CustomGrid container spacing={1}>
                            <Grid item xs={12}>
                                <MultipleAutocompleteFilledWithSelectAll
                                    title="Tooltips"
                                    color="primary"
                                    options={getTooltipOptions().filter(ele => !tooltips.map(ele => ele.name).includes(ele))}
                                    value={tooltips}
                                    selected={selectAll['tooltips']}
                                    onChange={handleUpdateTooltips}
                                    handleToggleSelectAll={handleToggleSelectAll}
                                    getOptionLabel={option => option.name}
                                    textFieldProps={{ placeholder: 'Add Tooltip' }}
                                />
                            </Grid>
                        </CustomGrid>
                    </Fragment>
                )
            case 'Details':
                return (
                    <Fragment>
                        Yet to be Added
                    </Fragment>
                )
            case 'Source':
                return null
            default:
                return null
        }
    }, [selectedTab, data, tooltips, selectAll])

    useEffect(() => {
        let tempReport = {}
        for (let ele of stateExplore.sidepanel) {
            if (ele.type == 'folder') {
                tempReport = ele?.children.filter(ele => tempId === ele?.id.toString())[0] ?? {}
            } else if (tempId === ele?.id.toString()) {
                tempReport = ele
                break
            }
        }
        setReportTempConfig(tempReport)
    }, [])

    useEffect(() => {
        extractDataFromLayer()
    }, [reportTempConfig])

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent='space-between'
                    alignItems="center"
                    spacing={2}
                >
                    <div>
                        {headers.map((ele, index) => (
                            <span key={index} onClick={handleChangeSelectedTab(ele)} className={`${classes.marginRight} ${selectedClass(ele)}`}>{ele}</span>
                        ))}
                        <span onClick={handleChangeSelectedTab('Tooltips')} className={`${selectedClass('Tooltips')}`}>Tooltips</span>
                    </div>
                    {/* <div onClick={handleChangeSelectedTab('Source')} className={`${selectedClass('Source')}`}>Source</div> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} className={`${classes.marginTop}`}>
                    {FieldsLayout}
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default memo(LayerCardContent)