import React from 'react'
import { Divider, Grid } from '@material-ui/core'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../styles'
import { useHoverActiveStyles } from '../../../static/hoverActiveStyles'

import { TypographySemiBold } from '../../../customMui/customMuiStyles'

const NotificationsRightSidepanel = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                >
                    <TypographySemiBold variant="body1">Notifications</TypographySemiBold>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Divider className={`${classes.sidepanelDivider}`} />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY}`}>
                <Grid container spacing={1}>
                    sdsd
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NotificationsRightSidepanel