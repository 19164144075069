import { makeStyles } from "@material-ui/core"
import { checkMuiLightDark, getLightDarkGrayscaleColor } from "../../../../common/functions"

export const useStyles = makeStyles((theme) => ({
    marginRight: {
        marginRight: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    sidepanelDivider: {
        height: theme.spacing(0.5)
    },
    flexHeader: {
        flex: '0 1 0'
    },
    flexContent: {
        flex: '1 1 0'
    },
    transition: {
        transition: 'all 0.5s ease-in-out'
    },
    dragOpacity: {
        opacity: 0.5
    },
    dragBorder: {
        border: `2px solid ${theme.palette.secondary.main}`,
    },
    contentHeaderRightMargin: {
        marginRight: theme.spacing(2)
    },
    contentHeaderLeftMargin: {
        marginLeft: theme.spacing(2)
    },
    disabledIconColor: {
        fill: `${theme.palette.grayscaleLight['60'] ?? theme.palette.background}`,
        color: `${theme.palette.grayscaleLight['60'] ?? theme.palette.background}`
    },
    reportPadding: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    reportDescription: {
        color: getLightDarkGrayscaleColor(theme, 100, 90, true)
    },
    headerHover: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '10%',
            height: '2px',
            left: '0',
            bottom: '0',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            left: '0',
            bottom: theme.spacing(-0.5),
            backgroundColor: (theme.palette.type === 'light' ? theme.palette.grayscaleDark['90'] : theme.palette.grayscaleLight['100']) ?? theme.palette.background,
            transition: 'transform 0.5s ease',
            transformOrigin: 'left',
        },
        transition: 'all 0.5s ease-in-out'
    },
    layerMiniCardMaxHeight: {
        maxHeight: '150px',
    },
    omniGradientSvg: {
        fill: checkMuiLightDark(theme, `url(#omniGradient) ${theme.palette.text.primary}`, theme.palette.text.primary)
    },
    backIconRotate: {
        transform: 'rotate(90deg)'
    },
    layerCardPadding: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`
    },
    layerSummaryIconMarginRight: {
        marginRight: theme.spacing(0.5)
    },
    layerSummaryIconMarginTop: {
        marginTop: theme.spacing(0.5)
    },
    enableOptionsIcon: {
        '&:hover': {
            '& > div > div > div > div > div > div': {
                display: 'block'
            }
        },
    },
    optionsMenu: {
        display: 'none',
    },
    linkUnderlineHover: {
        position: 'relative',
        '&:hover': {
            '&::before': {
                transform: 'scaleX(1)',
                transformOrigin: 'center',
            },
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            display: 'block',
            width: '100%',
            height: '2px',
            bottom: theme.spacing(-1),
            left: 0,
            backgroundColor: theme.palette.text.primary,
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease',
            transformOrigin: 'center',
        },
    },
    linkUnderline: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '0%',
            height: '2px',
            left: '0',
            bottom: '0',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            left: '0',
            bottom: theme.spacing(-1),
            backgroundColor: theme.palette.text.primary,
            transition: 'transform 0.2s ease',
            transformOrigin: 'center',
        },
    },
    backdrop: {
        width: '100vw',
        height: '100vh',
        zIndex: `${theme.zIndex.appBar + 1}`,
        backgroundColor: 'transparent'
    },
    popper: {
        zIndex: `${theme.zIndex.appBar + 2}`,
    },
    profileMenu: {
        marginTop: `${theme.spacing(1.5)}px`,
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        minWidth: theme.spacing(28),
    },
    profileMenuItem: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        cursor: 'pointer'
    },
    profileMenuItemLogout: {
        // backgroundColor: theme.palette.error.main,
        // color: theme.palette.primary.contrastText
    },
    popperArrow: {
        position: "relative",
        marginTop: "16px",
        "&:before": {
            backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            right: "10%"
        }
    },
    svgMarginRight: {
        marginRight: theme.spacing(1)
    },
}))