import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import {
  TypographySemiBold,
  CustomBody1SemiBoldButton,
  CustomHeading3ButtonSecondary,
  LabelMedium,
  CustomSlider,
} from "../../../../customMui/customMuiStyles";
import { styled } from "@material-ui/core";
import { useStyles as bentoStyles } from "./bentoStyles";
import { ReactComponent as Logo } from "../../../../assets/Logo 32px.svg";
import { Autocomplete } from "@material-ui/lab";
import { TreeSelect, ConfigProvider } from "antd";
import { capitalizeFirstLetter } from "../../../../common/functions";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import {
  CustomDateRange,
  CustomDoubleSlider,
  SingleSelectFilled,
  StyledAutocompletePopper,
} from "../../../../customMui/customMuiComponents";
import { DateRangePicker } from "rsuite";


function ConfigureParameter(props) {
  const bentoClasses = bentoStyles();
  const { SHOW_PARENT } = TreeSelect;

  return (
    <div style={{ height: "100%" }}>
      <p className={`${bentoClasses.texth1}  ${bentoClasses.textColorPrimary}`}>
        New Bento Module
      </p>
      <div style={{ marginTop: "8px" }}>
        <TypographySemiBold variant="body2">
          Configure the metric that will be used towards the new Bar Bento
          Module.
        </TypographySemiBold>
      </div>
      <Grid container spacing={1} >
        <Grid item xs={6}>
          <div>
            <div style={{ marginTop: "16px", paddingRight: "16px" }}>
              <TypographySemiBold variant="body2">Bento Name</TypographySemiBold>

              <input
                type="text"
                value={props.reportData?.["name"]}
                onChange={props.handleChangeReportData("name")}
                placeholder="Give your module a recognizable name"
                className={`${bentoClasses.textInputStyle}`}
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <TypographySemiBold variant="body2">
                Location(s)
              </TypographySemiBold>

              <Autocomplete
                PopperComponent={StyledAutocompletePopper}
                multiple
                id="tags-standard"
                options={[
                  ...props.countries,
                  ...props.states,
                  ...props.districts,
                ]}
                getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
                onChange={props.handleChangeMultipleReportData("locations")}
                value={props.reportData?.['locations']}
                limitTags={1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Location(s)"
                  />
                )}
                disabled
              />
            </div>

            {![
              "poi",
              "poiInternal",
              "wellbeingPrices",
              "wellbeingPlaces",
              "wellbeingCensus",
            ].includes(props.reportData?.reportType) && (
                <Grid item xs={12} style={{ marginTop: "16px" }}>
                  <TypographySemiBold variant="body2">
                    Date Range
                  </TypographySemiBold>
                  <CustomDateRange
                    color="primary"
                    name="dateRange"
                    character=" to "
                    //title="Date Range"
                    onChange={props.handleChangeTreeSelect("dateRange")}
                    value={props.reportData?.["dateRange"]}
                    disabledDate={DateRangePicker.afterToday()}
                    disabled
                  />
                </Grid>
              )}
            {["visitation", "poiVisitation", "poi", "poiInternal"].includes(
              props.reportData?.reportType
            ) && (
                <>
                  <div style={{ marginTop: "16px" }}>
                    <TypographySemiBold variant="body2">
                      Point of Interest(s)
                    </TypographySemiBold>

                    <TreeSelect
                      // dropdownStyle={bentoClasses.treeSelectStyle}
                      popupClassName={`${bentoClasses.treeSelectStyle}`}
                      showCheckedStrategy={SHOW_PARENT}
                      //style={bentoClasses.treeSelectStyle}
                      style={{ width: "100%" }}
                      dropdownStyle={{ overflow: "auto", zIndex: 1301, widht: "100%" }}
                      //dropdownMatchSelectWidth={false}
                      popupMatchSelectWidth={false}
                      treeData={props.poiData}
                      placeholder="Point of Interest(s)"
                      value={props.reportData?.["pois"]}
                      onChange={props.handleChangeTreeSelect("pois")}
                      maxTagCount={2}
                      notFoundContent={
                        <LabelMedium variant="body1">No POIs found</LabelMedium>
                      }
                      // disabled multiple select
                      treeCheckable={true}
                      multiple={true}
                      allowClear
                      disabled
                    />
                  </div>

                  {/*
                  <div style={{ marginTop: "16px" }}>
                    <TypographySemiBold variant="body2">
                      Brands(s)
                    </TypographySemiBold>

                    <Autocomplete
                      PopperComponent={StyledAutocompletePopper}
                      multiple
                      id="tags-standard"
                      value={props.reportData?.["brands"]}
                      options={props.brands}
                      getOptionLabel={(option) => option.value}
                      onChange={props.handleChangeMultipleReportData("brands")}
                      // value={props.reportData?.['locations']}
                      limitTags={1}
                      //defaultValue={[top100Films[13]]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Brand(s)"
                        />
                      )}
                    />
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <TypographySemiBold variant="body2">
                      Rating
                    </TypographySemiBold>

                    <CustomSlider
                      title="Rating"
                      color="primary"
                      value={props.reportData?.["ratings"]}
                      onChange={props.handleChangeMultipleReportData("ratings")}
                      valueLabelDisplay="auto"
                      marks={props.ratingMarks}
                      min={props.ratingMarks[0].value}
                      max={props.ratingMarks[props.ratingMarks.length - 1].value}
                      step={0.1}
                    />
                  </div>
*/}
                </>
              )}

            {![
              "poi",
              "poiInternal",
              "mobility",
              "wellbeingPrices",
              "wellbeingPlaces",
              "wellbeingCensus",
            ].includes(props.reportData?.reportType) && (
                <>
                  <div style={{ marginTop: "16px" }}>
                    <Grid item xs={12}>
                      <CustomDoubleSlider
                        title="Visit Time"
                        color="primary"
                        value={props.reportData?.["visitTime"]}
                        onChange={props.handleChangeMultipleReportData("visitTime")}
                        valueLabelDisplay="auto"
                        marks={props.visitTimeMarks}
                        min={props.visitTimeMarks[0].value}
                        max={
                          props.visitTimeMarks[props.visitTimeMarks.length - 1].value
                        }
                        disabled
                      />
                    </Grid>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <Grid item xs={12}>
                      <CustomDoubleSlider
                        title="Visit Duration"
                        color="primary"
                        value={props.reportData?.["visitDuration"]}
                        onChange={props.handleChangeMultipleReportData(
                          "visitDuration"
                        )}
                        valueLabelDisplay="auto"
                        marks={props.visitDurationMarks}
                        min={props.visitDurationMarks[0].value}
                        max={
                          props.visitDurationMarks[
                            props.visitDurationMarks.length - 1
                          ].value
                        }
                        disabled
                      />
                    </Grid>
                  </div>
                </>
              )}


            {["mobility"].includes(props.reportData?.reportType) && (
              <Grid item xs={12} style={{ paddingBottom: "16px" }}>
                <SingleSelectFilled
                  color="primary"
                  name="resolution"
                  title="Resolution"
                  value={props.reportData?.["resolution"]}
                  onChange={props.handleChangeReportData("resolution")}
                  options={[
                    "Block (175m Radius)",
                    "County/District (Official Boundary Polygon)",
                  ]}
                />
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${bentoClasses.configLeftContainer}`}>
            <div className={`${bentoClasses.bentoIconBackgroud}`}>
              <Logo />
            </div>

            <div style={{ marginTop: "24px" }}>
              <p className={`${bentoClasses.texth1} ${bentoClasses.textColorPrimary}`}>Configure Parameter</p>

              <p className={`${bentoClasses.textb1}`}>
                Hover any of the layers shown on the left to see its metadata
                here, or click the layer item to edit its properties and/or
                settings.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', marginTop: '5vh' }}
      >
        <button onClick={(e) => { props.handleBack(e) }} className={`${bentoClasses.blackButton} ${bentoClasses.bigButton}`}>
          Back
        </button>
        <button onClick={(e) => { props.handleNext(e) }} className={`${bentoClasses.primaryButton} ${bentoClasses.bigButton}`} disabled={props.checkButtonDisabled()}>
          Next Step
        </button>
      </Grid>
    </div>
  );
}

export default ConfigureParameter;
