import { makeStyles } from "@material-ui/core"
import { DrawerSize, MiniDrawerSize } from "../../common/config"
import { getLightDarkGrayscaleColor } from "../../common/functions"

export const useStyles = makeStyles((theme) => ({
    miniDrawer: {
        [theme.breakpoints.up('sm')]: {
            flexShrink: 0,
            width: MiniDrawerSize,
        },
        '& > div': {
            width: 'inherit',
        },
        transition: 'all 0.5s ease-in-out',
    },
    miniDrawerBorder: {
        borderRight: '4px solid',
    },
    miniDrawerStyle: {
        width: `60px`,
        maxWidth: `60px`,
        minWidth: `60px`,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            flexShrink: 0,
            width: DrawerSize,
        },
        '& > div': {
            width: `100%`,
            [theme.breakpoints.up('sm')]: {
                width: `inherit`,
            }
        },
        transition: 'all 0.5s ease-in-out',
    },
    elementVisibility: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    customGridContent: {
        flex: 1,
        flexWrap: 'nowrap',
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(1)}px)`
    },
    overflowContainer: {
        flex: 1,
        overflowY: 'auto'
    },
    drawerContent: {
        padding: `${theme.spacing(1.5)}px 0 ${theme.spacing(1)}px`,
        maxWidth: `calc(${DrawerSize} - ${MiniDrawerSize})`,
        height: '100%',
    },
    drawerMiniContentBackground: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '90'),
        zIndex: 0
    },
    drawerContentBackground: {
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        zIndex: 0
    },
    drawerHeaderButton: {
        marginTop: theme.spacing(0.75),
    },
    drawerContentHeader: {
        padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
        width: 'inherit'
    },
    dividerDisabled: {
        height: '2px',
        background: theme.palette.text.disabled,
    },
    miniRightDrawer: {
        flexShrink: 0,
        width: MiniDrawerSize,
        '& > div': {
            width: 'inherit',
        },
        transition: 'all 0.5s ease-in-out',
    },
    rightDrawer: {
        flexShrink: 0,
        width: 'inherit',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        minWidth: MiniDrawerSize,
        '& > div': {
            width: 'inherit',
        },
        transition: 'all 0.5s ease-in-out',
    },
    dividerMargin: {
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(0.5)}px`,
    },
    dividerMarginMinimized: {
        margin: `0 ${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    },
    padding: {
        padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(1.5)}px`,
    },
    inverse: {
        transform: 'rotate(180deg)'
    },
    flexWithoutBasis: {
        [theme.breakpoints.down('xs')]: {
            flex: '1 1 0',
            maxWidth: 'fit-content',
            transition: 'all 0.5s ease-in-out',
        },
    },
    showMenuIcon: {
        "&:hover": {
            '& > div:nth-child(2)': {
                display: 'block !important'
            }
        }
    },

    navigationIcons:{
        height: "calc(100vh - 64px)",
        display:"flex",
        marginTop:"64px"
    },
}))