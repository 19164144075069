import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { DateRangePicker } from 'rsuite'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'
import { useStyles as layoutStyles } from '../../../../static/layoutStyles'

import * as actions from '../../../../redux/actions'
import { checkScrollVisible, getErrorMessageFromResponse, getfromLocalStorage } from '../../../../common/functions'
import { CustomBody1BoldButton, CustomDividerColor, TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { CustomDateRange, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import useOptimizeService from '../Service/useOptimizeService'
import { customToast } from '../../../../common/customToastify'
import { convertToObject, optimizeChartsLayout, optimizeData } from '../Config/optimizeConfig'

const OptimizeFilterSidepanel = () => {
    const classes = useStyles()
    const classesLayout = layoutStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const stateOptimize = useSelector(state => state.optimizeReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)
    const { createCombinationsReport } = useOptimizeService()
    
    const commonStyles = `${classesHoverActive.foreground} ${classesHoverActive.bottomMedium} ${classesHoverActive.borderRadius} ${classes.layerCardPadding}`
    const primaryIconClass = `${classesHoverActive.primaryIcon} ${classesHoverActive.cursor}`

    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [dateRange, setDateRange] = useState([])

    // const handleUpdateDateRange = (val) => dispatch(actions.handleUpdateOptimizeDateRange(val ? val : []))
    const handleUpdateDateRange = (val) => setDateRange(val ? val : [])

    const getDisabledState = () => {
        // return true
        return stateOptimize.dataLoading || dateRange.length === 0
    }

    const addDataToState = (data) => {
        let tempData = convertToObject(data)
        let tempCharts = Object.keys(tempData).map(ele => optimizeChartsLayout(data[ele], ele))
        dispatch(actions.handleUpdateOptimizeCharts(tempCharts))
        dispatch(actions.handleUpdateOptimizeData(tempData))
    }

    const handleSubmit = async () => {
        dispatch(actions.handleUpdateOptimizeDataLoading(true))
        await createCombinationsReport(dateRange)
            .then(res => {
                addDataToState(res)
                customToast('Recommendations Data Loaded', 'success')
            })
            .catch(err => {
                customToast(getErrorMessageFromResponse(err, 'Failed to load recommendations data'), 'error')
            })
        // addDataToState(optimizeData)
        dispatch(actions.handleUpdateOptimizeDataLoading(false))
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('monitorSidepanelContentScrollArea')), [dispatch])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <TypographySemiBold variant="body1">Optimization Parameters</TypographySemiBold>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomDividerColor />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="monitorSidepanelContentScrollArea">
                <Grid
                    container 
                    direction="row"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <CustomDateRange
                            color="primary"
                            name='dateRange'
                            character=' to '
                            title="Observation Date Range*"
                            onChange={handleUpdateDateRange}
                            value={dateRange}
                            disabledDate={DateRangePicker.combine(DateRangePicker.allowedMaxDays(30), DateRangePicker.afterToday())}
                            disabled={stateOptimize.dataLoading}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <CustomBody1BoldButton onClick={handleSubmit} variant='outlined' disabled={getDisabledState()} fullWidth>
                    {stateOptimize.dataLoading && <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} />}
                    {stateOptimize.dataLoading || 'Get Recommendations'}
                </CustomBody1BoldButton>
            </Grid>
        </Grid>
    )
}

export default OptimizeFilterSidepanel