import * as actions from "../actions/actionTypes"

const initialState = {
    activeContent: 'none',
    rightSidePanel: false,
    report: {},
    button: false
}

const rightSidepanelReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.RIGHT_SIDEPANEL_ACTIVE_CONTENT:
            return {
                ...state,
                activeContent: action.payload,
            }
        case actions.RIGHT_SIDEPANEL_TOGGLE:
            return {
                ...state,
                rightSidePanel: action.payload,
            }
        case actions.CREATE_VISIT_REPORT:
            return {
                ...state,
                report: action.payload,
            }
        case actions.BUTTON_CHANGE:
            return {
                ...state,
                button: action.payload,
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default rightSidepanelReducer