import * as actions from "../actions/actionTypes"

const initialState = {
    sidepanel: [
        // {
        //     id: 'root',
        //     name: 'Root',
        //     children: [
        //         {
        //             id: 'd36f3',
        //             name: 'Child 4',
        //             children: [
        //                 {
        //                     id: '3v7d3',
        //                     name: 'Child 3'
        //                 }
        //             ],
        //         },
        //         {
        //             id: '3df6g',
        //             name: 'Child 2',
        //             children: [
        //                 {
        //                     id: 'd36f3',
        //                     children: [],
        //                     name: 'Child 4'
        //                 },
        //             ],
        //         },
        //     ]
        // },
        // {
        //     id: 'root1',
        //     name: 'Root1',
        //     children: [
        //         {
        //             id: 'd36f31',
        //             name: 'Child 41',
        //             children: [
        //                 {
        //                     id: '3v7d31',
        //                     name: 'Child 31'
        //                 }
        //             ],
        //         },
        //         {
        //             id: '3df6g1',
        //             name: 'Child 21',
        //             children: [
        //                 {
        //                     id: 'd36f31',
        //                     children: [],
        //                     name: 'Child 41'
        //                 },
        //             ],
        //         },
        //     ]
        // },
    ],
    loading: false,
    dataLoading: false,
    dataError: false,
    dateRange: [],
    data: {},
    // selected: [],
    selected: 'none',
    expanded: [],
    charts: []
}

const optimizeReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.UPDATE_OPTIMIZE_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            }
        case actions.UPDATE_OPTIMIZE_DATA:
            return {
                ...state,
                data: action.payload
            }
        case actions.UPDATE_OPTIMIZE_SIDEPANEL: {
            const temp = {...state.sidepanel}
            temp[action.payload.index] = action.payload
            return {...state, sidepanel: temp}
        }
        case actions.UPDATE_OPTIMIZE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actions.UPDATE_OPTIMIZE_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload,
                dataError: false
            }
        case actions.UPDATE_OPTIMIZE_DATA_ERROR:
            return {
                ...state,
                dataLoading: false,
                dataError: action.payload
            }
        case actions.SELECT_OPTIMIZE_SIDEPANEL:
            return {
                ...state,
                selected: action.payload
            }
        case actions.UPDATE_OPTIMIZE_CHARTS:
            return {
                ...state,
                charts: action.payload
            }
        // case actions.SELECT_OPTIMIZE_SIDEPANEL: {
        //     let temp = [...state.selected]
        //     if (state.selected.includes(action.payload.id)) {
        //         temp = temp.filter(ele => ele !== action.payload.id)
        //     } else {
        //         temp.push(action.payload.id)
        //     }
            
        //     return {
        //         ...state,
        //         selected: temp,
        //     }
        // }
        case actions.EXPAND_LIST_OPTIMIZE_SIDEPANEL:
            return {
                ...state,
                "expanded": action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default optimizeReducer