import * as actions from "../actions/actionTypes"

const initialState = {
    selected: 'none',
    loading: false
}

const homeReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.UPDATE_HOME_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actions.CHANGE_HOME_SELECTED:
            return {
                ...state,
                selected: action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default homeReducer