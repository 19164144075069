import { Fragment, useMemo } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { addDataToMap, updateMap, togglePerspective, toggleMapControl, enlargeFilter, setFilter, layerConfigChange } from "kepler.gl/actions"
import { processRowObject } from 'kepler.gl/processors'

import * as actions from '../../../../redux/actions'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import { mapConfig } from '../../../../config/keplerMapConfig'
import { poiAdminData, poiData } from '../Config/exploreConfig'
import { findValueInObject } from '../../../../common/functions'

const useExploreService = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    
    const currentLocation = location.pathname.match(/^\/[^\/]+/)[0]
    const activeDashboard = useParams().activeDashboard
    
    const classesHoverActive = useHoverActiveStyles()
    
    const stateExplore = useSelector(state => state.exploreReducer)

    const getPois = (value, obj=[...poiAdminData, ...poiData], key="value") => {
        if (obj[key] === value) {
            return obj
        } else {
            let keys = Object.keys(obj)
            for (let i = 0, len = keys.length; i < len; i++) {
                let k = keys[i]
                if (obj[k] && typeof obj[k] == 'object') {
                    let found = getPois(value, obj[k], key)
                    if (found) {
                        return found
                    }
                }
            }
        }
    }

    return useMemo(() => ({
        getPoiSname: (data) => {
            let tempPois = []
            for (let i of data.split(',')) {
                let temp = getPois(i)
                temp?.title && tempPois.push(temp.title)
            }
            return tempPois.join(', ')
        }
    }), [stateExplore.selected, stateExplore.datasets, stateExplore.filters, stateExplore.layers])
}

export default useExploreService