import React from "react";
import Plot from "react-plotly.js";
import { AutoSizer } from "react-virtualized";
function BarChart() {
  const layout = {
    autosize: true,
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    xaxis: {
      showgrid: false,
      zeroline: false,
      showline: false,
      showticklabels: false,
      //   mirror: 'ticks',
      //   gridcolor: '#bdbdbd',
      //   gridwidth: 0,
      //   zerolinecolor: '#969696',
      //   zerolinewidth: 4,
      //   linecolor: '#636363',
      //   linewidth: 6
    },
    yaxis: {
      // tickwidth:38, // len of a point on yaxis
      // dtick:1, // how many divisions between two points. eg 1 to 2 -> 1.1 1.2 1.3 ...
      //  ticklen:10,// thickness of dash
      showline: false,
      showgrid: false,
      zeroline: false,
      //  showticklabels: false,
      side: "right",
      //   mirror: 'ticks',
      //   gridcolor: '#bdbdbd',
      //   gridwidth: 0,
      //   zerolinecolor: '#969696',
      //   zerolinewidth: 4,
      //   linecolor: '#636363',
      //   linewidth: 6
    },
    // bargap:0.04,
    barcornerradius: 4,

 
  };
  return (
    <AutoSizer>
      {({ height, width }) => (
        <Plot
          data={[
            {
              type: "bar",
              x: [1, 2, 3, 4, 5, 6,7,8,9,10,75,53,97,45,34,56,97,35,76,65],
              y: [
                "apple",
                "bananna",
                "watermelon",
                "lemons",
                "rabbit",
                "bronod",
                "appled",
                "banannad",
                "watermelond",
                "lemonsd",
                "rabbitd",
                "bronodfs",
              ],
              width: 0.5,
              text: new Array(20).fill("$999"),
              textposition: "inside",
              insidetextanchor: "start",
              orientation: "h",
              marker: {
                color: "#44C8C1",
                width: 58,
                size: 19,
                // line:{
                //   width:0.1,
                //   color: 'white',
                // }
              },
              textfont: {
                color: "#FFFFFF",
                size: 14,
              },
            },
          ]}
          layout={{ ...layout, 
            width: width + 80, 
            height: height + 100 }}
          config={{ responsive: true }}
          //style={{ width: "100%", height: "100%" }}
          className="h-full w-full mb-8"
        />
      )}
    </AutoSizer>
  );
}

export default BarChart;