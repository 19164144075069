import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'

import useMonitorService from '../Service/useMonitorService'

import { checkScrollVisible, getErrorMessageFromResponse } from '../../../../common/functions'
import { campaign_bidStrategy, campaign_objective, campaign_specialAdCategory, campaign_status } from '../Config/monitorConfig'
import { CustomBody1BoldButton, CustomDividerColor, TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { CustomTextFieldBody1, SingleSelectFilled } from '../../../../customMui/customMuiComponents'
import { customToastSystem } from '../../../../common/customToastify'

const ErrorAccount = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()

    const { createCampaign } = useMonitorService()

    const defaultCampaignData = {
        platform: 'facebook',
        name: '',
        status: 'Paused',
        objective: 'none',
        bidStratergy: 'none',
        specialAdCategory: 'none',
        lifetimeBudget: '100',
    }

    const [contentHasScroll, setContentHasScroll] = useState(false)
    const [campaignData, setCampaignData] = useState(defaultCampaignData)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        await createCampaign(campaignData)
            .then(res => {
                setCampaignData(defaultCampaignData)
                customToastSystem('Campaign Created Successfully', 'success')
            })
            .catch(err => customToastSystem(getErrorMessageFromResponse(err, 'Failed to Create Campaign'), 'error'))
        setLoading(false)
    }

    const handleChangeCampaignData = (e) => setCampaignData(prev => ({...prev, [e.target.name]: e.target.value}))

    const checkButtonDisabled = () => {
        const selectCheck = [campaignData['platform'], campaignData['status'], campaignData['objective'], campaignData['bidStratergy']].some(ele => ele === 'none')
        const textfieldCheck = [campaignData['name']].some(ele => ['', '0'].includes(ele))
        const budgetFieldCheck = campaignData['lifetimeBudget'] < 100

        return selectCheck || textfieldCheck || budgetFieldCheck
    }

    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [])

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <div>
                <Typography style={{textAlign: 'center'}} variant="h2">Facebook Business Manager is not linked</Typography>
            </div>
        </Grid>
    )
}

export default ErrorAccount