import {handleActions} from 'redux-actions'
import {ActionTypes} from 'kepler.gl/actions'

const keplerCustomReducer = handleActions(
    {
        [ActionTypes.LAYER_TYPE_CHANGE]: (state, action) => {
            return {
                ...state
            }
        }
    },
    {}
)

export default keplerCustomReducer