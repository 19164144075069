import { makeStyles } from "@material-ui/core"
import { checkMuiLightDark, getLightDarkGrayscaleColor } from "../common/functions"

export const useHoverActiveStyles = makeStyles((theme) => ({
    margin: {
        margin: `${theme.spacing(1)}px 0`,
    },
    marginSidepanel: {
        margin: `0 ${theme.spacing(1.5)}px`,
    },
    marginAll: {
        margin: `${theme.spacing(1)}px`,
    },
    marginTopMapIcon: {
        marginTop: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    marginTopDouble: {
        marginTop: theme.spacing(4),
    },
    marginTopEight: {
        marginTop: theme.spacing(8),
    },
    marginTopFooter: {
        marginTop: theme.spacing(15),
    },
    marginTopHalf: {
        marginTop: theme.spacing(0.5),
    },
    marginRightHalf: {
        marginRight: theme.spacing(0.5),
    },
    marginRightOne: {
        marginRight: theme.spacing(1),
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    marginRightDouble: {
        marginRight: theme.spacing(4),
    },
    marginBottom: {
        marginBottom: theme.spacing(3),
    },
    marginBottomDouble: {
        marginBottom: theme.spacing(3),
    },
    marginBottomSmall: {
        marginBottom: theme.spacing(1),
    },
    marginBottomHalf: {
        marginBottom: theme.spacing(0.5),
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    marginLeftOne: {
        marginLeft: theme.spacing(1)
    },
    teamMarginLeftDouble: {
        marginLeft: theme.spacing(4)
    },
    teamMarginBottom: {
        marginBottom: theme.spacing(1)
    },
    teamPadding: {
        padding: theme.spacing(2)
    },
    teamMemberPadding: {
        padding: theme.spacing(1),
    },
    plansPadding: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    paddingLeftDouble: {
        paddingLeft: theme.spacing(4),
    },
    reportMargin: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    layerPadding: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    tooltipPadding: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    heightWithoutTeamPadding: {
        height: `calc(100% - 2 * ${theme.spacing(2)}px)`,
    },
    padding: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    paddingTestimonials: {
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    },
    paddingLandingDrawer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    paddingFlex: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        height: `calc(100% - ${theme.spacing(2.5)}px)`,
    },
    fullWidthWithoutPadding: {
        width: `calc(100% - 2 * ${theme.spacing(2)}px)`,
    },
    miniSidepanel: {
        height: '100vh',
        width: '50px',
        paddingLeft: `${theme.spacing(1.5)}px`,
        zIndex: theme.zIndex.drawer + theme.zIndex.appBar,
        backgroundColor: 'black',
    },
    overflowY: {
        overflowY: 'auto',
    },
    addScrollMargin: {
        maxWidth: 'inherit',
        marginRight: `-${theme.spacing(1)}px`
    },
    tooltipOverflow: {
        maxHeight: 'min(300px, 20vh)',
        overflowY: 'auto',
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    paragraph2Text: {
        ...theme.typography.paragraph2
    },
    paragraph3Text: {
        ...theme.typography.paragraph3
    },
    paragraph4Text: {
        ...theme.typography.paragraph4
    },
    paragraph5Text: {
        ...theme.typography.paragraph5
    },
    heading1Icon: {
        height: theme.typography.h1.lineHeight,
        width: theme.typography.h1.lineHeight,
    },
    heading2Icon: {
        height: theme.typography.h2.lineHeight,
        width: theme.typography.h2.lineHeight,
    },
    heading3Icon: {
        height: theme.typography.h3.lineHeight,
        width: theme.typography.h3.lineHeight,
    },
    heading4Icon: {
        height: theme.typography.h4.lineHeight,
        width: theme.typography.h4.lineHeight,
    },
    heading5Icon: {
        height: theme.typography.h5.lineHeight,
        width: theme.typography.h5.lineHeight,
    },
    paragraph1Icon: {
        height: theme.typography.paragraph1 ? theme.typography.paragraph1.lineHeight : 'inherit',
        width: theme.typography.paragraph1 ? theme.typography.paragraph1.lineHeight : 'inherit',
    },
    paragraph2Icon: {
        height: theme.typography.paragraph2 ? theme.typography.paragraph2.lineHeight : 'inherit',
        width: theme.typography.paragraph2 ? theme.typography.paragraph2.lineHeight : 'inherit',
    },
    paragraph3Icon: {
        height: theme.typography.paragraph3 ? theme.typography.paragraph3.lineHeight : 'inherit',
        width: theme.typography.paragraph3 ? theme.typography.paragraph3.lineHeight : 'inherit',
    },
    paragraph4Icon: {
        height: theme.typography.paragraph4 ? theme.typography.paragraph4.lineHeight : 'inherit',
        width: theme.typography.paragraph4 ? theme.typography.paragraph4.lineHeight : 'inherit',
    },
    paragraph5Icon: {
        height: theme.typography.paragraph5 ? theme.typography.paragraph5.lineHeight : 'inherit',
        width: theme.typography.paragraph5 ? theme.typography.paragraph5.lineHeight : 'inherit',
    },
    wrapParent: {
        height: '100%',
        width: '100%',
    },
    wrapParentViewPort: {
        height: '90vh',
        width: '100%',
    },
    wrapHeight: {
        height: '100%',
    },
    wrapWidth: {
        width: '100%',
    },
    fullWidthMobile: {
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    divider: {
        height: '2px',
        background: theme.palette.primaryGradient,
    },
    dividerLoginRegister: {
        height: '2px',
        background: theme.palette.gradient4
    },
    dividerDisabled: {
        height: '4px',
        background: theme.palette.text.disabled,
    },
    dividerLandingDrawerHeader: {
        height: '2px',
        background: theme.palette.primary.contrastText,
    },
    dividerLandingDrawer: {
        height: '1px',
        background: theme.palette.primary.contrastText
    },
    borderPlansPrimary: {
        border: `2px solid ${theme.palette.primary.main}`,
    },
    borderPlansSecondary: {
        border: `2px solid ${theme.palette.secondary.main}`,
    },
    borderPlansTertiary: {
        border: `2px solid ${theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main}`,
    },
    borderPlansQuarternary: {
        border: `2px solid ${theme.palette.quarternary ? theme.palette.quarternary.main : theme.palette.primary.main}`,
    },
    borderPlansTextPrimary: {
        border: `2px solid ${theme.palette.text.primary}`,
    },
    darkBackground: {
        backgroundColor: theme.palette.text.primary,
    },
    lightDarkTextColor: {
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
    },
    defaultColor: {
        fill: theme.palette.text.primary,
        color: theme.palette.text.primary,
    },
    defaultColorBackground: {
        backgroundColor: theme.palette.text.primary
    },
    defaultColorBorder: {
        borderColor: `${theme.palette.text.primary} !important`,
    },
    whiteColor: {
        color: theme.palette.primary.contrastText,
        fill: theme.palette.primary.contrastText,
    },
    successIcon: {
        fill: theme.palette.success.main,
        color: theme.palette.success.main,
    },
    failureIcon: {
        fill: theme.palette.error.main,
        color: theme.palette.error.main,
    },
    disabledIcon: {
        color: theme.palette.grayscaleLight?.[60] ?? theme.palette.primary.main,
        fill: theme.palette.grayscaleLight?.[60] ?? theme.palette.primary.main,
    },
    primaryIcon: {
        color: `${theme.palette.primary.main} !important`,
        fill: `${theme.palette.primary.main} !important`,
    },
    primaryIconBackground: {
        backgroundColor: `${theme.palette.primary.main} !important`,
    },
    primaryIconBorder: {
        borderColor: `${theme.palette.primary.main} !important`,
    },
    secondaryIcon: {
        color: `${theme.palette.secondary.main} !important`,
        fill: `${theme.palette.secondary.main} !important`,
    },
    secondaryIconBackground: {
        backgroundColor: theme.palette.secondary.main,
    },
    secondaryIconBorder: {
        borderColor: `${theme.palette.secondary.main} !important`,
    },
    tertiaryIcon: {
        color: `${theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit'} !important`,
        fill: `${theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit'} !important`,
    },
    tertiaryIconBackground: {
        backgroundColor: theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit',
    },
    tertiaryIconBorder: {
        borderColor: `${theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit'} !important`,
    },
    quarternaryIcon: {
        color: `${theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit'} !important`,
        fill: `${theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit'} !important`,
    },
    quarternaryIconBackground: {
        backgroundColor: theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit',
    },
    quarternaryIconBorder: {
        borderColor: `${theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit'} !important`,
    },
    errorIcon: {
        fill: theme.palette.error.main,
        color: theme.palette.error.main,
    },
    primaryActiveBorder: {
        borderRightColor: theme.palette.primary.main + '!important',
        borderBottomColor: theme.palette.primary.main + '!important',
    },
    secondaryActiveBorder: {
        borderRightColor: theme.palette.secondary.main + '!important',
        borderBottomColor: theme.palette.secondary.main + '!important',
    },
    tertiaryActiveBorder: {
        borderRightColor: `${theme.palette.tertiary?.main ?? 'inherit'} !important`,
        borderBottomColor: `${theme.palette.tertiary?.main ?? 'inherit'} !important`,
    },
    quarternaryActiveBorder: {
        borderRightColor: `${theme.palette.quarternary?.main ?? 'inherit'} !important`,
        borderBottomColor: `${theme.palette.quarternary?.main ?? 'inherit'} !important`,
    },
    defaultActiveBorder: {
        borderColor: `${theme.palette.grayscaleDark?.[checkMuiLightDark(theme, '80', '20')] ?? theme.palette.primary.main} !important`,
    },
    removeFillSvg: {
        '& > span > svg': {
            fill: 'inherit'
        }
    },
    primaryMobileSvg: {
        '& > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.primary.main,
            }
        }
    },
    secondaryMobileSvg: {
        '& > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.secondary.main,
            }
        }
    },
    tertiaryMobileSvg: {
        '& > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit',
            }
        }
    },
    quarternaryMobileSvg: {
        '& > path': {
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit',
            }
        }
    },
    errorIconDimension: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    iconDimension: {
        fontSize: theme.spacing(3),
    },
    transparentBorder: {
        border: '2px solid transparent',
    },
    Paragraph2: {
        ...theme.typography.paragraph2,
    },
    Paragraph3: {
        ...theme.typography.paragraph3,
    },
    cursor: {
        cursor: "pointer",
    },
    cursorGrab: {
        cursor: 'grabbing'
    },
    datePickerMui: {
        '& > div': {
            ...theme.typography.paragraph5,
            '& > div > button > span > svg': {
                fontSize: '1.5rem'
            },
        },
    },
    dateRange: {
        width: '89%',
		'& > .rs-picker-toggle': {
			backgroundColor: theme.palette.foreground,
			borderColor: 'transparent',
            borderBottom: '1px solid',
			'& > input': {
				backgroundColor: theme.palette.foreground,
			},
			'& > .rs-picker-toggle-value > span': {
				color: theme.palette.primary.contrastText,
			},
			'& > svg': {
				fill: theme.palette.primary.main,
			}
		},
		'& > .rs-picker-toggle-active': {
			color: theme.palette.primary.contrastText,
		},
		'& .rs-picker:not(.rs-picker-disabled):hover': {
			borderColor: theme.palette.primary.main,
		}
    },
    contentTextNoSelect: {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        userSelect: 'none',
    },
    contentTextElipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        width: '100%',
        position: 'absolute'
    },
    bottomHigh: {
        boxShadow: theme.shadows[1],
    },
    bottomMedium: {
        boxShadow: theme.shadows[2],
    },
    bottomLow: {
        boxShadow: theme.shadows[3],
    },
    borderRadius: {
        borderRadius: theme.shape.borderRadius,
    },
    background: {
        transition: 'all 0.5s ease-in-out',
        backgroundColor: getLightDarkGrayscaleColor(theme, '80', '90'),
    },
    foreground: {
        transition: 'all 0.5s ease-in-out',
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
    },
    backdrop: {
        transition: 'all 0.5s ease-in-out',
        backgroundColor: 'rgba(0,0,0,0.1)'
    },
    summaryBackdrop: {
        transition: 'all 0.5s ease-in-out',
        backgroundColor: `${theme.palette.text.primary}40`,
    },
    drawerZindex: {
        zIndex: theme.zIndex.drawer + 1,
    },
    primaryBackground: {
        backgroundColor: theme.palette.primary.main,
    },
    primaryGradientBackground: {
        background: theme.palette.primaryGradient,
    },
    secondaryBackground: {
        backgroundColor: theme.palette.secondary.main,
    },
    secondaryGradientBackground: {
        background: theme.palette.secondaryGradient,
    },
    tertiaryBackground: {
        backgroundColor: theme.palette.tertiary ? theme.palette.tertiary.main : 'inherit',
    },
    quarternaryBackground: {
        backgroundColor: theme.palette.quarternary ? theme.palette.quarternary.main : 'inherit',
    },
    tertiaryGradientBackground: {
        background: theme.palette.tertiaryGradient,
    },
    quarternaryGradientBackground: {
        background: theme.palette.quarternaryGradient,
    },
    disabledBackground: {
        backgroundColor: theme.palette.text.disabled,
    },
    textPrimaryBackground: {
        backgroundColor: theme.palette.text.primary,
    },
    disabledBackgroundWithOpacity: {
        backgroundColor: `${theme.palette.text.disabled}70`,
    },
    modalBackdropCloseIcon: {
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'absolute',
    },
    modalCloseDiv: {
        backgroundColor: `${theme.palette.foreground}40`,
        color: theme.palette.primary.contrastText,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        border: '1px solid transparent',
        borderRadius: `${theme.spacing(2.5)}px`,
        boxShadow: theme.shadows[2],
        transition: 'all 0.3s ease-in-out',
        '& > p > svg': {
            fill: theme.palette.primary.contrastText,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.foreground}99`,
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
            '& > p > svg': {
                fill: theme.palette.secondary.main,
            }
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            '& > p > svg': {
                fill: theme.palette.primary.contrastText,
            }
        },
    },
    modalCloseDivPrimary: {
        backgroundColor: `${theme.palette.foreground}40`,
        color: theme.palette.primary.contrastText,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        border: '1px solid transparent',
        borderRadius: `${theme.spacing(2.5)}px`,
        boxShadow: theme.shadows[2],
        transition: 'all 0.3s ease-in-out',
        '& > p > svg': {
            fill: theme.palette.primary.contrastText,
        },
        '&:hover': {
            backgroundColor: theme.palette.foreground,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '& > p > svg': {
                fill: theme.palette.primary.main,
            }
        },
        '&:active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '& > p > svg': {
                fill: theme.palette.primary.contrastText,
            }
        },
    },
    modalCloseDivTertiary: {
        backgroundColor: `${theme.palette.foreground}40`,
        color: theme.palette.tertiary ? theme.palette.tertiary.contrastText : theme.palette.primary.contrastText,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        border: '1px solid transparent',
        borderRadius: `${theme.spacing(2.5)}px`,
        boxShadow: theme.shadows[2],
        transition: 'all 0.3s ease-in-out',
        '& > p > svg': {
            fill: theme.palette.tertiary ? theme.palette.tertiary.contrastText : theme.palette.primary.contrastText,
        },
        '&:hover': {
            backgroundColor: theme.palette.foreground,
            borderColor: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
            color: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
            '& > p > svg': {
                fill: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
            }
        },
        '&:active': {
            backgroundColor: theme.palette.tertiary ? theme.palette.tertiary.main : theme.palette.primary.main,
            color: theme.palette.tertiary ? theme.palette.tertiary.contrastText : theme.palette.primary.contrastText,
            '& > p > svg': {
                fill: theme.palette.tertiary ? theme.palette.tertiary.contrastText : theme.palette.primary.contrastText,
            }
        },
    },
    modalContent: {
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        alignItems: 'flex-start',
        width: '100%',
        margin: `${theme.spacing(1)}px 0`,
        flexDirection: 'column',
        maxHeight: '45vh',
        [theme.breakpoints.down('sm')]: {
            height: '75vh',
            maxHeight: '75vh',
        },
    },
    modalIconButton: {
        float: 'right',
        margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 0`,
    },
    modalCloseIconSize: {
        width: `calc(1 * ${theme.typography.h1.fontSize})`,
        height: `calc(1 * ${theme.typography.h1.fontSize})`,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    confirmDialog: {
		backgroundColor: theme.palette.foreground,
		color: theme.palette.text.primary,
	},
    layersOptions: {
        visibility: 'hidden',
    },
    layerOptionsHover: {
        '&:hover': {
            '& > div > div:nth-of-type(2)': {
                visibility: 'visible',
            }
        }
    },
    leftBorderGradient2: {
        backgroundRepeat: 'repeat-y',
        backgroundImage: theme.palette.gradient2,
        backgroundSize: theme.spacing(1),
    },
    primaryGradientText: {
        background: theme.palette.primaryGradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    gradientI: {
        background: theme.palette.gradient1,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    gradientII: {
        background: theme.palette.gradient2,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    gradientIV: {
        background: theme.palette.omniGradient ?? theme.palette.primary.main,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    settingsHeadersHoverEffect: {
        position: 'relative',
        '&:hover': {
            '&::before': {
                transform: 'scaleX(0.8)',
                transformOrigin: 'left',
            },
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            display: 'block',
            width: '30%',
            height: '4px',
            bottom: theme.spacing(-1),
            left: 0,
            backgroundColor: theme.palette.primary.main,
            transform: 'scaleX(0)',
            transition: 'transform 0.2s ease',
            transformOrigin: 'left',
        },
    },
    settingsHeadersHover: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '40%',
            height: '4px',
            left: '0',
            bottom: '0',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '40%',
            height: '4px',
            left: '0',
            bottom: theme.spacing(-1),
            backgroundColor: theme.palette.primary.main,
            transition: 'transform 0.2s ease',
            transformOrigin: 'left',
        },
    },
    primaryBorder: {
        transition: 'all 0.5s ease',
        '&:hover': {
            borderColor: theme.palette.primary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'border-color',
            borderColor: theme.palette.primary.dark,
        },
    },
    successButton: {
        transition: 'all 0.3s ease-in-out',
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.success.light,
        },
        '&:active': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    errorButton: {
        transition: 'all 0.3s ease-in-out',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.light,
        },
        '&:active': {
            backgroundColor: theme.palette.error.dark,
        },
    },
    primaryText: {
        transition: 'all 0.5s ease',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            color: theme.palette.primary.dark,
        },
    },
    secondaryText: {
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            color: theme.palette.secondary.dark,
        },
    },
    secondaryBorder: {
        transition: 'all 0.5s ease',
        '&:hover': {
            borderColor: theme.palette.secondary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'border-color',
            borderColor: theme.palette.secondary.dark,
        },
    },
    tertiaryText: {
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.tertiary ? theme.palette.tertiary.light : 'inherit',
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            color: theme.palette.tertiary ? theme.palette.tertiary.dark : 'inherit',
        },
    },
    quarternaryText: {
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme.palette.quarternary ? theme.palette.quarternary.light : 'inherit',
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            color: theme.palette.quarternary ? theme.palette.quarternary.dark : 'inherit',
        },
    },
    tertiaryBorder: {
        transition: 'all 0.5s ease',
        '&:hover': {
            borderColor: theme.palette.tertiary ? theme.palette.tertiary.light : 'inherit',
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'border-color',
            borderColor: theme.palette.tertiary ? theme.palette.tertiary.dark : 'inherit',
        },
    },
    primaryBackgroundHover: {
        transition: 'all 0.5s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'background-color',
            backgroundColor: theme.palette.primary.dark,
        },
    },
    primarySvg: {
        transition: 'all 0.5s ease',
        '&:hover': {
            fill: theme.palette.primary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            fill: theme.palette.primary.dark,
        },
    },
    secondarySvg: {
        transition: 'all 0.5s ease',
        '&:hover': {
            fill: theme.palette.secondary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            fill: theme.palette.secondary.dark,
        },
    },
    tertiarySvg: {
        transition: 'all 0.5s ease',
        '&:hover': {
            fill: theme.palette.tertiary ? theme.palette.tertiary.light : 'inherit',
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            fill: theme.palette.tertiary ? theme.palette.tertiary.dark : 'inherit',
        },
    },
    quarternarySvg: {
        transition: 'all 0.5s ease',
        '&:hover': {
            fill: theme.palette.quarternary ? theme.palette.quarternary.light : 'inherit',
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            fill: theme.palette.quarternary ? theme.palette.quarternary.dark : 'inherit',
        },
    },
    primarySvgBackground: {
        transition: 'all 0.5s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            backgroundColor: theme.palette.primary.dark,
        },
    },
    secondarySvgBackground: {
        transition: 'all 0.5s ease',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            transitionDelay: '-2s',
            transitionProperty: 'font-color',
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    omniGradientSvgBackground: {
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.omniGradient ?? theme.palette.primary.main,
        },
    },
    gradient4SvgBackground: {
        transition: 'all 0.5s ease',
        '&:hover': {
            background: theme.palette.gradient4,
        },
    },
}))