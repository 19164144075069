import ReactGA from 'react-ga'

export const ReactGaSuccess = (category, action) => ReactGA.event({
    category,
    action
})

export const ReactGaError = (description, fatal=true) => ReactGA.exception({
    description,
    fatal
})

export const ReactGaPageView = (page) => ReactGA.pageview(page)