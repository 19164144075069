import * as actions from "../actions/actionTypes"

const initialState = {
    open: null,
    x: null,
    y: null,
    report: {},
    index: null
}

const exploreContextMenuReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.OPEN_EXPLORE_CONTEXT_MENU:
            return action.payload
        case actions.CLOSE_EXPLORE_CONTEXT_MENU:
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default exploreContextMenuReducer