import {
    withStyles,
    Select,
    Input,
    TextField,
    MenuItem,
    Switch,
    FormControlLabel,
    FormLabel,
    Checkbox,
    AppBar,
    MobileStepper
} from "@material-ui/core"
import {
    TreeView,
    TreeItem,
    Autocomplete,
} from '@material-ui/lab'
import { forwardRef } from "react"

import {
    Primary,
    PrimaryTint1,
    PrimaryTint2,
    PrimaryShade,
    Heading2,
    Text,
    Foreground,
    DisabledText,
    Paragraph3,
    Paragraph2,
    Background,
    Failure,
    SmallestFont,
} from "../styles/defaultStylesConfig"

export const CustomTreeView = withStyles(theme => ({

}))(TreeView)

export const CustomTreeItem = withStyles(theme => ({
    root: {
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: Foreground,
            '&:hover': {
                backgroundColor: Foreground,
            },
            '&:active': {
                backgroundColor: Foreground,
            },
        },
        color: Text,
    },
    label: {
        ...Heading2,
        '&:hover': {
            backgroundColor: Foreground,
        },
    },
    selected: {
        backgroundColor: Foreground,
        color: Primary,
    },
    expanded: {},
    iconContainer: {
        width: '0px',
    }
}))(TreeItem)

export const CustomMobileStepper = withStyles(theme => ({
    dot: {
        width: SmallestFont,
        height: SmallestFont,
        backgroundColor: DisabledText,
        // borderColor: Primary,
    },
    dotActive: {
        backgroundColor: Primary,
    }
}))(MobileStepper)

export const CustomAppBar = withStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
		padding: '0 0.028vw 0 0.028vw'
    },
    colorPrimary: {
        backgroundColor: Foreground,
    }
}))(AppBar)

export const CustomSelect = withStyles(theme => ({
    root: {
        color: Primary,
        '&:hover': {
            color: PrimaryTint1,
        },
    },
    icon: {
        color: Primary,
    },
    nativeInput: {
        color: Primary,
    },
}))(Select)

export const CustomInput = withStyles(theme => ({
    underline: {
        '&::after': {
            borderBottom: `2px solid ${Primary}`,
        },
    },
    colorDefault: {
        color: theme.palette.text.primary,
    }
}))(Input)

export const CustomAutocomplete = withStyles(theme => ({
    root: {
        '& .MuiFormLabel-root': {
            color: DisabledText,
        },
    },
    paper: {
        backgroundColor: Foreground,
    },
    inputRoot: {
        color: Text,
        ...Paragraph2,
        '&::after': {
            borderBottom: `2px solid ${Primary}`,
        }
    },
    option: {
        backgroundColor: Foreground,
        color: Text,
        ...Paragraph2,
        '&:hover': {
            backgroundColor: PrimaryTint1,
            color: '#FAFAFA',
        },
        '&:active': {
            color: '#FAFAFA',
            backgroundColor: PrimaryShade,
        },
        '&[aria-selected="true"]': {
            color: '#FAFAFA',
            backgroundColor: Primary,
            '&:hover': {
                backgroundColor: PrimaryTint1,
                color: '#FAFAFA',
            },
            '&:active': {
                backgroundColor: PrimaryShade,
            },
        }
    },
    noOptions: {
        color: Text,
        ...Paragraph2,
    },
    listbox: {
        overflowY: 'auto',
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            width: '5px'
        },
        "&::-webkit-scrollbar-track": {
            background: Background,
            borderRadius: '5px'
        },
        "&::-webkit-scrollbar-thumb": {
            background: Primary,
            borderRadius: '5px'
        },
    },
    popupIndicator: {
        color: Primary,
    },
    clearIndicator: {
        color: Failure,
    },
}))(Autocomplete)

export const CustomAutocompleteMultiple = withStyles(theme => ({
    root: {
        '& .MuiFormLabel-root': {
            color: DisabledText,
        },
    },
    paper: {
        backgroundColor: Foreground,
    },
    inputRoot: {
        color: Text,
        ...Paragraph2,
        '&::after': {
            borderBottom: `2px solid ${Primary}`,
        }
    },
    option: {
        color: Text,
        backgroundColor: Foreground,
        ...Paragraph2,
        '&[aria-selected="true"]': {
            backgroundColor: Foreground,
            '&:hover': {
                backgroundColor: Background,
            },
        },
        '&:hover': {
            backgroundColor: Background,
        },
    },
    noOptions: {
        color: Text,
        ...Paragraph2,
    },
    listbox: {
        overflowX: 'hidden',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: '5px'
        },
        "&::-webkit-scrollbar-track": {
            background: Background,
            borderRadius: '5px'
        },
        "&::-webkit-scrollbar-thumb": {
            background: Primary,
            borderRadius: '5px'
        },
    },
    popupIndicator: {
        color: Primary,
    },
    clearIndicator: {
        color: Failure,
    },
}))(Autocomplete)

export const CustomTextField = withStyles(theme => ({
    root: {
        color: Text,
        '& label': {
            color: DisabledText,
        },
        '& label.Mui-focused': {
            color: DisabledText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Primary,
        },
        '& .MuiInputBase-root': {
            color: Text,
        },
        '& .MuiInputAdornment-root .MuiTypography-root': {
            color: Primary,
        }
    },
}))(TextField)

export const CustomTextFieldPhone = forwardRef((props, ref) => 
    <CustomTextField
        {...props}
        inputRef={ref}
    />
)

export const CustomMenuItem = withStyles(theme => ({
    selected: {},
    root: {
        '&:hover': {
            color: '#FAFAFA',
            backgroundColor: PrimaryTint1,
        },
        '&:active': {
            color: '#FAFAFA',
            backgroundColor: PrimaryShade,
        },
        '&$selected': {
            backgroundColor: Primary,
            color: '#FAFAFA',
            '&:hover': {
                color: '#FAFAFA',
                backgroundColor: PrimaryTint1,
            },
            '&:active': {
                color: '#FAFAFA',
                backgroundColor: PrimaryShade,
            },
        }
    }
}))(MenuItem)

export const CustomMenuItemSelect = withStyles(theme => ({
    selected: {},
    root: {
        '&:hover': {
            backgroundColor: PrimaryTint1,
            color: '#FAFAFA',
        },
        '&:active': {
            color: '#FAFAFA',
            backgroundColor: PrimaryShade,
        },
        '&$selected': {
            backgroundColor: Primary,
            color: '#FAFAFA',
            '&:hover': {
                color: '#FAFAFA',
                backgroundColor: PrimaryTint1,
            },
            '&:active': {
                color: '#FAFAFA',
                backgroundColor: PrimaryShade,
            },
        }
    }
}))(MenuItem)

export const CustomFormControlLabel = withStyles(theme => ({
    root: {
        margin: '5px 0'
    },
    label: {
        ...Paragraph3,
    },
}))(FormControlLabel)

export const CustomFormControlLabelPara2 = withStyles(theme => ({
    root: {
        margin: '5px 0'
    },
    label: {
        ...Paragraph2,
    },
}))(FormControlLabel)

export const CustomFormLabel = withStyles(theme => ({
    root: {
        color: Text,
    },
}))(FormLabel)

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 34,
        height: `calc(${Paragraph3.fontSize} + 0.25rem)`,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: Foreground,
        '&$checked': {
            transform: 'translateX(16px)',
            color: Foreground,
            '& + $track': {
                opacity: 1,
                backgroundColor: Primary,
                borderColor: Primary,
            },
        },
    },
    thumb: {
        width: Paragraph3.fontSize,
        height: Paragraph3.fontSize,
        boxShadow: 'none',
        backgroundColor: '#FAFAFA',
    },
    track: {
        border: `1px solid ${DisabledText}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: DisabledText,
    },
    checked: {},
}))(Switch)

export const NotificationFormControlLabel = withStyles(theme => ({
    root: {
        margin: '5px 0'
    },
    label: {
        ...Paragraph2,
    },
}))(FormControlLabel)

export const NotificationSwitch = withStyles((theme) => ({
    root: {
        width: `calc(2.3*${Paragraph2.fontSize})`,
        height: `calc(${Paragraph2.fontSize} + 0.25rem)`,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: Foreground,
        '&$checked': {
            transform: 'translateX(16px)',
            color: Foreground,
            '& + $track': {
                opacity: 1,
                backgroundColor: Primary,
                borderColor: Primary,
            },
        },
    },
    thumb: {
        width: Paragraph2.fontSize,
        height: Paragraph2.fontSize,
        boxShadow: 'none',
        backgroundColor: '#FAFAFA',
    },
    track: {
        border: `1px solid ${DisabledText}`,
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: DisabledText,
    },
    checked: {},
}))(Switch)

export const CustomCheckbox = withStyles((theme) => ({
    root: {
        color: Primary,
        padding: '0',
    },
    colorPrimary: {
        '&.Mui-checked': {
            color: Primary,
        }
    },
}))(Checkbox)