import React, { Fragment, useMemo } from 'react'
import { Divider, Grid } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { useSelector, useDispatch } from 'react-redux'
import KeplerGl from 'kepler.gl'
import { addDataToMap } from 'kepler.gl/actions'
import { processRowObject } from 'kepler.gl/processors'
import { useNavigate } from 'react-router-dom'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../styles'
import { useHoverActiveStyles } from '../../../static/hoverActiveStyles'

import * as actions from '../../../redux/actions'
import { customToastSystem } from '../../../common/customToastify'
import { CustomGrid, CustomHoverTooltip, TypographyMedium, TypographySemiBold } from '../../../customMui/customMuiStyles'
import { AutoCompleteSearch, SingleSelectFilled } from '../../../customMui/customMuiComponents'

import useExploreReportService from '../Explore/Service/fetchReportsService'
import useSearchService from './Service/useSearchService'
import { capitalizeFirstLetter, getErrorMessageFromResponse, getfromLocalStorage, getNumberLocaleString, numFormatter } from '../../../common/functions'
import { searchPoiLayerConfig } from '../Explore/Config/keplerLayerConfig'

import Loading from '../../../common/loading'
import { getCountryCode, getCountryCodeFromCountryName, getPoisFromKey, reportListReverse } from '../Explore/Config/exploreConfig'
import ChatComponent from '../../Sidepanel/chatComponent'

const SearchRightSidepanel = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const stateSearch = useSelector(state => state.searchReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)
    const { createExplorationReport } = useExploreReportService()
    const { getSearchResult, getSearchResultImage, getSearchResultReport } = useSearchService()

    const handleUpdateDatabaseId = (e) => dispatch(actions.handleUpdateSearchDatabase(e.target.value))

    const handleFetchSearchResult = async (_, val) => {
        const invalidCharacters = /^[^<>]*$/
        if (val.length <= 5 || val.length >= 100 || !invalidCharacters.test(val)){
            customToastSystem('Enter a valid search query!', 'error')
            return
        }
        dispatch(actions.handleToggleSearchLoading(true))
        if (val) {
            dispatch(actions.handleUpdateSearchQuery(val))
            if (stateSearch.database === 'Creatives') {
                await getSearchResultImage(val)
                    .then(res => {
                        dispatch(actions.handleUpdateSearchData(res))
                        dispatch(actions.handleToggleSearchLoading(false))
                    })
                    .catch(err => {
                        dispatch(actions.handleToggleSearchError())
                        customToastSystem(getErrorMessageFromResponse(err, 'Failed to get Search Results'), 'error')
                    })
            } else {
                let tempAccount = stateSettingsData.social?.account_name ?? 'none'
                let countryCode = getCountryCodeFromCountryName[stateSettingsData?.company?.country] ?? "none"
                // tempAccount = 'none'
                // if (['none', 'Test Demo IA'].includes(tempAccount)) {
                //     customToastSystem('Ad account not Set', 'error')
                // } else {
                //     // await getSearchResult(val, tempAccount, stateSearch.database)
                // }
                await getSearchResultReport(val, tempAccount, stateSearch.database, countryCode)
                    .then(async res => {
                        if (res.db_id === 'stat') {
                            dispatch(actions.handleUpdateSearchData(res))
                        } else {
                            if (getfromLocalStorage('group').toLowerCase() === 'baseuser') {
                                customToastSystem('Not enough permissions to generate report')
                            } else {
                                let tempReport = {
                                    type: getPoisFromKey(res.poiType.split(',')).join(','),
                                    district: res.district_code,
                                    name: res.NLQ,
                                    // country: getCountryCode[res?.district_code.split(',')?.[0].split('.')?.[0] ?? 'none'] ?? 'None',     // US districts don't have US in it so putting USA when we ain't getting any country code in the district code 
                                    country: getCountryCode[res?.district_code.split(',')?.[0].split('.')?.[0] ?? 'none'] ?? 'USA'
                                }
                                if (res.db_id === 'poi') {
                                    tempReport["reportType"] = reportListReverse[0] ?? 'none'
                                } else if (res.db_id === 'visitation') {
                                    let tempDateRange = res.date_range.split(',')
                                    tempReport['startDate'] = tempDateRange[0] ?? undefined
                                    tempReport['endDate'] = tempDateRange[1] ?? tempDateRange[0] ?? undefined
                                    tempReport["reportType"] = reportListReverse[9] ?? 'none'
                                }
                                // navigate('/explore')
                                // dispatch(actions.handleRightSidepanel(true))
                                // dispatch(actions.handleRightSidepanelActiveContent('Create Report'))
                                // dispatch(actions.handleCreateVisitReport(tempReport))
                                // console.log(tempReport)
                                await createExplorationReport(tempReport)
                                // dispatch(actions.handleUpdateSearchData(res))
                            }
                        }
                        dispatch(actions.handleToggleSearchLoading(false))
                    })
                    .catch(err => {
                        dispatch(actions.handleToggleSearchError())
                        customToastSystem(getErrorMessageFromResponse(err, 'Failed to get Search Results'), 'error')
                    })
            }
            dispatch(actions.handleUpdateSearchQuery(''))
        } else {
            customToastSystem('Enter a query', 'error')
        }
    }

    const getVisualization = () => {
        let tempType = stateSearch.data?.type ?? 'none'
        if (tempType === 'poi') {
            let tempMapData = stateSearch.data?.Output ?? []
            if (tempMapData.length > 0) {
                dispatch(addDataToMap(searchPoiLayerConfig(tempMapData)))
            } else {
                return null
            }

            return (
                <KeplerGl
                    id='search-exploration'
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    style={{height: '100%', display: 'flex', flex: 1}}
                />
            )
        } else if (tempType === 'stat') {
            return null
        } else if (tempType === 'visitation') {
            return null
        } else {
            return null
        }
    }

    const getExtraData = () => {
        let tempData = {...stateSearch.data}
        if (Object.keys(tempData).length > 0) {
            delete tempData.type
            delete tempData.Output
            return (
                <Fragment>
                    {
                        Object.keys(tempData).map(ele => (
                            <TypographySemiBold variant="body2" style={{display: 'block'}} className={`${classes.marginBottom}`}>{ele}: <span className={`${classesHoverActive.disabledIcon}`}>{tempData[ele] ?? 'none'}</span><br /></TypographySemiBold>
                        ))
                    }
                </Fragment>
            )
        }
        return null
    }

    const renderHeader = (params) => (
        <TypographySemiBold variant="body1">{capitalizeFirstLetter(params?.field?.split('_')?.join(' ') ?? params.field)}</TypographySemiBold>
    )
    const renderCell = (params) => {
        let tempValue = params.value
        if (Number(params.value)) {
            tempValue = numFormatter(params.value, 0)
            // return `${numFormatter(params.value)}\t${getNumberLocaleString(params.value.toFixed(2))}`
            return (
                <CustomHoverTooltip title={getNumberLocaleString(params.value)} placement="bottom" arrow interactive>
                    <TypographyMedium variant="body2">{tempValue}</TypographyMedium>
                </CustomHoverTooltip>
            )
        }
        return (
            <CustomHoverTooltip title={tempValue} placement="bottom" arrow interactive>
                <TypographyMedium variant="body2">{tempValue}</TypographyMedium>
            </CustomHoverTooltip>
        )
    }

    const SearchDataLayout = useMemo(() => {
        let tempData = {...stateSearch}
        if (tempData.error) {
            return (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{padding: '5vw'}}
                    className={`${classesHoverActive.wrapParent} ${classesHoverActive.foreground} ${classesHoverActive.bottomLow} ${classesHoverActive.borderRadius}`}
                >
                    <TypographySemiBold variant="h2" className={`${classesHoverActive.errorIcon}`}>Please try using another query</TypographySemiBold>
                </Grid>
            )
        } else if (tempData.loading) {
            return (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{padding: '5vw'}}
                    className={`${classesHoverActive.wrapParent} ${classesHoverActive.foreground} ${classesHoverActive.bottomLow} ${classesHoverActive.borderRadius}`}
                >
                    <Loading loadingText="Fetching Search data" />
                </Grid>
            )
        } else if (!tempData.query) {
            return (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{padding: '5vw'}}
                    className={`${classesHoverActive.wrapParent} ${classesHoverActive.foreground} ${classesHoverActive.bottomLow} ${classesHoverActive.borderRadius}`}
                >
                    <TypographySemiBold variant="h2" className={`${classesHoverActive.gradientIV}`}>Enter a search query to fetch data or generate a report</TypographySemiBold>
                </Grid>
            )
        } else if (Object.keys(tempData.data).length) {
            return (
                <Fragment>
                    {
                        stateSearch.data?.type === 'Creatives' &&
                        <CustomGrid container spacing={1}>
                            {
                                stateSearch.data?.Output.map((ele, idx) => (
                                    <Grid item xs={12} sm={6} key={idx}>
                                        <img src={ele ?? null} alt={stateSearch?.query ?? 'None'} className={`${classesHoverActive.wrapParent}`} />
                                    </Grid>
                                ))
                            }
                        </CustomGrid>
                    }
                    {
                        stateSearch.data?.type === 'Creatives' ||
                        <Fragment>
                            {getfromLocalStorage('group').toLowerCase() === 'admin' && getExtraData()}
                            {/* <TypographySemiBold variant="body2"><span className={`${classesHoverActive.disabledIcon}`}>Query: </span>{stateSearch.data?.['Predict SQL'] ?? 'none'}</TypographySemiBold> */}
                            {/* {getVisualization()} */}
                            <DataGrid
                                columns={Object.keys(stateSearch.data?.Output?.[0] ?? {}).map(ele => ({field: ele, headerName: capitalizeFirstLetter(ele.split('_').join(' ')), hide: ele === 'id', renderCell, renderHeader, width: 150, minWidth: 125, maxWidth: 150, resizable: true}))}
                                rows={stateSearch.data?.Output ?? []}
                                autoPageSize={true}
                                disableSelectionOnClick
                                componentsProps={{ panel: { disablePortal: true } }}
                            />
                        </Fragment>
                    }
                </Fragment>
            )
        }
    }, [stateSearch])
    return (
        <>
        {/*<Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelWideContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >  
            
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                >
                    <TypographySemiBold variant="body1">Search</TypographySemiBold>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Divider className={`${classes.sidepanelDivider}`} />
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid container direction="row" spacing={1}>
                    {
                        getfromLocalStorage('group').toLowerCase() === 'admin' &&
                        <Fragment>
                            <Grid item xs={4} md={2}>
                                <SingleSelectFilled
                                    name='datbaseId'
                                    color="primary"
                                    title="Type"
                                    options={['Search', 'Creatives']}
                                    value={stateSearch.database}
                                    onChange={handleUpdateDatabaseId}
                                />
                            </Grid>
                            <Grid item xs={8} md={10}>
                                <AutoCompleteSearch
                                    value={stateSearch.query}
                                    onChange={handleFetchSearchResult}
                                    placeholder={stateSearch.database === 'Creatives' ? "Describe a scenario to generate an image" : "Search for a report, or query eg: \"Fine Dining Places in Mumbai\" or \"Daily Spend in last month\""}
                                />
                            </Grid>
                        </Fragment>
                    }
                    {
                        getfromLocalStorage('group').toLowerCase() === 'admin' ||
                        <Grid item xs={12}>
                            <AutoCompleteSearch
                                value={stateSearch.query}
                                onChange={handleFetchSearchResult}
                                placeholder={"Search for a report, or query eg: \"Fine Dining Places in Mumbai\" or \"Daily Spend in last month\""}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={8} className={`${classes.flexContent} ${classesHoverActive.overflowY}`}>
                {SearchDataLayout}
            </Grid>
            
        </Grid>*/}
        <ChatComponent />
        </>
    )
}

export default SearchRightSidepanel
