export const chartTypes = ['Big Number', 'Bar', 'Bubble', 'Heatmap', 'Line', 'Parallel Coordinates', 'Radar', 'Treemap']

export const columnsForReportTypes = {
  "poi": ['country'],
  "mobility": [],
  "wellbeingPrices": [],
  "wellbeingPlaces": [],
  "wellbeingCensus": [],
  "CovidInfectionreports": [],
  "CovidVaccinationReports": [],
  "Congregation": [],
  "visitation": [],
  "districtMigrationHAs": [],
  "poiInternal": [],
  "poiVisitation": [],
  "wbiVisitation": [],
}

export const operators = {
  'In': "IN",
  'Equal to (=)': "EQ",
  'Not equal to (!=)': "NEQ",
  "Less than (<)": "LT",
  "Less or equal (<=)": "LTE",
  "Greater than (>)": "GT",
  "Greater or equal (>=)": "GTE",
  "Not in": "NOT_IN",
  "Between": "BETWEEN",
};

export const table_database = { 'pois': 0, 'mobility_normalised_aggregate': 1, 'wbi_us_wbi': 2, 'COVIDINFECTIONREPORTS': 3, 'COVIDVACCINATIONREPORTS': 4, 'CONGREGRATION': 5, 'ifa_visitation': 6, 'MIGRATION': 7, 'internal_pois': 8, 'poi_visitation': 9, 'wbi_india_wbi': 10, 'h9_population': 11, 'WBIAUDIENCE': 12, 'cdp_delta_behaviours': 13, 'cdp_delta_cluster': 14, 'cdp_delta_fullcontact_data': 15, 'cdp_delta_visits': 16, 'mobility_normalised_block': 17, 'custom_aqi_parsed': 18 }

export const chartData = [
  {
    total_visits: 442,
    visitation_count: 9,
    poiType: 'food.nightlife.billiards',
    district: 'Mumbai City'
  },
  {
    total_visits: 511,
    visitation_count: 9,
    poiType: 'food.nightlife.karaoke',
    district: 'Mumbai City'
  },
  {
    total_visits: 522,
    visitation_count: 18,
    poiType: 'food.restaurant.american',
    district: 'Mumbai City'
  },
  {
    total_visits: 781,
    visitation_count: 36,
    poiType: 'food.nightlife.brewery',
    district: 'Mumbai City'
  },
  {
    total_visits: 973,
    visitation_count: 18,
    poiType: 'food.nightlife.arcade',
    district: 'Mumbai City'
  },
  {
    total_visits: 1919,
    visitation_count: 45,
    poiType: 'food.restaurant.bistro',
    district: 'Mumbai City'
  },
  {
    total_visits: 2427,
    visitation_count: 45,
    poiType: 'food.nightlife.cocktail',
    district: 'Mumbai City'
  },
  {
    total_visits: 4759,
    visitation_count: 54,
    poiType: 'food.restaurant.deli',
    district: 'Mumbai City'
  },
  {
    total_visits: 5194,
    visitation_count: 81,
    poiType: 'food.restaurant.fine_dining',
    district: 'Mumbai City'
  },
  {
    total_visits: 5586,
    visitation_count: 99,
    poiType: 'food.restaurant.taqueria',
    district: 'Mumbai City'
  },
  {
    total_visits: 6194,
    visitation_count: 117,
    poiType: 'food.restaurant.bbq',
    district: 'Mumbai City'
  },
  {
    total_visits: 6443,
    visitation_count: 126,
    poiType: 'food.nightlife.nightclub',
    district: 'Mumbai City'
  },
  {
    total_visits: 6542,
    visitation_count: 161,
    poiType: 'food.nightlife.live_entertainment',
    district: 'Mumbai City'
  },
  {
    total_visits: 7930,
    visitation_count: 168,
    poiType: 'food.restaurant.food_stall',
    district: 'Mumbai City'
  },
  {
    total_visits: 23802,
    visitation_count: 405,
    poiType: 'food.restaurant.breakfast',
    district: 'Mumbai City'
  },
  {
    total_visits: 35506,
    visitation_count: 698,
    poiType: 'food.restaurant.seafood',
    district: 'Mumbai City'
  },
  {
    total_visits: 36186,
    visitation_count: 687,
    poiType: 'food.restaurant.takeout',
    district: 'Mumbai City'
  },
  {
    total_visits: 51182,
    visitation_count: 801,
    poiType: 'food.restaurant.food_court',
    district: 'Mumbai City'
  },
  {
    total_visits: 56367,
    visitation_count: 886,
    poiType: 'food.restaurant.continental',
    district: 'Mumbai City'
  },
  {
    total_visits: 57905,
    visitation_count: 1304,
    poiType: 'food.restaurant.catering',
    district: 'Mumbai City'
  },
  {
    total_visits: 62080,
    visitation_count: 1089,
    poiType: 'food.restaurant.pizza_pasta',
    district: 'Mumbai City'
  },
  {
    total_visits: 164727,
    visitation_count: 2545,
    poiType: 'food.restaurant.casual',
    district: 'Mumbai City'
  },
  {
    total_visits: 178354,
    visitation_count: 3240,
    poiType: 'food.restaurant.asian',
    district: 'Mumbai City'
  },
  {
    total_visits: 188883,
    visitation_count: 3206,
    poiType: 'food.restaurant.tea',
    district: 'Mumbai City'
  },
  {
    total_visits: 191943,
    visitation_count: 3149,
    poiType: 'food.nightlife.bar_pub',
    district: 'Mumbai City'
  },
  {
    total_visits: 194992,
    visitation_count: 3318,
    poiType: 'food.restaurant.cafe',
    district: 'Mumbai City'
  },
  {
    total_visits: 503488,
    visitation_count: 7996,
    poiType: 'food.restaurant.fast_food',
    district: 'Mumbai City'
  },
  {
    total_visits: 542947,
    visitation_count: 7699,
    poiType: 'food.restaurant.indian',
    district: 'Mumbai City'
  },
  {
    total_visits: 933493,
    visitation_count: 16566,
    poiType: 'food.restaurant',
    district: 'Mumbai City'
  }
]

