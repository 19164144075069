import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { useHoverActiveStyles } from '../static/hoverActiveStyles'

import { CustomLinearProgress } from '../customMui/customMuiStyles'

import { ReactComponent as Logo } from '../assets/Logo 64px.svg'

const Loading = ({loadingText = "Loading", info = ""}) => {
    const classesHoverActive = useHoverActiveStyles()
    const stateExploreProgress = useSelector(state => state.exploreDownloadProgressReducer)

    return (
        <Fragment>
            <Logo />
            <CustomLinearProgress className={`${classesHoverActive.margin}`} style={{width: '100%'}} />
            <Typography variant="body2" className={`${classesHoverActive.margin} ${classesHoverActive.gradientIV}`}>{loadingText}...</Typography>
            <Typography variant="h2" className={`${classesHoverActive.gradientII}`} style={{textAlign: 'center'}}>
                {/* Did you know?<br />{stateExploreProgress.info} */}
                {stateExploreProgress.info}
            </Typography>
        </Fragment>
    )
}

export default Loading