import axios from 'axios'
import { getfromLocalStorage, puttoLocalStorage } from '../common/functions'

let host = process.env.REACT_APP_MOBILITY_URL;
const mobilityAxios = axios.create({baseURL:host});

const getToken = () => {
    return new Promise(async(resolve, reject)=>{
        const temp_token = getfromLocalStorage("userJwtReportPoi")
        if (temp_token)
            resolve(temp_token)
        else {
            let myHeaders = new Headers()
            myHeaders.append("Authorization", "Basic dGVzdDE6UGE1NVdvcmQxQA==")
            myHeaders.append("Content-Type", "application/json")

            let raw = JSON.stringify({
                "username": "debdeep",
                "password": "Debdeep@123"
            })

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            }

            fetch(`${host}/auth/login`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let temp = JSON.parse(result) ?? {}
                    puttoLocalStorage('userJwtReportPoi', temp.token)
                    resolve(temp.token)
                })
                .catch(error => reject(error))

        }
    })
}
// Add a request interceptor
mobilityAxios.interceptors.request.use(
    async config => {
        // let token = process.env.REACT_APP_MOBILITY_TOKEN
        let token = ""
        await getToken()
            .then(res => {
                // console.log(`getToken called ${res}`)
                token = res
            })
            .catch(err=>{
                console.log(err)
            })
        if (token) {
            config.headers['Authorization'] = `Bearer ${token.replace(/^"(.+(?="$))"$/, '$1')}`;
        }
        if(config.headers['Content-Type'] !== 'multipart/form-data') 
            config.headers['Content-Type'] = 'application/json'
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

export default mobilityAxios;