const createObject = (key, valueArray) => valueArray.map(ele => ({[key]: ele}))
export const convertToObject = (a) => {
    let tempResult = {}
    Object.keys(a).forEach(combination => {
        tempResult[combination] = {...a[combination], 'tableData': {}}
        let tempData = a[combination].data
        let name = combination.split('_')[0]
        let nameValues = Object.keys(tempData)
        nameValues.forEach((currentName) => {
            let tempMetricData = tempData[currentName]
            Object.keys(tempMetricData).forEach((currentMetric, idx) => {
                if (idx === 0) {
                    let tempLength = tempMetricData[currentMetric].length
                    if (tempResult[combination]['tableData'][name]) {
                        tempResult[combination]['tableData'][name] = tempResult[combination]['tableData'][name].concat(createObject(name, Array(tempLength).fill(currentName)))
                    } else {
                        tempResult[combination]['tableData'][name] = createObject(name, Array(tempLength).fill(currentName))
                    }
                }
                if (tempResult[combination]['tableData'][currentMetric]) {
                    tempResult[combination]['tableData'][currentMetric] = tempResult[combination]['tableData'][currentMetric].concat(createObject(currentMetric, tempMetricData[currentMetric]))
                } else {
                    tempResult[combination]['tableData'][currentMetric] = createObject(currentMetric, tempMetricData[currentMetric])
                }
            })
        })
        let tempLength = tempResult[combination]['tableData'][name].length
        let tempObjectResult = []
        for (let i=0; i < tempLength; i++) {
            let tempObject = {
                id: i
            }
            Object.keys(tempResult[combination]['tableData']).forEach(ele => {
                tempObject = {...tempObject, ...tempResult[combination]['tableData'][ele][i]}
            })
            tempObjectResult.push(tempObject)
        }
        tempResult[combination]['tableData'] = tempObjectResult
    })
    return tempResult
}

export const optimizeChartsLayout = (data, name) => {
    let tempName = name.split('_')
    let tempKey = Object.keys(data.data)[0]
    let tempData = data.data[tempKey]
    let tempColumns = Object.keys(tempData).filter(ele => !tempName.includes(ele))
    switch (data.chart_type) {
        case 'bar':
            return {
                chartType: 'bar',
                x: tempName[0],
                y: tempName[1],
                // x: 'age',
                // y: 'spend,impressions,clicks',
                size: 12,
                breakdown: tempColumns[0],
                summary: [],
                name
            }
        case 'pie':
            return {
                chartType: 'pie',
                x: tempName[0],
                y: tempName[1],
                // x: 'age',
                // y: 'spend,impressions,clicks',
                size: 12,
                breakdown: tempColumns[0],
                summary: [],
                name
            }
        case 'time-series':
            return {
                chartType: 'pie',
                x: tempName[0],
                y: tempName[1],
                // x: 'age',
                // y: 'spend,impressions,clicks',
                size: 12,
                breakdown: tempColumns[0],
                summary: [],
                name
            }
        default:
            return null
    }
}

export const nameMapping = {
    'adset name': 'Adset Name',
    'impressions': 'Impressions',
    'spend': 'Spends',
    'clicks': 'Clicks',
    'reach': 'Reach',
    'region': 'Region',
    'CPC': 'CPC',
    'CPR': 'CPR',
    'CPM': 'CPM',
    'device': 'Device',
    'age_group': 'Age',
    'platform': 'Platform',
    'gender': 'Gender'
}