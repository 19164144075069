import React, { useState, useEffect } from "react";
import { chartTypes, columnsForReportTypes, operators, table_database } from "../Config/chartConfig";
import { useStyles as bentoStyles } from "./bentoStyles";
import { ReactComponent as Logo } from "../../../../assets/Logo 32px.svg";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles as contentStyles } from "../../styles";
import { useHoverActiveStyles } from "../../../../static/hoverActiveStyles";
import {
  CustomBody1SemiBoldButton,
  CustomDividerColor,
  CustomSvgIconLabelMedium,
  CustomSwitchLabelMedium,
  CustomTextField,
  LabelMedium,
  TypographySemiBold,
} from "../../../../customMui/customMuiStyles";
import {
  ColorlibConnector,
  CustomDateRange,
  CustomDoubleSlider,
  CustomStepper,
  CustomTextFieldBody1,
  CustomTreeSelect,
  MultipleAutocompleteFilled,
  SingleSelectFilled,
} from "../../../../customMui/customMuiComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  brandsForPOIs,
  poiData,
  ratingMarks,
  reportList,
  reportListReverse,
  visitDurationMarks,
  visitTimeMarks,
} from "../../Explore/Config/exploreConfig";
import {
  capitalizeFirstLetter,
  districtCodeToDistrictDropdowns,
  getErrorMessageFromResponse,
} from "../../../../common/functions";
import {
  countries,
  districts,
  states,
} from "../../../../config/combinedCountryStateDistrict";
import { DateRangePicker } from "rsuite";
import { useStyles } from "../../../../static/layoutStyles";
import ConfigureParameter from "./configureParameter";
import AddFilter from "./addFilter";
import * as actions from "../../../../redux/actions";
import { ReactComponent as ThemeModeIcon } from "../../../../assets/icons/Theme Mode.svg";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/Close.svg";
import { useNavigate } from "react-router-dom";
import useBentoboxService from "../Service/fetchBentoboxService";
import { customToastSystem } from "../../../../common/customToastify";
import useExploreService from "../../Explore/Service/useExploreService";

const CreateChart = () => {
  const classes = useStyles();
  const bentoClasses = bentoStyles();
  const classesContent = contentStyles();
  const classesHoverActive = useHoverActiveStyles();
  const stateExplore = useSelector((state) => state.exploreReducer);
  const stateLayout = useSelector((state) => state.layoutReducer);
  const stateSettingsData = useSelector(state => state.settingsDataReducer);
  const [formWindow, setFormWindow] = useState("chartType");
  const [brands, setBrands] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const [complexFilterAnchorEl, setComplexFilterAnchorEl] = useState(null);
  const [anchorCoordinates, setAnchorCoordinates] = useState({});
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { getColumns, createChart, getChart, createBento, getBento } = useBentoboxService()
  const [metrics, setMetrics] = useState([])
  const [filters, setFilters] = useState([])
  const [columns, setColumns] = useState([])
  const [availableColumns, setAvailableColumns] = useState([])
  const { getPoiSname } = useExploreService()
  const [buttonLoading, setButtonLoading] = useState(false)



  const handleClick = (event) => {
    setComplexFilterAnchorEl(event.currentTarget);
    setAnchorCoordinates({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setComplexFilterAnchorEl(null);
    setAnchorCoordinates({ x: 0, y: 0 });
  };

  const handleChangeThemeMode = () => {
    // handleCLickClose()
    dispatch(actions.handleChangeTheme());
  };

  const [reportData, setReportData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [bentoPayload, setBentoPayload] = useState({
    "cache_timeout": null,
    // type of chart
    "type": "none",
    // add all the columns aka dimensions
    "columns": [],
    // add the database name
    "database": {
      "allows_subquery": "true",
      "backend": "presto",
      "disable_data_preview": "false",
      "name": ""
    },
    "datasource_name": null,
    "edit_url": null,
    // add spark_job_id
    "spark_job_id": null,
    // add selected metrics
    "metrics": [],
    // give the chart a name
    "name": "",
    "offset": 0,
    "limit": 100,
    // add order_by for any filter
    "order_by_choices": [
    ],
    // add user's details
    "owners": [
      {
        "first_name": "First name",
        "id": 1,
        "last_name": "last name",
        "username": "user@ia.com"
      }
    ],
    // add filters
    "params": {},
    "schema_name": "",
    "table_name": "",
    "template_params": null,
    "time_grain_sqla": null,
    "verbose_map": null
  })

  useEffect(() => {
    if (Object.keys(stateExplore.selectedConfig).length > 1) {
      var defaultReportData = {
        ...stateExplore.selectedConfig,
        reportType: reportListReverse[stateExplore.selectedConfig?.reportType] ?? "none",
        pois: stateExplore.selectedConfig?.poitype?.split(",") || [],
        locations:
          districtCodeToDistrictDropdowns(
            stateExplore.selectedConfig?.district_codes,
            stateExplore.selectedConfig?.country
          ) ?? [],
        brands: stateExplore.selectedConfig?.brands?.split(",").map(ele => ({ value: ele })) ?? [],
        dateRange: stateExplore.selectedConfig?.start_date && stateExplore.selectedConfig?.end_date ? [
          new Date(stateExplore.selectedConfig?.start_date),
          new Date(stateExplore.selectedConfig?.end_date),
        ] : [],
        resolution: "none",
        ratings: [ratingMarks[0].value, ratingMarks[ratingMarks.length - 1].value],
        visitDuration: [
          visitDurationMarks[0].value,
          visitDurationMarks[visitDurationMarks.length - 1].value,
        ],
        visitTime: [
          visitTimeMarks[0].value,
          visitTimeMarks[visitTimeMarks.length - 1].value,
        ],
      };
    }
    console.log(defaultReportData);
    setReportData(defaultReportData)
    createListAvailableBrands(defaultReportData.pois)
  }, [])

  useEffect(async () => {
    //reportList[reportData.reportType]
    await getColumns(stateExplore.selectedConfig?.reportType)
      .then(res => {
        const allColumns = res.columns.map(ele => {
          return {
            "column_name": ele.name,
            "filterable": "true",
            "groupby": "true",
            "type": ele.dataType
          }
        })
        setBentoPayload({ ...bentoPayload, database: Object.assign(bentoPayload.database, { name: "pois" }), table_name: table_database[res.table_name], schema_name: "pois", datasource_name: table_database[res.database_name] })
        setAvailableColumns(allColumns)
      })
  }, [stateExplore.selectedConfig?.reportType])

  const handleChangePayload = (key) => (e) => {
    switch (key) {
      case "type":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
      case "columns":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
      case "metrics":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
      case "name":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
      case "order_by_choices":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
      case "params":
        setBentoPayload({ ...bentoPayload, [key]: e.target.value })
        break
    }
  }

  const checkButtonDisabled = () => {
    switch (activeStep) {
      case 0:
        return bentoPayload['type'] == 'none'
      case 1:
        let tempCheck = reportData?.name && reportData?.district_codes.split(',').length > 0
        switch (reportData?.reportType) {
          case 'poiVisitation':
          case 'visitation':
          case 'wbiVisitation':
            return !(tempCheck && reportData?.dateRange.length > 0 && reportData?.pois.length > 0)
          case 'poi':
          case 'poiInternal':
            return !(tempCheck && reportData?.pois.length > 0)
          case 'mobility':
            return !(tempCheck && reportData?.resolution !== 'none')
          case 'Congregation':
          case 'districtMigrationHAs':
            return !(tempCheck && reportData?.dateRange.length > 0)
          case 'wellbeingPrices':
          case 'wellbeingPlaces':
          case 'wellbeingCensus':
          case 'wbiVisitation':
          case 'CovidInfectionreports':
          case 'CovidVaccinationReports':
          default:
            return !tempCheck
        }
      default:
        return true
    }
  }

  const handleCreateModule = async () => {
    setButtonLoading(true)
    const data = {
      ...bentoPayload
    }
    data['name'] = reportData['name']
    data['spark_job_id'] = reportData.id
    data['metrics'] = metrics.map(ele => ({
      "expression": ele.column,
      "metric_name": ele.aggregate.toLowerCase(),
      "verbose_name": "Total_".concat(ele.aggregate.toLowerCase()),
      "aggregator": ele.aggregate
    }))
    data['columns'] = availableColumns.filter(item => columns.map(ele => ele.column).includes(item.column_name))
    data['order_by_choices'] = [[data['metrics'][0].verbose_name, 'asc']]

    data['params'] = {}

    switch (reportData.reportType) {
      case 'mobility':
        data['params']['district_code'] = [{ "operation": "IN", "value": reportData.district_codes.split(','), "type": "VARCHAR" }]
        data['params']['poiType'] = [{ "operation": "IN", "value": reportData.pois, "type": "VARCHAR" }]
        break
      case 'poi':
      case 'poiInternal':
        data['params']['district_code'] = [{ "operation": "IN", "value": reportData.district_codes.split(','), "type": "VARCHAR" }]
        data['params']['poiType'] = [{ "operation": "IN", "value": reportData.pois, "type": "VARCHAR" }]
        //  data['params']['brands'] = [{ "operation": "IN", "value": reportData['brands'].map(ele => ele.value), "type": "VARCHAR" }]
        //  data['params']['ratings'] = [{ "operation": "BETWEEN", "value": reportData['ratings'], "type": "FLOAT" }]
        break
      case 'poiVisitation':
      case 'visitation':
        data['params']['district_code'] = [{ "operation": "IN", "value": reportData.district_codes.split(','), "type": "VARCHAR" }]
        data['params']['poiType'] = [{ "operation": "IN", "value": reportData.pois, "type": "VARCHAR" }]
        data['params']['start_date'] = [{ "operation": "GT", "value": reportData['start_date'], "type": "DATE" }]
        data['params']['end_date'] = [{ "operation": "GT", "value": reportData['end_date'], "type": "DATE" }]
        //  data['params']['brands'] = [{ "operation": "IN", "value": reportData['brands'].map(ele => ele.value), "type": "VARCHAR" }]
        //  data['params']['ratings'] = [{ "operation": "BETWEEN", "value": reportData['ratings'], "type": "FLOAT" }]
        break
      case 'wellbeingPrices':
      case 'wellbeingPlaces':
      case 'wellbeingCensus':
        data['params']['district_code'] = [{ "operation": "IN", "value": reportData.district_codes.split(','), "type": "VARCHAR" }]
        break
      case 'wbiVisitation':
        data['params']['district_code'] = [{ "operation": "IN", "value": reportData.district_codes.split(','), "type": "VARCHAR" }]
        data['params']['poiType'] = [{ "operation": "IN", "value": reportData.pois, "type": "VARCHAR" }]
        //  data['params']['brands'] = [{ "operation": "IN", "value": reportData['brands'].map(ele => ele.value), "type": "VARCHAR" }]
        //  data['params']['ratings'] = [{ "operation": "BETWEEN", "value": reportData['ratings'], "type": "FLOAT" }]
        data['params']['wbi_score_lower'] = [{ "operation": "GTE", "value": reportData['wbi_score_lower'], "type": "FLOAT" }]
        data['params']['wbi_score_upper'] = [{ "operation": "LTE", "value": reportData['wbi_score_upper'], "type": "FLOAT" }]
        break
      case 'Congregation':
      case 'CovidInfectionreports':
      case 'CovidVaccinationReports':
      case 'districtMigrationHAs':
        break
      default:
        break
    }
    filters.forEach(ele => {
      data['params'][ele.column] = [{ operation: operators[ele.operator], value: operators[ele.operator] == 'IN' ? ele.filter_value.split(',').map(ele => ele.trim()) : ele.filter_value, type: 'VARCHAR' }]
    })
    data['owners'] = [{
      "first_name": stateSettingsData.user.firstName,
      "id": stateSettingsData.user.id,
      "last_name": stateSettingsData.user.lastName,
      "username": stateSettingsData.user.email
    }]

    console.log("charts playlaod data ", data);


    await createChart({ payload: data, chart_type: data.type, chart_metadata: {} })
      .then(async res => {
        await getChart(res.id)
          .then(async response => {
            
            let tempData = {}
            let responseData = response
            if (Object.keys(response[0]).includes('poiType')) {
              responseData.forEach(ele => ({
                ...ele,
                poiType: getPoiSname(ele.poiType)
              }))
            }
            tempData['data'] = responseData
            tempData['axis'] = {
              x: data.metrics.map(ele => ele.verbose_name),
              y: data.columns.map(ele => ele.column_name)
            }

            await createBento({
              "name": reportData.name + " Bento",
              "chart_ids": String(res.id),
              "bento_box_metadata": {},
              "type": 0,
              "report_id": reportData.id
            })
              .then(res => {
                console.log(res);
                dispatch(actions.handleAddChartData({ id: res.id, data: tempData }))
                //dispatch(actions.handleAddChartAxis({ x: data.metrics.map(ele => ele.verbose_name), y: data.columns.map(ele => ele.column_name) }))
                dispatch(actions.handleUpdateBentoSidepanel([Object.assign(res, {path: res.id, type: 'bento', color: 'primary'})]))
                navigate(`/dashboard/${res.id}`)
                customToastSystem('Chart Created Successfully!', 'success')
              })
              .catch(err => {
                customToastSystem(getErrorMessageFromResponse(err, 'Failed to create BentoBox'), 'error')
              })
          })
          .catch(err => {
            customToastSystem(getErrorMessageFromResponse(err, 'Failed to get Chart'), 'error')
          })
      })
      .catch(err => {
        customToastSystem(getErrorMessageFromResponse(err, 'Failed to create Chart'), 'error')
      })
    setButtonLoading(false)
  }

  // next - back button navigation event handler

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // old next - back  navigation functions

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (formWindow) {
      case "chartType":
        setFormWindow("columns");
        break;
      case "columns":
        setFormWindow("filters");
        break;
      case "filters":
        setFormWindow("create");
        break;
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    switch (formWindow) {
      case "chartType":
        navigate(-1);
        break;
      case "columns":
        setFormWindow("chartType");
        break;
      case "filters":
        setFormWindow("columns");
        break;
    }
  };


  // form data change handlers

  const handleChangeReportData = (name) => (e) => setReportData({ ...reportData, [name]: e.target.value });
  const handleChangeMultipleReportData = (name) => (_, val) => setReportData({ ...reportData, [name]: val ? val : [] });
  const handleChangeTreeSelect = (name) => (val) => {
    setReportData({ ...reportData, [name]: val ? val : [] });
    if (name == "pois") {
      createListAvailableBrands(val)
    }
  };


  // other functions

  const createListAvailableBrands = (listOfPOIs) => {
    const allTheBrands = new Set();

    for (const poi of listOfPOIs) {
      for (const property in brandsForPOIs) {
        if (property.startsWith(poi)) {
          brandsForPOIs[property].forEach((brand) => allTheBrands.add(brand));
        }
      }
    }

    const arrayOfBrandObj = new Array();
    for (const brand of allTheBrands) {
      let obj = {
        value: brand,
      };
      arrayOfBrandObj.push(obj);
    }

    setBrands(arrayOfBrandObj);
  }

  const createChartForm = () => {
    switch (formWindow) {
      case "chartType":
        return (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              direction="column"
              className={`${classesContent.sidepanelPadding} ${classesHoverActive.wrapParentViewPort}`}
            >
              <Grid item spacing={1} direction="column">
                {/* <TypographySemiBold variant="h2">
                  New Bento Module
                </TypographySemiBold> */}
                <p
                  className={`${bentoClasses.texth1} ${bentoClasses.textColorPrimary}`}
                >
                  New Bento Module
                </p>

                <TypographySemiBold variant="body2">
                  Select a visualization type.
                </TypographySemiBold>
              </Grid>
              <Box style={{ flex: "1 1 0" }}>
                <Grid container spacing={1}>
                  {chartTypes.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={index}
                      className={`${bentoClasses.chartOptionsContainer}`}
                    >
                      <label>
                        <input
                          type="radio"
                          name="chartType"
                          className={`${bentoClasses.chartTypeRadioBtn}`}
                          value={item}
                          required
                          checked={item === selectedChart}
                          onClick={() => setSelectedChart(item)}
                        />
                        <div className={`${bentoClasses.chartOption}`}>
                          <div>
                            <Logo />
                          </div>
                          <div>{item}</div>
                        </div>
                      </label>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <CustomBody1SemiBoldButton
                  style={{ height: "6vh", padding: "14px 41px" }}
                  variant="text"
                  onClick={handleCancel}
                >
                  Cancel
                </CustomBody1SemiBoldButton>
                <CustomBody1SemiBoldButton
                  style={{ height: "6vh", padding: "14px 41px" }}
                  variant="outlined"
                  type="submit"
                >
                  Next Step
                </CustomBody1SemiBoldButton>
              </Grid>
            </Grid>
          </form>
        );
      case "columns":
        return (
          <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelPadding} ${classesHoverActive.wrapParentViewPort}`}
          >
            <Grid item spacing={1} direction="column">
              <TypographySemiBold variant="h2">
                New Bento Module
              </TypographySemiBold>
              <TypographySemiBold variant="body2">
                Configure the columns that will be used towards the new Bar
                Bento Module.
              </TypographySemiBold>
            </Grid>
            <Box style={{ flex: "1 1 0" }}>
              <Grid item xs={12}>
                <CustomTextFieldBody1
                  name="name"
                  color="primary"
                  title="Bento Name"
                  placeholder="Bento Name"
                  value={reportData?.["name"]}
                  onChange={handleChangeReportData("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleAutocompleteFilled
                  color="primary"
                  name="locations"
                  title="Location(s)"
                  value={reportData?.["locations"]}
                  getOptionLabel={(option) =>
                    capitalizeFirstLetter(option.name)
                  }
                  options={[...countries, ...states, ...districts]}
                  textFieldProps={{ placeholder: "Select Location(s)" }}
                  onChange={handleChangeMultipleReportData("locations")}
                  limitTags={1}
                />
              </Grid>
              {![
                "poi",
                "poiInternal",
                "wellbeingPrices",
                "wellbeingPlaces",
                "wellbeingCensus",
              ].includes(reportData?.reportType) && (
                  <Grid item xs={12}>
                    <CustomDateRange
                      color="primary"
                      name="dateRange"
                      character=" to "
                      title="Date Range"
                      onChange={handleChangeTreeSelect("dateRange")}
                      value={reportData?.["dateRange"]}
                      disabledDate={DateRangePicker.afterToday()}
                    />
                  </Grid>
                )}
              {["visitation", "poiVisitation", "poi", "poiInternal"].includes(
                reportData?.reportType
              ) && (
                  <>
                    <Grid item xs={12}>
                      <CustomTreeSelect
                        name="pois"
                        color="primary"
                        treeData={poiData}
                        placeholder="Select POIs"
                        title="Point of Interest(s)"
                        value={reportData?.["pois"]}
                        onChange={handleChangeTreeSelect("pois")}
                        notFoundContent={
                          <LabelMedium variant="body1">No POIs found</LabelMedium>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MultipleAutocompleteFilled
                        color="primary"
                        name="brands"
                        title="Brand(s)"
                        value={reportData?.["brands"]}
                        getOptionLabel={(option) => option.value}
                        options={brands}
                        textFieldProps={{ placeholder: "Select Brand(s)" }}
                        onChange={handleChangeMultipleReportData("brands")}
                        limitTags={1}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomDoubleSlider
                        title="Rating"
                        color="primary"
                        value={reportData?.["ratings"]}
                        onChange={handleChangeMultipleReportData("ratings")}
                        valueLabelDisplay="auto"
                        marks={ratingMarks}
                        min={ratingMarks[0].value}
                        max={ratingMarks[ratingMarks.length - 1].value}
                        step={0.1}
                      />
                    </Grid>
                  </>
                )}
              {![
                "poi",
                "poiInternal",
                "mobility",
                "wellbeingPrices",
                "wellbeingPlaces",
                "wellbeingCensus",
              ].includes(reportData?.reportType) && (
                  <>
                    <Grid item xs={12}>
                      <CustomDoubleSlider
                        title="Visit Time"
                        color="primary"
                        value={reportData?.["visitTime"]}
                        onChange={handleChangeMultipleReportData("visitTime")}
                        valueLabelDisplay="auto"
                        marks={visitTimeMarks}
                        min={visitTimeMarks[0].value}
                        max={visitTimeMarks[visitTimeMarks.length - 1].value}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomDoubleSlider
                        title="Visit Duration"
                        color="primary"
                        value={reportData?.["visitDuration"]}
                        onChange={handleChangeMultipleReportData("visitDuration")}
                        valueLabelDisplay="auto"
                        marks={visitDurationMarks}
                        min={visitDurationMarks[0].value}
                        max={
                          visitDurationMarks[visitDurationMarks.length - 1].value
                        }
                      />
                    </Grid>
                  </>
                )}
              {["mobility"].includes(reportData?.reportType) && (
                <Grid item xs={12}>
                  <SingleSelectFilled
                    color="primary"
                    name="resolution"
                    title="Resolution"
                    value={reportData?.["resolution"]}
                    onChange={handleChangeReportData("resolution")}
                    options={[
                      "Block (175m Radius)",
                      "County/District (Official Boundary Polygon)",
                    ]}
                  />
                </Grid>
              )}
            </Box>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <CustomBody1SemiBoldButton
                style={{ height: "6vh" }}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </CustomBody1SemiBoldButton>
              <CustomBody1SemiBoldButton
                style={{ height: "6vh" }}
                variant="outlined"
                onClick={handleSubmit}
              >
                Next Step
              </CustomBody1SemiBoldButton>
            </Grid>
          </Grid>
        );
      case "filters":
        return (
          <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelPadding} ${classesHoverActive.wrapParentViewPort}`}
          >
            <Grid item spacing={1} direction="column">
              <TypographySemiBold variant="h2">
                New Bento Module
              </TypographySemiBold>
              <TypographySemiBold variant="body2">
                Configure the metric that will be used towards the new Bar Bento
                Module.
              </TypographySemiBold>
            </Grid>
            <Box style={{ flex: "1 1 0" }}>
              <Grid item xs={12}>
                <CustomTextFieldBody1
                  name="name"
                  color="primary"
                  title="Bento Name"
                  placeholder="Bento Name"
                  value={reportData?.["name"]}
                  onChange={handleChangeReportData("name")}
                />
              </Grid>
            </Box>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <CustomBody1SemiBoldButton
                style={{ height: "6vh" }}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </CustomBody1SemiBoldButton>
              <CustomBody1SemiBoldButton
                style={{ height: "6vh" }}
                variant="outlined"
                onClick={handleSubmit}
              >
                Create Chart
              </CustomBody1SemiBoldButton>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };


  // step form data

  const steps = [
    {
      label: "Select Visualization",
      description: (
        <form onSubmit={handleNext}>
          <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelPadding} ${classesHoverActive.wrapParentViewPort}`}
          >
            <Grid item spacing={1} direction="column">
              <TypographySemiBold variant="h2">
                New Bento Module
              </TypographySemiBold>
              <TypographySemiBold variant="body2">
                Select a visualization type.
              </TypographySemiBold>
            </Grid>
            <div style={{ marginTop: "16px", height: "auto" }}>
              <Grid container spacing={1}>
                {chartTypes.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={index}
                    className={`${bentoClasses.chartOptionsContainer}`}
                  >
                    <label>
                      <input
                        type="radio"
                        name="chartType"
                        className={`${bentoClasses.chartTypeRadioBtn}`}
                        value={item}
                        required
                        checked={item === bentoPayload['type']}
                        onClick={handleChangePayload('type')}
                      />
                      <div className={`${bentoClasses.chartOption}`}>
                        <div>
                          <Logo />
                        </div>
                        <div>{item}</div>
                      </div>
                    </label>
                  </Grid>
                ))}
              </Grid>
            </div>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                marginTop: "auto",
                marginBottom: "60px",
              }}
            >
              <button className={`${bentoClasses.blackButton} ${bentoClasses.bigButton}`} onClick={handleCancel}>Cancel</button>
              <button
                className={`${bentoClasses.primaryButton} ${bentoClasses.bigButton}`} disabled={checkButtonDisabled()}>
                Next Step
              </button>
            </Grid>
          </Grid>
        </form>
      ),
    },
    {
      label: "Configure Paramters",
      description:
        (
          <ConfigureParameter
            handleChangeMultipleReportData={handleChangeMultipleReportData}
            ratingMarks={ratingMarks}
            brands={brands}
            handleChangeTreeSelect={handleChangeTreeSelect}
            poiData={poiData}
            reportData={reportData}
            handleChangeReportData={handleChangeReportData}
            countries={countries}
            states={states}
            districts={districts}
            visitTimeMarks={visitTimeMarks}
            visitDurationMarks={visitDurationMarks}
            handleNext={handleNext}
            handleBack={handleBack}
            checkButtonDisabled={checkButtonDisabled}
            handleChangePayload={handleChangePayload}
          />
        ),
    },
    {
      label: "Add Filters",
      description:
        (
          <AddFilter
            reportData={reportData}
            bentoPayload={bentoPayload}
            handleChangePayload={handleChangePayload}
            handleBack={handleBack}
            handleCreateModule={handleCreateModule}
            disabled={checkButtonDisabled}
            filters={filters}
            metrics={metrics}
            columns={columns}
            setFilters={setFilters}
            setMetrics={setMetrics}
            setColumns={setColumns}
            availableColumns={availableColumns}
            buttonLoading={buttonLoading}
          />
        ),
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        overflowX: "hidden",
        backdropFilter: "blur(10px)",
      }}
    >
      <div className={`${bentoClasses.bentoMainContainer}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "470px" }}>
            <Stepper
              sx={{ padding: "0px" }}
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((step, index) => (
                <Step key={step.label} disabled={false}>
                  <StepLabel
                    icon={
                      <p
                        className={`
                          ${bentoClasses.textColorPrimary} 
                          ${activeStep === index ? bentoClasses.activeStepperLabelText : bentoClasses.stepperLabelText}
                          `}
                      >
                        {step.label}
                      </p>
                    }
                  >
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <div style={{ padding: "24px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={`${classes.profileMenuItem}`}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <LabelMedium variant="body1">
                    <CustomSvgIconLabelMedium
                      component={ThemeModeIcon}
                      className={`${classes.svgMarginRight}`}
                    />
                    Theme: {capitalizeFirstLetter(stateLayout.theme)}
                  </LabelMedium>
                </div>
                <div>
                  <CustomSwitchLabelMedium
                    disableRipple
                    checked={stateLayout.theme !== "light"}
                    onChange={handleChangeThemeMode}
                    name="theme"
                  />
                </div>
              </div>
            </Grid>
          </div>
        </div>

        <div>
          {steps[activeStep]?.description}
        </div>


        <Menu
          id="complex-filters-context-menu"
          anchorEl={complexFilterAnchorEl}
          open={Boolean(complexFilterAnchorEl)}
          onClose={handleClose}
          classes={{
            paper: classes.menuPaper,
          }}
          keepMounted
          anchorReference="anchorPosition"
          anchorPosition={
            anchorCoordinates.y !== 0 && anchorCoordinates.x !== 0
              ? { top: anchorCoordinates.y, left: anchorCoordinates.x }
              : undefined
          }
        >
          <Grid item xs={12}>
            <SingleSelectFilled
              color="primary"
              name="Column"
              title="Column"
              value={reportData?.["column"]}
              onChange={handleChangeReportData("column")}
              options={["Count of all Columns"]}
              placeholder="Select the column that you would like to use"
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFilled
              color="primary"
              name="Aggregate"
              title="Aggregate"
              value={reportData?.["aggregate"]}
              onChange={handleChangeReportData("filter")}
              options={["AVG", "COUNT", "COUNT_DISTINCT", "MAX", "MIN", "SUM"]}
              placeholder="Select the aggregate that you would like to use"
            />
          </Grid>
        </Menu>

      </div>
    </div>
  );
};

export default CreateChart;
