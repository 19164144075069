import axios from 'axios'
import { getfromLocalStorage, getFromSessionStorage } from '../common/functions'

let host = process.env.REACT_APP_AUTH_URL
const authAxios = axios.create({baseURL:host})

const getToken = () => {
    return new Promise(async(resolve, reject)=>{

        const temp_token = getfromLocalStorage("userJwt")
        if (temp_token)
            resolve(temp_token)
        else {
            const email = getfromLocalStorage("email")
            if (email === ""){
                reject({"message": "No email found"})
            } 

            var bodyFormData = new FormData()
            bodyFormData.append("username", email)
            bodyFormData.append("password", "VerySecret123!")
            axios.post(host+"/login/access-token/"+getFromSessionStorage('rid'),  bodyFormData)
                .then(res => {
                    //puttoLocalStorage("userJwt", res.data.access_token)
                    resolve(res.data.access_token)
                })
                .catch(error => {
                    reject(error)
                })
        }
    })
}

// Add a request interceptor
authAxios.interceptors.request.use(
    async config => {
        let token =""
        await getToken()
        .then(res => {
            // console.log(`getToken called ${res}`)
           token  = res
        })
        .catch(err=>{
            console.log(err)
        })
        // console.log(token)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        if(config.headers['Content-Type'] !== 'multipart/form-data') 
            config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    })

export default authAxios