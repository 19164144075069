import { ReactComponent as GoogleLogo } from '../../../../assets/SSO Logo/Google.svg'
import { ReactComponent as FacebookLogo } from '../../../../assets/SSO Logo/Facebook.svg'
import { ReactComponent as LinkedinLogo } from '../../../../assets/SSO Logo/LinkedIn.svg'

export const monitorChartLayoutConfig = {
    'campaign': {
        'summarized': [
            {title: 'Total Spends', value: "spend", difference: '9.8', type: "currency"},
            {title: 'Total Impressions', value: "impressions", difference: '-9.8'},
            {title: 'Total Clicks', value: "clicks", difference: '-9.8' },
            {title: 'Purchases', value: "purchases", difference: '9.8'},
        ],
        'charts': [
            {chartType: 'time-series', x: 'start_date', y: 'spend', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'time-series', x: 'start_date', y: 'REACH,impressions,clicks,purchases', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'bar', x: 'age_group', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            // {chartType: 'pie', x: '', y: 'REACH,impressions,clicks,leads', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'gender', y: 'impressions', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'age_group', y: 'clicks', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'parallel-coordinate', x: 'age_group,leads', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},  //x: breakdown, end, y: in-between
        ]
    },
    'LEAD_GENERATION': {
        'summarized': [
            {title: 'Total Spends', value: "spend", difference: '9.8', type: "currency"},
            {title: 'Total Impressions', value: "impressions", difference: '-9.8'},
            {title: 'Total Clicks', value: "clicks", difference: '-9.8' },
            {title: 'Purchases', value: "purchases", difference: '9.8'},
        ],
        'charts': [
            {chartType: 'time-series', x: 'start_date', y: 'spend', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'time-series', x: 'start_date', y: 'REACH,impressions,clicks,purchases', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'bar', x: 'age_group', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'gender', y: 'impressions', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'age_group', y: 'clicks', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'parallel-coordinate', x: 'age_group,leads', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},  //x: breakdown, end, y: in-between
        ]
    },
    'CONVERSIONS': {
        'summarized': [
            {title: 'Total Spends', value: "spend", difference: '9.8', type: "currency"},
            {title: 'Total Impressions', value: "impressions", difference: '-9.8'},
            {title: 'Total Clicks', value: "clicks", difference: '-9.8' },
            {title: 'Purchases', value: "purchases", difference: '9.8'},
        ],
        'charts': [
            {chartType: 'time-series', x: 'start_date', y: 'spend', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'time-series', x: 'start_date', y: 'REACH,impressions,clicks,purchases', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'bar', x: 'age_group', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'gender', y: 'impressions', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'age_group', y: 'clicks', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'parallel-coordinate', x: 'age_group,purchases', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},  //x: breakdown, end, y: in-between
        ]
    },
    'LINK_CLICKS': {
        'summarized': [
            {title: 'Total Spends', value: "spend", difference: '9.8', type: "currency"},
            {title: 'Total Impressions', value: "impressions", difference: '-9.8'},
            {title: 'Total Clicks', value: "clicks", difference: '-9.8' },
            {title: 'Purchases', value: "total_purchases", difference: '9.8'},
        ],
        'charts': [
            {chartType: 'time-series', x: 'start_date', y: 'spend', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'time-series', x: 'start_date', y: 'REACH,impressions,clicks,total_purchases', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'bar', x: 'age_group', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'gender', y: 'impressions', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'age_group', y: 'clicks', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'parallel-coordinate', x: 'age_group,total_purchases', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},  //x: breakdown, end, y: in-between
        ]
    },
    'BRAND_AWARENESS': {
        'summarized': [
            {title: 'Total Spends', value: "spend", difference: '9.8', type: "currency"},
            {title: 'Total Impressions', value: "impressions", difference: '-9.8'},
            {title: 'Total Clicks', value: "clicks", difference: '-9.8' },
            {title: 'Purchases', value: "total_purchases", difference: '9.8'},
        ],
        'charts': [
            {chartType: 'time-series', x: 'start_date', y: 'spend', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'time-series', x: 'start_date', y: 'REACH,impressions,clicks,total_purchases', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'bar', x: 'age_group', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            // {chartType: 'pie', x: '', y: 'REACH,impressions,clicks,leads', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'gender', y: 'impressions', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'pie', x: 'age_group', y: 'clicks', size: 6, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},
            {chartType: 'parallel-coordinate', x: 'age_group,leads', y: 'spend,impressions,clicks', size: 12, summary: ['Category A has the strongest Value.', 'Category J has the weakest Value.', 'Average Aggregate Value is 77.5 Increments.', 'Value ranges from 55 (Low) to 100 (High).']},  //x: breakdown, end, y: in-between
        ]
    },
}

export const monitorDataKeys = ['spend', 'revenue', 'CPL', 'CPC', 'CPI', 'CPV', 'impressions', 'purchases', 'clicks', 'REACH', 'installs', 'leads', "conversions", "CPconv", "CTR", "PER", "actions_video_view", "actions_post_engagement as post enagagement", "totalviews", "frequency", "messaging_conversation_started"]
// export const monitorDataKeys = ['spend', 'revenue', 'CPL', 'CPC', 'CPI', 'CPV', 'impressions', 'purchases', 'clicks', 'REACH', 'views', 'installs', 'leads', "conversions", "CPconv", "CTR", "PER", "actions_video_view", "engagements", "app_installs", "video_views"]
// export const monitorDataKeys = ['spend', 'revenue', 'CPL', 'CPC', 'CPI', 'CPV', 'impressions', 'purchases', 'clicks', 'REACH', 'views', 'installs', 'leads']
export const monitorDataKeysNameMapValue = {
    'Spends': 'spend',
    'Revenue': 'revenue',
    'CPL': 'CPL',
    'CPC': 'CPC', 
    'CPI': 'CPI',
    'CPV': 'CPV',
    'Impressions': 'impressions',
    'Clicks': 'clicks',
    'Reach': 'REACH',
    'Views': 'video_starts',
    'Installs': 'installs',
    'Leads': 'leads',
    'Purchase Revenue': 'purchase_revenue',
    'Purchases': 'total_purchases',
    'Actions App Install': 'actions_app_install',
    'App Installs': 'app_installs',
    'CTR': 'CTR',
    'Post Engagement Rate': 'PER',
    'Action Video Views': 'actions_video_view',
    'Post Engagement': 'actions_post_engagement as post enagagement',
    'Conversions': 'conversions',
    'Video Views': 'video_views',
    'Engagements': 'engagements',
    'Frequency': 'frequency',
    'Messaging Conversation Started': 'messaging_conversation_started',
    'Cost per App Installs': 'cost_per_app_installs',
}

export const monitorDataKeysNameMapKeys = {
    'spend': 'Spends',
    'clicks': 'Clicks',
    'impressions': 'Impressions',
    'REACH': 'Reach',
    'leads': 'Leads',
    'conversions': 'Conversions',
    'purchases': 'Purchases',
    'total_purchases': 'Purchases',
    'video_starts': 'Views',
    'installs': 'Installs',
    'revenue': 'Revenue',
    'purchase_revenue': 'Purchase Revenue',
    'actions_app_install': 'Actions App Install',
    'actions_video_view': 'Action Video Views',
    'actions_post_engagement as post enagagement': 'Post Engagement',
    'PER': 'Post Engagement Rate',
    'CPL': 'CPL',
    'CPC': 'CPC', 
    'CPI': 'CPI',
    'CPV': 'CPV',
    'CTR': 'CTR',
    'video_views': 'Video Views',
    'engagements': 'Engagements',
    'app_installs': 'App Installs',
    'frequency': 'Frequency',
    'messaging_conversation_started' : 'Messaging Conversation Started',
    'cost_per_app_installs': 'Cost per App Installs',
}

export const monitorDataKeysNameMapTitle = {
    spend: 'Total Spends',
    revenue: 'Total Revenue',
    impressions: 'Total Impressions',
    purchases: 'Purchases',
    clicks: 'Total Clicks',
    video_starts: 'Total Views',
    video_views: 'Total Video Views',
    engagements: 'Total Engagements',
    app_installs: 'Total Installs',
    leads: 'Total Leads',
    CPL: 'Cost per Lead',
    CPC: 'Cost per Click',
    CPI: 'Cost per Thousand (CPM)',
    CPV: 'Cost per View',
    CTR: 'Click Through Rate',
    PER: 'Post Engagement Rate',
    frequency: 'Frequency',
    messaging_conversation_started : 'Messaging Conversation Started',
    cost_per_app_installs: 'Cost per App Installs',
  }

export const monitorDataKeysTitleMapName = {
    'Total Spends': 'spend',
    'Total Revenue': 'revenue',
    'Total Impressions': 'impressions',
    'Purchases': 'purchases',
    'Total Clicks': 'clicks',
    'Total Views': 'video_starts',
    'Total Video Views': 'video_views',
    'Total Engagements': 'engagements',
    'Total Installs': 'app_installs',
    'Total Leads': 'leads',
    'Cost per Lead': 'CPL',
    'Cost per Click': 'CPC',
    'Cost per Thousand (CPM)': 'CPI',
    'Cost per View': 'CPV',
    'Click Through Rate': 'CTR',
    'Post Engagement Rate': 'PER',
    'Frequency': 'frequency',
    'Messaging Conversation Started': 'messaging_conversation_started',
    'Cost per App Installs': 'cost_per_app_installs',
  }

export const currencyDataKeys = ['spend', 'spends', 'CPL', 'CPC', 'CPI', 'CPV', 'CPR', 'CPM', 'revenue']
export const percentDataKeys = ['CTR', 'PER']

// export const 

export const fetchMonitorData = {
    'aggregate': monitorDataKeys.map(ele => ({x: ele, y: 'start_date', groupType: 'campaign'})),
    'age_group': monitorDataKeys.map(ele => ({x: ele, y: 'age_group', groupType: 'age_group'})),
    'gender': monitorDataKeys.map(ele => ({x: ele, y: 'gender', groupType: 'gender'})),
    'region': monitorDataKeys.map(ele => ({x: ele, y: 'region', groupType: 'region'})),
    'impression_device': monitorDataKeys.map(ele => ({x: ele, y: 'impression_device', groupType: 'impression_device'})),
    'device_platform': monitorDataKeys.map(ele => ({x: ele, y: 'device_platform', groupType: 'impression_device'})),
    'publisher_platform': monitorDataKeys.map(ele => ({x: ele, y: 'publisher_platform', groupType: 'impression_device'})),
    'placement_name': monitorDataKeys.map(ele => ({x: ele, y: 'placement_name', groupType: 'impression_device'})),
}

export const campaignObjectives = {
    'Ad Labels': 'ADLABELS',
    'Bid Stratergy': 'BID_STRATEGY',
    'Budget Rebalance Flag': 'BUDGET_REBALANCE_FLAG',
    'Buying Type': 'BUYING_TYPE',
    'Daily Budget': 'DAILY_BUDGET',
    'Execution Options': 'EXECUTION_OPTIONS',
    'Iterative Split Test Configs': 'ITERATIVE_SPLIT_TEST_CONFIGS',
    'Lifetime Budget': 'LIFETIME_BUDGET',
    'Name': 'NAME',
    'Objective': 'OBJECTIVE',
    'Facing Type': 'PACING_TYPE',
    'Promoted Object': 'PROMOTED_OBJECT',
    'Source Camapign ID': 'SOURCE_CAMPAIGN_ID',
    'Special Ad Category': 'SPECIAL_AD_CATEGORY',
    'Spend CAP': 'SPEND_CAP',
    'Status': 'STATUS',
    'Topline ID': 'TOPLINE_ID',
    'Upstream Events': 'UPSTREAM_EVENTS',
}

export const campaign_status = {
    'Active' : "active",
    'Archived' : "archived",
    'Deleted' : "deleted",
    'Paused' : "paused",
}

export const campaign_objective = {
    'App Installs' : "app_installs",
    'Brand Awareness' : "brand_awareness",
    'Conversions' : "conversions",
    'Event Responses' : "event_responses",
    'Lead Generation' : "lead_generation",
    'Link Clicks' : "link_clicks",
    'Local Awareness' : "local_awareness",
    'Messages' : "messages",
    'Offer Claims' : "offer_claims",
    'Page Likes' : "page_likes",
    'Post Engagement' : "post_engagement",
    'Product Catalog Sales' : "product_catalog_sales",
    'Reach' : "reach",
    'Store Visits' : "store_visits",
    'Video Views' : "video_views"
}

export const campaign_bidStrategy = {
    'Cost Cap' : "cost_cap",
    'Lowest Cost Without Cap' : "lowest_cost_without_cap",
    'Lowest Cost With Bid Cap' : "lowest_cost_with_bid_cap"
}

export const campaign_executionOptions = {
    'Inlcude Recommendations' : "include_recommendations",
    'Validate Only' : "validate_only",
}

export const campaign_specialAdCategory = {
    'Credit' : "credit1",
    'Employment' : "employment",
    'Housing' : "housing",
    'Issues Elections Politics' : "issues_elections_politics",
    // 'none' : "none",
    'Online Gambling and Gaming' : "online_gambling_and_gaming"
}

export const campaign_specialAdCategory_api = {
    'Credit' : "credit1",
    'Employment' : "employment",
    'Housing' : "housing",
    'Issues Elections Politics' : "issues_elections_politics",
    'none' : "none",
    'Online Gambling and Gaming' : "online_gambling_and_gaming"
}

export const breakdowns = {
    "Age": "age_group",
    "Gender":"gender",
    "Device Platform":"device_platform",
    "Impression Device":"impression_device",
    "Publisher Platform-Impression device":"publisher_platform_impression_device",
    "Publisher Platform Platform Position":"publisher_platform_platform_position",
    "Region":"region",
    "Campaign":"campaign"
}

export const summarizedCards = [
    { title: 'Total Spends', value: "spend", difference: '9.8', type: 'currency' },
    { title: 'Total Revenue', value: "revenue", difference: '0', type: 'currency' },
    { title: 'Total Impressions', value: "impressions", difference: '-9.8', type: 'value' },
    // { title: 'Total Purchases', value: "total_purchases", difference: '-9.8', type: 'value' },
    { title: 'Purchases', value: "purchases", difference: '-9.8', type: 'value' },
    { title: 'Total Clicks', value: "clicks", difference: '-9.8', type: 'value' },
    // { title: 'Total Reach', value: "REACH", difference: '-9.8', type: 'value' },
    { title: 'Total Views', value: "video_starts", difference: '9.8', type: 'value' },
    { title: 'Total Video Views', value: "video_views", difference: '9.8', type: 'value' },
    { title: 'Total Engagements', value: "engagements", difference: '9.8', type: 'value' },
    // { title: 'Post Engagement', value: "actions_post_engagement as post enagagement", difference: '9.8', type: 'value' },
    // { title: 'Total Conversions', value: "conversions", difference: '9.8', type: 'value' },
    { title: 'Total Installs', value: "app_installs", difference: '9.8', type: 'value' },
    { title: 'Total Leads', value: "leads", difference: '9.8', type: 'value' },
    { title: 'Cost per Lead', value: "CPL", difference: '-9.8', type: 'currency' },
    { title: 'Cost per Click', value: "CPC", difference: '9.8', type: 'currency' },
    { title: 'Cost per Thousand (CPM)', value: "CPI", difference: '9.8', type: 'currency' },
    { title: 'Cost per View', value: "CPV", difference: '9.8', type: 'currency' },
    // { title: 'Cost per Conversion', value: "CPConv", difference: '9.8', type: 'currency' },
    { title: 'Click Through Rate', value: "CTR", difference: '9.8', type: 'percentage' },
    { title: 'Post Engagement Rate', value: "PER", difference: '9.8', type: 'percentage' },
    { title: 'Frequency', value: "frequency", difference: '9.8', type: 'value' },
    { title: 'Messaging Conversation Started', value: "messaging_conversation_started", difference: '9.8', type: 'value' },
    { title: 'Cost per App Installs', value: "cost_per_app_installs", difference: '9.8', type: 'currency' },
]

export const group_dict = {
    'region': 0,
    'age_group': 1,
    'campaign_name': 2,
    'adset_name': 3,
    'gender': 4,
    'publisher_platform': 5,
    'placement_name': 6,
    'impression_device': 7,
    'device_platform': 8,
    'start_date': 9,
    'ad_name': 10,
    'marketing_channel': 11,
    'objective': 12,
}

export const GroupTypes_dict = {
    "account": 0,
    "adgroup": 1,
    "ads": 2,
    "age_group": 3,
    "campaign": 4,
    "gender": 5,
    "keyword": 6,
    "placement_name": 7,
    "publisher_platform_platform_position": 8,
    "region": 9,
    "search": 10,
    "shopping": 11,
    "video": 12,
    "impression_device": 13,
    "device_platform": 14,
}

export const platform_dict = {
    "Facebook": 0,
    "Google Ads": 1,
    "LinkedIn Ads": 2,
}

export const platform_logos = {
    "Facebook": FacebookLogo,
    "Google Ads": GoogleLogo,
    "LinkedIn Ads": LinkedinLogo,
}

export const Metrics_dict = {
    'spend': 0,
    'revenue': 1,
    'CPL': 2,
    'CPC': 3,
    'CPI': 4,
    'CPV': 5,
    'impressions': 6,
    'purchases': 7,
    'clicks': 8,
    'REACH': 9,
    'views': 10,
    'installs': 11,
    'leads': 12,
    //"": 13,
    "conversions": 14,
    "CPconv": 15,
    "CTR": 16,
    "PER": 17,
    "actions_video_view": 18,
    "actions_post_engagement as post enagagement": 19,
    'totalviews': 20,
    "frequency": 21,
    "messaging_conversation_started": 22
}

export const chartTypeToValueMap = {
    'Time Series': 'time-series',
    'Bar': 'bar',
    'Line': 'line',
    'Parallel Co-ordinate': 'parallel-coordinate',
    'Pie': 'pie'
}

export const valueToChartTypeMap = {
    'time-series': 'Time Series',
    'bar': 'Bar',
    'line': 'Line',
    'parallel-coordinate': 'Parallel Co-ordinate',
    'pie': 'Pie',
}