import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import sherlockAxios from "../../../../axiosSetup/sherlockaxios.service"
import mobilityAxiosReport from "../../../../axiosSetup/mobilityaxiosReport.service"

import * as actions from '../../../../redux/actions'
import { formatDateShort, generateUuid, getFromSessionStorage, sortObjects } from "../../../../common/functions"
import { ReactGaError, ReactGaSuccess } from "../../../../common/reactGA"

const useOptimizeService = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const stateOptimize = useSelector(state => state.optimizeReducer)
    const stateSettingsData = useSelector(state => state.settingsDataReducer)

    const generateTreeStructure = (array) => {
        const result = []

        for (let i of array) {
            let tempResult = {
                id: generateUuid(),
                value: i.value ? i.value : "No Name",
                name: i.title ? i.title : "No Name",
                display: i.title,
                objective: i.objective ? i.objective : 'campaign',
                title: i.title ? i.title : "No Name",
                isLeaf: false,
                children: [],
                type: 'campaign'
            }
            if (i.children) {
                for (let j of i.children) {
                    const adResult = []
                    if (j.children) {
                        for (let k of j.children) {
                            adResult.push({
                                ...k,
                                id: generateUuid(),
                                name: k.title ? k.title : "No Name",
                                type: 'ad',
                                campaign: i.value ? i.value : "No Name",
                                adset: j.value ? j.value : "No Name"
                            })
                        }
                    }
                    tempResult['children'].push({
                        ...j,
                        id: generateUuid(),
                        name: j.title ? j.title : "No Name",
                        children: adResult,
                        type: 'adset',
                        campaign: i.value ? i.value : "No Name",
                    })
                }
            }
            result.push(tempResult)
        }
        
        return result
    }

    const createCombinationsReport = async (dateRange) => {
        return new Promise(async (resolve, reject) => {
            let tempData = {
                'account_name': stateSettingsData.social?.account_name ?? 'none',
                'sdate': formatDateShort(dateRange[0]) ?? 'none',
                'edate': formatDateShort(dateRange[1]) ?? 'none',
                'k': 5
            }
            // await mobilityAxiosReport.post(`/recommendations/recommendation/${getFromSessionStorage('rid')}`, tempData, {'Content-Type': 'application/json'})
            // await mobilityAxiosReport.post(`/recommendations/recommendation`, tempData, {'Content-Type': 'application/json'})
            await mobilityAxiosReport.post(`/recommendations/`, tempData, {'Content-Type': 'application/json'})
                .then(res => {
                    ReactGaSuccess('Fetch Recommendations data', 'Fetched recommendation data Successfully')
                    resolve(res.data)
                })
                .catch(err => { 
                    ReactGaError(`Failed to Generate Recommendations data: ${err}`)
                    reject(err)
                })
        })
    }
    
    return useMemo(() => ({
        createCombinationsReport
    }), [stateOptimize])
}

export default useOptimizeService