import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import '../node_modules/primeflex/primeflex.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store'
import { Auth0Provider } from "@auth0/auth0-react"
import { StylesProvider } from '@material-ui/core/styles'
import { PrimeReactProvider } from 'primereact/api';

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/loginconfirmed`}
    >
      <StylesProvider injectFirst>
        <PrimeReactProvider>
        <App />
        </PrimeReactProvider>
      </StylesProvider>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()