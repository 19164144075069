import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useExploreReportService from "../Service/fetchReportsService";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { CustomPrimaryBasicButton } from "../../../../customMui/customMuiStyles";
import { customToastSystem } from "../../../../common/customToastify";
import { getErrorMessageFromResponse } from "../../../../common/functions";
import useExploreService from "../Service/useExploreService";
import Loading from "../../../../common/loading";
import { useHoverActiveStyles } from "../../../../static/hoverActiveStyles";
import Header from "../../../Header/header";
import { useStyles as layoutStyles } from '../../../../static/layoutStyles'

const ReportVisitationSummary = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const stateExplore = useSelector(state => state.exploreReducer)
  const { getAggregatedVisitationData } = useExploreReportService()
  const reportId = useParams().activeDashboard
  const navigate = useNavigate()
  const { getPoiSname } = useExploreService()
  const [loading, setLoading] = useState(true)
  const classesHoverActive = useHoverActiveStyles()
  const { uploadAudience } = useExploreReportService()
  const classesLayout = layoutStyles()





  const uploadAudienceFunc = async () => {
    setLoadingBtn(true)
    await uploadAudience(reportId)
      .then(res => customToastSystem('Uploaded Audience', 'success'))
      .catch(err => customToastSystem(getErrorMessageFromResponse(err, 'Failed To Upload Audience'), 'error'))
    setLoadingBtn(false)
  }

  useEffect(async () => {
    await getAggregatedVisitationData(reportId)
    setLoading(false)
  }, [reportId])

  const VisitationCountTable = useMemo(() => {
    let data = stateExplore.aggregatedVisitationData
    let columns = [{
      field: "district",
      headerName: "District",
      type: "string",
      width: 200,
      resizable: true,
      renderHeader: (params) => <strong>{"District"}</strong>
    }
    ]

    let uniqueSnames = new Set();

    let row = {}
    let id = 0

    // Loop through the original array
    data.forEach((item) => {

      if (!uniqueSnames.has(item.sname)) {
        uniqueSnames.add(item.sname);
        columns.push({
          field: item.sname,
          headerName: item.sname,
          type: "string",
          width: 200,
          resizable: true,
          renderHeader: (params) => <strong>{item.sname}</strong>
        })
      }

      const { district, sname, total_visits } = item;

      // Create a new object for the district if it doesn't exist
      if (!row[district]) {
        row[district] = { id: id, district };
        id += 1;
      }

      // Add the sname and total_visits to the district object
      row[district][sname] = total_visits;
    });

    row = Object.values(row)

    return (
      <div className={classesLayout.contentFilter}>
        <div className={classesLayout.toolbar} />
        <div className={classesLayout.main}>
          <DataGrid
            columns={columns}
            rows={row ?? []}
            autoHeight={true}
            disableSelectionOnClick
            componentsProps={{ panel: { disablePortal: true } }}
            style={{ backgroundColor: 'white', padding: '1em', height: '80vh' }}
            pageSize={10}
          />
        </div>
      </div>
    )
  }, [stateExplore.aggregatedVisitationData])


  if (loading) {
    return <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ padding: '5vw', height: '100vh' }}
      className={`${classesHoverActive.wrapParent} ${classesHoverActive.foreground} ${classesHoverActive.bottomLow} ${classesHoverActive.borderRadius}`}
    >
      <Loading />
    </Grid>
  }

  return (<>
    <Header />
    {VisitationCountTable}
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <CustomPrimaryBasicButton onClick={() => navigate(`/explore/${reportId}`)}>
        Back
      </CustomPrimaryBasicButton>
      <CustomPrimaryBasicButton onClick={uploadAudienceFunc}>
        {loadingBtn ? <CircularProgress className={`${classesHoverActive.primaryIcon}`} size={18} /> : "Upload Audience"}
      </CustomPrimaryBasicButton>
    </div>
  </>)

}

export default ReportVisitationSummary