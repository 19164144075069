import { Grid } from '@material-ui/core'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { ReactComponent as H3 } from '../assets/images/H3.svg'

export const CustomToastify = () => {
    return (
        <div>
            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                theme={'colored'}
                rtl={false}
                draggable
                pauseOnHover
                closeOnClick
                pauseOnFocusLoss
                limit={5}
            />
        </div>
    )
}

export const toastContent = (message, showButton, classes, onClick) => {
    return (
        <Grid container spacing={1} className={`${classes.toastInteractive}`} onClick={onClick}>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {/* <>
                        <H3 />
                    </>
                    &nbsp;&nbsp; */}
                    <>
                        <span className={classes.Paragraph4}>{message[0]}</span>
                    </>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.Paragraph3}`}>
                {message[1]}
            </Grid>
            {
                showButton &&
                <Grid item xs={12} className={`${classes.toastButton} ${classes.Paragraph5}`}>
                    Review &gt;
                </Grid>
            }
        </Grid>
    )
}

export const toastInterative = (message, background, showButton, classes, onClick, position) => {
    toast(() => toastContent(message, showButton, classes, onClick), {
        position: position,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        pauseOnFocusLoss: true,
        style: {background, borderRadius: '10px'},
    })
}

export const customToast = (message, type) => {
    toast(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: type,
    })
}

export const customToastSystem = (message, type) => {
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: type,
    })
}