import * as actions from "../actions/actionTypes"

const initialState = {
    theme: 'light',
    palette: 'infinite',
}

const layoutReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.CHANGE_THEME:
            return {
                ...state,
                theme: state.theme === 'light' ? 'dark' : 'light',
            }
        case actions.CHANGE_PALETTE:
            return {
                ...state,
                palette: action.payload,
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default layoutReducer