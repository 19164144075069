import * as actions from "../actions/actionTypes"

const initialState = {
    html: 'none',
    networkGraphLoading: false,
    brandOptions: [],
    brandTypeOptions: [],
    typeOptions: []
}

const homeReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.UPDATE_NETWORK_GRAPH_HTML:
            return {
                ...state,
                html: action.payload
            }
        case actions.UPDATE_NETWORK_GRAPH_LOADING:
            return {
                ...state,
                networkGraphLoading: action.payload
            }
        case actions.UPDATE_NETWORK_GRAPH_BRAND_OPTIONS:
            return {
                ...state,
                brandOptions: action.payload
            }
        case actions.UPDATE_NETWORK_GRAPH_BRAND_TYPE_OPTIONS:
            return {
                ...state,
                brandTypeOptions: action.payload
            }
        case actions.UPDATE_NETWORK_GRAPH_TYPE_OPTIONS:
            return {
                ...state,
                typeOptions: action.payload
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default homeReducer