import axios from 'axios'
import { getfromLocalStorage, getFromSessionStorage, puttoLocalStorage } from '../common/functions'
import { getUserDetails } from '../Website/LoginRegister/services/login.service'
import authAxios from './authaxios.service'
import authpwlessaxios from './authpwlessaxios.service'

let host = process.env.REACT_APP_MOBILITY_REPORT_URL
let host2 = process.env.REACT_APP_MOBILITY_REPORT_URL_V2
const mobilityAxiosReport = axios.create({ baseURL: host })
export const mobilityAxiosReportV2 = axios.create({ baseURL: host2 })
const listOfRequests = [mobilityAxiosReport, mobilityAxiosReportV2]

const getToken = () => {
    return new Promise(async (resolve, reject) => {

        const temp_token = getfromLocalStorage("userJwtReport")
        if (temp_token)
            resolve(temp_token)
        else {
            await getUserDetails()
                .then(async res => {
                    let bodyFormData = new FormData()
                    bodyFormData.append("username", res.email)
                    bodyFormData.append("password", "secretPasswordHash")
                    authpwlessaxios.post("/login/access-token/" + getFromSessionStorage('rid'), bodyFormData)
                        .then(resp => {
                            puttoLocalStorage("userJwtReport", resp.data.access_token)
                            resolve(resp.data.access_token)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(_err => reject('Incorrect Email'))

        }
    })
}
// Add a request interceptor
listOfRequests.forEach(request => request.interceptors.request.use(
    async config => {
        let token = ""
        await getToken()
            .then(res => {
                // console.log(`getToken called ${res}`)
                token = res
            })
            .catch(err => {
                console.log(err)
            })
        // console.log(token)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token.replace(/^"(.+(?="$))"$/, '$1')}`
        }
        if (config.headers['Content-Type'] !== 'multipart/form-data')
            config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    }))

export default mobilityAxiosReport