import * as actions from "../actions/actionTypes"
import { exploreLoading } from "../../common/loadingContent"

const initialState = {
    loading: null,
    info: exploreLoading()
}

const exploreDownloadProgressReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.UPDATE_EXPLORE_DOWNLOAD_PROGRESS:
            return {
                ...state,
                loading: action.payload
            }
        case actions.UPDATE_LOADING_TEXT:
            return {
                ...state,
                info: exploreLoading()
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default exploreDownloadProgressReducer