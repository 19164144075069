import * as actions from "../actions/actionTypes"

const initialState = {
    rightSidePanel: false,
    leftSidePanel: true,
    leftDrawerMobile: false
}

const leftSidepanelReducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.LEFT_DRAWER_MOBILE_TOGGLE:
            return {
                ...state,
                leftDrawerMobile: !state.leftDrawerMobile,
            }
        case actions.LEFT_SIDEPANEL_TOGGLE:
            return {
                ...state,
                leftSidePanel: !state.leftSidePanel,
            }
        case actions.LOGOUT:
            return initialState
        default:
            return state
    }
}

export default leftSidepanelReducer