import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import KeplerGlSchema from 'kepler.gl/schemas'

import sherlockAxios, { sherlockAxiosV2 } from '../../../../axiosSetup/sherlockaxios.service'

import * as actions from '../../../../redux/actions'
import { formatDateShort, getErrorMessageFromResponse, getFromSessionStorage, getKeyFromValue } from '../../../../common/functions'
import { customToast, customToastSystem } from '../../../../common/customToastify'
import { ReactGaError, ReactGaSuccess } from '../../../../common/reactGA'

import { ReactComponent as DashboardIcon } from '../../../../assets/icons/New.svg'
import sherlockTestAxios from '../../../../axiosSetup/sherlocktest.service'

const useBentoboxService = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const activeDashboard = useParams().activeDashboard

    const currentLocation = location.pathname.match(/^\/[^\/]+/)[0]
    const stateKepler = useSelector(state => state.keplerGl)
    const stateExplore = useSelector(state => state.exploreReducer)
    const stateBentobox = useSelector(state => state.bentoBoxReducer)

    const handleReportError = (val, multi = false) => {
        if (!multi) {
            dispatch(actions.handleUpdateExploreReportError(true))
            navigate(val.parentPath)
        }
        return true
    }

    const checkDataFields = (data, key) => {
        if (key === 'poiCodeCongregation') {
            let tempData = data.map(ele => {
                let temp = {
                    ...ele,
                    ...ele[key] ?? {}
                }
                delete temp['poiCodeCongregation']
                return temp
            })
            return tempData
        } else {
            const getObjectFromArray = (val) =>
                val[key].map(el => {
                    let temp = Object.assign({}, val, el)
                    delete temp[key]
                    delete temp['poiCodeMobilityRDB']
                    return temp
                })
            return (
                [].concat(...data.map(ele => {
                    if (ele[key].length > 0) {
                        return getObjectFromArray(ele)
                    } else {
                        return ele
                    }
                }))
            )
        }
    }

    const dataFields = (report, data) => {
        if (report === 'Mobility') {
            if (data.length > 0) {
                if (data[0].poiCodeMobilityR) {
                    return checkDataFields(data, 'poiCodeMobilityR')
                } else if (data[0].poiCodeCenus) {
                    return checkDataFields(data, 'poiCodeCenus')
                }
            }
            return data
        } else if (report === 'Census') {
            return checkDataFields(data, 'poiCodeCenus')
        } else if (report === 'Economic') {
            return checkDataFields(data, 'poiCodeSocio')
        } else if (report === 'CovidIndexes') {
            return checkDataFields(data, 'poiCodecovidIndex')
        } else if (report === 'deviceCount') {
            return checkDataFields(data, 'poiDeviceReport')
        } else if (report === 'Congregation') {
            return checkDataFields(data, 'poiCodeCongregation')
        } else if (report === 'prosperityIndexes') {
            return checkDataFields(data, 'poiCustomReportsprosperityIndexes')
        } else if (report === 'CovidInfectionreports') {
            return checkDataFields(data, 'poiCustomReportsCovidInfectionreports')
        } else if (report === 'CovidVaccinationReports') {
            return checkDataFields(data, 'poiCustomReportsCovidVaccinationReports')
        } else if (report === 'districtMigrationHAs') {
            return checkDataFields(data, 'poiCustomReportsdistrictMigrationHAs')
        } else if (report === 'HealthCareReports') {
            return checkDataFields(data, 'poiCodeHealth')
        } else if (report === 'pois' || report === 'HealthCareReports') {
            return data
        }
    }

    const handleGetDownloadProgress = (progressEvent) => dispatch(actions.handleUpdateExploreDownloadProgress(((progressEvent?.loaded / progressEvent?.total ?? 0) * 100).toFixed(2)))

    const getCurrentMapConfig = () => {
        if (currentLocation.includes('explore') && stateExplore.selected !== 'none') {
            let tempConfig = {}
            if (stateKepler.exploration) {
                tempConfig = KeplerGlSchema.getConfigToSave(stateKepler.exploration)?.config
                // console.log(tempConfig)
                // console.log(tempConfig.visState, stateExplore.reportData[stateExplore.selected])
                tempConfig && dispatch(actions.handleUpdateExploreSaveConfig({ id: stateExplore.selected, config: tempConfig }))
            }
        }
    }

    const getColumns = (report_type) => {
        return new Promise(async (resolve, reject) => {
            const params = {
                report_type,
                rid: getFromSessionStorage('rid')
            }
            await sherlockAxios.get(`/mobility/get_table_metadata/`, { params })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    // chart requests
    const createChart = (data) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.post(`/chart_box/create/${getFromSessionStorage('rid')}`, data)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    const getChart = (chart_box_id) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.get(`/chart_box/get_chart_box_by_id/${chart_box_id}/${getFromSessionStorage('rid')}`)
                .then(res => {
                    resolve(JSON.parse(res.data.data))
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    // bento requests
    const createBento = (data) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.post(`/bento_box/create/${getFromSessionStorage('rid')}`, data)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    const getBento = (bento_box_id) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.get(`/bento_box/get_bento_box_by_id/${bento_box_id}/${getFromSessionStorage('rid')}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    const getBentoBasedOnReportId = (report_id) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.get(`/bento_box/get_bento_by_report_id/${report_id}/${getFromSessionStorage('rid')}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    // get all bento
    const getAllBento = (type = 0) => {
        return new Promise(async (resolve, reject) => {
            await sherlockAxios.get(`/bento_box/${type}/${getFromSessionStorage('rid')}`)
                .then(res => {
                    resolve(res.data.map(ele => ({...ele, type: 'bento', color: 'primary', path: ele?.id })).toReversed())
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    // if useBentoboxService re-renders, it will make all the functions it is returning using useMemo to re-run as we haven't passed the dependency array on which value change you want to re-run all this functions, another better approach would be to returned this functions as individual memoized versions
    return useMemo(() => ({
        getColumns,
        createChart,
        getChart,
        createBento,
        getBento,
        getAllBento,
        getBentoBasedOnReportId,
    }), [])
}

export default useBentoboxService