import React, { useEffect, useState } from 'react'
import { Divider, Grid } from '@material-ui/core'

import { ReactComponent as AddIcon } from '../../../../assets/icons/Add.svg'

import { useStyles } from './styles'
import { useStyles as contentStyles } from '../../styles'
import { useHoverActiveStyles } from '../../../../static/hoverActiveStyles'
import { TypographySemiBold } from '../../../../customMui/customMuiStyles'
import { checkScrollVisible } from '../../../../common/functions'

import useExploreRightSidepanelConfig from './useExploreRightSidepanelConfig'

const ExploreRightSidepanel = () => {
    const classes = useStyles()
    const classesContent = contentStyles()
    const classesHoverActive = useHoverActiveStyles()
    const { sidepanelHeader, sidepanelContent } = useExploreRightSidepanelConfig()
    
    const [contentHasScroll, setContentHasScroll] = useState(false)

    const { header, content} = sidepanelContent()

    useEffect(() => setContentHasScroll(checkScrollVisible('exploreSidepanelContentScrollArea')), [content])

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className={`${classesContent.sidepanelContentArea} ${classesContent.sidepanelPadding} ${classesHoverActive.wrapParent}`}
        >
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems="center"
                >
                    <TypographySemiBold variant="body1">{header.header}</TypographySemiBold>
                    {header.icon}
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.flexHeader}`}>
                <Divider className={`${classes.sidepanelDivider}`} />
            </Grid>
            <Grid item xs={12} className={`${classes.flexContent} ${classesHoverActive.overflowY} ${contentHasScroll ? classesHoverActive.addScrollMargin : undefined}`} id="exploreSidepanelContentScrollArea">
                {content}
            </Grid>
        </Grid>
    )
}

export default ExploreRightSidepanel