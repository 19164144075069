import { makeStyles } from "@material-ui/core"
import { getLightDarkGrayscaleColor } from "../../common/functions"

export const useStyles = makeStyles((theme) => ({
    headerBorder: {
        borderBottom: `4px solid`,
    },
    backdrop: {
        width: '100vw',
        height: '100vh',
        zIndex: `${theme.zIndex.appBar + 1}`,
        backgroundColor: 'transparent'
    },
    popper: {
        zIndex: `${theme.zIndex.appBar + 2}`,
    },
    profileMenu: {
        marginTop: `${theme.spacing(1.5)}px`,
        backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
        minWidth: theme.spacing(28),
    },
    profileMenuItem: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        cursor: 'pointer'
    },
    profileMenuItemLogout: {
        // backgroundColor: theme.palette.error.main,
        // color: theme.palette.primary.contrastText
    },
    popperArrow: {
        position: "relative",
        zIndex: -1,
        marginTop: "16px",
        "&:before": {
            backgroundColor: getLightDarkGrayscaleColor(theme, '100', '80'),
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            right: "10%"
        }
    },
    svgMarginRight: {
        marginRight: theme.spacing(1)
    },
}))